<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <ValidationObserver ref="HospitalDetailForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">
      <div class="layout-column flex ma-2 elevation-5"
           style="border-radius:4px;position:relative;"
           v-bind:style="{background: compColor.contentFrame}">

        <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1" :class="hoverHeader">
          <div class="layout-row">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="getData()"
                    icon="refresh"
                    :hide-label="true"
                    tooltip="Ansicht aktualisieren"
                    :label="'Aktualisieren'"></lx-btn>
          </div>
          <div class="flex layout-row"></div>
        </div>

        <v-divider v-if="!hoverHeader" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

        <div ref="scrollContainer" style="overflow-y: auto;" class="flex pa-1 layout-column">
          <lx-expansion-panels :single-open="false" v-model="panel">
            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-file-tree"
                                         main-headline="Downloads"
                                         sub-headline="Stellen Sie ihren Nutzern und Kunden Dateien zur Verfügung.">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-1">
                  <div class="flex-100">
                    <downloads :showEditor="true" :showDialog="showPopup" ref="childComponent"></downloads>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
          </lx-expansion-panels>
        </div>
      </div>
    </ValidationObserver>

    <v-dialog v-model="deletionDialog" persistent width="20%" overlay-opacity="0.9">
      <v-card class="flex" :dark="compColor.isInDarkMode"
              v-bind:class="[compColor.contentFrameBack]">
        <v-card-title class="title elevation-4 layout-row pa-0">
          <div class="pa-1 flex" v-bind:class="[compColor.contentFrameBack]"
               style="z-index: 2;border-radius:4px 4px 0 0;">
            <div class="py-1 px-1 layout-row layout-align-start-center"
                 v-bind:class="[compColor.contentFrameFront,compColor.widget.headline]">
              <div class="layout-column layout-align-center-center pr-3">
                <v-avatar :color="compColor.avatarTile" tile height="40"
                          width="40"
                          style="min-width:40px;">
                  <v-icon :color="compColor.primeColor">delete_forever
                  </v-icon>
                </v-avatar>
              </div>
              Löschen bestätigen
            </div>
          </div>
        </v-card-title>
        <v-divider v-bind:class="compColor.textInput"
                   v-if="compColor.isInDarkMode"></v-divider>
        <v-card-text class="pa-5">
          Möchten Sie die ausgewählte Datei wirklich löschen? Eine Wiederherstellung
          ist dann nicht möglich.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <div class="layout-row" style="width:100%;">
            <!--                    <v-spacer></v-spacer>-->
            <v-btn :dark="compColor.isInDarkMode"
                   class="white--text text-none mx-1 flex"
                   :color="compColor.primeColor" @click="deleteItem">
              ja
            </v-btn>
            <v-btn :dark="compColor.isInDarkMode"
                   class="white--text text-none mx-1 flex"
                   :color="compColor.primeColor" @click="deletionDialog = null">
              nein
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <lx-dialog v-model="modal" :allow-drag="true" :overlay="true" :size="{x: '70%', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          <span v-if="options[method]">{{ options[method].title }}</span>
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-0 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <div class="py-4 layout-row layout-wrap">
            <!-- Template Edit -->
            <div class="flex-100" v-if="method === 'edit'">

              <div class="px-4">
                <div class="px-6">
                  <v-switch inset dense :dark="compColor.isInDarkMode"
                            :color="compColor.primeColor"
                            hide-details class="ma-0 mb-0"
                            :readonly="noLocationSelected"
                            v-model="noLocationSelected">
                    <template #label>
                      <div>Für alle sichtbar <small :class="compColor.primeClass">(kann nicht deaktiviert werden, dies
                        passiert automatisch bei Auswahl einer Medizinischen Einrichtung)</small></div>
                    </template>
                  </v-switch>

                </div>

                <v-divider class="mb-4" :dark="isDarkMode"></v-divider>

                <div class="layout-row layout-wrap flex px-3 pb-2">
                  <div v-for="(location, index) in locationList" :key="index" class="mx-3 flex-30">
                    <ValidationProvider :ref="'Nachrichtentext' + index" :name="'Nachrichtentext' + index"
                                        v-slot="{ errors }">

                      <div class="layout-row flex">
                        <div class="">
                          <v-switch inset dense :dark="compColor.isInDarkMode" :color="compColor.primeColor"
                                    hide-details class="ma-0 mb-0"
                                    v-model="model.locations"
                                    :label="location.name"
                                    :value="location._id"
                                    :value-comparator="comparator">

                            <!--                          v-model="item.is_selected"-->
                          </v-switch>
                        </div>
                      </div>

                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>

            <!-- Template Upload -->
            <div class="flex-100 layout-row pa-3" v-if="method === 'upload'">

              <div class="layout-row flex mr-2 pt-2"
                   @drop.prevent="addFilesByDragAndDrop" @dragover.prevent
                   style="height: 100%;width: 100%;">


                <div class="px-2 py-16 layout-column flex"
                     v-bind:class="{'blue-grey lighten-5' : !compColor.isInDarkMode,'blue-grey darken-3' : compColor.isInDarkMode}"
                     style="border:1px dashed #526b77 !important;border-radius:10px;">

                  <div class="flex layout-column layout-align-center-center px-7 pb-2"
                       style="position:relative;">
                    <!--                                                <v-icon size="60" :color="compColor.primeColor">mdi-paperclip</v-icon>-->
                    <!--                                                                    <v-icon size="120" :color="compColor.primeColor" style="position:absolute;top:50%;left:50%;margin-left:-60px;margin-top:-60px;opacity:0.1;">-->
                    <!--                                                                        cloud_upload-->
                    <!--                                                                    </v-icon>-->
                    <span class="subtitle-1 font-weight-light mb-4"
                          v-bind:class="{'blue-grey--text text--darken-2': !compColor.isInDarkMode, 'blue-grey--text text--lighten-4': compColor.isInDarkMode}"
                          style="text-align:center;">Ziehen Sie ihre Dateien aus ihrem Dateibrowser auf dieses Feld und lassen Sie sie los oder</span>
                    <!--                          <span class="title font-weight-black blue-grey&#45;&#45;text text&#45;&#45;lighten-3 mb-1">oder</span>-->

                    <v-btn @click="openHiddenFileInput()" depressed
                           :color="compColor.primeColor" dark
                           class="text-none">
                      Wählen Sie ihre Dateien aus
                    </v-btn>
                    <input type="file" hidden ref="hiddenFileInput"
                           @change="addFilesByDialog"/>

                  </div>
                </div>

              </div>

              <!--                  </fake-input-frame>-->
              <fake-input-frame label="Hochzuladene Dateien" class="ml-2 flex pa-2"
                                :input-background="isDarkMode ? 'transparent':'#f5f5f5'">

                <div v-for="(file, index) in files"
                     :key="index"
                     class="px-2 py-1"
                     :class="index < files.length - 1 ? 'mb-1' : ''"
                     :style="{'background': file.selected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
                     style="border-radius: 4px;">
                  <div class="layout-row">
                    <div class="pa-1 pr-2 layout-column layout-align-center-center">
                      <v-btn text icon @click="removeFile(file)"
                             color="blue-grey darken-2"
                             title="Entfernen">
                        <v-icon>delete_forever</v-icon>
                      </v-btn>
                    </div>
                    <v-divider vertical class="mr-2"></v-divider>
                    <div class="pa-1 pr-2 layout-column layout-align-center-center">

                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            icon
                            color="blue-grey darken-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-eye-check</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            style="max-height: 35px !important;min-height: 35px; !important;height: 35px !important;"
                            v-for="(item, index) in locationList"
                            :key="index"
                          >
                            <!--                                <v-list-item-title>-->
                            <div class="layout-row layout-align-start-center">
                              <!--                                    @click.stop="item.selected = !item.selected"-->
                              <v-checkbox :label="item.name"
                                          height="20"
                                          @click.stop="file.l.push(item)"
                                          hide-details
                                          :color="compColor.prime"
                                          class="mb-0 mr-3 mt-0">

                              </v-checkbox>
                            </div>
                            <!--                                </v-list-item-title>-->
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <v-divider vertical class="mr-2"></v-divider>
                    <div class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start">
                      <span style="line-height: 16px;"><strong>{{ file.f.name }}</strong></span>
                      <small style="opacity: 0.6;line-height: 15px;">{{ Math.round(file.f.size / 1000) }} kb</small>
                    </div>
                    <div class="layout-column layout-align-center-center mx-1 ml-2 mr-2" v-if="file.unsaved">
                      <div class="py-1 px-1 font-weight-bold amber"
                           style="font-size:9px;line-height: 14px;border-radius:3px;">
                        unsaved
                      </div>
                    </div>
                    <div class="layout-column layout-align-center-center ml-2">
                      <v-icon
                        :color="file.selected ? compColor.prime + ' lighten-3': (isDarkMode ? 'blue-grey darken-3' : 'blue-grey lighten-3')"
                        size="25">mdi-file
                      </v-icon>
                    </div>
                  </div>
                </div>

              </fake-input-frame>

            </div>

            <!-- Template Create -->
            <div class="flex-100 pa-4 pt-6" v-if="method === 'create'">
              <div>
                <v-text-field
                  :dark="compColor.isInDarkMode"
                  :color="compColor.primeColor"
                  label="Ordername"
                  placeholder=" "
                  autofocus
                  outlined
                  clearable
                  hide-details
                  v-model="model.name"
                  :dense="denseFields"
                ></v-text-field>
              </div>
            </div>

            <!-- Template Rename -->
            <div class="flex-100 pa-4" v-if="method === 'rename'">
              <div class="flex-100 pb-4">
                <v-text-field
                    :dark="compColor.isInDarkMode"
                    :color="compColor.primeColor"
                    label="Quellname"
                    placeholder=" "
                    autofocus
                    outlined
                    clearable
                    hide-details
                    disabled
                    v-model="model.source"
                    :dense="denseFields"
                ></v-text-field>
              </div>

              <div class="flex-100">
                <v-text-field
                    :dark="compColor.isInDarkMode"
                    :color="compColor.primeColor"
                    label="Zielname"
                    placeholder=" "
                    autofocus
                    outlined
                    clearable
                    hide-details
                    v-model="model.target"
                    :dense="denseFields"
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">

          <div class="flex mr-1">
            <lx-btn :color="'#353a47'"
                    block
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="files.length <= 0"
                    class="flex"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click.stop="upload"
                    icon="save"
                    v-if="method === 'upload'"
                    :tooltip="options[method].savebtn"
                    :label="options[method].savebtn"></lx-btn>

            <lx-btn :color="'#353a47'"
                    v-else
                    block
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="options[method].disabledFn()"
                    class="flex"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click.stop="savePopup"
                    icon="save"
                    :tooltip="options[method].savebtn"
                    :label="options[method].savebtn"></lx-btn>
          </div>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click.stop="closePopup"
                  icon="cancel"
                  tooltip="Vorgang abbrechen und das Popup schließen"
                  :label="'Abbrechen'"></lx-btn>

        </div>
      </template>
    </lx-dialog>

  </div>
</template>

<script>
import Downloads from '../../../components/lx_dashboard/components/widgets/Downloads'
import axios from 'axios'

export default {
  components: {
    Downloads
  },
  name: "DownloadsDetails",
  data() {
    return {
      hoverHeader: false,
      panel: [0],
      isUploading: false,
      model: {},
      modal: false,
      deletionDialog: false,
      method: null,
      options: {
        'create': { title: 'Ordner erstellen', savebtn: 'Erstellen', disabledFn: () => { return !this.model.name || this.model.name.length <= 0} },
        'upload': { title: 'Datei(en) hochladen', savebtn: 'Hochladen', disabledFn: () => { return !this.model.name || this.model.name.length <= 0} },
        'edit': { title: 'Dateiberechtigung bearbeiten', savebtn: 'Speichern', disabledFn: () => { return !this.model.name || this.model.name.length <= 0} },
        'rename': { title: 'Datei umbenennen', savebtn: 'Umbenennen', disabledFn: () => { return !this.model.target || this.model.source === this.model.target } },
      },
      files: [],
      payload: null,
      locationList: [],
    }
  },
  created() {
    this.$nextTick(() => {
      let scroller = this.$refs.scrollContainer
      scroller.addEventListener('scroll', () => {
        if (scroller.scrollTop > 0) {
          let number = scroller.scrollTop
          number = number > 4 ? 4 : number
          this.hoverHeader = 'elevation-' + number
        } else {
          this.hoverHeader = ''
        }
      })
    })
  },
  computed: {
    noLocationSelected: {
      get() {
        return this.model.locations.length <= 0
      },
      set(value) {
        if (value) {
          this.model.locations = []
        }
      }
    },
  },
  methods: {
    /**
     * Browser benutzen seit hundert Jahren einen Scheiss Datei Dialog aus Button und Input wenn man <input type="file"/> benutzte
     * da das hässlich ist versteckt man dieses Ding und aktiviert es durch den Click eines Buttons der per ID pder Refs darauf zugreift
     * **/
    openHiddenFileInput() {
      this.$refs.hiddenFileInput.click()
    },
    /**
     * wird bei onChange aufgerufen wenn im Dateidialog Dateien ausgewählt werden
     * diese werden an Methode addFiles übergeben wo sie zerlegt und aufbereitet werden
     * **/
    addFilesByDialog() {
      let file = this.$refs.hiddenFileInput;
      if (file && file.files) {
        this.addFiles(file.files)
      }
    },
    /**
     * wird bei drop Event aufgerufen wenn im Drag and Drop Bereich Dateien fallengelassen wurden
     * diese werden an Methode addFiles übergeben wo sie zerlegt und aufbereitet werden
     * **/
    addFilesByDragAndDrop(e) {
      if (this.isUploading) return;

      this.addFiles(e.dataTransfer.files)
    },
    /**
     * zerlegt die Files Objekte des Browsers und push sie dann in das this.files Array welches die Tabelle füllt
     * **/
    addFiles(files) {
      if (!files) return;

      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      ([...files]).forEach(f => {
        this.files.push({ f: f, l: [] });
      });
    },
    removeFile(file) {
      this.files = this.files.filter(f => {
        return f !== file;
      });
    },
    upload() {
      this.isUploading = true;
      let formData = new FormData();
      let uploads = {};

      this.files.forEach((f, x) => {
        const name = 'file' + (x + 1).toString();

        formData.append(name, f.f);
        formData.append(name + '_path', this.payload || '');

        if (Array.isArray(f.l) && f.l.length > 0) {
          f.l.forEach((location) => {
            formData.append(name + '_locations', location._id.toString());
          })
        }

        uploads[name] = f;
      });

      axios.post('/document', formData, { headers: { 'content-type': 'multipart/form-data' } })
        .then(() => {
          this.files = [];
          this.closePopup();
          this.$refs.childComponent.getDocuments()
        })
        .finally(() => {
          this.isUploading = false;

          //reset file input um neue dateien hochladen zu können
          if (this.$refs['hiddenFileInput']) {
            this.$refs['hiddenFileInput'].value = ''
          }
        })
    },
    changeViewPermission(data) {
      this.closePopup();

      this.$http.post('/document/' + encodeURIComponent(data.prefix), data)
        .then((res) => {
          this.NotifySuccess(res);
          this.getData();
        })
        .catch(this.NotifyError)
    },
    savePopup() {
      let result = {};

      switch (this.method) {
        case 'edit':
          if (this.model.locations.length === 0) {
            let self = this
            this.DialogConfirm(
                'Hinweis',
                'Durch das abwählen von allen Medizinischen Einrichtungen, wird diese Datei für alle sichtbar.',
                { ok: 'Bestätigen', cancel: this.$t('buttons.cancel.title') })
                .then(() => {
                  this.changeViewPermission(this.model)
                })
                .catch(() => {
                  self.modal = true;
                });

            return
          }

          return this.changeViewPermission(this.model)

        case 'create':
          result.name = this.model.name;
          break

        case 'rename':
          let data = {
            source: [this.model.path || '', this.model.source].join('/'),
            target: [this.model.path || '', this.model.target].join('/'),
          }

          this.closePopup()
          this.model = {}

          this.$http.patch('/document/rename', data)
            .then(() => {
              this.getData()
            })
            .catch(this.NotifyError)

          return
      }

      this.closePopup();
      this.$refs.childComponent.createFolder(result, this.payload);
      this.model = {}
      this.files = []
    },
    closePopup() {
      this.modal = false;
      this.model = {}
      this.files = []
      if (this.$refs['hiddenFileInput']) {
        this.$refs['hiddenFileInput'].value = ''
      }
    },
    getData() {
      this.$refs.childComponent.getDocuments()
    },
    deleteItem() {
      this.deletionDialog = false;
      this.$refs.childComponent.deleteItem(this.payload)
    },
    showPopup(event, type, value) {
      event.preventDefault() // stop bubbling to top element
      event.stopPropagation() //stop full bubbling

      this.$http.get('/hospital')
        .then(res => {
          if (res.data && res.data.data) {
            this.locationList = res.data.data || []

            // Sort list
            this.locationList.sort((left, right) => {
              if (!left || !right || !left.name || !right.name) {
                return 0
              }

              return left.name.localeCompare(right.name, 'de', { sensitivity: 'base' });
            })
          }
        })
        .finally(() => {
          this.method = type

          switch (this.method) {
            case 'create':
              this.payload = value
              this.modal = true
              break

            case 'upload':
              this.payload = value.prefix
              this.modal = true
              break

            case 'edit':
              this.payload = value
              this.model = {
                name: value.name,
                prefix: value.prefix,
                locations: value.locations || []
              };
              this.modal = true;
              break

            case 'delete':
              this.payload = value
              this.deletionDialog = true
              break

            case 'rename':
              this.payload = value
              this.model = {
                source: value.name,
                target: value.name,
                path: value.prefix.split(/[//\\]+/).slice(1, -1).join('/')
              }

              this.modal = true
              break

            default:
              console.error(`unknown method ${type}`)
          }
        })
    },
    comparator(a, b) {
      return a === b;
    }
  }
}
</script>

<style scoped>

</style>
