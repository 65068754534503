function NumberFloat() {
    if (!(this instanceof NumberFloat)) {
        return new NumberFloat()
    }

    return {}
}

function NumberInt() {
    if (!(this instanceof NumberInt)) {
        return new NumberInt()
    }

    return {}
}

export {NumberInt, NumberFloat}