<script>
import Vue from 'vue'

export default Vue.component('lxPdfViewer', {
    props: {
      value: {
        type: Blob,
        default: () => {
          return []
        }
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        counter: 0,
        type: null,
        data: '',
        name: 'test',
      }
    },
    mounted () {
    },
    methods: {
      openCloseLxExpansionPanel (id) {
        if(this.singleOpen){
          let temp = this.value.map((x) => x);

          while (this.value.length) {
            this.value.pop();
          }
          if(temp.indexOf(id) < 0){
            this.value.push(id)
          }
        } else {
            if (this.value.indexOf(id) >= 0) {
              this.value.splice(this.value.indexOf(id), 1)
            } else {
              this.value.push(id)
            }
        }
        this.$emit('input', this.value)
      },
    },
    watch: {
      value(blob){
        let objectURL = URL.createObjectURL(blob)
        this.type = blob.type
        this.data = objectURL
      }
    },
    computed: {},
    render (h) {
      let object
      if(this.type){
        object = h('object', {
          staticClass: 'pdf-preview flex',
          attrs: {
            type: this.type,
            data: this.data,
            height: '100%',
            width: '100%"'
          }
        })
      } else {
        object = ""
      }

      return h(
          'div', {
            staticClass: 'flex layout-column',
            style: 'border-radius:6px;',
            id: 'superObject',
          }, [object]
      )
    }
  })
</script>

<style scoped lang="scss">

</style>
