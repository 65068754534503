<template>
  <div>
    <div class="layout-row layout-align-center-start">

      <v-text-field
        :name="name"
        :key="keyValue"
        class="flex"
        outlined
        append-icon="date_range"
        :color="compColor.textInput"
        :dark="compColor.isInDarkMode"
        :label="label"
        :placeholder="placeholder"
        :hint="hint"
        :persistent-hint="persistent_hint"
        ref="input"
        v-model="internalModel"
        @click:append.stop="openDialog"
        @change="$emit('change')"
        @blur="updateValue"
        :required="required"
        :hide-details="hideDetails"
        :readonly="disabled"
        :error-messages="getErrorMsg">
      </v-text-field>

      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <div id="square" style="max-width: 492px;width: 492px;min-width: 492px;min-height: 300px;"
               class="layout-column pa-0 white">
            <div class="flex-30 layout-row py-2 px-4 white--text text--darken-2 white"
                 v-bind:class="[compColor.textInput]" @mousedown="dragThatThing" @mouseup="leftThatThing"
                 title="Drag Window">
              <v-icon>drag_indicator</v-icon>

              <div class="flex layout-row layout-align-center-center" style="cursor: pointer;position:relative;">
                <span style="font-weight: 300;font-size: 25px;"
                      class="mr-1">{{ getPopupValues.today | formatDate('dddd') }},</span>
                <span style="font-weight: 900;font-size: 30px;"
                      class="ml-1">{{ getPopupValues.today | formatDate('DD. MMMM YYYY') }}</span>
              </div>
              <!--        <v-btn :ripple="false" large text icon color="blue-grey darken-2" @click.stop="dialogVisible=false">-->
              <v-icon>drag_indicator</v-icon>
              <!--        </v-btn>-->
            </div>
            <!--      <v-divider class="ma-0"></v-divider>-->
            <div class="layout-row layout-align-center-center pa-3">
              <div class="layout-column flex">
                <v-btn depressed block small color="grey lighten-2" class="blue-grey--text mb-1" @click="lastDay">
                  gestern
                </v-btn>
                <v-btn depressed block small color="grey lighten-2" class="blue-grey--text mb-1" @click="thisDay">
                  heute
                </v-btn>
                <v-btn depressed block small color="grey lighten-2" class="blue-grey--text mb-1" @click="nextDay">
                  morgen
                </v-btn>
                <!--          <v-btn depressed block small class="blue-grey&#45;&#45;text mb-1" @click="thisDay">heute</v-btn>-->
                <!--          <v-btn depressed block small class="blue-grey&#45;&#45;text mb-1" @click="nextDay">morgen</v-btn>-->
                <!--<v-btn depressed small color="teal" class="white--text" @click="nextDay">morgen</v-btn>-->
              </div>
              <div style="width:40px;">
                <v-divider vertical></v-divider>
              </div>
              <div class="layout-column flex">
                <div class="layout-row layout-align-center-center pb-1">
                  <div class="flex layout-row">
                    <v-btn depressed small color="grey lighten-2" @click="nextWeek(-1)" class="ma-0">
                      <v-icon class="blue-grey--text">chevron_left</v-icon>
                    </v-btn>
                    <span class="flex text-center px-2 blue-grey--text grey lighten-4"
                          style="line-height: 28px;">Woche</span>
                    <v-btn depressed small color="grey lighten-2" @click="nextWeek(1)" class="ma-0">
                      <v-icon class="blue-grey--text">chevron_right</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div class="layout-row layout-align-center-center pb-1">
                  <div class="flex layout-row">
                    <v-btn depressed small color="grey lighten-2" @click="nextMonth(-1)" class="ma-0">
                      <v-icon class="blue-grey--text">chevron_left</v-icon>
                    </v-btn>
                    <span class="flex text-center px-2 blue-grey--text grey lighten-4"
                          style="line-height: 28px;">Monat</span>
                    <v-btn depressed small color="grey lighten-2" @click="nextMonth(1)" class="ma-0">
                      <v-icon class="blue-grey--text">chevron_right</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div class="layout-row layout-align-center-center pb-1">
                  <div class="flex layout-row">
                    <v-btn depressed small color="grey lighten-2" @click="nextYear(-1)" class="ma-0">
                      <v-icon class="blue-grey--text">chevron_left</v-icon>
                    </v-btn>
                    <span class="flex text-center px-2 blue-grey--text grey lighten-4"
                          style="line-height: 28px;">Jahr</span>
                    <v-btn depressed small color="grey lighten-2" @click="nextYear(1)" class="ma-0">
                      <v-icon class="blue-grey--text">chevron_right</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <v-divider class="ma-0"></v-divider>
            <div style="height: 250px;" class="pa-2 flex layout-row blue-grey--text">
              <div class="layout-column" style="max-width: 58px;width: 58px;min-width: 58px;">
                <div class="layout-column layout-align-center-center px-1" @click="scrollList(-1)">
                  <v-icon>expand_less</v-icon>
                </div>
                <div class="flex layout-column" style="overflow: hidden;position:relative;">
                  <div style="width:75px;height:100%;position: absolute;overflow-y:scroll;" id="yearList">
                    <div class="linked" style="text-align: center;min-width: 55px;" v-bind:id="'year-id-' + item"
                         v-for="(item, index) in yearArray" :key="index" @click="selectYear(item)"
                         v-bind:class="{ 'pink--text active': index === getPopupValues.selectedYear - 1900 }">
                      <span>{{ item }}</span></div>
                  </div>
                </div>
                <div class="layout-column layout-align-center-center px-1" @click="scrollList(1)">
                  <v-icon>expand_more</v-icon>
                </div>
              </div>
              <v-divider vertical></v-divider>
              <div class="flex-30 layout-column layout-align-center-start pl-3 pr-2 ml-2">
                <div class="linked" v-for="(item, index) in monthArray" :key="index" @click="selectMonth(index)"
                     v-bind:class="{ 'pink--text active': index === getPopupValues.selectedMonth }">
                  <strong class="mr-1" style="font-weight:900;opacity: 0.25;">{{ index + 1 | leadingZero }}</strong>
                  <!--            <span style="opacity: 0.25;" class="ml-1 mr-1">|</span>-->
                  <span>{{ item }}</span>
                </div>
              </div>
              <v-divider vertical></v-divider>
              <div class="flex-60 layout-column layout-align-center-center">
                <table style="margin: 10px;">
                  <thead>
                    <tr>
                      <th class="transparent">
                        <div>KW</div>
                      </th>
                      <th class="transparent pink--text">
                        <div>Mo</div>
                      </th>
                      <th class="transparent pink--text">
                        <div>Di</div>
                      </th>
                      <th class="transparent pink--text">
                        <div>Mi</div>
                      </th>
                      <th class="transparent pink--text">
                        <div>Do</div>
                      </th>
                      <th class="transparent pink--text">
                        <div>Fr</div>
                      </th>
                      <th class="transparent pink--text">
                        <div>Sa</div>
                      </th>
                      <th class="transparent pink--text">
                        <div>So</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in getPopupValues.dayArray" :key="index">
                      <td>
                        <div>{{ row.kw }}</div>
                      </td>
                      <td v-for="(day, index2) in row.days" :key="index2"
                          v-bind:class="{ 'yellow lighten-5 not-in': !day.in, 'pink white--text active': day.active }">
                        <div @click="selectDay(day.value, day.month)" v-on:dblclick="applySelectedDate">
                          {{ day.value }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <v-divider class="ma-0"></v-divider>
            <div class="layout-row layout-align-end-center px-1 py-1">
              <v-btn depressed color="grey lighten-2" class="text-none flex ma-0 blue-grey--text"
                     @click="applySelectedDate">Datum übernehmen
              </v-btn>
              <v-btn depressed color="grey lighten-2" class="text-none ml-1 my-0 mr-0 blue-grey--text"
                     @click.stop="dialog=false">abbrechen
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import gsap from 'gsap/dist/gsap'
import Draggable from 'gsap/dist/Draggable'
import lodash from 'lodash'

export default Vue.component('lxDatepicker', {
  /**
   * ##########################################################################################################
   * #  notwendig damit Kommunikation mit der Validierung vee-validate in der Parent Komponente funktioniert  #
   * ##########################################################################################################
   * */
  $_veeValidate: {
    name() {
      return this.name
    },
    value() {
      return this.value
    }
  },
  data() {
    return {
      dialog: false,
      min: 1900, // kleinste mögliche Jahreszahl
      max: 2100, // größte mögliche Jahreszahl
      internalModel: '', // enthält das v-model des inputfeldes innerhalb des Datepickers
      dialogVisible: false,
      yearArray: [], // alle Jahre, die per Schleife von min bis max gefüllt werden
      monthArray: moment.months(), // alle Monate, übersetzt aus der moment.js DE Localisationsdatei
      dayArray: [], // Array was mit alle Tagen des aktuellen Monats und Jahres befüllt wird
      dateTimeToday: moment(), // die Variable dateTimeToday erhält den heutigen Tag als Wert, wird nicht mehr geändert
      internalValue: moment(), // interner Wert des Datepickers zum Start auf den heutigen Tag gesetzt, später den gewählten
      internalChange: false, // ob das Parent v-model aus dem Datepicker heraus geändert wurde 'true' oder von außen 'false'
    }
  },
  props: {
    value: [String, Date, Object], // der Typ den das v-model im Parent haben kann ist entweder ein Object, String oder Date
    name: {
      type: String
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    hideDetails: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: { // bekommt die Errormessages übergeben
      type: [Array, String],
      required: false
    },
    required: {
      type: [Boolean]
    },
    hint: {
      type: String
    },
    persistent_hint: {
      type: Boolean
    },
    keyValue: {
      type: String
    }
  },
  computed: {
    /**
     * wenn vee-validate in der Parentkomponente einen Fehler erkennt und damit this.error sich ändert dann werden
     * hier im Datepicker die Errormessages getriggert durch diese computed Property
     * */
    getErrorMsg: function () {
      if (!this.error) {
        return []
      }
      return this.error
    },
    /**
     * wenn das Popup sich öffnet braucht es anhand des aktuell verwendeten Datum (v-model Wert oder heutiger Tag)
     * die richtigen Tage zum anzeigen, also die des letzten Monats , des jetzigen und des Nächsten
     * mit welchen Wochentag der Monat beginnt etc. sowie die dazu passenden Kalenderwochen
     * dies alles wird in der computed Property getPopupValues als Objekt gespeichert
     * */
    getPopupValues: function () {
      let popupValues = {}
      let today = this.internalValue

      if (!moment(today).isValid()) {
        // today must be set to generate days of month in datepicker
        today = this.dateTimeToday
        // today = null
      }

      if (today) {
        popupValues.today = today
        popupValues.selectedYear = today.get('year')
        popupValues.selectedMonth = today.get('month')
        popupValues.selectedDay = today.get('date')
      }

      // Anzahl der Tage im letzten Monat
      let lastMonth = moment(today).date(0).date()

      // Versatz der Tage angenommen als Montag = 0 und Samstag = 6
      let engDate = moment(today).set('date', 1).day() - 1
      if (engDate < 0) {
        engDate += 7
      }

      // Anzahl der Tage des ausgewählten/aktuellen Monats
      let monthLength = moment(today).endOf('month').date()

      // Nummer der Kalenderwoche für die erste Woche des ausgewählten/aktuellen Monats
      let kalenderwoche = moment(today).isoWeek()

      let dayArray = []

      for (let i = 0; i < 6; i++) {
        let row = []
        for (let j = 0; j < 7; j++) {
          let val = (i * 7 + j) - engDate + 1
          let obj = {
            in: true,
            active: val === popupValues.selectedDay,
            value: val,
            month: 0
          }

          // Bereich um die letzten Felder der 6 x 7 Matrix mit den Daten des nächsten Monats auffüllen
          if (obj.value > monthLength) {
            obj.value = obj.value - monthLength
            obj.in = false
            obj.month = 1
          }

          // Bereich um die ersten Felder der 6 x 7 Matrix mit den Daten des vorherigen Monats auffüllen
          if (i === 0 && j < engDate) {
            // value = lastMonth + value
            obj.in = false
            obj.value = lastMonth + j - engDate + 1
            obj.month = -1
          }

          row.push(obj)
        }

        let kwObj = {
          kw: kalenderwoche + i,
          days: row
        }

        dayArray.push(kwObj)
      }

      // popupValues.dayArray = dayArray
      this.$set(popupValues, 'dayArray', dayArray)

      return popupValues
    }
  },
  created() {
    gsap.registerPlugin(Draggable)

    // das Jahresarray wird mit Jahreszahlen von min bis max gefüllt z.B.: 1900 bis 2100
    for (let i = 0; i < this.max - this.min + 1; i++) {
      this.yearArray.push(i + this.min)
    }
  },
  methods: {
    /**
     * wird bei click auf den Button neben dem Datepicker Input aufgerufen um das Popup damit zu öffnen
     * anschließend wird das aktuelle ausgewählte Jahr in die MItte gescrollt damit es sichtbar ist
     * auch wenn kein Datum im v-model der Parent Komponente ist gibt es im Popup einen Wert zum anzeigen und
     * das ist das heutige Datum
     * */
    openDialog() {
      if (!this.disabled) {
        this.dialog = true
        setTimeout(() => {
          this.scrollYearIntoView()
          this.dragThatThing()
        }, 0)
      }
    },
    /**
     * Wird von vielen Methoden aufgerufen um das aktuell ausgewählte Jahr im Popup in die Mitte zu scrollen
     * dies kann notwendig sein da ein Datum in einem Jahr liegt welches eventuell noch nicht sichtbar ist in der Liste
     * da nur maximal 9-10 Jahreszahlen gleichzeitig angezeigt werden können
     * */
    scrollYearIntoView() {
      if (this.dialog) {
        let t = this.dateTimeToday.get('year')
        if (moment(this.internalValue).isValid()) {
          t = this.internalValue.get('year')
        }
        if (t >= this.min && t <= this.max) {
          let el = document.getElementById('year-id-' + (t - 4))
          el.scrollIntoView()
        }
      }
    },
    /**
     * wird aufgerufen wenn man das Input Feld verlässt, da dabei der onBlur Event geworfen wird
     * der eingegebene String wird dabei überprüft ob er einem validen Datum entspricht. Wenn dem so ist,
     * wird der momentjs Datumswert an das v-model der parent Komponente übergeben mittels $emit('input' ...)
     * nur so ist eine reactive Änderung möglich und das Partent Model bekommt die Änderung aktiv mit
     * */
    updateValue(e) {
      let dateValue = null
      let payload = null
      if (e.target) {
        dateValue = moment(e.target.value, 'DD.MM.YYYY')
        payload = e.target.value
      } else {
        dateValue = moment(e, 'DD.MM.YYYY')
        payload = e
      }

      this.internalChange = true

      if (dateValue.isValid()) {
        payload = dateValue
        this.internalValue = dateValue.hour(12).minutes(0).second(0).millisecond(0)
        this.$emit('input', payload)
      } else {
        this.internalValue = this.dateTimeToday
        this.$emit('input', payload.toString().length > 0 ? payload : null)
      }
    },
    /**
     * wird bei click auf die Hoch und Runter Buttons im Jahresbereich des Popups aufgerufen
     * die Richtung wird mit -1 oder 1 über die Variable direction übergeben
     * */
    scrollList(direction) {
      let el = document.getElementById('yearList')
      if (el) {
        el.scrollTop += (30 * direction)
      }
    },
    /**
     * Wird aufgerufen wenn man das popup draggen möchte, dabei klickt man auf den Header des Popups
     * - es wird der Body aus dem HTML geholt über die ID 'app'
     * - dann die Größe des Fenster bestimmt mit getBoundingClientRect, da Body auf 100% in beide Richtung stretcht
     * - dann wird Popup zu Draggable Obejct gemacht in den Grenzen des Body (bounds)
     * */
    dragThatThing() {
      Draggable.create('#square', {
        bounds: "#bodyTag",
        // throwProps: true,
        onDragStart: function () {
          this.target.style.opacity = 0.6
        },
        onDragEnd: function () {
          this.target.style.opacity = 1.0
        }
      })
    },
    /**
     * Wird aufgerufen wenn man beim popup draggen loslässt
     * - killt das Draggable Object
     * */
    leftThatThing() {
      Draggable.get('#square').kill()
    },
    /**
     * Wird bei Klick auf den heute Button im Popup aufgerufen
     * - dies setzt den Wert des internen Wert this.internalValue auf den heutigen tag
     * - dabei kann es zu einem Jahreswechsel kommen und damit einem neu ausgewählten Jahr
     *   welches wieder in die Mitte gescrollt wird damit es besser sichtbar ist,
     *   deshalb wird this.scrollYearIntoView() aufgerufen
     * */
    thisDay() {
      this.internalValue = moment(this.dateTimeToday)
      this.scrollYearIntoView()
    },
    /**
     * Wird bei Klick auf den morgen Button im Popup aufgerufen
     * - dies setzt den Wert des internen Wert this.internalValue auf den morgigen tag
     * - dabei kann es zu einem Jahreswechsel kommen und damit einem neu ausgewählten Jahr
     *   welches wieder in die Mitte gescrollt wird damit es besser sichtbar ist,
     *   deshalb wird this.scrollYearIntoView() aufgerufen
     * */
    nextDay() {
      let date = this.internalValue
      if (!moment(date).isValid()) {
        date = lodash.clone(this.dateTimeToday, true)
      }
      this.internalValue = moment().add(1, 'day')
      this.scrollYearIntoView()
    },
    lastDay() {
      let date = this.internalValue
      if (!moment(date).isValid()) {
        date = lodash.clone(this.dateTimeToday, true)
      }
      this.internalValue = moment().add(-1, 'day')
      this.scrollYearIntoView()
    },
    /**
     * Wird bei Klick auf den Plus oder Minus Buttons für Wochen im Popup aufgerufen
     * - bei klick auf Minus wird value = -1 übergeben
     * - bei klick auf Plus wird value = 1 übergeben
     * - dies setzt den Wochen Wert des internen Wert this.internalValue auf die nächste oder vorherige Woche
     * - dabei kann es zu einem Monatswechsel, damit verbunden einem Jahreswechsel kommen und damit
     *   einem neu ausgewählten Jahr welches wieder in die Mitte gescrollt wird damit es besser sichtbar ist,
     *   deshalb wird this.scrollYearIntoView() aufgerufen
     * */
    nextWeek(value) {
      let date = this.internalValue
      if (!moment(date).isValid()) {
        date = lodash.clone(this.dateTimeToday, true)
      }
      this.internalValue = moment(date).add(value, 'week')
      this.scrollYearIntoView()
    },
    /**
     * Wird bei Klick auf den Plus oder Minus Buttons für Monate im Popup aufgerufen
     * - bei klick auf Minus wird value = -1 übergeben
     * - bei klick auf Plus wird value = 1 übergeben
     * - dies setzt den Monats Wert des internen Wert this.internalValue auf den nächsten oder vorherigen Monat
     * - dabei kann es zu einem Jahreswechsel kommen und damit einem neuen  ausgewählten Jahr
     *   welches wieder in die Mitte gescrollt wird damit es besser sichtbar ist,
     *   deshalb wird this.scrollYearIntoView() aufgerufen
     * */
    nextMonth(value) {
      let date = this.internalValue
      if (!moment(date).isValid()) {
        date = lodash.clone(this.dateTimeToday, true)
      }
      this.internalValue = moment(date).add(value, 'month')
      this.scrollYearIntoView()
    },
    /**
     * Wird bei Klick auf den Plus oder Minus Buttons für Jahre  im Popup aufgerufen
     * - bei klick auf Minus wird value = -1 übergeben
     * - bei klick auf Plus wird value = 1 übergeben
     * - dies setzt den Jahres Wert des internen Wert this.internalValue auf das nächstes oder vorherige Jahr
     * - ausgewählte Jahre werden in in die Mitte gescrollt damit sie besser sichtbar sind
     *   deshalb wird this.scrollYearIntoView() aufgerufen
     * */
    nextYear(value) {
      let date = this.internalValue
      if (!moment(date).isValid()) {
        date = lodash.clone(this.dateTimeToday, true)
      }
      this.internalValue = moment(date).add(value, 'year')
      this.scrollYearIntoView()
    },
    /**
     * Wird bei Klick auf einen Tag im Popup aufgerufen
     * - setzt den Tages Wert des internen Wert this.internalValue auf den neuen Tag
     * - sollte der Tag im letzten Monat liegen wird Month = -1 und im nächsten Monat = +1
     * */
    selectDay(day, month) {
      let date = this.internalValue
      if (!moment(date).isValid()) {
        date = lodash.clone(this.dateTimeToday, true)
      }
      this.internalValue = moment(date).add(month, 'month').set('date', day)
    },
    /**
     * Wird bei Klick auf einen Monat im Popup aufgerufen
     * - setzt den Monats Wert des internen Wert this.internalValue auf den neuen Monat
     * */
    selectMonth(month) {
      let date = this.internalValue
      if (!moment(date).isValid()) {
        date = lodash.clone(this.dateTimeToday, true)
      }
      this.internalValue = moment(date).set('month', month)
    },
    /**
     * Wird bei Klick auf ein Jahr im Popup aufgerufen
     * - setzt den Jahres Wert des internen Wert this.internalValue auf das neue Jahr
     * - ausgewählte Jahre werden in in die Mitte gescrollt damit sie besser sichtbar sind
     *   deshalb wird this.scrollYearIntoView() aufgerufen
     * */
    selectYear(year) {
      let date = this.internalValue
      if (!moment(date).isValid()) {
        date = lodash.clone(this.dateTimeToday, true)
      }
      this.internalValue = moment(date).set('year', year)
      this.scrollYearIntoView()
    },
    applySelectedDate() {
      this.internalChange = true
      this.internalModel = this.internalValue.format('DD.MM.YYYY')
      this.$emit('blur', this.internalValue)
      this.$emit('input', this.internalValue.hour(12).minutes(0).second(0).millisecond(0))
      this.dialog = false
    }
  },
  watch: {
    /**
     * Lauscht auf dem v-model des parents dieser Komponente, wenn also von außen über GetData oder ein Reset
     * das Datum geändert wird, allerdings ändert sich dieses v-model auch wenn man im Datepicker das Datum übernimmt
     * und per $emit('input') dem v-model mitteilt
     *
     * verschiedene Kombinationen von neuen Wert zu altem Wert erfordern verschiedene Reaktionen:
     *
     * 1) das neue Value ist nicht definiert, und die boolean Variable this.internalchange ist true,
     *    das bedeutet der Wert wurde händisch im Datepickerinput herausgelöscht
     * 2) alle anderen Fälle in denen NICHT das neue Value vom Typ string ist und gleichzeitig
     *    das alte Value ein Object ist, schauen ob newValue ein valides Datum ist:
     *      - wenn ja dann formatieren in DD.MM.YYYY und an input geben per internen v-model this.internalModel
     *      - wenn nicht, dann String direkt übergeben, führt aber zu Validierungsfehler da kein Datum
     *    in jedem der beiden Fälle wird aber das newValue an den interen Platzhalter this.internalValue übergeben
     * 3) alle anderen Kombinationen müssen nicht berücksichtigt werden
     * */
    value: {
      handler: function (newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue === undefined && this.internalChange) {
            this.internalChange = false
            this.internalModel = ''
          } else {
            // if (!(typeof newValue === 'string' && typeof oldValue === 'object' && oldValue !== null)) {

            // check if newValue is defined, because of moment(undefined) is valid and same as new Date
            if (newValue && moment(newValue).isValid()) {
              this.internalModel = moment(newValue).format('DD.MM.YYYY')
            } else {
              this.internalModel = newValue
            }

            this.internalValue = newValue instanceof moment || !moment(newValue).isValid() ? newValue : moment(newValue)
            // }
          }
        }
      },
      immediate: true
    }
  }
})
</script>

<style scoped lang="scss">
.not-in {
  color: #b9a564;
}

div.teal--text.active {
  color: #4db6ac;

  span {
    font-weight: 700;
  }
}

td {
  cursor: pointer;
}

td.active {
  div {
    font-weight: 700;
  }
}

.linked {
  cursor: pointer;
  line-height: 19px;
  font-size: 15px;
  height: 19px;
}

.other-month {
  background: #ffcb52;
  opacity: 0.4;
  color: black;
  border: 1px solid #6d6d6d;
}

/*table {*/
/*border: 1px solid #222e3e2b;*/
/*}*/
table thead th {
  font-size: 14px;
  background: #e0e0e0;
  border-bottom: 1px solid #4cb6ac !important;

  &:first-child {
    padding-right: 10px;
    background: rgba(0, 150, 136, 0.13);
  }

  div {
    text-align: center;
    line-height: 30px;
    width: 30px;
    height: 30px;
  }
}

table tbody td {
  font-size: 14px;
  background: #ffe8f0;

  &:not(:first-child):hover {
    background: darken(#ccebe8, 10);
  }

  &:first-child {
    background: transparent;
    padding-right: 10px;
  }

  div {
    text-align: center;
    line-height: 30px;
    width: 30px;
    height: 30px;
  }
}

#square {
  max-width: 650px;
  width: 650px;
  min-width: 650px;
  min-height: 300px;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  margin-left: -275px;
  margin-top: -200px;
  box-shadow: 0 0 42px rgba(0, 0, 0, 0.7);
}

</style>
