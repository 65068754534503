<script>
import Vue from 'vue'

const colors = ['light-blue','orange','green','teal','amber']

function getIndicesOf (searchStr, str, caseSensitive) {
  if (!searchStr || searchStr.length === 0) {
    return []
  }

  let searchStrings =  searchStr.split(' ')
  let indices = []

  if(str){
    for(let i = 0; i < searchStrings.length; i++){
      let searchStrLen = searchStrings[i].length
      if(searchStrLen > 0){
        let startIndex = 0
        let searchString
        let index

        if (!caseSensitive) {
          str = str.toLowerCase()
          searchString = searchStrings[i].toLowerCase()
        }

        while ((index = str.indexOf(searchString, startIndex)) > -1) {

          indices.push({s: index, l: searchStrLen, c: colors[i]})
          startIndex = index + searchStrLen
        }
      }
    }

    indices.sort((a,b) => {
      if (a.s < b.s) {
        return -1;
      }
      return 0;
    })

    if(indices.length > 1){
    for(let j=0; j < indices.length; j++){
      if(j > 0){
        let end = indices[j - 1].s + indices[j - 1].l - 1
        if(indices[j].s <= end){
          let delta = end - indices[j - 1].s
          indices[j].s = end + 1
          indices[j].l -= delta
        }
      }
    }
  }
  }
  return indices
}

export default Vue.component('lxTextHighlight', {
  props: {
    text: {
      type: String,
      default: null
    },
    searchString: {
      type: [Object,String],
      default: null
    },
    color: {
      type: String,
      default: 'blue-grey'
    }
  },
  render (h) {
    let searchValue = this.searchString.searching || ''
    let indices = getIndicesOf(searchValue, this.text)

    let parts = []

    if (indices?.length > 0) {
      let oldStart = 0

      for (let items in indices) {
        let res = this.text.substring(oldStart, indices[items].s)
        oldStart = indices[items].s + indices[items].l

        if (res.length > 0) {
          let noHighlightPart = h('span', {style: 'white-space: nowrap;'}, res)
          parts.push(noHighlightPart)
        }

        let highlightText = this.text.substring(indices[items].s, oldStart)
        let highlightPart = h('span', { staticClass: 'orange lighten-5 font-weight-black', style: 'white-space: nowrap;text-decoration: underline orange;' }, highlightText)
            // , style: 'text-decoration: overline'
        parts.push(highlightPart)
      }

      let textnode = this.text.substring(oldStart)
      let noHighlightPart2 = h('span', {style: 'white-space: nowrap;'}, textnode)
      parts.push(noHighlightPart2)
    } else {
      let nothingToHighlight = h('span', {style: 'white-space: nowrap;'}, this.text)
      parts.push(nothingToHighlight)
    }

    return h(
      'div', {staticClass: 'layout-row flex layout-align-start-center'}, parts
    )
  }
})
</script>
