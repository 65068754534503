<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">

          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="refresh"
                  :hide-label="true"
                  tooltip="Daten neu abholen um die Ansicht zu aktualisieren"
                  :label="'Aktualisieren'"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="isLoadingData"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="$router.push({path: '/administration/pharmacy/new'})"
                  icon="mdi-plus-circle"
                  tooltip="Neue Apotheke anlegen"
                  :label="'Apotheke anlegen'"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  disabled
                  classes="text-none mr-1"
                  @click="setUsersActivity()"
                  icon="mdi-toggle-switch-off"
                  tooltip="Ausgewähltes Apotheke deaktivieren"
                  :label="'Apotheke deaktivieren'"></lx-btn>

          <lx-report
            tooltip="Bericht generieren"
            label="Bericht generieren"
            url="/reports/pharmacy/dependencies"
            :options="{filename: 'dependencies', type: 'medicalunit'}"
          ></lx-report>

        </div>

        <div class="flex layout-row">

        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px;"
             :style="{'background': compColor.lxExpansion.background}">

          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1);" class="flex layout-column">
            <lx-data-table
              v-model="tableObj.selection"
              tableName="PharmacyList"
              :items="items"
              :headers="tableObj.headers"
              :loading="tableObj.loading"
              :tableOptions="tableObj.options"
              :hideContextFilter="true"
              @changeOptions="changeTableOptions"
              @rowClicked="openPharmacyDetails">

              <template v-slot:cellrenderer="{ value }">
                <div v-if="value.f.renderer === 'icon'" style="padding:1px;height:35px;">
                  <v-icon v-if="value.i[value.f.value]" :color="compColor.prime">mdi-check</v-icon>
                </div>
              </template>

            </lx-data-table>
          </div>
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="layout-row pa-0">
        <lx-pagination :page-name="'orderList'"
                       :page-options="pagingOptions"
                       :page-change="getData">
        </lx-pagination>
      </div>

    </div>
  </div>
</template>
<script>
import router from '../../../router'
import CheckIconCellRenderer from '../../../components/lx_ag-grid/components/cellRenderer/booleanCheckIconCell'
import LxReport from '@/components/lx_report/LxReport';

export default {
  name: 'PharmacyList',
  data() {
    return {
      // exportTypes: [
      //   {caption: 'JSON', icon: 'mdi-code-json', type: 'json', enabled: true, extension: '.json'},
      //   {caption: 'CSV', icon: 'mdi-file-delimited', type: 'csv', enabled: true, extension: '.csv'},
      //   {caption: 'Excel', icon: 'mdi-file-excel', type: 'xlsx', enabled: true, extension: '.xlsx'},
      //   {caption: 'XML', icon: 'mdi-xml', type: 'xml', enabled: true, extension: '.xml'}
      // ],
      tableObj: {
        loading: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
        options: {
          sortBy: ['ordernr'],
          sortDesc: [false],
          // getClassForItem: (row) => {
          //   if (row.isExpanded) {
          //     return 'row-bold'
          //   }
          //   return ''
          // }
        },
        filterValue: null,
        selection: null,
        headers: [
          {
            text: 'Apothekenname',
            value: 'name',
            width: '300px',
            sortable: true
          },
          {
            text: 'E-Mail',
            value: 'email',
            width: '300px',
            sortable: true
          },
          {
            text: 'Aktiv',
            value: 'is_active',
            sortable: true,
            renderer: 'icon'
          }
        ]
      },
      items: [],

      isLoadingData: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
      frameworkComponents: null,
      columnDefs: null,
      rowData: null,
      FilterAreFloating: false,
      allowBlurOnLoading: true
    }
  },
  components: {
    LxReport
    // AgGridVue
    // DeleteDialog
  },
  created() {
    this.createColumnDefs();
  },
  beforeMount() {
    this.frameworkComponents = {
      // agColumnHeader: agGridComponentHeader,
      checkIconCellRenderer: CheckIconCellRenderer
    }
  },
  methods: {
    /**
     * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
     */
    changeTableOptions(value) {
      if (value) {
        this.tableObj.options = value
      }
      this.getData()
    },
    /**
     * Methode wird vom Grid angesprungen wenn man doppelt auf eine Zeile klickt. Dann wird die Detailansicht geöffent.
     * Kann auch an einen Editbutton in einer Spalte gehangen werden aber Doppelklick ist komfortabler.
     * **/
    openPharmacyDetails(params) {
      if (params?._id) {
        router.push({ path: `/administration/pharmacy/${params._id.toString()}/details` });
      }
    },

    /**
     * hier werden wie gewohnt die Spalten des Grids definiert,
     * Besonderheit hier ist nur:
     *
     *     filterParams: {
     *       newRowsAction: 'keep'
     *    }
     *
     * dies ist Wichtig damit nach "getData" ein eventueller Eintrag im Filterinput erhalten bleibt
     * **/
    createColumnDefs() {
      this.columnDefs = [
        {
          headerName: '',
          width: 60,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: false,
          checkboxSelection: true,
          pinned: 'left',
          sortable: false,
          filter: false
        },
        {
          headerName: 'Name',
          field: 'name',
          filterParams: {
            newRowsAction: 'keep'
          },
          headerComponentParams: {
            sortChangedAction: this.sortChanged
          }
        },
        {
          headerName: 'E-Mail',
          field: 'email',
          width: 300,
          filterParams: {
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'Aktiv',
          field: 'is_active',
          filter: false,
          width: 120,
          sortable: false,
          filterParams: {
            newRowsAction: 'keep'
          },
          cellRenderer: 'checkIconCellRenderer',
          cellRendererParams: {
            icon: 'message',
            color: this.compColor.primeColor
          }
        }
      ]
    },

    /**
     * Delete
     **/
    setUsersActivity(val) {
      let items = [];

      let itemAsObject = this.gridApi.selectionController.selectedNodes;

      for (let i in itemAsObject) {
        let test = itemAsObject[i].data._id;
        items.push(test)
      }

      this.showDeleteDialog = false;

      if (val && val === 'yes') {
        let items = [];
        let itemAsObject = this.gridApi.selectionController.selectedNodes;
        for (let i in itemAsObject) {
          let test = itemAsObject[i].data.id;
          items.push(test)
        }
      }
    },


    /**
     * Wird verschiedenen Stellen aus aufgerufen und soll die Daten vom Backend abholen. Mittels axios....bla bla bla
     * Besonderheit:
     * "this.pagingOptions.count = res.data.count"
     * - Anzahl der Datensätze vom Server wird auch in den pagingOptions gespeichert
     *
     * "this.$store.dispatch('setPagingOptions', this.pagingOptions)"
     * - nach der Speicherung der Anzahl werden die pagingOptions wieder in den Store geschrieben
     *
     * "this.gridOptions.api.ensureIndexVisible(this.$store.getters.gridScrollIndex, 'top')"
     * - sorgt dafür das nach dem neuladen der Daten das Grid wieder an die Stelle scrollt wo es war. notwendig z.B. bei der Rückkehr zum Grid
     * **/
    getData(options) {
      if (this.tableObj.loading) return
      this.tableObj.loading = true

      if (options && typeof options.skip === 'number') {
        this.pagingOptions.skip = options.skip
      }
      if (options && options.limit) {
        this.pagingOptions.limit = options.limit
      }

      const params = this.$helper.GetQueryParam({
        ...this.tableObj.options,
        skip: this.pagingOptions.skip,
        limit: this.pagingOptions.limit,
        query: {},
        count: true,
        // searchString: this.searchString,
        // searchFields: ['medicalunit_name', 'name', 'costcenter', 'email']
      })

      this.$http.get('/pharmacy', params)
        .then(res => {
          if (res.data) {
            this.items = res.data.data || []
            this.pagingOptions.count = res.data.count || 0
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.tableObj.loading = false
        })
    },
  },
  computed: {}
}
</script>
<!-- Add 'scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.view-top-toolbar {
  .btn {
    min-width: 40px;
  }

  .btn__content {
    padding: 0 5px !important;
  }
}
</style>
