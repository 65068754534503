<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <!-- TOOLBAR -->
        <div class="layout-row">
          <v-btn :dark="compColor.isInDarkMode" :color="compColor.primeColor"
                 class="white--text text-none ma-1" @click="getDataHolidays()">
            <v-icon left dark>refresh</v-icon>
            <span>Aktualisieren</span>
          </v-btn>

          <v-text-field
            :dark="compColor.isInDarkMode"
            :dense="true"
            :color="compColor.primeColor"
            label="Jahr"
            outlined
            hide-details
            style="max-width:100px;margin-top: 2px;"
            class="ml-4 mr-1"
            v-model="yearToGetHolidaysFrom"
          ></v-text-field>
          <v-btn :dark="compColor.isInDarkMode" :color="compColor.primeColor"
                 class="white--text text-none mr-1" @click="getNewHolidays()">
            <v-icon left dark>cancel</v-icon>
            <span>Feiertage abholen</span>
          </v-btn>
        </div>

        <div class="flex layout-row"></div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px" :style="{'background': compColor.lxExpansion.background}">
          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1); overflow-y: auto" class="flex layout-column">
            <v-container>
              <v-expansion-panels accordion multiple v-model="panel" :readonly="true">
                <v-expansion-panel class="mb-1" v-for="(item,index) in statesWithHolidays" :key="index">

              <v-expansion-panel-header
                v-bind:class="[compColor.expansionHeader,compColor.textInput+ '--text']">
                {{ item[0].name }} <sup class="ml-2 blue-grey--text">({{ item[0].holidays.length }})</sup>
                <template v-slot:actions>
                  <v-icon @click="openCloseExpansionPanel(index)">expand_more</v-icon>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content v-bind:class="[compColor.contentFrameBack]">
                <!--                                    <pre class="caption">{{item}}</pre>-->
                <div class="blue-grey--text text--darken-2 blue-grey lighten-5 ma-1 pa-2"
                     v-for="(holiday,ind) in item[0].holidays" :key="ind">
                  <span
                    style="display:inline-block;min-width: 120px;">{{ holiday.date | formatDate('DD.MM.YYYY') }}</span>
                  <span>{{ holiday.name }}</span>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LxDropdownButton from "@/components/lx_dropdown_button/LxDropdownButton.vue";

export default {
  name: 'DeliveryHolidayList',
  data() {
    return {
      daysPerState: null,
      panel: [],
      statecodes: ['BW', 'BY', 'BE', 'BB', 'HB', 'HH', 'HE', 'MV', 'NI', 'NW', 'RP', 'SL', 'SN', 'ST', 'SH', 'TH'],
      statesWithHolidays: [],
      yearToGetHolidaysFrom: null
    }
  },
  components: {LxDropdownButton},
  created() {
    this.getDataHolidays();
  },
  methods: {
    openCloseExpansionPanel(index) {
      if (this.panel.indexOf(index) < 0) {
        this.panel.push(index)
      } else {
        this.panel.splice(this.panel.indexOf(index), 1)
      }
    },
    getNewHolidays() {
      let year = parseInt(this.yearToGetHolidaysFrom, 10);

      if (year && year > 2019 && year < 2030) {
        this.$http.get('/api/updateholidays/' + year)
          .then(this.NotifySuccess)
          .catch(this.NotifyError)
          .finally(this.loading = false)
      }
    },
    getDataHolidays() {
      this.statesWithHolidays = []

      this.statecodes.forEach((item) => {
        this.$http.get('/calendar/holidays/GER/' + item)
          // this.$http.get('/calendar/holidays/GER/' + this.model.state)
          .then(res => {
            // this.daysPerState = res.data
            //this.statesWithHolidays = res.data
            this.statesWithHolidays.push(res.data)
          })
          .catch(this.NotifyError)
      })

    },
  },
  computed: {}
}
</script>
<!-- Add 'scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
