<template>
    <v-icon :color="color">{{icon}}</v-icon>
</template>

<script>
    import Vue from 'vue'

    export default Vue.extend({
        data() {
            return {
                model: false,
                titleText: '',
                color: 'grey lighten-2',
                icon: 'block'
            }
        },
        created() {
            let fieldName = this.params.colDef.field

            if (this.params.data[fieldName]) {
                this.color = this.params.color
                this.icon = 'done'
            }
        },
        computed: {}
    })
</script>
*
