<template>
    <div>
        <div class="pa-1 layout-row layout-align-center-center" style="margin:2px;cursor:pointer;" @click.stop.prevent="copyLink()">
            <v-tooltip bottom open-delay="300" :color="compColor.primeColor" style="opacity:1.0 !important;">
                <template v-slot:activator="{ on }">
                    <!--              <v-icon color="primary" dark>mdi-home</v-icon>-->
                    <v-icon color="blue-grey lighten-3" v-on="on">assignment_returned</v-icon>
                </template>
                <span>Link in Zwischenablage kopieren: <strong>{{model}}</strong></span>
            </v-tooltip>

        </div>
        <!--<input type="hidden" id="testing-code" :value="model">-->
    </div>

</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    data() {
      return {
        model: "1234"
      }
    },
    created() {

      if (this.params.data && this.params.data.tokenid) {
        this.model = window.location.origin + '/auth/register/' + this.params.data.tokenid
      }

    },
    methods: {
      copyLink() {
        const hiddenElement = document.createElement('input');
        // hiddenElement.style.display = 'none';
        hiddenElement.setAttribute('type', 'text');
        hiddenElement.setAttribute('value', this.model.toString());
        document.body.appendChild(hiddenElement);

        hiddenElement.select();
        try {
          document.execCommand('copy');
        } catch (err) {
          console.log('Oops, unable to copy');
        }

        window.getSelection().removeAllRanges();
        document.body.removeChild(hiddenElement);
      }
    }
  })
</script>
