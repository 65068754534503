<template>
    <div class="flex lx-abda-search-field" v-bind:class="[{'layout-row': !dataOrientation}, dataOrientation]">
        <div class="px-1 pt-2 flex" v-show="getSetSingleSearchField">
            <v-text-field
                    label="Suchbegriff(e) eingeben  (PZN, Artikelname, Wirkstoff etc.)"
                    prepend-inner-icon="search"
                    :color="compColor.primeColor"
                    :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
                    outlined
                    :dense="denseFields"
                    :hide-details="true"
                    :dark="compColor.isInDarkMode"
                    :loading="loadingSearch"
                    class="flex elevation-0 mb-0"
                    clearable
                    v-model="searchString"
            ></v-text-field>
<!--          :background-color="compColor.inputs"-->
        </div>
        <div class="px-1 pt-2 flex" v-show="!getSetSingleSearchField">
            <v-text-field
                    label="Artikelname"
                    append-icon="search"
                    :color="compColor.primeColor"
                    :loading="loadingSearch"
                    :hide-details="true"
                    :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
                    outlined
                    :dense="denseFields"
                    :dark="compColor.isInDarkMode"
                    class="flex elevation-0 mb-0"
                    clearable
                    v-model="searchModel.Produktname"
            ></v-text-field>
        </div>
        <div class="px-1 pt-2 flex" v-show="!getSetSingleSearchField">
            <v-text-field
                    label="PZN"
                    append-icon="search"
                    :color="compColor.primeColor"
                    :loading="loadingSearch"
                    :hide-details="true"
                    :dark="compColor.isInDarkMode"
                    :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
                    outlined
                    :dense="denseFields"
                    class="flex elevation-0 mb-0"
                    clearable
                    v-model="searchModel.pzn"
            ></v-text-field>
        </div>
        <div class="px-1 pt-2 flex" v-show="!getSetSingleSearchField">
            <v-text-field
                    label="Wirkstoff"
                    append-icon="search"
                    :color="compColor.primeColor"
                    :loading="loadingSearch"
                    :hide-details="true"
                    :dark="compColor.isInDarkMode"
                    :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
                    outlined
                    :dense="denseFields"
                    class="flex elevation-0 mb-0"
                    clearable
                    v-model="searchModel.agent"
            ></v-text-field>
        </div>
        <div class="px-1 pt-2 flex" v-show="!getSetSingleSearchField">
            <v-text-field
                    label="Hersteller"
                    append-icon="search"
                    :color="compColor.primeColor"
                    :loading="loadingSearch"
                    :hide-details="true"
                    :dark="compColor.isInDarkMode"
                    :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
                    outlined
                    :dense="denseFields"
                    class="flex elevation-0 mb-0"
                    clearable
                    v-model="searchModel.manufacturer_Langname"
            ></v-text-field>
        </div>
        <div class="px-1 py-0 pt-2 layout-column layout-align-center-center">

          <v-tooltip bottom open-delay="350">
            <template v-slot:activator="{ on }">

              <div v-on="on">
          <v-switch inset :color="compColor.primeColor" :dark="compColor.isInDarkMode" hide-details
                    v-model="getSetSingleSearchField" class="ma-0 pa-0 mr-n2 ml-1"></v-switch>
              </div>
            </template>
            <span>{{getSetSingleSearchField ? 'auf Einzelsuchfelder umschalten':'auf Kombinationssuchfeld umschalten'}}</span>
          </v-tooltip>

<!--          <v-menu offset-y>-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <v-btn-->
<!--                  color="blue-grey"-->
<!--                  dark-->
<!--                  icon-->
<!--                  text-->
<!--                  v-bind="attrs"-->
<!--                  v-on="on"-->
<!--              >-->
<!--                <v-icon small>mdi-cog</v-icon>-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <v-list>-->
<!--              <v-list-item class="pa-0">-->
<!--                <v-list-item-content class="py-0 px-2">-->
<!--                  <div class="pa-2">-->
<!--                    <v-switch inset :color="compColor.primeColor" :dark="compColor.isInDarkMode" hide-details-->
<!--                            v-model="getSetSingleSearchField" :label="isMobileDevice ? '' : 'Einzelsuchfeld'" class="ma-0 pa-0"></v-switch>-->
<!--                  </div>-->
<!--                </v-list-item-content>-->
<!--              </v-list-item>-->
<!--            </v-list>-->
<!--          </v-menu>-->


        </div>
    </div>

</template>

<script>
import Vue from 'vue'
import lodash from 'lodash'
import {isMobile} from 'mobile-device-detect';

const validCharacters = /[^a-z0-9 .,:_öüäÖÜÄß-]/gi;

    export default Vue.component('lxAbdaSearchFields', {
        props: [
            'dataModel',
            'dataMethod',
            'dataLayout',
            'isOpen',
            'dataOrientation'
        ],
        data: function () {
            return {
                isMobileDevice: isMobile,
                searchModel: {},
                searchString: null,
                // showSingleField: false,
                searchDelayTimer: null,
                // searchModel: {},
                loadingSearch: false,
                // searchIngredient: null,
                // allColumnIds: [],
                panel: this.isOpen ? [0] : []
            }
        },
        methods: {
            cleanInputValues(character) {
                if (typeof character === 'string' && character.length > 0) {
                    let replacedString = character.replace(validCharacters, '');
                    if (replacedString !== character) {
                        this.$nextTick(function () {
                            this.searchString = replacedString
                        })
                    }
                } else if (typeof character === 'object') {
                    let tempObj = lodash.cloneDeep(character);
                    let foundValue = false;
                    for (let i in tempObj) {
                        if (tempObj[i] && tempObj[i].length > 0) {
                            let replacedString = tempObj[i].replace(validCharacters, '');
                            if (replacedString !== tempObj[i]) {
                                foundValue = true;
                                tempObj[i] = replacedString
                            }
                        }
                    }
                    if (foundValue) {
                        this.$nextTick(function () {
                            this.searchModel = tempObj
                        })
                    }
                }

                this.changed();
            },
            preventClick(event) {
                event.preventDefault(); // stop bubbling to top element
                event.stopPropagation(); //stop full bubbling
            },
            changed() {
                clearInterval(this.searchDelayTimer);

                this.searchDelayTimer = setInterval(() => {
                    clearInterval(this.searchDelayTimer);
                    let data = (this.getSetSingleSearchField === true) ? {searching: this.searchString} : this.searchModel

                    // NO EMPTY VALUES; filter dataset with null or empty string
                    data = Object.keys(data).reduce((prev, curr) => {
                      if (data[curr]) {
                        prev[curr] = data[curr]
                      }

                      return prev
                    }, {})


                    this.dataMethod(data);
                }, 700)
            },
            resetFields(){
                this.searchString = null;
                this.searchModel = {};
            },
        },
        computed: {},
        watch: {
            searchModel: {
                handler() {
                    this.cleanInputValues(this.searchModel)
                },
                deep: true
            },
            searchString: {
                handler() {
                    this.cleanInputValues(this.searchString)
                }
            },
            getSetSingleSearchField: {
                handler() {
                    if(!this.getSetSingleSearchField){
                        this.searchString = null
                    } else {
                        this.searchModel = {}
                    }
                    clearInterval(this.searchDelayTimer);
                    this.changed()
                }
            }
        }
    })
</script>

<style lang="scss">
.lx-abda-search-field{
  .v-input.v-input--switch .v-input__slot {
    height: 25px;
  }
}
</style>
