<template>
  <v-form @submit="forget" id="reset_fields">
    <div class="flex layout-column pt-4" style="max-width: 700px;">
      <ValidationObserver ref="LoginForm" v-slot="{ errors, valid, pristine, reset }" class="layout-column flex">
        <!--        <v-divider class="mx-5"></v-divider>-->
        <div class="flex px-10 pt-5 layout-column" style="overflow-y: auto;">

          <v-alert
            border="left"
            prominent
            style="font-size:15px;"
            class="pa-3 mx-2 mb-9"
            v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
            <template slot="prepend">
              <v-avatar>
                <v-icon large>mdi-information-variant</v-icon>
              </v-avatar>
            </template>
            Falls Sie ihr Passwort vergessen haben, können Sie an dieser Stelle die Zurücksetzung veranlassen und eine
            Neuvergabe anfordern.<br>
            Tragen Sie dafür bitte Ihren Benutzernamen <strong
            class="text-uppercase text-decoration-underline">oder</strong> ihre E-Mail Adresse in das unten stehende
            Formular ein und klicken Sie "Anfordern".
            Im Anschluss erhalten Sie eine E-Mail. Durch anklicken des enthaltenden Links öffnet sich im Browser ein
            Formular, in dem Sie ein neues Passwort vergeben können.
          </v-alert>

          <validation-provider tag="div" ref="Benutzername" name="Benutzername" rules="" v-slot="{ errors }">
            <v-text-field
              v-model="model.login"
              :error-messages="errors[0]"
              color="white"
              @keydown="resetAlert"
              dark
              outlined
              placeholder=" "
              prepend-inner-icon="supervised_user_circle"
              label="Benutzername"
              class="mx-2"
              :disabled="getCookieQuestionVisibility.visibility"
            ></v-text-field>
          </validation-provider>
          <div class="layout-row layout-align-center-center">
            <span class="white--text mb-7">oder</span>
          </div>
          <validation-provider tag="div" ref="Email" name="Email" rules="email" v-slot="{ errors }">
            <v-text-field
              v-model="model.email"
              :error-messages="errors[0]"
              color="white"
              @keydown="resetAlert"
              dark
              outlined
              placeholder=" "
              prepend-inner-icon="email"
              label="E-Mail"
              class="mx-2"
              :disabled="getCookieQuestionVisibility.visibility"
            ></v-text-field>
          </validation-provider>
          <div class="layout-row mx-2 my-5 pa-3 green white--text layout-align-start-center"
               style="border-left: 8px solid #5cd060 !important;border-radius: 3px;" v-if="serveremailsent && pristine">
            <!--                    v-if="loginError && pristine"-->
            <v-avatar>
              <v-icon dark large>error_outline</v-icon>
            </v-avatar>
            <div class="flex layout-column layout-align-start-center ml-2">
              <p class="ma-0">Eine Email mit einem Link zum Zurücksetzen Ihres Passwortes wurde an Sie gesendet.</p>
            </div>
          </div>
        </div>
        <v-divider class="mx-5"></v-divider>
        <div class="pa-2 d-flex flex-row">
          <div class="grow mr-2">
            <v-btn block class="green text-none white--text" type="submit"
                   :disabled="getCookieQuestionVisibility.visibility || pristine || errors['Email'] && errors['Email'].length > 0"
                   :loading="loading">Anfordern
            </v-btn>
          </div>
          <v-btn class="text-none" @click="clearForm(reset)" :disabled="pristine" title="Formularfelder leeren">
            <v-icon>clear</v-icon>
          </v-btn>
        </div>
      </ValidationObserver>
    </div>
  </v-form>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.component('ForgotPasswordComponent', {
  data() {
    return {
      model: {},
      loading: false,
      serveremailsent: false,
      servererror: false
    }
  },
  created() {
    // this.$store.dispatch('initAuth')
  },
  methods: {
    resetAlert() {
      this.serveremailsent = false
    },
    /**
     * Hide message, that email was sucessfully send to user
     */
    resetMailMessage() {
      this.serveremailsent = false
    },

    /**
     *  Send mail to user for changing password
     *
     *  Param for backend is user object with login name and/or email address
     *
     * @param ev
     */
    forget(ev) {
      if (this.loading) {
        return
      }

      ev.preventDefault(); // wichtig damit Formular mit Enter abgeschickt werden kann

      this.loading = true;

      let query = { login: null };

      if (this.model.login && this.model.email) {
        query.login = this.model.email
      } else {
        query.login = this.model.login || this.model.email
      }


      axios.post('/forgotPassword', query)
        .then(() => {
          this.loading = false;
          this.model = {};
          if (this.$refs.LoginForm) {
            this.$refs.LoginForm.reset()
          }
          this.serveremailsent = true

        })
        .catch(() => {
          this.loading = false;
          this.model = {};
          if (this.$refs.LoginForm) {
            this.$refs.LoginForm.reset()
          }
          this.servererror = true
        })
    },

    /**
     * Clear model an view of messages
     */
    clear() {
      this.model = {};
      this.serveremailsent = false;
      this.servererror = false
    },
    clearForm(reset) {
      reset();
      this.model = {};
      this.loginError = false
    }
  }
})
</script>
