<template>
  <div class="layout-row flex layout-align-center-center" title="Kommentar">
    <v-icon :dark="compColor.isInDarkMode">{{this.icon}}</v-icon>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    data: function () {
      return {
        icon: 'cancel'
      };
    },
    created(){
      if(this.params.menuIcon){
        this.icon = this.params.menuIcon
      }

    },
    beforeMount() {
    },
    mounted() {

    },
    methods: {

    }
  })
</script>
<style lang="scss">
</style>
