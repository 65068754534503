<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <lx-obfuscation-panel
      :enabled="!isModule"
      class="flex layout-column">

      <template v-slot:obfuscation-text>
        <div class="flex layout-column layout-align-center-center ma-2">
          <v-icon size="120" :color="compColor.prime">mdi-shield-lock</v-icon>
          <span class="subtitle-1 font-weight-light"
                v-bind:class="{'blue-grey--text text--darken-2': !isDarkMode, 'blue-grey--text text--lighten-4': isDarkMode}"
                style="max-width: 450px; text-align:center;">Dieses Modul ist nicht aktiviert</span>
        </div>
      </template>

      <ValidationObserver ref="ImportSpecialFeeForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">
        <div class="layout-column flex ma-2 elevation-5"
             style="border-radius:4px;position:relative;"
             v-bind:style="{background: compColor.contentFrame}">

          <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
            <div class="layout-row layout-wrap">
              <lx-btn
                :color="'#353a47'"
                :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                :disabled="!isModule || isBusy || files.length === 0"
                :label="'Importieren'"
                iconSize="20"
                width="auto"
                classes="text-none mr-1"
                @click="upload"
                icon="cloud_upload"
                tooltip="Hochgeladene Dateien Importieren"
                small
              ></lx-btn>
              <lx-btn
                :color="'#353a47'"
                :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                :label="'Datei(en) hinzufügen'"
                :disabled="!isModule || isBusy"
                iconSize="20"
                width="auto"
                classes="text-none mr-1"
                @click="openHiddenFileInput"
                icon="mdi-plus-circle"
                tooltip="Dateien zum Importliste hinzufügen"
                small
              ></lx-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <div>
                    <lx-btn :color="'#353a47'"
                            :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                            small
                            iconSize="20"
                            v-on="on"
                            width="auto"
                            height="40"
                            classes="text-none mr-1"
                            isMenu
                            :hide-label="true"
                            icon="settings"
                            tooltip="Weitere Aktionen"
                            :label="'Einstellungen'"></lx-btn>
                  </div>
                </template>
                <v-list v-bind:class="{'blue-grey darken-3': compColor.isInDarkMode}" class="py-0">
                  <v-list-item :dark="compColor.isInDarkMode"
                               @click="$router.push({path: '/administration/import/history'})">
                    <v-list-item-icon>
                      <v-icon :color="compColor.primeColor" :dark="compColor.isInDarkMode"
                              size="18">mdi-sort-clock-ascending-outline
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>History</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item :dark="compColor.isInDarkMode"
                               @click="$router.push({path: '/administration/specialfeeimport/settings/specialfee'})">
                    <v-list-item-icon>
                      <v-icon :color="compColor.primeColor" :dark="compColor.isInDarkMode"
                              size="18">settings
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Einstellungen</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item :dark="compColor.isInDarkMode" @click="openDeleteDialog">
                    <v-list-item-icon>
                      <v-icon :color="'#e7848b'" :dark="compColor.isInDarkMode"
                              size="18">delete
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Sonderentgeltartikel löschen</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="flex layout-row">
              <input type="file" hidden ref="hiddenFileInput" multiple @change="addFilesByDialog"/>
            </div>
          </div>

          <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

          <div class="flex layout-row lx-delivery-background pa-1" style="overflow-y: hidden">

            <div class="elevation-3 flex layout-column" style="border-radius: 6px;"
                 :style="{'background': compColor.lxExpansion.background}">
              <!--        <div ref="scrollContainer" style="overflow-y: auto;" class="flex pa-1 layout-column">-->

              <div
                class="layout-column flex ma-1 pa-2"
                @drop.prevent="addFilesByDragAndDrop"
                @dragenter.prevent="dropOverlayVisible = true"
                @dragleave.prevent="dropOverlayVisible = false"
                @dragover.prevent
                style="position:relative;border-radius:4px;"
              >
                <!--              || files.length === 0-->
                <v-overlay
                  absolute
                  :value="dropOverlayVisible || loading > 0"
                  :z-index="0"
                  @dragover.prevent style="pointer-events: none;"
                >
                  <div class="flex layout-column layout-align-center-center px-12">
                    <v-icon size="120" :color="compColor.primeColor">cloud_upload</v-icon>
                    <span class="subtitle-1 font-weight-light"
                          v-bind:class="{'blue-grey--text text--darken-2': !isDarkMode, 'blue-grey--text text--lighten-4': isDarkMode}"
                          style="max-width: 450px; text-align:center;">Ziehen Sie ihre Dateien aus ihrem Dateibrowser auf dieses Feld und lassen Sie sie los</span>
                  </div>
                </v-overlay>

                <lx-loading-animation :loading="loading > 0">
                  <span class="mb-n3 pa-0 font-weight-light" style="font-size:1em">Daten</span>
                  <span class="mb-n3 pa-0 font-weight-bold" style="font-size:3.5em;">werden</span>
                  <span class="mt-n3 pa-0 font-weight-light" style="font-size:2.1em">hochgeladen</span>
                </lx-loading-animation>

                <fake-input-frame label="Liste aller zu importierenden Dateien" class="flex pa-2"
                                  style="position: relative;">


                  <div class="flex layout-column layout-align-center-center px-12"
                       v-if="!dropOverlayVisible"
                       style="z-index:1;position: absolute;opacity:0.3;top:50%;left:50%;transform: translateX(-50%) translateY(-50%)">
                    <v-icon size="120" :color="compColor.primeColor">cloud_upload</v-icon>
                    <span class="subtitle-1 font-weight-light"
                          v-bind:class="{'blue-grey--text text--darken-2': !isDarkMode, 'blue-grey--text text--lighten-4': isDarkMode}"
                          style="max-width: 450px; text-align:center;">Ziehen Sie ihre Dateien aus ihrem Dateibrowser auf dieses Feld und lassen Sie sie los</span>
                  </div>

                  <div class="pa-2" style="position:absolute;left:0;top:0;right:0;bottom:0;z-index:2;overflow-y: auto;">

                    <v-alert
                      v-if="files.length > 0"
                      border="left"
                      outlined
                      text
                      class="py-2"
                      style="font-size:15px;"
                      :class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}"
                    >
                      <template slot="prepend">
                        <v-avatar>
                          <v-icon large>mdi-information-variant</v-icon>
                        </v-avatar>
                      </template>
                      Für das Importieren von <strong>NUB-Artikel</strong>, wählen Sie bitte die entsprechenden
                      Medizinischen Einrichtungen aus. Bei keiner Auswahl von Medizinischen Einrichtungen, werden diese
                      Artikel als <strong>ZE-Artikel</strong> importiert.
                    </v-alert>

                    <div v-for="(file, index) in files"
                         :key="index"
                         class="px-2 py-1"
                         :class="index < files.length - 1 ? 'mb-1' : ''"
                         :style="{'background': file.selected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
                         style="border-radius: 4px;">
                      <div class="layout-row">
                        <div class="pa-1 pr-2 layout-column layout-align-center-center">
                          <v-btn text icon @click="removeFile(file)"
                                 color="blue-grey darken-2"
                                 title="Entfernen">
                            <v-icon>delete_forever</v-icon>
                          </v-btn>
                        </div>
                        <v-divider vertical class="ml-1 mr-3"></v-divider>
                        <div class="layout-row flex py-2">
                          <div class="blue-grey--text text--darken-1 layout-column layout-align-center-start pl-3">
                            <span style="line-height: 16px;"><strong>{{ file.name }}</strong></span>
                            <small style="opacity: 0.6;line-height: 15px;">{{ Math.round(file.size / 1000) }} kb</small>
                          </div>
                          <v-divider vertical class="mx-3"></v-divider>
                          <div class="layout-row">
                            <v-select
                              class="chipSelect mb-0"
                              v-model="file.locations"
                              :dark="isDarkMode"
                              :background-color="isDarkMode ? 'transparent':'#f5f5f5'"
                              :color="compColor.primeColor"
                              :items="locations"
                              label="Standorte"
                              hide-details
                              :menu-props="{ bottom: true, offsetY: true }"
                              item-text="name"
                              item-value="_id"
                              chips
                              multiple
                              outlined
                              small-chips
                              deletable-chips
                            ></v-select>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </fake-input-frame>
              </div>

            </div>
          </div>
        </div>
      </ValidationObserver>

    </lx-obfuscation-panel>


    <lx-dialog v-model="showDeleteModal" :allow-drag="true" :overlay="true" :size="{x: '600px', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          Sonderentgeltartikel löschen
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <div class="flex layout-column pa-1">
            <v-alert
              border="left"
              outlined
              text
              :class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}"
            >Löschen Sie die Einträge nur, wenn Sie die Zusatzentgeltartikel deaktivieren möchten. Importieren Sie zum Ändern einfach einen neuen Datensatz.
            </v-alert>

            <v-list
              :dark="isDarkMode"
              :background-color="isDarkMode ? 'transparent':'#f5f5f5'"
              shaped
            >
              <v-list-item-group
                v-model="deleteListModel"
                multiple
              >
                <template v-for="(item, i) in articleInfoList">
                  <v-divider
                    v-if="!item"
                    :key="`divider-${i}`"
                  ></v-divider>

                  <v-list-item
                    v-else
                    :key="`item-${i}`"
                    :value="item"
                    :active-class="compColor.prime + '--text text--accent-4'"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title v-if="item.type==='ze_item'">ZE-Artikel-Liste</v-list-item-title>
                        <v-list-item-title v-else>NUB-Artikel für <b>{{ item.name }}</b></v-list-item-title>
                        <v-list-item-subtitle>{{ item.count }} Einträge vorhanden</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          :color="compColor.prime"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <lx-btn
            :color="'#353a47'"
            :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
            small
            iconSize="20"
            classes="text-none mr-1 flex"
            @click.stop="deleteLists"
            icon="delete_forever"
            tooltip="Ausgewählte Einrichtungen berreinigen"
            :label="'Entfernen'"
            :disabled="!deleteListModel || deleteListModel.length === 0"
          ></lx-btn>
          <lx-btn
            :color="'#353a47'"
            :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
            small
            iconSize="20"
            classes="text-none mr-1 flex"
            @click.stop="showDeleteModal = false"
            icon="cancel"
            tooltip="Vorschaufenster schließen"
            :label="'Schließen'"
          ></lx-btn>
        </div>
      </template>
    </lx-dialog>

  </div>
</template>

<script>
import LxObfuscationPanel from '@/components/lx_obfuscation_panel/LxObfuscationPanel'
import LxLoadingAnimation from '@/components/lx_loading_animation/LxLoadingAnimation'
import axios from "axios";

export default {
  name: 'ImportSpecialFee',
  components: {
    LxObfuscationPanel,
    LxLoadingAnimation
  },
  data() {
    return {
      // fileVisibility: true,
      loading: 0,
      showDeleteModal: false,
      articleInfoList: [],
      deleteListModel: [],
      dropOverlayVisible: false,
      locations: [],
      modules: [],
      files: [],
    }
  },
  computed: {
    isBusy() {
      return this.loading > 0
    },
    isModule() {
      return Array.isArray(this.modules) && this.modules.indexOf('ze_item') !== -1
    }
  },
  mounted() {
    this.loading++
    this.$http.get('/settings/modules')
      .then(res => {
        this.modules = res.data?.modules || []
      })
      .finally(() => {
        this.loading--
      })

    this.getLocations()
  },
  methods: {
    getLocations() {
      const filter = { config: { opts: { sort: { name: 1 } } } }

      this.loading++
      this.$http.get('/hospital', filter)
        .then(res => {
          this.locations = (res.data && Array.isArray(res.data.data)) ? res.data.data : []
        })
        .finally(() => {
          this.loading--
        })
    },
    /**
     * Browser benutzen seit hundert Jahren einen Scheiss Datei Dialog aus Button und Input wenn man <input type="file"/> benutzte
     * da das hässlich ist versteckt man dieses Ding und aktiviert es durch den Click eines Buttons der per ID pder Refs darauf zugreift
     * **/
    openHiddenFileInput() {
      this.$refs.hiddenFileInput.click()
    },
    /**
     * wird bei onChange aufgerufen wenn im Dateidialog Dateien ausgewählt werden
     * diese werden an Methode addFiles übergeben wo sie zerlegt und aufbereitet werden
     * **/
    addFilesByDialog() {
      let file = this.$refs.hiddenFileInput;
      if (file && file.files) {
        this.addFiles(file.files)
      }
    },
    /**
     * wird bei drop Event aufgerufen wenn im Drag and Drop Bereich Dateien fallengelassen wurden
     * diese werden an Methode addFiles übergeben wo sie zerlegt und aufbereitet werden
     * **/
    addFilesByDragAndDrop(e) {
      this.dropOverlayVisible = false
      if (this.isBusy) return;

      this.addFiles(e.dataTransfer.files)
    },
    /**
     * zerlegt die Files Objekte des Browsers und push sie dann in das this.files Array welches die Tabelle füllt
     * **/
    addFiles(files) {
      if (!files) return;

      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      ([...files]).forEach(f => {
        this.files.push(f);
      });
    },
    /**
     * entfernt beim click auf den Papierkorb in der Tabelle die zugehörige Datei
     * **/
    removeFile(file) {
      this.files = this.files.filter(f => {
        return f !== file;
      });
    },
    upload() {
      if (this.isBusy) return;

      this.loading++

      let formData = new FormData();
      let importFiles = {};

      this.files.forEach((f, x) => {
        const name = 'file' + (x + 1).toString();
        formData.append(name, f);
        if (Array.isArray(f.locations) && f.locations.length > 0) {
          formData.append(name + '_locations', JSON.stringify(f.locations))
        }

        importFiles[name] = f;
      });

      axios.post('/imports/specialfee', formData, { headers: { 'content-type': 'multipart/form-data' } })
        .then((res) => {
          const data = res.data;
          this.files = [];

          if (Array.isArray(data)) {
            data.forEach((file) => {
              const filename = file.filename;
              const title = `Datei Import von ${filename}`;
              const nFaulty = file.faulty;
              const nTotal = file.total;

              if (file.success !== true) {
                this.NotifySystem({
                  title: title,
                  text: file.message,
                  type: 'error',
                  duration: -1
                });
              } else {
                const msgType = (nFaulty === 0) ? 'success' : 'warning';

                this.NotifySystem({
                  title: title,
                  text: `Es wurden ${nTotal - nFaulty} von ${nTotal} Datensätze(n) importiert.</br>Davon waren ${nFaulty} fehlerhaft.`,
                  type: msgType,
                  duration: (nFaulty === 0) ? 3000 : -1
                });
              }
            });

            // Remove only success files from List
            data.forEach((result) => {
              if (result.success === false && importFiles[result.name]) {
                this.files.push(importFiles[result.name]);
              }
            });
          }
        })
        .finally(() => {
          this.loading--

          //reset file input um neue dateien hochladen zu können
          if (this.$refs['hiddenFileInput']) {
            this.$refs['hiddenFileInput'].value = ''
          }
        })
    },
    openDeleteDialog() {
      this.loading++

      this.$http.get('/abda/specialarticles/list')
        .then((res) => {
          this.articleInfoList = res.data
          this.deleteListModel = []
          this.showDeleteModal = !!this.articleInfoList

          if (!this.showDeleteModal) {
            this.NotifySystem('Es gibt keine aktiven Zusatzentgeltartikel')
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading--
        })
    },
    deleteLists() {
      this.showDeleteModal = false

      this.deleteListModel.forEach((item) => {
        this.loading++

        this.$http.delete('/abda/specialarticles/list/' + item._id)
          .then(this.NotifySuccess)
          .catch(this.NotifyError)
          .finally(() => {
            this.loading--
          })
      })
    },
  }
}
</script>

<style scoped>

</style>
