<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="back()"
                  icon="mdi-chevron-left"
                  :hide-label="true"
                  tooltip="zurück zur Übersicht"
                  :label="'Zurück'"></lx-btn>

          <lx-dropdown-button :color="'#353a47'"
                              :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                              small
                              hide-list-icons
                              iconSize="20"
                              width="auto"
                              :disabled="isLoadingData > 0"
                              classes="text-none mr-1 v-btn--tile"
                              icon="refresh"
                              tooltip="Ansicht aktualisieren"
                              :items="refreshItems"
                              @click="getData"
                              :label="'Aktualisieren'">
          </lx-dropdown-button>

        </div>

        <div class="flex layout-row">
        </div>

      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>


      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px"
             :style="{'background': compColor.lxExpansion.background}">


          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1); overflow-y: auto"
               class="flex layout-column">
            <v-container>
              <v-row no-gutters>
                <v-col>
                  <bar-chart
                      v-if="loaded"
                      :chartdata="chartdataUsers"
                      :options="optionsUsers"/>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import LxDropdownButton from '@/components/lx_dropdown_button/LxDropdownButton'
import BarChart from "@/components/charts/BarChart";

const translator = {
  active_accounts: 'Aktive Konten im Zeitraum',
  inactive_accounts: 'Inaktive Konten im Zeitraum',
  locked_accounts: 'Gesperrte Konten',
  total_accounts: 'Konten in Datenbank'
}

function toChartData(input, label) {
  let labels = [];
  let data = [];
  let bgcolors = [];
  let borders = [];

  Object.keys(input).forEach((k, i) => {

    labels.push(translator[k] || k);
    data.push(input[k]);

    let rgb = {
      r: Math.floor(((i % 3) / 3) * 64),
      g: Math.floor(((i % 6) / 6) * 128),
      b: Math.floor(((i % 9) / 9) * 196),
      d: 196 + ((i % 4) * 8)
    }

    borders.push(`rgba(${rgb.r + ((i % 2) * 32)}, ${rgb.g + 64}, ${rgb.d - rgb.b}, 1.00`)
    bgcolors.push(`rgba(${rgb.r + ((i % 2) * 32)}, ${rgb.g + 64}, ${rgb.d - rgb.b}, 0.50`)
  })

  return {
    labels: labels,
    datasets: [
      {
        label: label,
        borderColor: borders,
        backgroundColor: bgcolors,
        data: data
      },
    ]
  }
}

export default {
  name: 'StatisticsUsersDetails',
  components: {BarChart, LxDropdownButton},
  data() {
    const basicOptions = {
      responsive: true,
      maintainAspectRatio: false,
    }

    return {
      isLoadingData: 0,
      loaded: false,

      refreshItems: [
        {caption: 'Letzten 24 Stunden', value: 1, default: true},
        {caption: 'Letzten 7 Tage', value: 7},
        {caption: 'Letzten 30 Tage', value: 30},
        {caption: 'Letzten 60 Tage', value: 60},
      ],

      chartdataUsers: null,
      optionsUsers: {
        ...basicOptions,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        title: {
          display: true,
          text: 'Frontend Versionen'
        }
      },
    }
  },
  async mounted() {
    this.getData()
  },
  methods: {
    back() {
      window.history.back()
    },
    getData(event, dayspanValue) {
      this.loaded = false

      this.$http.get('statistics/data/users', {dayspan: dayspanValue || 1})
          .then((res) => {
            this.chartdataUsers = toChartData(res.data.o, 'Benutzerkonten')
          })
          .catch(this.NotifyError)
          .finally(() => {
            this.loaded = true
          })
    }
  }

}
</script>

<style scoped>

</style>