<template>
    <div class="flex px-10 py-6" style="overflow-y: auto;">
        <v-row no-gutters>
            <v-col cols="12">
                <v-alert
                        border="left"
                        prominent
                        style="font-size:15px;"
                        class="pa-3"
                        v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
                    <template slot="prepend">
                        <v-avatar>
                            <v-icon large>mdi-information-variant</v-icon>
                        </v-avatar>
                    </template>
                    Herzlichen Glückwunsch. Nach der Anmeldung haben Sie Zugriff auf die Organisation
                    <strong class="text-decoration-underline" v-if="company">"{{company}}"</strong>.
                </v-alert>
            </v-col>
        </v-row>

        <v-divider class="mx-5"></v-divider>

        <div class="pa-2 d-flex flex-row">
            <div class="grow mr-2">
                <v-btn block class="green text-none white--text" @click="redirect">Zum Login</v-btn>
            </div>
        </div>

    </div>
</template>

<script>

export default {
  name: 'RegistrationJoinUserComponent.vue',
  props: {
    company: String,
    email: String,
    token: String,
    redirect: {
      type: Function,
      default () {
        return () => {}
      }
    },
  }
}

</script>

<style scoped>

</style>