<template>
  <v-menu
      offset-y
      transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <div>
        <lx-btn
            :color="color"
            :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
            small
            iconSize="20"
            width="auto"
            classes="text-none rounded-r-0"
            :icon="icon"
            :tooltip="tooltip"
            :label="getDefaultActionCaption"
            :loading="loading > 0"
            :disabled="disabled || !hasDefaultAction"
            :hide-label="hideLabel"
            @click.self="clickDefault"
        ></lx-btn>
        <lx-btn
            :color="color"
            :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
            small
            hide-label
            iconSize="20"
            width="auto"
            classes="text-none rounded-l-0"
            icon="mdi-dots-vertical"
            :tooltip="tooltip"
            :disabled="disabled || !Array.isArray(items) || items.length === 0"
            v-bind="attrs"
            v-on="on"
        ></lx-btn>
      </div>
    </template>
    <v-list
        :color="compColor.primary"
        :dark="isDarkMode"
        :dense="denseFields"
    >
      <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :disabled="item.disabled === true"
          @click="clickSelect(item, index, $event)"
      >
        <v-list-item-icon v-if="!hideListIcons">
          <v-icon
              small
              v-text="item.icon"
          ></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
              style="cursor: pointer"
              v-text="item.caption"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "LxDropdownButton",
  props: {
    options: Object,
    url: String,
    color: {
      type: String,
      default () {
        return '#353a47'
      }
    },
    label: String,
    tooltip: String,
    disabled: {
      type: Boolean,
      default () {
        return false
      },
    },
    icon: {
      type: String,
      default () {
        return 'mdi-file-chart-outline'
      }
    },
    items: {type: Array, default() {return []}},
    hideLabel: {
      type: Boolean,
      default () {
        return false
      }
    },
    hideListIcons: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      loading: 0,
      defaultAction: -1
    }
  },
  computed: {
    hasDefaultAction () {
      return this.defaultAction !== -1 || this.items.find((e) => e.default === true) !== undefined
    },
    getDefaultActionCaption () {
      if (!Array.isArray(this.items)) {
        return ''
      }

      let item = undefined;

      if (this.defaultAction !== -1) {
        item = this.items[this.defaultAction]
      } else {
        item = this.items.find((e) => e.default === true)
      }

      if (item && item.caption) {
        return item.caption
      }

      return ''
    }
  },
  methods: {
    clickSelect (item, index, event) {
      if (!item || isNaN(index)) {
        return
      }

      this.defaultAction = index
      this.clickDefault(event)
    },
    clickDefault (event) {
      let item = undefined
      let index = -1

      if (this.defaultAction === -1) {
        item = this.items.find((e) => e.default === true)
      } else {
        index = this.defaultAction
        item = this.items[this.defaultAction]
      }

      if (item && event) {
        this.$emit('click', event, item.value, index)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>