<template>
  <div class="pa-0 elevation-2">
    <div class="layout-row" v-bind:class="[compColor.contentFrameBack]">
      <div class="white flex-30 pa-4 ma-2 layout-column layout-align-center-center"
           style="border:1px solid #eee !important;border-radius:3px;">
        <img
          src="https://xmedia.tv/apotv/pzn/image.php?id=42695&token=cffac484c5df2fc2b3f561c770a79aa4&width=300&height=200"
          style="width:100%"/>
      </div>
      <div class="flex pa-2 layout-column" style="position:relative;" v-bind:class="[compColor.widget.text]">
        <div class="font-weight-bold subtitle-1 mt-2" style="line-height: 1.0rem;">EUCABAL Balsam S 50 ML1)</div>
        <v-divider class="my-1" v-bind:class="[compColor.divider]"></v-divider>
        <div class="flex body-2">Eucabal- Balsam S. Wirkstoff: Eukalyptusöl, Kiefernnadelöl. Anwendungsgebiete: zur
          Besserung der Beschwerden bei Erkältungskrankheiten der Atemwege mit zähflüssigem Schleim.<br>mehr...
        </div>
        <div class="layout-row layout-align-end">
          <v-chip
            class="headline font-weight-black pa-5 elevation-4"
            color="pink"
            label
            text-color="white"
          >
            <v-icon left>euro_symbol</v-icon>
            5,95
          </v-chip>
        </div>
      </div>
    </div>
    <v-divider v-bind:class="[compColor.divider]"></v-divider>
    <div class="layout-row" v-bind:class="[compColor.contentFrameBack]">
      <div class="white flex-30 pa-4 ma-2 layout-column layout-align-center-center"
           style="border:1px solid #eee !important;border-radius:3px;">
        <img
          src="https://xmedia.tv/apotv/pzn/image.php?id=40212&token=7012ad837668b8c048574a81aec9e021&width=300&height=200"
          style="width:100%"/>
      </div>
      <div class="flex pa-2 layout-column" style="position:relative;" v-bind:class="[compColor.widget.text]">
        <div class="font-weight-bold subtitle-1 mt-2" style="line-height: 1.0rem;">DORITHRICIN Halstabletten Classic
          oder Waldbeere 20 ST1)
        </div>
        <v-divider class="my-1" v-bind:class="[compColor.divider]"></v-divider>
        <div class="flex body-2">Dorithricin® Halstabletten Classic. Wirkstoff:
          Tyrothricin,Benzalkoniumchlorid,Benzocain. Anwendungsgebiete: Zur symptomatischen Behandlung bei Infektionen
          des Mund- und Rachenraumes mit Halsschmerzen und Schluckbeschwerden.<br>mehr...
        </div>
        <div class="layout-row layout-align-end">
          <v-chip
            class="headline font-weight-black pa-5 elevation-4"
            color="pink"
            label
            text-color="white"
          >
            <v-icon left>euro_symbol</v-icon>
            5,95
          </v-chip>
        </div>
      </div>
    </div>
    <v-divider v-bind:class="[compColor.divider]"></v-divider>
    <div class="layout-row" v-bind:class="[compColor.contentFrameBack]">
      <div class="white flex-30 pa-4 ma-2 layout-column layout-align-center-center"
           style="border:1px solid #eee !important;border-radius:3px;">
        <img
          src="https://xmedia.tv/apotv/pzn/image.php?id=39306&token=62db5734c121c52916b44a8db7e0ec3c&width=300&height=200"
          style="width:100%"/>
      </div>
      <div class="flex pa-2 layout-column" style="position:relative;" v-bind:class="[compColor.widget.text]">
        <div class="font-weight-bold subtitle-1 mt-2" style="line-height: 1.0rem;">VITAMIN C-RATIOPHARM retard 500 mg
          Kapseln 100 ST
        </div>
        <v-divider class="my-1" v-bind:class="[compColor.divider]"></v-divider>
        <div class="flex body-2">VITAMIN C-ratiopharm® retard 500 mg Hartkapseln, retardiert. Wirkstoff: Ascorbinsäure.
          Anwendungsgebiete: Therapie von Vitamin-C-Mangelkrankheiten. Warnhinweis: Hartkapseln enthalten Sucrose.<br>mehr...
        </div>
        <div class="layout-row layout-align-end">
          <v-chip
            class="headline font-weight-black pa-5 elevation-4"
            color="pink"
            label
            text-color="white"
          >
            <v-icon left>euro_symbol</v-icon>
            15,95
          </v-chip>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Advertisment',
  data() {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
