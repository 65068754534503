<template>

  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">

          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="refresh"
                  :hide-label="true"
                  :tooltip="$t('buttons.refresh.tooltip')"
                  :label="$t('buttons.refresh.title')"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="$router.push({path: '/administration/delivery_dates/new'})"
                  icon="add_box"
                  :tooltip="$t('buttons.adddeliveryplan.tooltip')"
                  :label="$t('buttons.adddeliveryplan.title')"></lx-btn>
        </div>
        <div class="flex layout-row"></div>

      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px;"
             :style="{'background': compColor.lxExpansion.background}">


          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1);" class="flex layout-column">
            <lx-data-table
              v-model="tableObj.selection"
              tableName="DeliveryDateList"
              :items="items"
              :headers="tableObj.headers"
              :loading="tableObj.loading"
              :tableOptions="tableObj.options"
              :hideContextFilter="true"
              @changeOptions="changeTableOptions"
              @rowClicked="openDeliveryDateDetails">

              <template v-slot:cellrenderer="{ value }">
                <div v-if="value.f.renderer === 'delete'" style="padding:1px;height:35px;line-height:35px;">
                  <lx-btn :color="'#353a47'"
                          :icon-color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                          :disabled="tableObj.loading"
                          iconSize="22"
                          height="30"
                          width="auto"
                          as-text
                          as-icon
                          classes="text-none mr-1"
                          @click.stop="askForDeletion(value.i)"
                          icon="mdi-delete-forever"
                          :hide-label="true"
                          tooltip="Lieferplan löschen"
                          :label="'Löschen'"></lx-btn>
                  <!--                  <v-icon :color="compColor.prime" @click.stop="askForDeletion(value.i)">mdi-delete-forever</v-icon>-->
                </div>
                <div v-else-if="value.f.renderer === 'icon'" style="padding:1px;height:35px;line-height:35px;">
                  <v-icon v-if="value.i[value.f.value]" :color="compColor.prime">mdi-check</v-icon>
                </div>
              </template>
            </lx-data-table>
          </div>
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="layout-row pa-0">
        <lx-pagination :page-name="'orderList'"
                       :page-options="pagingOptions"
                       :page-change="getData">
        </lx-pagination>
      </div>

    </div>

    <v-dialog v-model="deleteDialog" persistent width="90%" max-width="400" overlay-opacity="0.9">
      <v-card class="flex" :dark="compColor.isInDarkMode" v-bind:class="[compColor.contentFrameBack]">
        <v-card-title class="title elevation-4 layout-row pa-0">
          <div class="pa-1 flex" v-bind:class="[compColor.contentFrameBack]"
               style="z-index: 2;border-radius:4px 4px 0 0;">
            <div class="py-1 px-1 layout-row layout-align-start-center"
                 v-bind:class="[compColor.contentFrameFront,compColor.widget.headline]">
              <div class="layout-column layout-align-center-center pr-3">
                <v-avatar :color="compColor.avatarTile" tile height="40" width="40"
                          style="min-width:40px;">
                  <v-icon :color="compColor.primeColor">delete_forever</v-icon>
                </v-avatar>
              </div>
              Löschen bestätigen
            </div>
          </div>
        </v-card-title>
        <v-divider v-bind:class="compColor.textInput" v-if="compColor.isInDarkMode"></v-divider>
        <v-card-text class="pa-5" v-if="deliveryPlanToDelete">
          Möchten Sie den ausgewählten Lieferplan <strong>"{{ deliveryPlanToDelete.name }}"</strong> wirklich
          löschen? Eine Wiederherstellung ist nicht möglich.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <div class="layout-row" style="width:100%;">
            <!--                    <v-spacer></v-spacer>-->
            <v-btn :dark="compColor.isInDarkMode" class="white--text text-none mx-1 flex"
                   :color="compColor.primeColor" @click="deleteDeliveryPlan()">
              Ja
            </v-btn>
            <v-btn :dark="compColor.isInDarkMode" class="white--text text-none mx-1 flex"
                   :color="compColor.primeColor" @click="deleteDialog = null">
              Nein
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--    </div>-->

  </div>
</template>
<script>


import {AgGridVue} from 'ag-grid-vue'
import router from '../../../router'
import CheckIconCellRenderer from '../../../components/lx_ag-grid/components/cellRenderer/booleanCheckIconCell'
import agGridComponentButtonCellEditor from "@/components/lx_ag-grid/components/cellRenderer/buttonActionCell"
// import agGridComponentButtonCellEditor from '../../../components/lx_ag-grid/components/cellRenderer/buttonActionCell'

const customComparator = (valueA, valueB) => {
  if (!valueA || !valueB) {
    return false
  }
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

export default {
  name: 'DeliveryDateList',
  data() {
    return {
      tableObj: {
        loading: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
        options: {
          sortBy: ['name'],
          sortDesc: [false],
          // getClassForItem: (row) => {
          //   if (row.isExpanded) {
          //     return 'row-bold'
          //   }
          //   return ''
          // }
        },
        filterValue: null,
        selection: null,
        headers: [
          {
            width: '30px',
            sortable: true,
            sticky: 'left',
            renderer: 'delete',
            noResize: true,
            alternateText: 'Löschen'
          },
          {
            text: 'Lieferplanname',
            value: 'name',
            width: '350px',
            sortable: true
          },
          {
            text: 'Wochenendlieferung',
            value: 'with_weekend',
            width: '120px',
            sortable: true,
            renderer: 'icon'
          },
          {
            text: 'Kommentar',
            value: 'comment',
            sortable: true
          }
        ]
      },
      items: [],

      isLoadingData: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
      frameworkComponents: null,
      columnDefs: null,
      rowData: null,
      FilterAreFloating: false,
      allowBlurOnLoading: true,
      defaultSortOpts: {
        colId: 'name',
        sort: 'asc'
      },
      deleteDialog: false,
      noDeletionPossibleDialog: false,
      deliveryPlanToDelete: null,
    }
  },
  components: {
    AgGridVue,
    // DeleteDialog
  },
  created() {
    this.createColumnDefs();
  },
  beforeMount() {
    this.frameworkComponents = {
      checkIconCellRenderer: CheckIconCellRenderer,
      agGridComponentButtonCellEditor: agGridComponentButtonCellEditor,
    }
  },
  methods: {
    /**
     * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
     */
    changeTableOptions(value) {
      if (value) {
        this.tableObj.options = value
      }
      this.getData()
    },
    /**
     * Methode wird vom Grid angesprungen wenn man doppelt auf eine Zeile klickt. Dann wird die Detailansicht geöffent.
     * Kann auch an einen Editbutton in einer Spalte gehangen werden aber Doppelklick ist komfortabler.
     * **/
    openDeliveryDateDetails(params) {
      if (params?._id) {
        router.push({ path: `/administration/delivery_dates/${params._id.toString()}/details` });
      }
    },
    /**
     * hier werden wie gewohnt die Spalten des Grids definiert,
     * Besonderheit hier ist nur:
     *
     *     filterParams: {
     *       newRowsAction: 'keep'
     *    }
     *
     * dies ist Wichtig damit nach "getData" ein eventueller Eintrag im Filterinput erhalten bleibt
     * **/
    createColumnDefs() {
      this.columnDefs = [
        // {
        //     headerName: '',
        //     width: 60,
        //     headerCheckboxSelection: true,
        //     headerCheckboxSelectionFilteredOnly: false,
        //     checkboxSelection: true,
        //     pinned: 'left',
        //     sortable: false,
        //     filter: false
        // },
        {
          headerName: '',
          width: 50,
          pinned: 'left',
          filter: false,
          sortable: false,
          cellRendererSelector: function () {
            return {
              component: 'agGridComponentButtonCellEditor'
            }
          },
          cellRendererParams: {
            icon: 'delete_forever',
            action: this.askForDeletion,
            btntype: true
            // alreadySet: this.listModel
          }
        },
        {
          headerName: 'Lieferplanname',
          field: 'name',
          filter: false,
          comparator: customComparator,
          filterParams: {
            newRowsAction: 'keep'
          },
          headerComponentParams: {
            sortChangedAction: this.sortChanged
          }
        },
        {
          headerName: 'Wochenendlieferung',
          field: 'with_weekend',
          filter: false,
          width: 200,
          sortable: false,
          filterParams: {
            newRowsAction: 'keep'
          },
          cellRenderer: 'checkIconCellRenderer',
          cellRendererParams: {
            icon: 'message',
            color: this.compColor.primeColor
          }
        },
        {
          headerName: 'Kommentar',
          field: 'comment',
          filter: false,
          width: 300,
          filterParams: {
            newRowsAction: 'keep'
          },
          headerComponentParams: {
            sortChangedAction: this.sortChanged
          }
        }
      ]
    },
    askForDeletion(val) {
      if (val && val._id) {
        this.deliveryPlanToDelete = val;
        //
        // if (!this.isPharmacy && val.is_active) {
        //   this.noDeletionPossibleDialog = true;
        // } else {
        if (!this.isPharmacy && val.is_active) {
          this.noDeletionPossibleDialog = true;
        } else {
          this.DialogConfirm(
            'Löschen eines Lieferplans',
            'Möchten Sie den ausgewählten Lieferplan "' + this.deliveryPlanToDelete.name + '" wirklich löschen? Eine Wiederherstellung ist nicht möglich.',
            { ok: 'Löschen', cancel: this.$t('buttons.cancel.title') }
          )
            .then(() => {
              this.$http.delete('/deliveryplan/' + this.deliveryPlanToDelete._id)
                .then(this.NotifySuccess)
                .catch(this.NotifyError)
                .finally(this.getData)
            })
            .catch(() => {
            })
        }
      }
    },
    /**
     * Wird verschiedenen Stellen aus aufgerufen und soll die Daten vom Backend abholen. Mittels axios....bla bla bla
     * Besonderheit:
     * "this.pagingOptions.count = res.data.count"
     * - Anzahl der Datensätze vom Server wird auch in den pagingOptions gespeichert
     *
     * "this.$store.dispatch('setPagingOptions', this.pagingOptions)"
     * - nach der Speicherung der Anzahl werden die pagingOptions wieder in den Store geschrieben
     *
     * "this.gridOptions.api.ensureIndexVisible(this.$store.getters.gridScrollIndex, 'top')"
     * - sorgt dafür das nach dem neuladen der Daten das Grid wieder an die Stelle scrollt wo es war. notwendig z.B. bei der Rückkehr zum Grid
     * **/
    getData(options) {
      if (this.tableObj.loading) return
      this.tableObj.loading = true
      // let query = '{}';

      if (options && typeof options.skip === 'number') {
        this.pagingOptions.skip = options.skip
      }
      if (options && options.limit) {
        this.pagingOptions.limit = options.limit
      }

      const params = this.$helper.GetQueryParam({
        ...this.tableObj.options,
        skip: this.pagingOptions.skip,
        limit: this.pagingOptions.limit,
        query: {},
        count: true,
      })

      this.$http.get('/deliveryplans', params)
        .then(res => {
          if (res.data) {
            this.items = res.data.result || []
            this.pagingOptions.count = res.data.count || 0
          }
        })
        .catch(this.NotifyError)
        .finally(this.tableObj.loading = false)
    }
  },
  computed: {}
}
</script>
<!-- Add 'scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
