<template>
  <component v-bind:is="activeView"></component>
</template>

<script>
import UserDetails from '@/views/Administration/Rights/UserDetails'
import UserDetailsRoot from '@/views/Administration/Rights/UserDetailsRoot'

export default {
  name: 'UserDetailsView',
  components: {
    UserDetails,
    UserDetailsRoot
  },
  data () {
    return {
      isRootUser: this.getUser().is_system,
      activeView: (this.getUser().is_system === true) ? UserDetailsRoot : UserDetails
    }
  }
}
</script>