<template>
  <div class="flex layout-row layout-align-center-center" style="position: relative;">
    <div class="flex"></div>
    <div class="layout-row layout-align-center-center pa-1">
      <lx-btn :color="compColor.nav.contentFrame"
              :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
              small
              iconSize="22"
              width="30"
              height="30"
              classes="ml-1"
              @click="firstPage"
              :disabled="pageIndex === 1"
              icon="first_page"
              tooltip="Zur ersten Seite"></lx-btn>

      <lx-btn :color="compColor.nav.contentFrame"
              :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
              small
              iconSize="22"
              width="30"
              height="30"
              classes="ml-1"
              @click="prevPage"
              :disabled="pageCount === 0 || pageIndex === 1"
              icon="chevron_left"
              tooltip="Eine Seite zurück"></lx-btn>

      <div class="layout-row">

        <div class="ml-1 px-3 layout-column layout-align-center-center blue-grey--text text--darken-2 body-2"
             style="border-radius: 3px;"
        :style="{background: compColor.isInDarkMode ? 'rgba(0,0,0,0.2)' : 'rgba(255,255,255,0.3)', height: denseFields ? '27px':'30px'}">
          <span><small>Seite </small>{{ pageIndex }}</span>
        </div>

      <v-menu bottom>
        <template v-slot:activator="{ on }">

          <v-btn :height="denseFields ? '27':'30'" :color="compColor.nav.contentFrame" v-on="on" class="ml-1 white--text elevation-0 text-none">
              <v-icon class="mr-2" :color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor">arrow_drop_up</v-icon>
              <span :class="isDarkMode || isMediumMode ? compColor.primeHighlightClass : compColor.primeClass">{{limit}} <small>pro Seite</small> </span>

          </v-btn>
        </template>
        <v-list>
          <v-subheader>Datensätze pro Seite</v-subheader>
          <v-divider></v-divider>
          <v-list-item-group color="primary">
            <v-list-item v-for="(page, key) in pageSizes" :key="key" @click="changePageSize(page.text)">
              <v-list-item-content>
                <v-list-item-title v-text="page.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

        <div class="ml-1 px-3 layout-row layout-align-center-center blue-grey--text text--darken-2 body-2"
             style="border-radius: 3px;"
             :style="{background: compColor.isInDarkMode ? 'rgba(0,0,0,0.2)' : 'rgba(255,255,255,0.3)', height: denseFields ? '27px':'30px'}">
          <v-icon small v-bind:class="['mr-1','blue-grey--text', {'text--darken-3': compColor.isInDarkMode, 'text--lighten-2': !compColor.isInDarkMode}]">mdi-database-search</v-icon>
<!--          <span>{{pageCount}}</span>-->
          <span>{{ ((pageIndex - 1) * limit) + 1 }}<small> bis </small>{{ (((pageIndex - 1) * limit) + pageCount) }}</span>
        </div>

      </div>
      <lx-btn :color="compColor.nav.contentFrame"
              :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
              small
              iconSize="22"
              width="30"
              height="30"
              classes="ml-1"
              @click="nextPage"
              :disabled="pageCount < limit"
              icon="chevron_right" tooltip="Eine Seite weiter"></lx-btn>
    </div>
    <div class="flex"></div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.component('LxPaginationLight', {
    props: {
      pageOptions:{
        type: Object,
        required: true
      },
      pageChange: {
        type: Function,
        default: null
      },
      pageName: {
        type: String,
        default: null
      }
    },
    data: function () {
      return {
        limit: 50,
        skip: 0,
        pageCount: 0,
        pageIndex: 1,
        pageSizes: [
          {text: 5},
          {text: 10},
          {text: 25},
          {text: 50},
          {text: 100},
          {text: 200}
        ]
      }
    },
    methods: {
      doExecute: function () {
        if (this.pageChange && typeof this.pageChange === 'function') {
          this.skip = Math.ceil((this.pageIndex - 1) * this.limit)
          this.pageChange({skip: this.skip, limit: this.limit})
        }
      },
      prevPage() {
        this.pageIndex = Math.max(--this.pageIndex, 1);
        this.doExecute()
      },
      nextPage() {
        this.pageIndex++
        this.doExecute()
      },
      firstPage() {
        this.pageIndex = 1;
        this.doExecute()
      },
      changePageSize(param) {
        this.limit = param;
        this.pageIndex = 1
        this.doExecute()
      }
    },
    watch: {
      pageOptions: {
        handler(value) {
          if(value.limit){
            this.limit = value.limit
          }
          if(value.count || value.count === 0){
            this.pageCount = value.count
          }
          if(value.skip === 0){
            this.pageIndex = 1
          }
        },
        deep: true
      },
    }
  })
</script>

<style scoped lang="scss">
  .v-subheader {
    font-size: 13px;
  }

  .v-btn.v-btn--small {
    padding: 0 4px !important;
  }

  .lxpager {
    margin: 2px 2px;
    padding: 6px 6px;
    color: #000;
    cursor: pointer;
  }

  .lxpager.disabled {
    color: #CCC;
  }

  .v-btn {
    min-width: auto;

    &.btn--small {
      max-width: 28px;
    }
  }

  .list__tile__content.pagesize-is-active {
    .list__tile__title {
      font-weight: bold;
      color: #00bfa5;
    }
  }

  .v-list-item__content {
    padding: 0 !important;
  }
</style>
