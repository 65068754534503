<template>
  <div class="pa-0 red">
    <div style="position:absolute;top:0;left:0;right:0;bottom:0;opacity: 0.2;"
         class="layout-column layout-align-center-center" v-bind:class="[compColor.widget.text]">
      <v-icon size="40" color="blue-grey">mdi-widgets</v-icon>
      <div class="caption my-1" style="line-height: 1.0rem;">kein Widget hinterlegt
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'News'
}
</script>
