<template>
  <v-form @submit="initialLogin" id="login_fields">
    <div class="flex layout-row pt-4" style="max-width: 700px;min-height:450px;">
      <ValidationObserver ref="LoginForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">
        <!--                <v-divider class="mx-5"></v-divider>-->

        <div class="flex layout-column" style="overflow-y: auto;">
          <div class="px-10 pt-3 layout-column" v-if="twoFA">
            <h2 class="white--text mb-0 mt-0">Zwei-Faktor-Authentisierung</h2>
            <span class="white--text mb-6 body-2">Die Zwei-Faktor-Authentisierung ist aktiviert. Bitte öffnen Sie nun Ihre Authentikator App, tragen den aktuellen Zugriffscode in die unten stehenden Felder ein und bestätigen die Eingabe.  </span>

            <div class="layout-column pt-3"
                 style="border:1px solid white;border-radius:5px;background:rgba(255,255,255,0.15)">

              <code-input v-model="model.passcode" ref="codeInput" :enter-func="initialLogin"
                          :error-reset-func="resetErrors" :style-version="1"></code-input>

            </div>

          </div>

          <div class="px-10 pt-5 layout-column" v-show="!twoFA">
            <validation-provider tag="div" ref="Benutzername" name="Benutzername" rules="required"
                                 v-slot="{ errors }">
              <v-text-field
                :disabled="getCookieQuestionVisibility.visibility"
                v-model="model.login"
                :error-messages="errors[0]"
                color="white"
                dark
                outlined
                autofocus
                prepend-inner-icon="supervised_user_circle"
                label="Benutzername oder E-Mail"
                placeholder=" "
                class="mx-2 white--text"
              ></v-text-field>
              <!--              autofocus-->
            </validation-provider>
            <validation-provider tag="div" ref="Passwort" name="Passwort" rules="required"
                                 v-slot="{ errors }">
              <v-text-field
                :disabled="getCookieQuestionVisibility.visibility"
                autocomplete="off"
                v-model="model.password"
                :error-messages="errors[0]"
                color="white"
                prepend-inner-icon="fingerprint"
                label="Passwort"
                dark
                outlined
                placeholder=" "
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                :type="showPassword ? 'text' : 'password'"
                hint="Wir empfehlen mindestens 8 Zeichen zu verwenden."
                @click:append="showPassword = !showPassword"
                class="mx-2 mt-4"
              ></v-text-field>
            </validation-provider>
          </div>

          <v-alert
            v-if="(!twoFA && loginError && !pristine) || (twoFA && loginError)"
            border="left"
            prominent
            color="red"
            style="font-size:15px;"
            class="px-5 py-3 mt-10 mx-12 red white--text"
          >
            <template slot="prepend">
              <v-avatar>
                <v-icon large>mdi-information-variant</v-icon>
              </v-avatar>
            </template>
            Ihre Anmeldedaten sind nicht korrekt. Bitte versuchen Sie es erneut.
          </v-alert>

        </div>
        <v-divider class="mx-5"></v-divider>
        <div class="pa-2 d-flex flex-row">
          <div class="grow mr-2" v-if="twoFA">
            <v-btn block class="green text-none white--text" type="submit"
                   :disabled="!model || !model.passcode || model.passcode.length < 6"
                   :loading="loading">Zugriffscode bestätigen
            </v-btn>
          </div>
          <div class="grow mr-2" v-else>
            <!--                        <div class="grow mr-0">-->
            <v-btn block class="green  text-none white--text" type="submit"
                   :loading="loading"
                   :disabled="!valid || pristine || getCookieQuestionVisibility.visibility">Anmelden
            </v-btn>
            <!--                        </div>-->
          </div>

          <v-btn class="text-none" @click="clearForm(reset)" :disabled="pristine"
                 title="Formularfelder leeren">
            <v-icon>clear</v-icon>
          </v-btn>
        </div>
      </ValidationObserver>
    </div>


  </v-form>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import CodeInput from '../components/CodeInput'
import moment from 'moment'

const LOCAL_STORE = process.env.VUE_APP_BASE_NAME + '-trust-token';

export default Vue.component('LoginComponent', {
  name: 'LoginComponent',
  components: {
    CodeInput
  },
  data() {
    return {
      twoFA: false,
      count: 0,

      model: {},
      showPassword: false,
      messageStr: '',
      loading: false,
      loginError: false,

    }
  },
  created() {
    if (this.authStore.getters.isLoggedIn) {
      this.logout(true)
      this.model = {}
    }
  },
  watch: {},
  computed: {},
  methods: {
    initialLogin(ev) {
      this.loading = true;
      this.ResetMessaging();

      ev.preventDefault(); // wichtig damit Formular mit Enter abgeschickt werden kann

      let trusted = JSON.parse(window.localStorage.getItem(LOCAL_STORE));

      if (trusted && trusted.expire && trusted.token) {
        let timeNow = moment();
        let expireTime = moment(trusted.expire);
        let isafter = expireTime.isAfter(timeNow);
        if (isafter) {
          this.model.trust_token_id = trusted.token
        } else {
          window.localStorage.removeItem(LOCAL_STORE)
        }
      }

      this.model.frontend_info = {
        version: 1,
        fields: [
          'dashboard', 'ag_grids'
        ]
      }

      axios.post('/login', this.model)
        .then(resLogin => {

          if (resLogin.data && resLogin.data.trust_token_id) {
            let trusted = {
              token: resLogin.data.trust_token_id,
              expire: resLogin.data.trust_token_expire
            };
            window.localStorage.setItem(LOCAL_STORE, JSON.stringify(trusted))
          }

          // Login in auth store
          this.authStore.dispatch('login', { data: resLogin.data, type: 'login' });
          this.model = {}; // falls man sich in der anwendung erneut einloggen muss damit die name und pwd nicht vom letzten versuch drin stehen
          this.twoFA = false;
          this.resetErrors();
          this.$refs['codeInput'].resetSingleFields();
          if (this.$refs.ReLoginForm) {
            this.$refs.ReLoginForm.reset()
          }
        })
        .catch(error => {
          let errorMsg = error.message;

          if (errorMsg.indexOf('406') >= 0) {
            this.twoFA = true;
            this.resetErrors()
          } else {
            this.error = errorMsg;
            this.loginError = true;
            if (this.$refs.ReLoginForm) {
              this.$refs.ReLoginForm.reset()
            }
            if (this.twoFA) {
              this.model.passcode = null;
              this.$refs['codeInput'].resetSingleFields()
            } else {
              this.model = {}
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetErrors() {
      this.loginError = false;
    },
    clearForm(reset) {
      reset();
      this.twoFA = false;
      this.resetErrors();
      this.model = {};

      this.$refs['codeInput'].resetSingleFields()
    }
  }
})
</script>
