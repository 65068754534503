import Vue from 'vue'
import Router from 'vue-router'

import ShoppingCartDetails from './views/ShoppingCart/ShoppingCartDetails'
import ShoppingCartList from './views/ShoppingCart/ShoppingCartList'

import ClinicalUnits from './views/Administration/Locations/ClinicalUnitList'
import ClinicalUnitDetails from './views/Administration/Locations/ClinicalUnitDetails'

import ClinicalUnitStorages from './views/Administration/Locations/ClinicalUnitStorages'

import HolidayList from './views/Administration/Delivery/DeliveryHolidayList'
import DeliveryDates from './views/Administration/Delivery/DeliveryDateList'
import DeliveryDatesDetails from './views/Administration/Delivery/DeliveryDateDetails'

import Hospitals from './views/Administration/Locations/HospitalList'
import HospitalDetails from './views/Administration/Locations/HospitalDetails'

import MyCompanyDetails from './views/Administration/Company/MyCompanyDetails'

import Import from './views/Administration/Data/Import'
import ImportSettings from './views/Administration/Data/ImportSettings'

import TableFilter from './views/Administration/Settings/TableFilter'

import Dashboard from './views/Dashboard/Dashboard'

import DownloadsDetails from './views/Administration/Downloads/DownloadsDetails'

import OrderList from './views/Order/OrderList'
import OrderDetail from './views/Order/OrderDetails'
import OrderDetailsController from '@/views/Order/OrderDetailsController'

import UserList from './views/Administration/Rights/UserList'
import UserInvitations from './views/Administration/Rights/UserInvitations'
import UserInvitationsList from './views/Administration/Rights/UserInvitationsList'

import PharmacyList from './views/Administration/Locations/PharmacyList'

import PharmacyDetails from './views/Administration/Locations/PharmacyDetails'
import AdministrationContainer from './views/Administration/AdministrationContainer'
import AccountDetails from './views/Account/AccountDetails'

import CatalogMedicineList from '@/views/Administration/Data/CatalogMedicineList.vue'
import CatalogSpecialList from '@/views/Administration/Data/CatalogSpecialList.vue'

import CatalogEditor from './views/Administration/Data/CatalogEditor'

import DataExport from './views/Administration/Data/DataExport'
import NewsDetails from './views/Administration/News/NewsDetails'
import NewsList from './views/Administration/News/NewsList'

import ApiManagement from './views/Administration/Data/ApiManagement'

import MailLogList from './views/Administration/Logging/MailLogList'
import ImportLogList from './views/Administration/Logging/ImportLogList'
import Reports from './views/Reports/Reports'
import ImportSpecialFee from '@/views/Administration/Data/ImportSpecialFee'
import StatisticsMetadataDetails from "@/views/Administration/Statistics/StatisticsMetadataDetails";
import StatisticsOrderDetails from "@/views/Administration/Statistics/StatisticsOrderDetails";
import StatisticsMailQueueDetails from "@/views/Administration/Statistics/StatisticsMailQueueDetails";
import UserDetailsView from "@/views/Administration/Rights/UserDetailsView";
import StatisticsUsersDetails from "@/views/Administration/Statistics/StatisticsUsersDetails";
import Overview from "@/views/Administration/General/Overview.vue";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                requiresAuth: true,
                redirectRoot: {
                    from: '/dashboard',
                    to: '/administration/pharmacies'
                }
            }
        },
        {
            path: '/hello',
            name: 'hello',
            component: Dashboard,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/account',
            name: 'account',
            component: AccountDetails,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/administration',
            name: 'AdministrationContainer',
            component: AdministrationContainer,
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: 'overview',
                    name: 'GeneralOverview',
                    component: Overview,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'clinical_units',
                    name: 'ClinicalUnits',
                    component: ClinicalUnits,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'clinical_units/new',
                    name: 'ClinicalUnitNew',
                    component: ClinicalUnitDetails,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'clinical_units/:departmentid/details',
                    name: 'ClinicalUnitDetails',
                    component: ClinicalUnitDetails,
                    props: true,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'clinical_units/:departmentid/storage/:storageid',
                    name: 'ClinicalUnitStorages',
                    component: ClinicalUnitStorages,
                    props: true,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'pharmacies',
                    name: 'PharmacyList',
                    component: PharmacyList,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'pharmacy/new',
                    name: 'PharmacyNew',
                    component: PharmacyDetails,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'pharmacy/:pharmacyid/details',
                    name: 'PharmacyDetails',
                    component: PharmacyDetails,
                    props: true,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'users',
                    name: 'UserList',
                    component: UserList,
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: 'users/:userid/details',
                    name: 'UserDetailsView',
                    component: UserDetailsView,
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: 'invitations/create',
                    name: 'UserInvitations',
                    component: UserInvitations,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'invitations',
                    name: 'UserInvitationsList',
                    component: UserInvitationsList,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'holidays',
                    name: 'HolidayList',
                    // label: 'Gruppenverwaltung',
                    component: HolidayList,
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: 'delivery_dates',
                    name: 'DeliveryDates',
                    // label: 'Gruppenverwaltung',
                    component: DeliveryDates,
                    meta: {
                        requiresAuth: true,
                        // rightsList: [
                        //     {obj: '/deliveryplans', act: 'GET'}
                        // ]
                    }
                },
                {
                    path: 'delivery_dates/new',
                    name: 'DeliveryDatesNew',
                    component: DeliveryDatesDetails,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'delivery_dates/:hospitalid/details',
                    name: 'DeliveryDatesDetails',
                    component: DeliveryDatesDetails,
                    props: true,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'hospitals',
                    name: 'Hospitals',
                    // label: 'Gruppenverwaltung',
                    component: Hospitals,
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: 'hospitals/new',
                    name: 'HospitalNew',
                    component: HospitalDetails,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'hospitals/:hospitalid/details',
                    name: 'HospitalDetails',
                    component: HospitalDetails,
                    props: true,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'import',
                    name: 'Import',
                    component: Import,
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: 'catalog/sal',
                    name: 'CatalogSpecialList',
                    component: CatalogSpecialList,
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: 'catalog/aml',
                    name: 'CatalogMedicineList',
                    component: CatalogMedicineList,
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: 'catalog/details/:listtype/:locationid',
                    props: true,
                    name: 'CatalogEditor',
                    component: CatalogEditor,
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: 'import/settings/:importType',
                    name: 'ImportSettings',
                    component: ImportSettings,
                    props: true,
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: 'import/history',
                    name: 'ImportHistory',
                    component: ImportLogList,
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: 'specialfeeimport/settings/:importType',
                    name: 'SpecialfeeImportSettings',
                    component: ImportSettings,
                    props: true,
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: 'specialfeeimport',
                    name: 'ImportSpecialFee',
                    component: ImportSpecialFee,
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: 'tableview',
                    name: 'SettingsTableFilter',
                    component: TableFilter,
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: 'downloads',
                    name: 'DownloadsDetails',
                    component: DownloadsDetails,
                    meta: {
                        requiresAuth: true,
                    }
                },
                {
                    path: 'myCompany',
                    name: 'MyCompanyDetails',
                    component: MyCompanyDetails,
                    props: true,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'export/settings',
                    name: 'DataExport',
                    component: DataExport,
                    props: true,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'news',
                    name: 'NewsList',
                    component: NewsList,
                    props: true,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'news/:newsid/details',
                    name: 'NewsDetails',
                    component: NewsDetails,
                    props: true,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'news/new',
                    name: 'NewsNewDetails',
                    component: NewsDetails,
                    props: true,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'logging/mails',
                    name: 'MailLogList',
                    component: MailLogList,
                    props: true,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'logging/imports',
                    name: 'ImportLogList',
                    component: ImportLogList,
                    props: true,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'apikey/overview',
                    name: 'ApiManagement',
                    component: ApiManagement,
                    props: true,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'statistics/metadata',
                    name: 'StatisticsMetadataDetails',
                    component: StatisticsMetadataDetails,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'statistics/users',
                    name: 'StatisticsUsersDetails',
                    component: StatisticsUsersDetails,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'statistics/orders',
                    name: 'StatisticsOrderDetails',
                    component: StatisticsOrderDetails,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'statistics/mailqueue',
                    name: 'StatisticsMailQueueDetails',
                    component: StatisticsMailQueueDetails,
                    meta: {
                        requiresAuth: true
                    }
                },
            ]
        },
        {
            path: '/shopping',
            name: 'ShoppingCartList',
            component: ShoppingCartList,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/shopping/:departmentid/details',
            name: 'ShoppingCartDetails',
            component: ShoppingCartDetails,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/orders',
            name: 'OrderList',
            component: OrderList,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/orders/:orderid/details',
            name: 'OrderDetail',
            component: OrderDetail,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/orders/:orderid/check',
            name: 'OrderDetailsController',
            component: OrderDetailsController,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/reports',
            name: 'Reports',
            component: Reports,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '*',
            redirect: '/dashboard'
        }
        // {
        //   path: '/about',
        //   name: 'about',
        //   meta: {
        //     requiresAuth: true
        //   },
        //   // route level code-splitting
        //   // this generates a separate chunk (about.[hash].js) for this route
        //   // which is lazy-loaded when the route is visited.
        //   component: () => import(/* webpackChunkName: "about" */ './components/About.vue')
        // },
    ],
    meta: {
        adminNavigation: [
            {
                title: 'Anwendung',
                items: [
                    {
                        route: '/administration/overview',
                        icon: 'local_pharmacy',
                        title: 'Übersicht',
                        short: 'Ap',
                        subtitle: 'Übersicht der Anwendung',
                        visibility: {role: ['root']}
                    }
                ]
            },
            {
                title: 'Mein Unternehmen',
                items: [
                    {
                        route: '/administration/myCompany',
                        icon: 'house',
                        title: 'Mein Unternehmen',
                        short: 'MU',
                        subtitle: 'Daten bearbeiten',
                        visibility: {act: 'GET', obj: '/mycompany', is_system: false}
                    }
                ]
            },
            {
                title: 'Einrichtungen / Abteilungen',
                items: [
                    {
                        route: '/administration/pharmacies',
                        icon: 'local_pharmacy',
                        title: 'Apotheken',
                        short: 'Ap',
                        subtitle: 'Neu, Bearbeiten, Rechte',
                        visibility: {role: ['root']}
                    },
                    {
                        route: '/administration/hospitals',
                        icon: 'business',
                        title: 'Medizinische Einrichtung',
                        short: 'ME',
                        subtitle: 'Neu, Bearbeiten, Rechte',
                        visibility: {act: 'POST', obj: '/hospital', is_system: false}
                    },
                    {
                        route: '/administration/clinical_units',
                        icon: 'meeting_room',
                        title: 'Abteilung',
                        short: 'Ab',
                        subtitle: 'Neu, Bearbeiten, Rechte',
                        visibility: {act: 'PUT', obj: '/department/*', is_system: false}
                    }
                ]
            },
            {
                title: 'Lieferung',
                items: [
                    {
                        route: '/administration/delivery_dates',
                        icon: 'local_shipping',
                        title: 'Lieferpläne',
                        short: 'Li',
                        subtitle: 'Neu, Bearbeiten, Rechte',
                        visibility: {act: 'GET', obj: '/deliveryplans', is_system: false}
                    },
                    {
                        route: '/administration/holidays',
                        icon: 'supervised_user_circle',
                        title: 'Feiertage',
                        short: 'Fe',
                        subtitle: 'Abholen vom Dienst',
                        visibility: {role: ['root']}

                    }
                ]
            },
            {
                title: 'News und Ankündigungen',
                items: [
                    {
                        route: '/administration/news',
                        icon: 'menu_book',
                        title: 'News und Ankündigungen',
                        short: 'NA',
                        subtitle: 'Neu, Bearbeiten, Löschen',
                        visibility: {act: 'POST', obj: '/news', is_system: false}
                    }
                ]
            },
            {
                title: 'Datenstamm',
                items: [
                    {
                        route: '/administration/apikey/overview',
                        icon: 'vpn_key',
                        title: 'Schlüsselverwaltung',
                        short: 'Sc',
                        subtitle: 'Zuweisen von API Schlüsseln',
                        visibility: {role: ['root']}
                    },
                    {
                        route: '/administration/import',
                        icon: 'mdi-cloud-download',
                        title: 'Datenimport',
                        short: 'DI',
                        subtitle: 'Datenübernahme',
                        visibility: {act: 'POST', obj: '/abda/import', is_system: false}
                    },
                    {
                        route: '/administration/catalog/aml',
                        icon: 'mdi-playlist-edit',
                        title: 'Arzneimittelliste',
                        short: 'AM',
                        subtitle: 'Datenübersicht/-editor',
                        visibility: {act: 'POST', obj: '/abda/import', is_system: false}
                    },
                    {
                        route: '/administration/catalog/sal',
                        icon: 'mdi-playlist-edit',
                        title: 'Sonderartikelliste',
                        short: 'SL',
                        subtitle: 'Datenübersicht/-editor',
                        visibility: {act: 'POST', obj: '/abda/import', is_system: false}
                    },
                    {
                        route: '/administration/specialfeeimport',
                        icon: 'mdi-currency-usd',
                        title: 'Sonderentgeltartikel',
                        short: 'SE',
                        subtitle: 'ZE-/NUB-Artikel',
                        visibility: {act: 'POST', obj: '/abda/import', is_system: false}
                    },
                ]
            },
            {
                title: 'Datenexport',
                items: [
                    // {
                    //   route: '/administration/import',
                    //   icon: 'meeting_room',
                    //   title: 'Import',
                    //   subtitle: 'Datenübernahme',
                    //   visibility: { act: 'POST', obj: '/abda/import', is_system: false }
                    // },
                    {
                        route: '/administration/export/settings',
                        icon: 'mdi-cloud-upload',
                        title: 'Export',
                        short: 'Ex',
                        subtitle: 'Datenübergabe',
                        visibility: {act: 'GET', obj: '/settings/export', is_system: false}
                    }
                ]
            },
            {
                title: 'Ansichten',
                items: [
                    {
                        route: '/administration/tableview',
                        icon: 'mdi-filter',
                        title: 'Filter für Artikelsuche',
                        short: 'FA',
                        subtitle: 'Filterung von Spalten in der Artikelsuche',
                        visibility: {act: 'PUT', obj: '/settings/articlefields/groups', is_system: false}
                    }
                ]
            },
            {
                title: 'Downloads',
                items: [
                    {
                        route: '/administration/downloads',
                        icon: 'cloud_download',
                        title: 'Downloads',
                        short: 'Do',
                        subtitle: 'Downloadverwaltung',
                        visibility: {act: 'POST', obj: '/document', is_system: false}
                    }
                ]
            },
            {
                title: 'Nutzer- und Gruppenverwaltung',
                items: [
                    {
                        route: '/administration/invitations',
                        icon: 'email',
                        title: 'Einladungen',
                        short: 'Ei',
                        subtitle: 'neue Nutzer einladen',
                        visibility: {act: 'GET', obj: '/users/getInvitations'}
                    },
                    {
                        route: '/administration/users',
                        icon: 'account_circle',
                        title: 'Nutzer',
                        short: 'Nu',
                        subtitle: 'Bearbeiten, Rechtevergabe',
                        visibility: {act: 'GET', obj: '/users'}
                    }
                ]
            },
            {
                title: 'Logging',
                items: [
                    {
                        route: '/administration/logging/mails',
                        icon: 'mail',
                        title: 'Mailversand',
                        short: 'Ma',
                        subtitle: 'Mailfluss',
                        visibility: {role: ['root']}
                    },
                    {
                        route: '/administration/logging/imports',
                        icon: 'import_export',
                        title: 'Daten Importe',
                        short: 'DI',
                        subtitle: 'Ansicht für Importe',
                        visibility: {role: ['root']}
                    }
                ]
            },
            {
                title: 'Statistiken',
                items: [
                    {
                        route: '/administration/statistics/users',
                        icon: 'person',
                        title: 'Benutzer',
                        short: 'BD',
                        subtitle: 'Übersicht Benutzer',
                        visibility: {role: ['root']}
                    },
                    {
                        route: '/administration/statistics/metadata',
                        icon: 'person',
                        title: 'Metadaten',
                        short: 'MD',
                        subtitle: 'Auswertung Benutzerdaten',
                        visibility: {role: ['root']}
                    },
                    {
                        route: '/administration/statistics/orders',
                        icon: 'order',
                        title: 'Bestellungen',
                        short: 'SB',
                        subtitle: 'Auswertung Bestellungen',
                        visibility: {role: ['root']}
                    },
                    {
                        route: '/administration/statistics/mailqueue',
                        icon: 'mail',
                        title: 'Mail Warteschlange',
                        short: 'MW',
                        subtitle: 'Ansicht Mail Warteschlange',
                        visibility: {role: ['root']}
                    }
                ]
            }
        ]
    }
});

export default router
