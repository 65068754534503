<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <ValidationObserver ref="UserDetailsRootForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">
      <div class="layout-column flex ma-2 elevation-5"
           style="border-radius:4px;position:relative;"
           v-bind:style="{background: compColor.contentFrame}">

        <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1" :class="hoverHeader">
          <div class="layout-row">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="back()"
                    icon="mdi-chevron-left"
                    :hide-label="true"
                    tooltip="zurück zur Übersicht"
                    :label="'Zurück'"></lx-btn>

<!--            <lx-btn :color="'#353a47'"-->
<!--                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"-->
<!--                    small-->
<!--                    :disabled="isDirty"-->
<!--                    iconSize="20"-->
<!--                    width="auto"-->
<!--                    classes="text-none mr-1"-->
<!--                    @click="saveUserData(reset)"-->
<!--                    icon="save"-->
<!--                    tooltip="Änderungen speichern"-->
<!--                    :label="'Speichern'"></lx-btn>-->
<!--            <lx-btn :color="'#353a47'"-->
<!--                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"-->
<!--                    small-->
<!--                    :disabled="isDirty && !hasSelectedDepartementsToAdd && !hasSelectedDepartementsToRemove && !hasSelectedToAdd && !hasSelectedToRemove"-->
<!--                    iconSize="20"-->
<!--                    width="auto"-->
<!--                    classes="text-none mr-1"-->
<!--                    @click="clear(reset)"-->
<!--                    icon="cancel"-->
<!--                    tooltip="Letzte Änderungen rückgängig machen"-->
<!--                    :label="'Zurücksetzen'"></lx-btn>-->
          </div>
          <div class="flex layout-row"></div>
        </div>

        <v-divider v-if="!hoverHeader" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

        <div ref="scrollContainer" style="overflow-y: auto;" class="flex pa-1 layout-column">
          <lx-expansion-panels :single-open="false" v-model="panel">
            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-account"
                                         main-headline="Benutzerdaten"
                                         sub-headline="Allgemeine Daten eines Nutzers">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-gt-sm-50 flex-100 pa-2">
                    <v-text-field
                      :dark="compColor.isInDarkMode"
                      label="Anrede"
                      :color="compColor.primeColor"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      :value="salutations[model.salutation]"
                      disabled
                      outlined
                      class="mb-0"
                      :dense="denseFields"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">mdi-gender-male-female</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                  <div class="flex-gt-sm-50 flex-100 pa-2">
                    <v-text-field
                      :dark="compColor.isInDarkMode"
                      :color="compColor.primeColor"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      class="mb-0"
                      label="Titel"
                      v-model="model.title"
                      disabled
                      outlined
                      :dense="denseFields"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">account_balance</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                  <div class="flex-gt-sm-50 flex-100 pa-2">
                    <v-text-field
                      :dark="compColor.isInDarkMode"
                      :color="compColor.primeColor"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      class="mb-0"
                      label="Vorname"
                      v-model="model.first_name"
                      disabled
                      outlined
                      :dense="denseFields"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">person</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                  <div class="flex-gt-sm-50 flex-100 pa-2">
                    <v-text-field
                      :dark="compColor.isInDarkMode"
                      :color="compColor.primeColor"
                      label="Nachname"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      class="mb-0"
                      v-model="model.last_name"
                      disabled
                      outlined
                      :dense="denseFields"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">person</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                  <div class="flex-gt-sm-50 flex-100 pa-2">
                    <v-text-field
                      :dark="compColor.isInDarkMode"
                      :color="compColor.primeColor"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      class="mb-0"
                      label="Benutzername"
                      v-model="model.username"
                      disabled
                      outlined
                      :dense="denseFields"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">mdi-account-circle</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                  <div class="flex-gt-sm-50 flex-100 pa-2">
                    <v-text-field
                      :dark="compColor.isInDarkMode"
                      :color="compColor.primeColor"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      class="mb-0"
                      label="E-Mail"
                      v-model="model.email"
                      disabled
                      outlined
                      :dense="denseFields"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">email</v-icon>
                      </template>
                    </v-text-field>
                  </div>

                  <div class="flex-gt-sm-50 flex-50 pa-2">
                    <v-text-field
                        :dark="compColor.isInDarkMode"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        class="mb-0"
                        label="Letzte Anmeldung"
                        :value="model.last_login | formatDate('DD.MM.YYYY HH:mm')"
                        disabled
                        outlined
                        :dense="denseFields"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">timer</v-icon>
                      </template>
                    </v-text-field>
                  </div>

                  <div class="flex-gt-sm-50 flex-50 pa-2">
                    <v-text-field
                        :dark="compColor.isInDarkMode"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        class="mb-0"
                        label="Frontend Version"
                        v-model="model.frontend_version"
                        disabled
                        outlined
                        :dense="denseFields"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">label</v-icon>
                      </template>
                    </v-text-field>
                  </div>

                  <div class="flex-gt-sm-100 flex-100 pa-2">
                    <v-text-field
                      :dark="compColor.isInDarkMode"
                      :color="compColor.primeColor"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      class="mb-0"
                      label="Auth User Id"
                      v-model="model.auth_user_id"
                      disabled
                      outlined
                      :dense="denseFields"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">fingerprint</v-icon>
                      </template>
                    </v-text-field>
                  </div>

                  <div class="flex-100 pa-2">
                    <fake-input-frame label="Nutzeraktivierung" :disabled="authUser.auth_user_id === model.auth_user_id"
                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                      class="mb-6">
                      <div class="layout-row layout-align-start-center px-4 mt-n2"
                           :style="{height: denseFields ? '40px':'50px'}">
                        <v-switch
                          :dark="compColor.isInDarkMode"
                          inset
                          :dense="denseFields"
                          class="ma-0"
                          hide-details
                          v-model="model.is_active"
                          :color="compColor.primeColor"
                          :disabled="authUser.auth_user_id === model.auth_user_id"
                          name="is_active">
                          <template v-slot:label>
                            Ist aktiv <small class="ml-2" v-if="authUser.auth_user_id === model.auth_user_id"
                                             v-bind:style="{color: compColor.primeColor}">(nicht änderbar)</small>
                          </template>
                        </v-switch>
                      </div>
                    </fake-input-frame>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>

            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-account-group"
                                         main-headline="Benutzerrollen"
                                         sub-headline="Weisen sie dem  aktuellen Nutzer seine Rollen zu">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap pa-2 pt-0">
                  <div class="flex-100 pa-2">
<!--                    <v-sheet elevation="6">-->
                      <v-tabs
                        background-color="transparent"
                          v-model="tabIndex"
                          :dark="compColor.isInDarkMode"
                          :dense="denseFields"
                          :color="compColor.primeColor"
                          next-icon="mdi-arrow-right-bold-box-outline"
                          prev-icon="mdi-arrow-left-bold-box-outline"
                          show-arrows
                          class="mb-4"
                      >
                        <v-tabs-slider :color="compColor.primeColor"></v-tabs-slider>
                        <v-tab
                            v-for="(location, index) in model.locations || []"
                            :value="index"
                            :key="index"
                        >
                          <v-container>
                            <v-row>
                              {{ location.name }}
                            </v-row>
                            <v-row>
                              <small>{{location.pharmacy}}</small>
                            </v-row>
                          </v-container>


                          <v-icon class="ml-2" color="amber darken-1" v-show="location.is_default">mdi-star</v-icon>
                        </v-tab>
                      </v-tabs>

<!--                    </v-sheet>-->

                    <v-window v-if="tabIndex !== -1" v-model="tabIndex">
                      <div class="layout-row">
                        <fake-input-frame label="Zugeordnete Benutzerrollen"
                                          class="flex layout-column"
                                          :input-background="isDarkMode ? 'transparent':'#f0f0f0'">
                          <div class="layout-column pa-2">
                            <div v-for="(items, index) in  model.locations[tabIndex].roles"
                                 :key="index"
                                 class="px-2 py-1 mb-2"
                                 :class="index < model.locations[tabIndex].length - 1 ? 'mb-1' : ''"
                                 :style="{'background': items.selected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
                                 style="border-radius: 4px;">
                              <div class="layout-row">
                                <div class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start">
                                  <span style="line-height: 16px;"><strong>{{ items }}</strong></span>
                                </div>
                                <div class="layout-column layout-align-center-center ml-2">
                                  <v-icon
                                      :color="items.selected ? compColor.prime + ' lighten-3': (isDarkMode ? 'blue-grey darken-3' : 'blue-grey lighten-3')"
                                      size="25">mdi-account-supervisor-circle
                                  </v-icon>
                                </div>
                              </div>
                            </div>
                            <!-- end -->
                          </div>
                        </fake-input-frame>
                      </div>
                    </v-window>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
          </lx-expansion-panels>
        </div>
      </div>
    </ValidationObserver>

  </div>
</template>

<script>
import lodash from 'lodash'

function comparer(otherArray) {
  return function (current) {
    return otherArray.filter(function (other) {
      return other._id == current._id
    }).length == 0;
  }
}

export default {
  name: 'UserDetailsRoot',
  data() {
    return {
      hoverHeader: false,
      tabIndex: -1,
      authUser: null,
      notEditable: [],
      isPharmacy: (this.getUserLocationType() === "pharmacy"),
      /** Expansionpanel **/
      panel: [0, 1, 2],
      /** ist im Ladezustand ja/neine -> spinner animation etc. **/
      loading: false,
      /** Userid kommt aus route params wenn id dann User finden wenn nicht dann User neu **/
      userId: null,
      /** Models über die Felder **/
      model: {},
      oldModel: null,
      /** Booleans für Popup bei Fehler und Snackbar bei Erfolg **/
      errorDialog: false,

      /** Rechtegruppen Tabellen**/
      addedRoles: [],
      allRoles: [],
      addedDepartments: [],
      allDepartments: [],
      salutations: {
        'mr': "Herr",
        'ms': "Frau",
        'u': "Divers",
      }
    }
  },
  created() {
    this.userId = this.$route.params.userid;
    this.getUserData();
    this.authUser = this.getUser();

    this.$nextTick(() => {
      let scroller = this.$refs.scrollContainer
      scroller.addEventListener('scroll', () => {
        if (scroller.scrollTop > 0) {
          let number = scroller.scrollTop
          number = number > 4 ? 4 : number
          this.hoverHeader = 'elevation-' + number
        } else {
          this.hoverHeader = ''
        }
      })
    })
  },
  methods: {
    selectAllEntries(list) {
      let booleanValue = this[list + 'AreSelected']
      this[list].forEach(item => {
        this.$set(item, 'selected', !booleanValue)
      })
    },
    openCloseExpansionPanel(index) {
      if (this.panel.indexOf(index) < 0) {
        this.panel.push(index)
      } else {
        this.panel.splice(this.panel.indexOf(index), 1)
      }
    },
    /**
     * wird aufgerufen wenn man den zurücksetzen Button drückt.
     * - Ursprungsmodel wird wieder an model übergeben
     * - alle Fehler werden gelöscht
     * - alle Felder werden resetet sind also pristine und NICHT dirty
     *
     * */
    clear() {
      this.model = lodash.cloneDeep(this.oldModel)

      this.addedDepartments.forEach(item => {
        item.selected = false
      })
      this.allDepartments.forEach(item => {
        item.selected = false
      })
      this.addedRoles.forEach(item => {
        item.selected = false
      })
      this.allRoles.forEach(item => {
        item.selected = false
      })
    },

    /**
     * wird aufgerufen, wenn man den zurück Button drückt
     *
     * */
    back() {
      window.history.back()
    },

    /**
     * wird aufgerufen wenn man den speichern Button drückt.
     *
     * 1 Fall neu anlegen (UserID NICHT vorhanden === Neuanlage):
     *    - per axios.post('/users'... Model schicken
     *    - im Erfolgsfall route zu UserDetails erneut aufrufen, diesmal mit erhaltener UserID
     * 2 Fall User updaten (UserID vorhanden === Update):
     *    - per axios.patch('/users/' .. mit UserID und Model wird User upgedatet
     *    - anschließend GetData ausgeführt
     *
     * */
    // saveUserData(reset) {
    //   if (this.loading) {
    //     return
    //   }
    //
    //   this.loading = true;
    //   delete this.model.id;
    //
    //   let data = {
    //     is_active: this.model.is_active,
    //     initials: this.model.initials
    //   };
    //
    //   this.$http.put('/users/' + this.userId, data)
    //     .then(() => {
    //       reset()
    //       this.getUserData();
    //       this.oldModel = lodash.clone(this.model)
    //       this.NotifySystem('Änderungen gespeichert');
    //
    //     })
    //     .catch(this.NotifyError)
    //     .finally(() => {
    //       this.loading = false
    //     })
    // },
    // addRolesToUser() {
    //   if (this.loading) {
    //     return
    //   }
    //
    //   this.loading = true;
    //
    //
    //   let selected = []
    //   if (this.allRoles && this.allRoles.length > 0) {
    //     selected = this.allRoles.filter(item => {
    //       if (item.selected) return item
    //     })
    //   }
    //
    //   let addables = selected.map((key) => {
    //     return key.name
    //   });
    //
    //   let data = { user: this.model.auth_user_id, roles: addables };
    //
    //   this.$http.post('/auth/perm/add/roles/user', data)
    //     .then(() => {
    //       this.NotifySystem('Änderungen gespeichert');
    //     })
    //     .catch(this.NotifyError)
    //     .finally(() => {
    //       this.loading = false;
    //       this.getUserData()
    //
    //     })
    // },

    // removeRolesFromUser() {
    //   if (this.loading) {
    //     return
    //   }
    //
    //   this.loading = true;
    //
    //   let selected = []
    //   if (this.addedRoles && this.addedRoles.length > 0) {
    //     selected = this.addedRoles.filter(item => {
    //       if (item.selected) return item
    //     })
    //   }
    //
    //   let removeable = selected.map((key) => {
    //     return key.name
    //   });
    //
    //   let data = { user: this.model.auth_user_id, roles: removeable };
    //   this.$http.post('/auth/perm/delete/roles/user', data)
    //     .then(() => {
    //       this.NotifySystem('Änderungen gespeichert');
    //     })
    //     .catch(this.NotifyError)
    //     .finally(() => {
    //       this.loading = false;
    //       this.getUserData()
    //     })
    // },

    // addDepartmentToUser() {
    //   if (this.loading) {
    //     return
    //   }
    //
    //   this.loading = true;
    //
    //   let selected = []
    //   if (this.allDepartments && this.allDepartments.length > 0) {
    //     selected = this.allDepartments.filter(item => {
    //       if (item.selected) return item
    //     })
    //   }
    //
    //   let addables = selected.map((key) => {
    //     return key._id
    //   });
    //
    //   let data = { user: this.userId, departments: addables };
    //
    //   this.$http.put('/users/bulk/add/user/departments', data)
    //     .then(() => {
    //       this.NotifySystem('Änderungen gespeichert');
    //     })
    //     .catch(this.NotifyError)
    //     .finally(() => {
    //       this.loading = false;
    //       this.getUserData()
    //
    //     })
    //
    // },

    // removeDepartmentFromUser() {
    //   if (this.loading) {
    //     return
    //   }
    //
    //   this.loading = true;
    //
    //   let selected = []
    //   if (this.addedDepartments && this.addedDepartments.length > 0) {
    //     selected = this.addedDepartments.filter(item => {
    //       if (item.selected) return item
    //     })
    //   }
    //
    //   let removeable = selected.map((key) => {
    //     return key._id
    //   });
    //
    //   let data = { user: this.userId, departments: removeable };
    //   this.$http.post('/users/bulk/delete/user/departments', data)
    //     .then(() => {
    //       this.NotifySystem('Änderungen gespeichert');
    //     })
    //     .catch(this.NotifyError)
    //     .finally(() => {
    //       this.loading = false;
    //       this.getUserData()
    //     })
    // },
    getUserData() {
      if (this.loading) {
        return
      }

      this.loading = true;

      this.$http.get('/auth/perm/roles') // get all existing roles
        .then(resultRoles => {
          if (resultRoles.data) {
            this.$http.get('/users/id/' + this.userId) // get auth user data
              .then(resultUser => {
                if (resultUser.data) {
                  this.model = resultUser.data.result;
                  this.oldModel = Object.assign({}, this.model);

                  if (resultUser.data.departments) {
                    this.addedDepartments = resultUser.data.departments;
                  } else {
                    this.addedDepartments = []
                  }

                  // let query = {}
                  this.$http.get('/department?config={"query": {}}')
                    .then(resDep => {
                      if (resDep.data && resDep.data.data) {
                        let arr1 = resDep.data.data;
                        let arr2 = resultUser.data.departments;

                        if (arr1 && arr2 && arr1.length > 0 && arr2.length > 0) {
                          var onlyInA = arr1.filter(comparer(arr2));
                          var onlyInB = arr2.filter(comparer(arr1));

                          this.allDepartments = onlyInA.concat(onlyInB);
                        } else if (!arr2 && arr1 && arr1.length > 0) {
                          this.allDepartments = arr1
                        }

                      }
                    })
                    .catch(this.NotifyError)
                    .finally(() => {
                      // this.isLoadingData = false
                    });

                  this.$http.get('/auth/perm/roles/user/' + this.model.auth_user_id) // get roles of selected user
                    .then(resultUserRoles => {
                      if (resultUserRoles.data) {
                        let arr1 = resultRoles.data;
                        let arr2 = resultUserRoles.data;
                        let difference = arr1.filter(x => !arr2.includes(x));
                        let intersection = arr1.filter(x => arr2.includes(x));
                        this.allRoles = difference.map(a => {
                          return { name: a }
                        });
                        this.addedRoles = intersection.map(a => {
                          return { name: a }
                        });

                        //Falls man selber dieser Nutzer ist soll man admin und user rollen nicht entfernen können
                        if (this.authUser.auth_user_id === this.model.auth_user_id) {
                          let notEditable = ['pharmacyadmin', 'users', 'admin'];
                          this.notEditable = intersection.filter(x => notEditable.includes(x));
                          this.addedRoles = intersection.filter(x => !notEditable.includes(x)).map(a => {
                            return { name: a }
                          });
                        } else {
                          let notEditable = ['users'];
                          this.notEditable = intersection.filter(x => notEditable.includes(x));
                          this.addedRoles = intersection.filter(x => !notEditable.includes(x)).map(a => {
                            return { name: a }
                          });
                        }


                      } else {
                        let arr1 = resultRoles.data;
                        // this.allRoles = arr1.map(a => {
                        //     return {name: a}
                        // });
                        let notEditable = ['users'];
                        this.notEditable = arr1.filter(x => notEditable.includes(x));
                        this.allRoles = arr1.filter(x => !notEditable.includes(x)).map(a => {
                          return { name: a }
                        });
                      }

                      this.loading = false
                    })
                    .catch(this.NotifyError)
                    .finally(() => {
                      this.loading = false
                    })
                }
              })
              .catch(this.NotifyError)
              .finally(() => {
                this.loading = false
              })
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading = false
        })
    },
  },
  computed: {
    allDepartmentsAreSelected() {
      let selected = this.allDepartments.filter(item => {
        if (item.selected) return item
      })
      return selected.length === this.allDepartments.length
    },
    allAddedDepartmentsAreSelected() {
      let selected = this.addedDepartments.filter(item => {
        if (item.selected) return item
      })
      return selected.length === this.addedDepartments.length
    },
    isDirty() {
      if (!this.oldModel) {
        return false
      }
      return lodash.isEqual(this.oldModel, this.model)
    },
    hasSelectedToAdd() {
      let selected = this.allRoles.filter(item => {
        if (item.selected) return item
      })
      return selected.length > 0
    },
    hasSelectedToRemove() {
      let selected = this.addedRoles.filter(item => {
        if (item.selected) return item
      })
      return selected.length > 0
    },
    hasSelectedDepartementsToAdd() {
      let selected = this.allDepartments.filter(item => {
        if (item.selected) return item
      })
      return selected.length > 0
    },
    hasSelectedDepartementsToRemove() {
      let selected = this.addedDepartments.filter(item => {
        if (item.selected) return item
      })
      return selected.length > 0
    },
  },
}
</script>
