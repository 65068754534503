<template>
  <div class="layout-row layout-align-center-center" v-if="allowed"
       :class="isDarkMode ? ' blue-grey darken-3' : 'blue-grey lighten-4'"
       style="height:25px;min-height:25px;max-height:25px;border-radius: 4px;">

      <v-icon
          :color="(model.count > min) ? 'blue-grey darken-1' : 'blue-grey'"
          :style="{cursor: (model.count <= min) ? 'not-allowed':'pointer', opacity: (model.count <= min) ? '0.2':'1.0'}"
          @click="decrementCount"
      >indeterminate_check_box</v-icon>
    <div class="flex">
      <input
           v-model="model.count"
           @blur="checkValueType"
           @keydown.enter="checkValueType"
           style="text-align: center;width:100%;height:25px;min-height:25px;max-height:25px;"
           class="flex"
           type="number"
           v-bind:class="[{ 'grey--text text--lighten-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1': !compColor.isInDarkMode }]"/>
    </div>
    <v-icon
        :color="(model.count < max) ? 'blue-grey' : 'blue-grey darken-1'"
        :style="{cursor: (model.count < max) ? 'pointer': 'not-allowed', opacity: (model.count < max) ? '1.0':'0.2'}"
        @click="incrementCount"
    >add_box</v-icon>
  </div>
</template>

<script>
import Vue from 'vue'
import lodash from "lodash";

let intervalCounterTimer = null;

export default Vue.extend({
  // props: [
  //   'value',
  //   'field',
  //   'changeEvent',
  // ],
  props: {
    value: Object,
    field: String,
    changeEvent: Function,
    min: {
      type: Number,
      default () {
        return 1
      }
    },
    max: {
      type: Number,
      default () {
        return 9999
      }
    },
  },
  data() {
    return {
      // enableConfirmButton: false,
      number: null,
      hasChanges: false,
      confirmFn: null,
      intervallTime: 700,
      model: {
        original: 0,
        count: 0,
        data: null,
      },
      allowed: true,
    }
  },
  created() {
    // this.$set(this.value, this.field, 1)
    // this.$emit('input', this.value)

    // let params = this.params || 1;
    //
    // this.confirmFn = params.confirm;
    // this.model.count = params.value || 1;
    this.model.count = this.getValueByObjectPath(this.value, this.field) || 1;
    this.model.original = lodash.cloneDeep(this.model.count);
    // this.model.data = params.data;
    // if (params.intervallTime) {
    //     this.intervallTime = params.intervallTime
    // }
    //
    // /**
    //  * wenn artikel aus der AML liste oder der abda entfernt werden können diese noch in stationslisten oder hitlisten
    //  * stehen aber nicht mehr aufgelöst werden, in diesen Fällen wird keine pzn zurückgegeben sondern nur die referer_pzn
    //  * also die pzn welche in den stations oder hitlisten steht.
    //  * 1 Fall) wenn keine pzn und eine referer_pzn dann Artikel nicht mehr auflösbar
    //  * 2 Fall) wenn pzn und referer_pzn dann Artikel noch auflösbar
    //  * */
    // if (this.params.node && this.params.node.data) {
    //     let pzn = this.params.node.data.pzn;
    //     let referer_pzn = this.params.node.data.referer_pzn;
    //     if (!pzn && referer_pzn) {
    //         this.allowed = false
    //     }
    // }
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal && typeof newVal[this.field] === 'number' && newVal[this.field] >= 0 && newVal[this.field] !== this.model.count) {
          let temp = this.getValueByObjectPath(this.value, this.field)
          this.model.count = typeof temp === 'number' ? temp : 1;
          this.model.original = lodash.cloneDeep(this.model.count);
        }
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    /**
     *
     * @param obj The complete object
     * @param path The path as dot notation to the field value (e.g. 'address.street.number')
     * @returns {*}
     */
    getValueByObjectPath(obj, path) {
      if (path.indexOf('.') <= 0) {
        return obj[path]
      }
    },
    incrementCount() {
      let temp = parseInt(this.model.count, 10);
      temp = Math.min(++temp, Math.floor(this.max))
      this.model.count = temp

      this.$set(this.value, this.field, temp)
      this.$emit('input', this.value)
      this.$emit('changeEvent', {value: this.value, field: this.field})

      // this.hasChanges = this.model.original !== this.model.count;
      // this.confirm()
    },
    decrementCount() {
      let temp = parseInt(this.model.count, 10);
      temp = Math.max(--temp, Math.floor(this.min))
      this.model.count = temp

      this.$set(this.value, this.field, temp)
      this.$emit('input', this.value)
      this.$emit('changeEvent', {value: this.value, field: this.field})
      // this.hasChanges = this.model.original !== this.model.count;
      // this.confirm()
    },
    checkValueType() {
      let temp = parseInt(this.model.count, 10);
      if (isNaN(temp)) {
        this.model.count = Math.floor(this.min);
      } else {
        this.model.count = Math.min(Math.max(temp, Math.floor(this.min)), Math.floor(this.max))
      }

      this.$set(this.value, this.field, this.model.count)
      this.$emit('input', this.value)
      this.$emit('changeEvent', {value: this.value, field: this.field})
    },
    confirm() {
      // this.params.setValue(this.model.count)
      clearInterval(intervalCounterTimer);
      if (this.hasChanges) {
        // console.log('gibt änderungen')
        // intervalCounterTimer = setInterval(() => {
        //     if (typeof this.confirmFn === 'function') {
        //         if (this.confirmFn(this.model.count, this.model.data) === true) {
        //             this.model.original = this.model.count;
        //             this.hasChanges = false;
        //         }
        //     } else {
        //         this.params.setValue(this.model.count)
        //     }
        //     clearInterval(intervalCounterTimer)
        // }, this.intervallTime)
      }
    }
  }
})
</script>
