<template>
  <div class="pa-0">
    <v-list two-line class="pa-0 lx-dash-list" color="transparent">
      <v-list-item class="ma-2 py-1 px-6 elevation-3" v-bind:class="[compColor.contentFrameBack]"
                   style="border-radius:3px;" v-if="!newsList || newsList.length <= 0">
        <v-list-item-content v-bind:class="[compColor.widget.text]">
          <div class="flex layout-column px-3"
               v-bind:style="{'border-left': '4px solid  #eceff1','border-radius': '5px'}">
            <div class="font-weight-bold subtitle-2 my-1" style="line-height: 1.0rem;">derzeit sind kein
              News oder Ankündigungen vorhanden
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>

      <div class="my-2 elevation-3 mx-2" v-for="(item, index) in newsList" :key="index">
        <v-list-item class="pb-0 px-3" :style="{'background':compColor.context.background}" style="border-radius:6px;">

          <v-list-item-content v-bind:class="[compColor.widget.text]" class="pb-0">
              <news-item-widget
                :headline="item.headline"
                :subheading="item.subheading"
                :content="item.content"
                :color="item.color"
                :creation_date="item.creation_date"
                :dark="isDarkMode"
              ></news-item-widget>
<!--            <div class="flex layout-column px-3" v-bind:style="{'border-left': '4px solid ' + item.color,'border-radius': '5px'}">-->

<!--              &lt;!&ndash; headline with subheading &ndash;&gt;-->
<!--              <div class="layout-row">-->
<!--                  <div class="flex font-weight-bold subtitle-1 my-1" style="line-height: 1.0rem;">{{ item.headline }}</div>-->
<!--              </div>-->
<!--              <div v-if="item.subheading" class="font-weight-regular my-1" style="line-height: 1.0rem;font-size: 14px;">{{ item.subheading }}</div>-->

<!--              &lt;!&ndash; Content &ndash;&gt;-->
<!--              <v-divider class="mb-2" :class="{'blue-grey darken-3': isDarkMode}"></v-divider>-->

<!--              <div class="font-weight-regular subtitle-2 news-content" style="white-space: pre-line;line-height: 1.0rem;">{{ item.content }}</div>-->

<!--              &lt;!&ndash; Footer &ndash;&gt;-->
<!--              <v-divider class="mb-2" :class="{'blue-grey darken-3': isDarkMode}"></v-divider>-->

<!--              <span class="text&#45;&#45;lighten-2 text-right font-weight-regular caption" style="line-height: 1.0rem;"-->
<!--                  v-bind:class="[compColor.textInput + '&#45;&#45;text']">Nachricht vom {{ item.creation_date | formatDate('DD.MMM YYYY') }}-->
<!--              </span>-->

<!--            </div>-->
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
import NewsItemWidget from "@/components/newsItem/NewsItem.vue"

export default {
  name: 'News',
  components: {NewsItemWidget},
  data() {
    return {
      newsList: null,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      const params = {
        config: {
          opts: { sort: { creation_date: -1 } },
        }
      }

      this.$http.get('/news', params)
        .then(res => {
          if (res.data && Array.isArray(res.data.result)) {
            // appends default color
            this.newsList = res.data.result.map((v) => {
              return {color: this.isDarkMode ? '#4a525e' : '#E0E0E0', ...v}
            })
          }
        })
        .catch(this.NotifyError)
        .finally(this.loading = false)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
