const SET_STORAGE_LOCATIONS = 'SET_STORAGE_LOCATIONS'

const state = {
    storage_locations: null
}

const mutations = {
    [SET_STORAGE_LOCATIONS](state, payload) {
        state.storage_locations = payload
    }
}

const actions = {
    setStorageLocations({commit}, payload) {
        commit(SET_STORAGE_LOCATIONS, payload)
    }
}

const getters = {
    getStorageLocations: state => {
        return state.storage_locations
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
