<template>
  <div class="pa-0">
    <v-list two-line class="pa-0 lx-dash-list" color="transparent">
      <v-list-item class="ma-2 py-1 px-6 elevation-3" v-bind:class="[compColor.contentFrameBack]"
                   style="border-radius:3px;" v-if="!activityList || activityList.length <= 0">
        <v-list-item-content v-bind:class="[compColor.widget.text]">
          <div class="flex layout-column px-3"
               v-bind:style="{'border-left': '4px solid  #eceff1','border-radius': '5px'}">
            <div class="font-weight-bold subtitle-2 my-1" style="line-height: 1.0rem;">
              {{ $t('ACTIVITIES.NOTHING_TO_SHOW') }}
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>

      <div v-for="(activity, index) in activityList" :key="index" class="ma-2 elevation-3">
        <v-list-item class="pa-1" :style="{background: compColor.context.background}" style="border-radius:3px;">
          <v-list-item-avatar tile height="42" width="48" class="ma-0">
            <v-icon size="20"
                    v-bind:class="{'blue-grey--text text--lighten-3': compColor.isInDarkMode, 'blue-grey--text text--darken-5': !compColor.isInDarkMode}">
              assignment
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content v-bind:class="['pl-2','py-2',compColor.widget.text]">
            <div class="flex layout-column px-3"
                 v-bind:style="{'border-left': '4px solid #eceff1','border-radius': '5px'}">

              <!-- headline -->

              <div class="font-weight-bold subtitle-1 my-1" style="line-height: 1.0rem;">
                {{ $t('ACTIVITIES.' + activity.activityType.toUpperCase()) }}
              </div>

              <v-divider class="mb-1" v-bind:class="[compColor.divider]"></v-divider>

              <!-- Content elements -->

              <div v-if="activity.activityType==='report'">
                <div v-for="(value, key) in activity.content" :key="key">
                  <span class="font-weight-bold">{{ $t('ACTIVITIES.' + key.toUpperCase()) }}: </span>
                  <span v-if="key!=='reportDate'">{{ value }}</span>
                  <span v-if="key==='reportDate'">{{ value | formatDate('DD.MM.YYYY HH:mm') }}</span>
                </div>
              </div>

              <div v-if="activity.activityType==='import'">
                <div v-for="(value, key) in activity.content" :key="key">
                  <span class="font-weight-bold">{{ $t('ACTIVITIES.' + key.toUpperCase()) }}: </span>
                  <span v-if="key!=='date'">{{ $t('ACTIVITIES.' + value.toUpperCase()) }}</span>
                  <span v-if="key==='date'">{{ value | formatDate('DD.MM.YYYY HH:mm') }}</span>
                </div>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'Activity',
  data() {
    return {
      activityList: []
    }
  },

  created() {
    this.getData()
  },

  methods: {
    getData() {
      const params = {
        config: {
          opts: { sort: { lastModified: -1 } },
        }
      }

      this.$http.get('/dashboard/activities', params)
        .then(res => {
          if (res.data) {
            this.activityList = res.data || []
          }
        })
        .catch(this.NotifyError)
    }
  },

  watch: {
    '$route'(to) {
      if (to.name === 'Dashboard') {
        this.getData()
      }
    }
  }
}
</script>
