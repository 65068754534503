<template>
  <div class="layout-row px-1 pt-0 pb-1">
    <div class="layout-row layout-align-start-center">
      <lx-btn :color="'#353a47'"
              :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
              small
              iconSize="20"
              width="auto"
              classes="text-none mr-1"
              @click="modal = true"
              icon="mdi-fingerprint"
              tooltip="Passwort ändern"
              :label="userData ? $t('buttons.twofactor.title.active') : $t('buttons.twofactor.title.deactive')"></lx-btn>

      <div class="ml-5 pa-2 report layout-row layout-align-start-center"
           v-if="userData"
           style="border:1px solid;border-radius: 9px;"
           :class="[!compColor.isInDarkMode ? compColor.prime + ' lighten-5 white--text ' : '']"
           :style="{'border-color': compColor.isInDarkMode ? compColor.primeHighlightColor : 'transparent'}">
        <div class="layout-column layout-align-center-center mr-3">
          <v-icon size="25" :color="compColor.prime">mdi-information</v-icon>
        </div>
        <span style="font-size: 14px;" v-bind:class="compColor.primeClass">{{ $t('AUTH.TWO_FACTOR.ACTIVATED') }}</span>
      </div>
      <div class="ml-5 px-3 py-1 report layout-row layout-align-start-center amber"
           v-else
           style="border:1px solid;border-radius: 4px;">
        <span style="font-size: 12px;">inaktiv</span>
      </div>

    </div>

    <lx-dialog v-model="modal" :allow-drag="true" :allow-resize="true" :overlay="true" :size="{x: '70%', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center grey--text text--darken-2">
          <span v-if="!authStore.getters.getAuthUserData.is_active_2fa">{{
              $t('AUTH.TWO_FACTOR.TITLE_ACTIVATE')
            }}</span>
          <span v-else>{{ $t('AUTH.TWO_FACTOR.TITLE_DEACTIVATE') }}</span>
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <v-card color="white" class="elevation-0 ma-6" v-if="!onOffStart"
                  :class="{'blue-grey lighten-5': !isDarkMode, 'transparent': isDarkMode}">
            <div class="pa-4"
                 :class="{'blue-grey--text text--darken-2': !isDarkMode, 'blue-grey--text text--lighten-2': isDarkMode}">
              <p class="body-2 mb-4">{{ $t('AUTH.TWO_FACTOR.DEACTIVATION_HINT') }}</p>
              <div class="layout-row layout-align-center-center">
                <div class="flex">
                  <v-text-field
                      :dark="compColor.isInDarkMode"
                      :color="compColor.primeColor"
                      :dense="denseFields"
                      :label="$t('AUTH.TWO_FACTOR.ENTER_CODE')"
                      placeholder=" "
                      outlined
                      name="otp"
                      max-length="6"
                      @keydown="failedCode = true"
                      @keyup.enter="deactivate2FA"
                      prepend-inner-icon="mdi-barcode-scan"
                      v-model="deactivationConfirmationCode"
                  ></v-text-field>
                </div>
              </div>
            </div>

            <v-divider></v-divider>

            <div class="pa-3">
              <v-btn block
                     depressed
                     :color="compColor.primeColor"
                     :dark="compColor.isInDarkMode"
                     :disabled="!deactivationConfirmationCode"
                     class="text-none white--text"
                     @click="deactivate2FA">{{ $t('AUTH.TWO_FACTOR.BUTTONS.DEACTIVATE') }}
              </v-btn>
            </div>
          </v-card>

          <v-stepper v-model="currentStep" :dark="isDarkMode" vertical class="elevation-0 transparent"
                     v-if="onOffStart">
            <v-stepper-step :complete="currentStep > 1" step="1" :color="currentStep <= 1 ? 'blue-grey' : 'green'">
              <span class="blue-grey--text text--darken-2">{{ $t('AUTH.TWO_FACTOR.TOOL.TITLE') }}</span>
            </v-stepper-step>

            <v-stepper-content step="1" class="py-0 pl-0 pr-6">
              <v-card class="ma-6 elevation-0" :class="{'blue-grey lighten-5': !isDarkMode, 'transparent': isDarkMode}">
                <div class="pa-3">
                  <p class="body-2 ma-0"
                     :class="{'blue-grey--text text--darken-2': !isDarkMode, 'blue-grey--text text--lighten-2': isDarkMode}">
                    {{ $t('AUTH.TWO_FACTOR.TOOL.DESCRIPTION') }}</p>

                  <lx-alert-box color="orange" class="my-3">{{
                      $t('AUTH.TWO_FACTOR.TOOL.DONT_REMOVE_HINT')
                    }}
                  </lx-alert-box>

                  <div class="layout-row mb-2"
                       :class="{'blue-grey--text text--darken-2': !isDarkMode, 'white--text': isDarkMode}">
                    <div class="flex text-center body-2 my-0 ml-0 mr-1 pa-4 elevation-0"
                         :class="{'blue-grey darken-2': isDarkMode, 'blue-grey lighten-4': !isDarkMode}"
                         style="border-radius: 4px;">
                      <h3 class="mb-2">{{ $t('AUTH.TWO_FACTOR.TOOL.MICROSOFT.TITLE') }}</h3>
                      <p>{{ $t('AUTH.TWO_FACTOR.TOOL.MICROSOFT.DESCRIPTION') }}</p>

                      <v-divider></v-divider>

                      <div class="layout-row mt-2">
                        <div class="flex layout-row layout-align-center-center">
                          <small class="blue-grey--text text--lighten-1">{{
                              $t('AUTH.TWO_FACTOR.TOOL.DOWNLOADS')
                            }}</small>
                        </div>
                        <v-btn text icon class="ma-1"
                               href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                               target="_blank">
                          <v-avatar color="blue-grey lighten-3" size="36" tile>
                            <v-icon color="white">mdi-google-play</v-icon>
                          </v-avatar>
                        </v-btn>
                        <v-btn text icon class="ma-1"
                               href="https://apps.apple.com/app/microsoft-authenticator/id983156458" target="_blank">
                          <v-avatar color="blue-grey lighten-3" size="36" tile>
                            <v-icon color="white">mdi-apple</v-icon>
                          </v-avatar>
                        </v-btn>
                        <v-btn text icon class="ma-1"
                               href="https://www.microsoft.com/p/microsoft-authenticator/9nblgggzmcj6" target="_blank">
                          <v-avatar color="blue-grey lighten-3" size="36" tile>
                            <v-icon color="white">mdi-microsoft-windows</v-icon>
                          </v-avatar>
                        </v-btn>
                        <div class="flex"></div>
                      </div>
                    </div>

                    <div class="flex text-center body-2 my-0 ml-0 ml-1 pa-4 elevation-0"
                         :class="{'blue-grey darken-2': isDarkMode, 'blue-grey lighten-4': !isDarkMode}"
                         style="border-radius: 4px;">
                      <h3 class="mb-2">{{ $t('AUTH.TWO_FACTOR.TOOL.GOOGLE.TITLE') }}</h3>
                      <p>{{ $t('AUTH.TWO_FACTOR.TOOL.GOOGLE.DESCRIPTION') }}</p>

                      <v-divider></v-divider>

                      <div class="layout-row mt-2">
                        <div class="flex layout-row layout-align-center-center">
                          <small class="blue-grey--text text--lighten-1">{{
                              $t('AUTH.TWO_FACTOR.TOOL.DOWNLOADS')
                            }}</small>
                        </div>
                        <v-btn text icon class="ma-1"
                               href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                               target="_blank">
                          <v-avatar color="blue-grey lighten-3" size="36" tile>
                            <v-icon color="white">mdi-google-play</v-icon>
                          </v-avatar>
                        </v-btn>
                        <v-btn text icon class="ma-1" href="https://apps.apple.com/app/google-authenticator/id388497605"
                               target="_blank">
                          <v-avatar color="blue-grey lighten-3" size="36" tile>
                            <v-icon color="white">mdi-apple</v-icon>
                          </v-avatar>
                        </v-btn>
                        <div class="flex"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <v-divider :dark="isDarkMode"></v-divider>

                <div class="pa-3">
                  <v-btn block
                         depressed
                         :color="compColor.primeColor"
                         :dark="compColor.isInDarkMode"
                         class="text-none white--text"
                         @click="createSecret">{{ $t('AUTH.TWO_FACTOR.BUTTONS.CONTINUE') }}
                  </v-btn>
                </div>
              </v-card>
            </v-stepper-content>

            <v-stepper-step :complete="currentStep > 2" step="2" :color="currentStep <= 2 ? 'blue-grey' : 'green'">
              <span>{{ $t('AUTH.TWO_FACTOR.SECRET_KEY.TITLE') }}</span>
            </v-stepper-step>

            <v-stepper-content step="2" class="py-0 pl-0 pr-6">
              <v-card class="ma-6 elevation-0" :class="{'blue-grey lighten-5': !isDarkMode, 'transparent': isDarkMode}">
                <div class="pa-4"
                     :class="{'blue-grey--text text--darken-2': !isDarkMode, 'blue-grey--text text--lighten-2': isDarkMode}">
                  <p class="body-2">{{ $t('AUTH.TWO_FACTOR.SECRET_KEY.DESCRIPTION') }}</p>
                  <div class="layout-row">
                    <div class="flex body-2 my-0 ml-0 mr-2 pa-4" style="border-radius: 4px;"
                         :class="{'blue-grey darken-2': isDarkMode, 'blue-grey lighten-4': !isDarkMode}">
                      <h3 class="mb-2 text-center">{{ $t('AUTH.TWO_FACTOR.SECRET_KEY.CODE_SCAN.TITLE') }}</h3>
                      <p>{{ $t('AUTH.TWO_FACTOR.SECRET_KEY.CODE_SCAN.DESCRIPTION') }}</p>
                      <div class="layout-row layout-align-center-center">
                        <div class="white pa-4 mt-2">
                          <img v-if="model && model.qr_code" alt="" :src="'data:image/png;base64, ' + model.qr_code"/>
                        </div>
                      </div>
                    </div>

                    <div class="flex body-2 my-0 mr-0 ml-2 pa-4 layout-column" style="border-radius: 4px;"
                         :class="{'blue-grey darken-2': isDarkMode, 'blue-grey lighten-4': !isDarkMode}">
                      <h3 class="text-center mb-2">{{ $t('AUTH.TWO_FACTOR.SECRET_KEY.CODE_MANUAL.TITLE') }}</h3>
                      <p>{{ $t('AUTH.TWO_FACTOR.SECRET_KEY.CODE_MANUAL.DESCRIPTION') }}</p>
                      <div class="body-1 font-weight-black flex layout-column layout-align-center-center"
                           v-if="model && model.secret">
                        {{ model.secret }}
                      </div>

                      <lx-alert-box color="orange">{{
                          $t('AUTH.TWO_FACTOR.SECRET_KEY.CODE_MANUAL.HINT')
                        }}
                      </lx-alert-box>
                    </div>
                  </div>
                </div>

                <v-divider></v-divider>

                <div class="pa-3">
                  <v-btn block depressed :color="compColor.primeColor" :dark="compColor.isInDarkMode"
                         class="text-none white--text" @click="currentStep = 3">
                    {{ $t('AUTH.TWO_FACTOR.BUTTONS.CONTINUE') }}
                  </v-btn>
                </div>
              </v-card>

            </v-stepper-content>

            <v-stepper-step :complete="currentStep > 3" step="3" :color="currentStep <= 3 ? 'blue-grey' : 'green'"
                            :rules="[() => failedCode]">
              {{ $t('AUTH.TWO_FACTOR.CONFIRM_CODE.TITLE') }}
            </v-stepper-step>

            <v-stepper-content step="3" class="py-0 pl-0 pr-6">
              <v-card class="ma-6 elevation-0" :class="{'blue-grey lighten-5': !isDarkMode, 'transparent': isDarkMode}">
                <div class="pa-4"
                     :class="{'blue-grey--text text--darken-2': !isDarkMode, 'blue-grey--text text--lighten-2': isDarkMode}">
                  <p class="body-2 mb-4">{{ $t('AUTH.TWO_FACTOR.CONFIRM_CODE.DESCRIPTION') }}</p>
                  <div class="layout-row layout-align-center-center">
                    <div class="flex">
                      <v-text-field
                          ref="acivate2fa"
                          :dark="compColor.isInDarkMode"
                          :color="compColor.primeColor"
                          :dense="denseFields"
                          :label="$t('AUTH.TWO_FACTOR.CONFIRM_CODE.ENTER_CODE')"
                          placeholder=" "
                          outlined
                          max-length="6"
                          name="otp"
                          @keydown="failedCode = true"
                          @keyup.enter="activate2FA"
                          prepend-inner-icon="mdi-barcode-scan"
                          v-model="confirmationCode"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="pa-3">
                  <v-btn block depressed
                         :color="compColor.primeColor"
                         :dark="compColor.isInDarkMode"
                         :disabled="!confirmationCode"
                         class="text-none white--text"
                         @click="activate2FA">{{ $t('AUTH.TWO_FACTOR.BUTTONS.CONTINUE_AND_ACTIVATE') }}
                  </v-btn>
                </div>
              </v-card>
            </v-stepper-content>

            <v-stepper-step step="4" :color="currentStep <= 4 ? 'blue-grey' : 'green'">
              {{ $t('AUTH.TWO_FACTOR.BACKUP_CODES.TITLE') }}
            </v-stepper-step>

            <v-stepper-content step="4" class="py-0 pl-0 pr-6">
              <v-card class="ma-6 elevation-0" :class="{'blue-grey lighten-5': !isDarkMode, 'transparent': isDarkMode}">
                <div class="pa-4"
                     :class="{'blue-grey--text text--darken-2': !isDarkMode, 'blue-grey--text text--lighten-2': isDarkMode}">
                  <p class="body-2">{{ $t('AUTH.TWO_FACTOR.BACKUP_CODES.DESCRIPTION') }}</p>

                  <div class="layout-column font-weight-black pa-4 white" v-if="model">
                    <div class="layout-row" v-for="(item, index) in model.backup_codes" :key="index">
                      <span class="mr-3 blue-grey--text text--darken-2">{{ item }}</span>
                    </div>
                  </div>

                  <lx-alert-box color="orange">
                    {{ $t('AUTH.TWO_FACTOR.BACKUP_CODES.HINT') }}
                  </lx-alert-box>

                </div>
                <v-divider></v-divider>
                <div class="pa-3">
                  <v-btn block
                         depressed
                         :color="compColor.primeColor"
                         :dark="compColor.isInDarkMode"
                         class="text-none white--text"
                         @click="close">{{ $t('AUTH.TWO_FACTOR.BUTTONS.COMPLETE') }}
                  </v-btn>
                </div>
              </v-card>

            </v-stepper-content>
          </v-stepper>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  v-if="currentStep < 4"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1 flex"
                  @click.stop="close"
                  icon="mdi-cancel"
                  :tooltip="$t('AUTH.TWO_FACTOR.BUTTONS.CANCEL')"
                  :label="$t('AUTH.TWO_FACTOR.BUTTONS.CANCEL')"></lx-btn>
        </div>
      </template>
    </lx-dialog>
  </div>

</template>

<script>

export default {
  name: 'TwofactorEditComponent',
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      modal: false,
      onOffStart: null,
      failedCode: true,
      currentStep: 1,
      confirmationCode: null,
      deactivationConfirmationCode: null,
      model: null
    }
  },
  created() {
    this.onOffStart = !this.authStore.getters.getAuthUserData.is_active_2fa
  },
  computed: {
    userData() {
      return this.authStore.getters.getAuthUserData.is_active_2fa
    }
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.onOffStart = !this.authStore.getters.getAuthUserData.is_active_2fa
      }

      if (!newVal && oldVal) {
        this.failedCode = true
        this.currentStep = 1
        this.confirmationCode = null
        this.deactivationConfirmationCode = null
        this.model = null
      }
    },

    modal() {
      this.onOffStart = !this.authStore.getters.getAuthUserData.is_active_2fa
      this.currentStep = 1

      // set focus on input when trying to deactivate 2fa
      this.$nextTick(() => {
        if (!this.onOffStart && this.$refs?.deactivateInput?.$refs?.input) {
          setTimeout(() => this.$refs.deactivateInput.$refs.input.focus())
        }
      })
    },

    currentStep (newValue) {
      // set focus on input when trying to enable 2fa in step 3
      this.$nextTick(() => {
        if (newValue === 3 && this.$refs?.acivate2fa?.$refs?.input) {
          setTimeout(() => this.$refs.acivate2fa.$refs.input.focus())
        }
      })
    }
  },
  methods: {
    createSecret() {
      this.currentStep = 2
      this.$http.post(process.env.VUE_APP_BASE_AUTH + '/users/2fa')
          .then(res => {
            if (res.data) {
              this.model = res.data
            }
          })
          .catch(this.NotifyError)
    },

    deactivate2FA() {
      this.currentStep = 4
      let data = {
        "passcode": this.deactivationConfirmationCode
      }

      this.$http.post(process.env.VUE_APP_BASE_AUTH + '/users/2fa/deactivate', data)
          .then(() => {
            this.authStore.dispatch('updateUser', {is_active_2fa: false})
            this.close()
          })
          .catch(err => {
            this.NotifyError(err)
          })
          .finally(() => {
            this.deactivationConfirmationCode = null
          })
    },

    activate2FA() {
      this.currentStep = 4
      let data = {
        "passcode": this.confirmationCode
      }

      this.$http.post(process.env.VUE_APP_BASE_AUTH + '/users/2fa/activate', data)
          .then(() => {
            this.authStore.dispatch('updateUser', {is_active_2fa: true})
          })
          .catch(err => {
            this.failedCode = false
            this.confirmationCode = null
            this.currentStep = 3
            this.NotifyError(err)
          })
    },

    close() {
      this.failedCode = true
      this.confirmationCode = null
      this.model = null
      this.modal = false
    }
  }
}
</script>
