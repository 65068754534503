<template>
  <v-form @submit="login" id="login_fields">
    <div class="flex layout-row ma-auto" style="max-width: 700px;min-height:450px;">
      <ValidationObserver ref="LoginForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">
        <v-divider class="mx-5"></v-divider>
        <div class="flex px-10 pt-0 layout-column">

          <h2 class="white--text text-center mb-3 mt-5">Zwei-Faktor-Authentisierung</h2>
          <span class="white--text mb-5">Die Zwei-Faktor-Authentisierung ist für Ihren Account aktiviert. Bitte öffnen Sie nun Ihre Authentikator App, tragen den aktuellen Zugriffscode in die unten stehenden Felder ein und bestätigen die Eingabe.  </span>

          <div class="layout-row pa-4"
               style="border:1px solid white;border-radius:5px;background:rgba(255,255,255,0.15)">
            <div class="flex layout-column layout-align-center-center">
            </div>

            <input type="number" ref="factor1" min="0" max="9" @keyup="checkfield($event, 2)" autofocus
                   class="mx-2 py-3"
                   style="background:rgba(255,255,255,0.25);font-size:40px;text-align: center;max-width:60px;min-width:60px;border:1px solid white;border-radius:5px;outline:0;box-shadow: none;color:white;"/>
            <input type="number" ref="factor2" min="0" max="9" @keyup="checkfield($event, 3)"
                   class="mx-2 py-3"
                   style="background:rgba(255,255,255,0.25);font-size:40px;text-align: center;max-width:60px;min-width:60px;border:1px solid white;border-radius:5px;outline:0;box-shadow: none;color:white;"/>
            <input type="number" ref="factor3" min="0" max="9" @keyup="checkfield($event, 4)"
                   class="mx-2 py-3 mr-6"
                   style="background:rgba(255,255,255,0.25);font-size:40px;text-align: center;max-width:60px;min-width:60px;border:1px solid white;border-radius:5px;outline:0;box-shadow: none;color:white;"/>
            <input type="number" ref="factor4" min="0" max="9" @keyup="checkfield($event, 5)"
                   class="mx-2 py-3 ml-6"
                   style="background:rgba(255,255,255,0.25);font-size:40px;text-align: center;max-width:60px;min-width:60px;border:1px solid white;border-radius:5px;outline:0;box-shadow: none;color:white;"/>
            <input type="number" ref="factor5" min="0" max="9" @keyup="checkfield($event, 6)"
                   class="mx-2 py-3"
                   style="background:rgba(255,255,255,0.25);font-size:40px;text-align: center;max-width:60px;min-width:60px;border:1px solid white;border-radius:5px;outline:0;box-shadow: none;color:white;"/>
            <input type="number" ref="factor6" min="0" max="9" @keyup="checkfield($event, 7)"
                   class="mx-2 py-3"
                   style="background:rgba(255,255,255,0.25);font-size:40px;text-align: center;max-width:60px;min-width:60px;border:1px solid white;border-radius:5px;outline:0;box-shadow: none;color:white;"/>
            <div class="flex layout-column layout-align-center-center"></div>
          </div>
          <div class="layout-row layout-align-end pr-2">
            <!--                        model: {{model}}-->
            <small class="white--text">{{ !model || model.length === 0 ? '0' : model.length }} / 6</small>
          </div>
          <div class="layout-row mx-2 my-5 pa-3 red accent-2 white--text layout-align-start-center"
               style="border-left: 8px solid #d83b3b !important;border-radius: 3px;"
               v-if="loginError && pristine">
            <v-avatar>
              <v-icon dark large>error_outline</v-icon>
            </v-avatar>
            <div class="flex layout-column layout-align-start-center ml-2">
              <p class="ma-0">Ihre Anmeldedaten sind nicht korrekt. Bitte versuchen Sie es erneut.</p>
            </div>
          </div>
        </div>
        <v-divider class="mx-5"></v-divider>
        <div class="pa-2 d-flex flex-row">
          <div class="grow mr-2">
            <v-btn block class="pink darken-2 text-none white--text" type="submit"
                   :disabled="!model || model.length < 6"
                   :loading="loading">Zugriffscode bestätigen
            </v-btn>
          </div>
          <v-btn class="text-none" to="/auth/login"
                 title="Formularfelder leeren">
            <!--                        <v-icon>clear</v-icon>-->
            abbrechen
          </v-btn>
        </div>
      </ValidationObserver>
    </div>
  </v-form>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.component('TwofactorLoginComponent', {
  name: 'TwofactorLoginComponent',
  props: ['test'],
  data() {
    return {
      model: null,
      showPassword: false,
      messageStr: '',
      loading: false,
      loginError: false

    }
  },
  created() {
  },
  computed: {},
  methods: {
    fillModel() {
      let code = '';

      for (let i = 1; i < 7; i++) {
        if (this.$refs['factor' + i.toString()].value) {
          code += this.$refs['factor' + i.toString()].value
        }
      }

      this.model = code
    },
    checkfield(event, index) {
      // console.log(event)
      if (event.keyCode === 8 && index > 1) {
        let id_prev = index - 2;
        let id_prev2 = index - 1;
        id_prev < 1 ? 1 : id_prev;
        // console.log('Backspace: ', id_prev);
        if (this.$refs['factor' + id_prev.toString()]) {
          this.$refs['factor' + id_prev.toString()].focus()
        }
        if (!this.$refs['factor' + id_prev2.toString()].value) {
          this.$refs['factor' + id_prev2.toString()].style.background = 'rgba(255,255,255,0.25)'
        }
        this.fillModel()
      } else {

        let id_prev = index - 1;
        // console.log('letztes: ', this.$refs['factor' + id_prev.toString()].value);
        this.$refs['factor' + id_prev.toString()].value = this.$refs['factor' + id_prev.toString()].value.slice(0, 1);

        if (this.$refs['factor' + id_prev.toString()].value) {
          this.$refs['factor' + id_prev.toString()].style.background = 'rgb(61, 162, 61)' //'rgba(0,255,0, 0.2)'
        }

        // console.log(index, ' -->', )
        if (index < 7 && this.$refs['factor' + id_prev.toString()].value) {
          this.$refs['factor' + index.toString()].focus()
        }

        this.fillModel()
      }
    },
    login(ev) {
      this.loading = true;
      this.ResetMessaging();

      ev.preventDefault(); // wichtig damit Formular mit Enter abgeschickt werden kann

      axios.post('/login', this.model)
        .then(resLogin => {
          this.loading = false;

          // Login in auth store
          this.authStore.dispatch('login', { data: resLogin.data, type: 'login' });

          this.model = {} // falls man sich in der anwendung erneut einloggen muss damit die name und pwd nicht vom letzten versuich drin stehen
        })
        .catch(error => {
          this.error = error.message;
          this.loginError = true;
          this.model = {};
          if (this.$refs.LoginForm) {
            this.$refs.LoginForm.reset()
          }
          this.loading = false
        })
    },
    clearForm(reset) {
      reset();
      this.model = {};
      this.loginError = false
    }
  }
})
</script>
<style lang="scss">

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>
