<template>
  <v-form @submit="changePassword" id="reset_fields">
    <div class="flex layout-column " style="max-width: 700px;">
      <ValidationObserver ref="RegisterFromTokenForm" v-slot="{ valid, pristine, reset }" class="layout-column flex"
                          v-if="token && isNewUser">
        <div class="flex px-10 py-6" style="overflow-y: auto;">
          <v-row no-gutters>
            <v-col cols="12">
              <validation-provider name="Password" rules="required" v-slot="{ errors }">
                <v-text-field
                  color="white"
                  dark
                  placeholder=" "
                  outlined
                  :error-messages="errors[0]"
                  label="Passwort*"
                  prepend-inner-icon="fingerprint"
                  v-model="model.password"
                  :clearable="false"
                  :append-icon="showPW ? 'visibility' : 'visibility_off'"
                  @click:append="() => (showPW = !showPW)"
                  :type="showPW ? 'password' : 'text'"
                  autocomplete="off"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <validation-provider name="Confirmation" rules="required|password:Password" v-slot="{ errors }">
                <v-text-field
                  color="white"
                  dark
                  placeholder=" "
                  outlined
                  :error-messages="errors[0]"
                  label="Passwort (Wiederholung)*"
                  prepend-inner-icon="fingerprint"
                  v-model="model.password2"
                  :clearable="false"
                  :append-icon="showPW2 ? 'visibility' : 'visibility_off'"
                  @click:append="() => (showPW2 = !showPW2)"
                  :type="showPW2 ? 'password' : 'text'"
                  autocomplete="off"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-alert
                border="left"
                prominent
                style="font-size:15px;"
                class="pa-3"
                v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
                <template slot="prepend">
                  <v-avatar>
                    <v-icon large>mdi-information-variant</v-icon>
                  </v-avatar>
                </template>
                Bitte vergeben Sie ein sicheres Passwort, welches neben Buchstaben auch Zahlen und Sonderzeichen
                beinhaltet. Bitte merken Sie sich Ihr neues Passwort und geben Sie es nicht an Dritte weiter.
              </v-alert>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mx-5"></v-divider>
        <div class="pa-2 layout-row">
          <div class="grow mr-2">
            <v-btn block class="green text-none white--text" type="submit" :disabled="!valid || pristine"
                   :loading="loading">Neues Passwort übernehmen
            </v-btn>
          </div>
          <v-btn class="text-none" @click="clearForm(reset)" :disabled="pristine" title="Formularfelder leeren">
            <v-icon>clear</v-icon>
          </v-btn>
        </div>
      </ValidationObserver>

    </div>

  </v-form>
</template>

<script>
import Vue from 'vue'
import router from '../../../router'

export default Vue.component('NewPasswordComponent', {
  props: {
    token: {
      type: String,
      default: function () {
        return false;
      },
      validator: function (token) {
        return token.match(/\b[0-9a-f]{40}\b/i) != null;
      }
    },
  },
  data() {
    return {
      user: {},
      loading: false,
      serveremailsent: false,
      servererror: false,
      email: '',
      model: {},
      showPW: true,
      showPW2: true,
      tokenValid: false,
      invitecompany: 'test',
      isNewUser: true
    }
  },
  created() {
    this.tokenValid = (this.token.match(/\b[0-9a-f]{40}\b/i) != null);
  },
  methods: {
    changePassword(ev) {
      if (this.loading) {
        return
      }

      ev.preventDefault(); // wichtig damit Formular mit Enter abgeschickt werden kann

      this.loading = true;

      this.$http.post('/forgotPassword/confirm', { guid: this.token, password: this.model.password })
        .then(() => {
          this.loading = false;
          router.push({ path: `/auth/login` })
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading = false
        })
    },
    clearForm(reset) {
      reset();
      this.model = {};
    },
  }
})
</script>
