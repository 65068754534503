<script>

export default {
    name: 'NewsItemWidget',
    props: {
        color: {
            type: [String],
            default: '#E0E0E0'
        },
        headline: String,
        subheading: String,
        content: String,
        creation_date: [String,Date],
        dark: Boolean
    }
}

</script>

<template>
  <div class="flex layout-column">
    <!-- date of creation -->
    <div class="text--lighten-2 text-right font-weight-regular mb-2" style="font-size:11px;"
         v-if="creation_date"
         v-bind:class="[compColor.textInput + '--text']">
      <span style="opacity:0.5;" class="mr-1">Nachricht vom</span> {{ creation_date | formatDate('DD.MMM YYYY') }}
    </div>

    <div class="lx-news-item flex layout-row pr-1 py-2 mb-4" :class="(dark) ? compColor.widget.text + ' text--lighten-1' : compColor.widget.text + 'text--darken-1'">

      <div class="layout-column px-2 mr-2">
        <div class="flex" style="width:5px;border-radius:5px;" :style="{'background': color}"></div>
      </div>

      <div class="flex layout-column">
        <!-- headline with subheading -->
        <div class="layout-row">
          <div class="flex font-weight-bold subtitle-1" style="line-height:18px;">{{ headline }}</div>
        </div>

        <div v-if="subheading" class="font-weight-regular" style="font-size: 13px;line-height: 18px;">
          {{ subheading }}
        </div>

        <!-- Content -->
        <v-divider class="mt-2" :class="{'blue-grey darken-3': dark}"></v-divider>

        <div class="font-weight-regular subtitle-2 news-content mt-3" style="white-space: pre-line;line-height: 1.0rem;">
          <div class="pr-2">
            {{ content }}
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<style scoped lang="scss">
.lx-news-item {
  background: #f2f2f2;
  border-radius: 5px;

  .news-content {
    //background: #f3f5f5;
    min-height: 20px;
    max-height: 160px;
    overflow-y: auto;
    //border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    &::-webkit-scrollbar-corner {
      //background: white;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-track {
      &:horizontal {
        //background: red;
        border-top: 1px solid transparent;
      }

      &:vertical {
        //background: red;
        border-left: 1px solid transparent;
      }

      background-color: transparent;
    }
  }
}

.lx-dark-app {
  .lx-news-item {
    background: #3b414d;

    .news-content {
      //background: #313642;
    }
  }
}
</style>
