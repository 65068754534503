<template>
  <div class="py-3">

    <h5 style="color: #1ebce7">Operatoren</h5>
    <ul>
      <li>
        <b style="color: #1ebce7">!</b> Nicht
      </li>
      <li>
        <b style="color: #1ebce7">&lt;</b> kleiner als
      <li>
        <b style="color: #1ebce7">&gt;</b> größer als
      </li>
      <li>
        <b style="color: #1ebce7">=</b> entspricht genau
      </li>
    </ul>

    <p>Verwenden Sie den Operator <b style="color: #1ebce7">!</b> um eine Suchwert zu negieren. z.B. <b>!notfalldepot</b> liefert alle Einträge zurück welche <b>nicht</b> Notfalldepot beinhalten</p>

    <ul v-if="filtered.length > 0">
      <li v-for="(item, index) in filtered" :key="index">
        <h4 v-if="item.title" style="color: khaki">{{ item.title }}</h4>
        <h5><b style="color: #e78c95">{{ item.keyword }}</b><small style="color: #1ebce7">(Operator)</small><b style="color: #e78c95">Wert</b></h5>

        <ul v-if="Array.isArray(item.hints)">
          <li v-for="(hint, index) in item.hints" :key="index"><b v-html="hint.example" style="color: #29e78e"></b>&nbsp;<i v-html="hint.hint"></i></li>
        </ul>
        <ul v-else>
          <li><b v-html="item.hints.example" style="color: #29e78e"></b>&nbsp;<i v-html="item.hints.hint"></i></li>
        </ul>

      </li>
    </ul>
  </div>
</template>

<script>
import {LxQueryHelper} from './LxQueryBuilder'

export default {
  name: 'lxQueryTooltip',
  props: {
    fields: {
      type: Array,
      default() {
        return []
      },
    }
  },
  data() {
    return {
      filtered: LxQueryHelper.FilterFieldsForTooltip(this.fields)
    }
  },
}
</script>

<style scoped>

</style>
