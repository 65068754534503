<template>
    <div class="text-center">
        <v-btn :small="btnsmall" :text="btntype" :icon="btntype" :color="compColor.primeColor" @click="startAction"
               v-if="action && allowed" :dark="!compColor.isInDarkMode" class="elevation-0 px-2">
            <v-icon :size="getFontSize" :small="btnsmall">{{icon}}</v-icon>
        </v-btn>
        <v-icon v-if="!action" color="error" title="KEINE ACTION ÜBERGEBEN">error</v-icon>
    </div>
</template>

<script>
    import Vue from 'vue'

    export default Vue.extend({
        data() {
            return {
                icon: '',
                action: null,
                alreadySetList: 'small',
                btntype: false,
                btnsmall: true,
                allowed: true,
            }
        },
        created() {
            if (this.params.icon) {
                this.icon = this.params.icon
            }
            if (this.params.action) {
                this.action = this.params.action
            }
            if (this.params.alreadySet) {
                this.alreadySetList = this.params.alreadySet
            }
            if (this.params.btntype) {
                this.btntype = this.params.btntype;
                this.btnsmall = false
            }

            /**
             * wenn artikel aus der AML liste oder der abda entfernt werden können diese noch in stationslisten oder hitlisten
             * stehen aber nicht mehr aufgelöst werden, in diesen Fällen wird keine pzn zurückgegeben sondern nur die referer_pzn
             * also die pzn welche in den stations oder hitlisten steht.
             * 1 Fall) wenn keine pzn und eine referer_pzn dann Artikel nicht mehr auflösbar
             * 2 Fall) wenn pzn und referer_pzn dann Artikel noch auflösbar
             * */
            if (this.params.node && this.params.node.data) {
                let pzn = this.params.node.data.pzn;
                let referer_pzn = this.params.node.data.referer_pzn;
                if (!pzn && referer_pzn) {
                    this.allowed = false
                }
            }
        },
        computed: {
            getFontSize() {
                let test = this.$store.getters.getAppFontSize || 16;
                return Math.round(test * 1.5)
            }
        },
        methods: {
            startAction(event) {
                event.stopPropagation(); //stop full bubbling
                if (this.action) {
                    this.action(this.params.node.data)
                }
            }
        }
    })
</script>
