<template>
  <div class="pa-0 ma-1 mt-2">
    <!--    open-on-click-->
    <v-treeview
      :items="items"
      :dark="compColor.isInDarkMode"
      :color="compColor.primeColor"
      item-key="id"
      :open.sync="open"
      dense
      hoverable
      return-object
      class="ma-1"
      style="border-radius:3px;"
      v-bind:class="[{'elevation-3': !showEditor}]"
    >
      <!--          compColor.contentFrameBack,-->
      <template v-slot:label="{item}">
        <div class="blue-grey--text body-2 pr-4"
             style="min-width:150px;line-height:34px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
             @click="download(item)">
          <!--          <v-btn text @click="download(item)" :disabled="!item.isFile" class="text-left text-none blue-grey&#45;&#45;text yellow"-->
          <!--                 :title="item.contenttype">-->
          <span v-bind:class="{'pending font-italic': item.isPending}">{{ item.name }}</span>
          <!--          </v-btn>-->
        </div>
      </template>
      <template v-slot:prepend="{item, open}">
        <div class="layout-row prependsysmbol">

          <div style="width:10px;height: 36px;" class="layout-column mr-1 branchsymbol" v-if="!item.isRoot">
            <div class="flex layout-row">
              <div class="blue-grey" style="width:1px;opacity:0.3;"></div>
              <div class="flex"></div>
            </div>
            <div class="blue-grey" style="height:1px;opacity:0.3;"></div>
            <div class="flex layout-row lowerpart">
              <div class="blue-grey" style="width:1px;opacity:0.3;"></div>
              <div class="flex"></div>
            </div>
          </div>
          <v-icon size="17" v-if="!item.isFile && item.isRoot" :color="compColor.primeColor">
            {{ 'mdi-file-tree' }}
          </v-icon>
          <v-icon size="17" v-else-if="!item.isFile && !item.isRoot"
                  v-bind:class="[{'orange--text': item.isPending}, compColor.isInDarkMode ?  'amber--text text--lighten-4' : 'amber--text text--darken-1']">
            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
          <div v-else class="layout-column layout-align-center-center">
            <v-icon size="17" v-if="item.contenttype === 'application/pdf'"
                    v-bind:class="[compColor.isInDarkMode ? 'red--text text--lighten-3' : 'red--text text--lighten-2']">
              picture_as_pdf
            </v-icon>
            <v-icon size="17" v-else-if="item.contenttype === 'image/jpeg' || item.contenttype === 'image/png'"
                    v-bind:class="[compColor.isInDarkMode ? 'green--text text--lighten-2' : 'green--text text--lighten-1']">
              mdi-file-image
            </v-icon>
            <v-icon size="17" v-else
                    v-bind:class="[compColor.isInDarkMode ? 'blue-grey--text' : 'blue-grey--text text--lighten-1']">
              mdi-file
            </v-icon>
          </div>
        </div>
      </template>
      <template v-slot:append="{item}" v-if="showEditor">
        <div class="layout-row layout-align-start-center" style="line-height:34px;">

          <div class="d-sm-none d-md-flex mr-3 text-right layout-column blue-grey--text text--lighten-3"
               style="width: 70px;">
            <small v-if="typeof item.size === 'number' && item.size > 0">{{ Math.round(item.size / 1000) }} kb</small>
          </div>
          <div class="d-sm-none d-md-flex px-1 blue-grey--text text--lighten-3 layout-column text-right"
               style="width: 80px;line-height: 15px;">
            <!--            <v-icon size="20" v-if="item.updated">mdi-clock-outline</v-icon>-->
            <small v-if="item.updated">{{ item.updated | formatDate('DD.MM.YYYY') }}</small>
            <!--            <div class="layout-row layout-align-end-center">-->
            <small v-if="item.updated">{{ item.updated | formatDate('HH:mm') }}</small>
            <!--            <small v-if="item.updated"><sup style="margin-left:1px;top: -0.25em">{{ item.updated | formatDate('ss') }}</sup></small>-->
            <!--            </div>-->
          </div>
          <div class="d-sm-none d-md-flex pr-1 blue-grey--text text--lighten-3 layout-row" style="width: 80px;"
               v-if="showEditor && item.isFile">

            <div class="pl-2 layout-column layout-align-center-start" style="line-height: 14px;">
              <small v-if="item.owner" class="amber--text">teilweise sichtbar</small>
              <small v-else class="green--text">Für alle sichtbar</small>
            </div>


            <!--            <small v-if="item.owner">{{ item.owner }}</small>-->

          </div>

          <div class="text-center" style="width: 30px;" v-if="!item.isFile">
            <v-tooltip bottom open-delay="350">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" small text icon :color="compColor.primeColor"
                       @click="showDialog($event, 'create', item)"
                       :disabled="item.isPending"
                       class="text-none mr-1" depressed dark>
                  <v-icon size="18">create_new_folder</v-icon>
                  <!--          Ordner erstellen-->
                </v-btn>
              </template>
              <span>Neues Verzeichnis erstellen</span>
            </v-tooltip>
          </div>
          <div class="text-center" style="width: 30px;" v-if="!item.isFile">
            <v-tooltip bottom open-delay="350">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" small text icon :color="compColor.primeColor"
                       @click="showDialog($event, 'upload', item)"
                       class="text-none mr-1" depressed dark>
                  <v-icon size="18">mdi-file-upload</v-icon>
                  <!--          Datei hochladen-->
                </v-btn>
              </template>
              <span>Datei hochladen</span>
            </v-tooltip>
          </div>
          <div class="text-center" style="width: 30px;" v-if="item.isFile && !item.isRoot">
            <v-tooltip bottom open-delay="350">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" small text icon :color="compColor.primeColor" @click="showDialog($event, 'edit', item)"
                       :disabled="item.isPending || (item.children && item.children.length > 0)"
                       class="text-none mr-1" depressed dark>
                  <v-icon size="17">settings</v-icon>
                  <!--          Löschen-->
                </v-btn>
              </template>
              <span>Dateiberechtigungen bearbeiten</span>
            </v-tooltip>
          </div>
          <!-- Rename -->
          <div class="text-center" style="width: 30px;" v-if="item.isFile && !item.isRoot">
            <v-tooltip bottom open-delay="350">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" small text icon :color="compColor.primeColor" @click="showDialog($event, 'rename', item)"
                       :disabled="item.children && item.children.length > 0"
                       class="text-none mr-1" depressed dark>
                  <v-icon size="20">mdi-rename-box</v-icon>
                </v-btn>
              </template>
              <span>Umbenennen</span>
            </v-tooltip>
          </div>
          <!-- Delete -->
          <div class="text-center" style="width: 30px;" v-if="item.isFile && !item.isRoot">
            <v-tooltip bottom open-delay="350">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" small text icon :color="compColor.primeColor" @click="preDelete($event, item)"
                       :disabled="item.children && item.children.length > 0"
                       class="text-none mr-1" depressed dark>
                  <v-icon size="20">delete_forever</v-icon>
                </v-btn>
              </template>
              <span>Löschen</span>
            </v-tooltip>
          </div>

        </div>
      </template>
    </v-treeview>
  </div>
</template>

<script>
function SortChildrensName(left, right) {
  const a = left.name.toLowerCase()
  const b = right.name.toLowerCase()

  if (a === b) return 0

  return a < b ? -1 : 1
}

function SortChildrensType(a, b) {
  if (a.isFile === b.isFile) return SortChildrensName(a, b)

  return Number(a.isFile) - (Number(b.isFile) * 2)
}

function ConvertFileListToTreeView(input, showRoot) {
  if (!Array.isArray(input)) return {};

  // Move out or template into a creator function.
  function createPath(info, name) {
    if (info && info.name.endsWith(name)) {
      return { ...info, children: [] };
    } else {
      return { size: 0, children: [] };
    }
  }

  // Resolves the path into objects iteratively (but looks eerily like recursion).
  function resolvePath(root, path) {
    path.name.split('/').reduce(function (pathObject, pathName) {
      // For each path name we come across, use the existing or create a subpath
      pathObject.children[pathName] = pathObject.children[pathName] || createPath(path, pathName);
      // Then return that subpath for the next operation
      return pathObject.children[pathName];
      // Use the passed in base object to attach our resolutions
    }, root);
  }

  // Convert To TreeView
  const tree = input.reduce(function (carry, pathEntry) {
    // On every path entry, resolve using the base object
    resolvePath(carry, pathEntry);
    // Return the base object for suceeding paths, or for our final value
    return carry;
    // Create our base object
  }, createPath());
  let id = 0;

  function Build(a, o) {
    if (!a.children) return;

    Object.keys(a.children).forEach(function (element) {
      if (element === "") return;
      const isFile = Object.keys(a.children[element].children).length === 0;

      let sub = {
        ...a.children[element],
        name: element,
        isFile: isFile,
        id: ++id,
        prefix: [o.prefix, element].join('/')
      };
      // Recursive add if not a file
      if (!isFile) {
        sub.children = [];
        sub = Build(a.children[element], sub);
        // sub.isFile = false;
      }
      o.children.push(sub);
    });

    // Sort childrens; bring FOLDERS UP
    if (Array.isArray(o.children)) {
      o.children.sort(SortChildrensType)
    }
    // Out out, the object is out
    return o;
  }

  let output = Build(tree, {
    name: 'Ordnerelemente',
    isRoot: true,
    children: []
  });
  return {
    lastid: id,
    items: (showRoot) ? [output] : output.children
  };
}

export default {
  name: 'Downloads',
  props: [
    'showEditor',
    'showDialog'
  ],
  data() {
    return {
      lastid: 0,
      open: [],
      items: [],
    }
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    download(item) {
      if (!item.isFile) return;
      let filename = item.prefix;

      this.$http.getAsBlob('/document/' + encodeURIComponent(filename))
        .then((res) => {
          let objectURL = URL.createObjectURL(res.data);
          const hiddenElement = document.createElement('a');
          hiddenElement.style.display = 'none';
          hiddenElement.href = objectURL;
          hiddenElement.setAttribute('download', item.name);
          document.body.appendChild(hiddenElement);
          hiddenElement.click();
          document.body.removeChild(hiddenElement);

          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(objectURL);
          }, 100);
        })
        .catch(this.NotifyError)
    },
    createFolder(res, item) {
      item.children = item.children || [];
      item.children.push({
        name: res.name,
        id: ++this.lastid,
        isPending: true,
        isFile: false,
        prefix: [item.prefix, res.name].join('/')
      });

      item.children.sort(SortChildrensType);
      this.open.push(item)
    },
    preDelete($event, item) {
      if (item.isPending === true) {
        return this.getDocuments();
      }

      this.DialogConfirm(
        'Löschen der Datei',
        'Möchten Sie die ausgewählte Datei "' + item.name + '" wirklich löschen? Eine Wiederherstellung ist nicht möglich.',
        { ok: 'Löschen', cancel: this.$t('buttons.cancel.title') }
      )
        .then(() => {
          let filename = item.prefix

          if (!item.isFile && !filename.endsWith('/')) {
            // Folder
            filename += '/'
          }

          this.$http.delete('/document/' + encodeURIComponent(filename))
            .then(() => {
              this.getDocuments()
            })
            .catch(this.NotifyError)
        })
        .catch(() => {
        })

      // this.showDialog($event, 'delete', item)
    },
    getDocuments() {
      this.$http.get('/documents')
        .then((res) => {
          let data = ConvertFileListToTreeView(res.data, this.showEditor);
          this.items = data.items;

          this.lastid = data.lastid;

          if (this.showEditor) this.open = [0];
        })
        .catch(this.NotifyError)
    },
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.v-treeview--dense .v-treeview-node__root {
  min-height: 30px;
}

.v-application--is-ltr .v-treeview-node__prepend {
  margin-right: 0px;
}

.v-treeview-node__children .v-treeview-node:last-child {
  & > .v-treeview-node__root > .v-treeview-node__content > .v-treeview-node__prepend > .prependsysmbol > .branchsymbol > .lowerpart > div {
    //background: red !important;
    display: none;
  }
}

.v-treeview-node__label {
  margin-left: 10px;
}

.v-treeview-node__label,
.v-treeview-node__append {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-collapse: collapse;
}
</style>
