<template>
  <v-navigation-drawer
          v-model="isHelperVisible"
          fixed
          temporary
          right
          width="40%"
          style="min-width: 750px;background-color: #546e7a7a !important;border-color: #546e7a !important;"
          class="elevation-12 layout-column help-drawer pa-3"
  >
    <div class="flex layout-column" style="height:100%;min-height: 100%;max-height: 100%;" v-bind:class="[compColor.contentFrameBack]">
    <div class="pa-7">
      <div class="layout-row pa-2 green lighten-5">
        <img src="../../assets/bot_02.png" style="width:200px;height:200px;"/>
        <div class="pa-3 flex">
          <div class="headline font-weight-black">Wo bin ich denn hier gelandet?</div>
          <div>Keine Angst, sie befinden sich nur in der <strong v-bind:class="[ compColor.textInput + '--text']">Detailansicht eines Warenkorbes</strong>. Hier können Sie nach Artikeln suchen und wenn Sie fündig geworden sind, diesen in den Warenkorb Ihrer Station übernehmen. Anschließend können Sie den Warenkorb als fertig markieren und diesen auch bestellen, so sie die nötigen Rechte dafür besitzen.</div>
        </div>
      </div>
    </div>
    <v-divider v-bind:class="[compColor.divider]"></v-divider>
    <div class="flex py-5 px-9" style="overflow-y: auto;" v-bind:class="[compColor.widget.text]">

        <!--          <ul>-->
        <!--            <li>Einführung</li>-->
        <!--            <li>Dashboard</li>-->
        <!--            <li>Account</li>-->
        <!--            <li>-->
        <!--              Administration-->
        <!--              <ul>-->
        <!--                <li>Allgemeines</li>-->
        <!--                <li>Standorte-->
        <!--                  <ul>-->
        <!--                    <li>Apotheken</li>-->
        <!--                    <li>Krankenhäuser</li>-->
        <!--                    <li>Stationen</li>-->
        <!--                  </ul>-->
        <!--                </li>-->
        <!--                <li>Nutzer- und Gruppenverwaltung-->
        <!--                  <ul>-->
        <!--                    <li>Nutzer</li>-->
        <!--                    <li>Gruppen</li>-->
        <!--                  </ul>-->
        <!--                </li>-->
        <!--              </ul>-->
        <!--            </li>-->
        <!--            <li>Warenkorb</li>-->
        <!--            <li>Bestellungen</li>-->
        <!--          </ul>-->

        <div class="">
            <div class="headline font-weight-black">Was sollte ich hier tun?</div>
            <div>Das ist recht einfach. Befolgen Sie dazu einfach die im Folgenden aufgeführten Schritte.</div>
        </div>
        <div class="pa-3">
          <ul>
            <li>Als erstes geben Sie in das Feld Artikelname einen Begriff ein nachdem sie suchen möchten, z.B.: "Aspirin". Wenn Sie den Button rechts neben dem Suchfeld drücken klappt eine Reihe mit weiteren Feldern aus nach denen Sie suchen können. Darunter die PZN, der Hersteller und andere. Sie sollten so relativ schnell das von Ihnen gesuchte Produkt finden.</li>
            <li>In der Tabelle unter den Suchfeldern finden Sie die Ergebnissliste Ihrer Suche. Alle Artikel die Ihren Suchkriterien entsprechen werden aufgelistet. In den Spalten  <i v-bind:class="[ compColor.textInput + '--text']">"Listentyp"</i> können Sie erkennen ob Ihr Artikel ein Stationsartikel ist,  ein Sonderartikel oder sogar ein Zusatzentgeld Artikel. In der Spalte <i v-bind:class="[ compColor.textInput + '--text']">"auf Lager"</i> sehen sie ob der Artikel derzeit vorrätig ist.</li>
            <li>Haben Sie den gewünschten Artikel gefunden, wählen sie noch die Anzahl aus die Sie übernehmen möchten, oder tragen diese direkt ein. Abschließend drücken Sie noch den Plus-Knopf am Ende der Zeile und legen so den Artikel mit der gewünschten Anzahl in Ihren Warenkorb.</li>
            <li>Die vorangegangen Schritte wiederholen Sie nun für alle benötigten Artikel.</li>
            <li>Wenn Sie für Ihre Artikel spezielle Lagerorte angeben möchten können Sie diese in der Spalte <i v-bind:class="[ compColor.textInput + '--text']">"Lagerorte"</i> auswählen. Allerdings müssen diese Lagerorte vorher in der Administration Ihrer Station hinterlegt worden sein.</li>
            <li>Die letzten Schritte unterscheiden sich nun je nachdem welche Rechte Sie besitzen, also ob Sie eine Krankenschwester bzw. ein Krankenpfleger, Arzt oder Oberartzt sind.
              <ol>
                <li><strong>Pflegekräfte</strong>...können einen Warenbkorb befüllen bzw. wieder leeren und ihn für einen Arzt als bestellbereit setzen. Dazu drücken Sie bitte den Button <i v-bind:class="[ compColor.textInput + '--text']">"Warenkorb zum bestellen markieren".</i> </li>
                <li><strong>Ärzte</strong>...können einen Warenbkorb befüllen, wieder leeren und ihn bestellen solang er keine Sonderartikel enthält. Dazu drücken Sie bitte den Button <i v-bind:class="[ compColor.textInput + '--text']">"Warenkorb bestellen".</i> </li>
                <li><strong>Oberärzte</strong>...besitzen sämtliche Berechtigungen und können somit einen Warenbkorb befüllen, wieder leeren und ihn bestellen auch wenn er Sonderartikel enthält. Dazu drücken Sie bitte den Button <i v-bind:class="[ compColor.textInput + '--text']">"Warenkorb bestellen".</i> </li>
              </ol>
            </li>
          </ul>
        </div>
      </div>
    <v-divider v-bind:class="[compColor.divider]"></v-divider>
    <div class="pa-3 layout-row">
      <v-btn dark class="text-none" :color="compColor.primeColor"><v-icon>chevron_left</v-icon>Letztes Thema</v-btn>
      <v-btn dark class="flex mx-1 text-none" :color="compColor.primeColor">Inhaltsverzeichniss</v-btn>
      <v-btn dark class="flex mr-1 text-none" :color="compColor.primeColor">Drucken</v-btn>
      <v-btn dark class="text-none" :color="compColor.primeColor">Nächstes Thema<v-icon>chevron_right</v-icon></v-btn>
    </div>
  </div>
  </v-navigation-drawer>
<!--  <v-card class="pa-5 elevation-0 flex layout-column blue-grey&#45;&#45;text text&#45;&#45;darken-2">-->



<!--    <div class="layout-row py-2 green lighten-5">-->
<!--      <img src="../../assets/bot_02.png" style="width:200px;height:200px;"/>-->
<!--      <div class="pa-3 flex">-->
<!--        <div class="headline font-weight-black">Wo bin ich denn hier gelandet?</div>-->
<!--        <div>Keine Angst, sie befinden sich nur in der <strong v-bind:class="[ compColor.textInput + '&#45;&#45;text']">Detailansicht eines Warenkorbes</strong>. Hier können Sie nach Artikeln suchen und wenn Sie fündig geworden sind, diesen in den Warenkorb Ihrer Station übernehmen. Anschließend können Sie den Warenkorb als fertig markieren und diesen auch bestellen, so sie die nötigen Rechte dafür besitzen.</div>-->
<!--      </div>-->
<!--    </div>-->

<!--&lt;!&ndash;    <div class="layout-row">&ndash;&gt;-->
<!--&lt;!&ndash;      <span>Administration</span>&ndash;&gt;-->
<!--&lt;!&ndash;      <v-icon>chevron_right</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;      <span>Standorte</span>&ndash;&gt;-->
<!--&lt;!&ndash;      <v-icon>chevron_right</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;      <span>Stationen</span>&ndash;&gt;-->
<!--&lt;!&ndash;      <v-icon>chevron_right</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;      <span>Detailansicht</span>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->

<!--    <div class="pa-3 flex" style="border:1px solid lime;overflow-y: scroll;">-->

<!--      &lt;!&ndash;          <ul>&ndash;&gt;-->
<!--      &lt;!&ndash;            <li>Einführung</li>&ndash;&gt;-->
<!--      &lt;!&ndash;            <li>Dashboard</li>&ndash;&gt;-->
<!--      &lt;!&ndash;            <li>Account</li>&ndash;&gt;-->
<!--      &lt;!&ndash;            <li>&ndash;&gt;-->
<!--      &lt;!&ndash;              Administration&ndash;&gt;-->
<!--      &lt;!&ndash;              <ul>&ndash;&gt;-->
<!--      &lt;!&ndash;                <li>Allgemeines</li>&ndash;&gt;-->
<!--      &lt;!&ndash;                <li>Standorte&ndash;&gt;-->
<!--      &lt;!&ndash;                  <ul>&ndash;&gt;-->
<!--      &lt;!&ndash;                    <li>Apotheken</li>&ndash;&gt;-->
<!--      &lt;!&ndash;                    <li>Krankenhäuser</li>&ndash;&gt;-->
<!--      &lt;!&ndash;                    <li>Stationen</li>&ndash;&gt;-->
<!--      &lt;!&ndash;                  </ul>&ndash;&gt;-->
<!--      &lt;!&ndash;                </li>&ndash;&gt;-->
<!--      &lt;!&ndash;                <li>Nutzer- und Gruppenverwaltung&ndash;&gt;-->
<!--      &lt;!&ndash;                  <ul>&ndash;&gt;-->
<!--      &lt;!&ndash;                    <li>Nutzer</li>&ndash;&gt;-->
<!--      &lt;!&ndash;                    <li>Gruppen</li>&ndash;&gt;-->
<!--      &lt;!&ndash;                  </ul>&ndash;&gt;-->
<!--      &lt;!&ndash;                </li>&ndash;&gt;-->
<!--      &lt;!&ndash;              </ul>&ndash;&gt;-->
<!--      &lt;!&ndash;            </li>&ndash;&gt;-->
<!--      &lt;!&ndash;            <li>Warenkorb</li>&ndash;&gt;-->
<!--      &lt;!&ndash;            <li>Bestellungen</li>&ndash;&gt;-->
<!--      &lt;!&ndash;          </ul>&ndash;&gt;-->

<!--      <div class="layout-row pt-2">-->
<!--        <div class="pa-3 flex">-->
<!--          <div class="headline font-weight-black">Was muss ich genau tun?</div>-->
<!--          <div>Das ist recht einfach. Befolgen Sie dazu einfach die im Folgenden aufgeführten Schritte.</div>-->
<!--        </div>-->

<!--        &lt;!&ndash;          <img src="./assets/bot_04.png" style="width:200px;height:200px;"/>&ndash;&gt;-->
<!--      </div>-->
<!--      <div class="pa-3">-->
<!--        <ul>-->
<!--          <li>Als erstes geben Sie in das Feld Artikelname einen Begriff ein nachdem sie suchen möchten, z.B.: "Aspirin". Wenn Sie den Button rechts neben dem Suchfeld drücken klappt eine Reihe mit weiteren Feldern aus nach denen Sie suchen können. Darunter die PZN, der Hersteller und andere. Sie sollten so relativ schnell das von Ihnen gesuchte Produkt finden.</li>-->
<!--          <li>In der Tabelle unter den Suchfeldern finden Sie die Ergebnissliste Ihrer Suche. Alle Artikel die Ihren Suchkriterien entsprechen werden aufgelistet. In den Spalten  <i v-bind:class="[ compColor.textInput + '&#45;&#45;text']">"Listentyp"</i> können Sie erkennen ob Ihr Artikel ein Stationsartikel ist,  ein Sonderartikel oder sogar ein Zusatzentgeld Artikel. In der Spalte <i v-bind:class="[ compColor.textInput + '&#45;&#45;text']">"auf Lager"</i> sehen sie ob der Artikel derzeit vorrätig ist.</li>-->
<!--          <li>Haben Sie den gewünschten Artikel gefunden, wählen sie noch die Anzahl aus die Sie übernehmen möchten, oder tragen diese direkt ein. Abschließend drücken Sie noch den Plus-Knopf am Ende der Zeile und legen so den Artikel mit der gewünschten Anzahl in Ihren Warenkorb.</li>-->
<!--          <li>Die vorangegangen Schritte wiederholen Sie nun für alle benötigten Artikel.</li>-->
<!--          <li>Wenn Sie für Ihre Artikel spezielle Lagerorte angeben möchten können Sie diese in der Spalte <i v-bind:class="[ compColor.textInput + '&#45;&#45;text']">"Lagerorte"</i> auswählen. Allerdings müssen diese Lagerorte vorher in der Administration Ihrer Station hinterlegt worden sein.</li>-->
<!--          <li>Die letzten Schritte unterscheiden sich nun je nachdem welche Rechte Sie besitzen, also ob Sie eine Krankenschwester bzw. ein Krankenpfleger, Arzt oder Oberartzt sind.-->
<!--            <ol>-->
<!--              <li><strong>Pflegekräfte</strong>...können einen Warenbkorb befüllen bzw. wieder leeren und ihn für einen Arzt als bestellbereit setzen. Dazu drücken Sie bitte den Button <i v-bind:class="[ compColor.textInput + '&#45;&#45;text']">"Warenkorb zum bestellen markieren".</i> </li>-->
<!--              <li><strong>Ärzte</strong>...können einen Warenbkorb befüllen, wieder leeren und ihn bestellen solang er keine Sonderartikel enthält. Dazu drücken Sie bitte den Button <i v-bind:class="[ compColor.textInput + '&#45;&#45;text']">"Warenkorb bestellen".</i> </li>-->
<!--              <li><strong>Oberärzte</strong>...besitzen sämtliche Berechtigungen und können somit einen Warenbkorb befüllen, wieder leeren und ihn bestellen auch wenn er Sonderartikel enthält. Dazu drücken Sie bitte den Button <i v-bind:class="[ compColor.textInput + '&#45;&#45;text']">"Warenkorb bestellen".</i> </li>-->
<!--            </ol>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="grey lighten-4 layout-row">-->
<!--      <v-btn dark class="text-none" :color="compColor.primeColor"><v-icon>chevron_left</v-icon>Letztes Thema</v-btn>-->
<!--      <v-btn dark class="flex mx-1 text-none" :color="compColor.primeColor">Inhaltsverzeichniss</v-btn>-->
<!--      <v-btn dark class="flex mr-1 text-none" :color="compColor.primeColor">Drucken</v-btn>-->
<!--      <v-btn dark class="text-none" :color="compColor.primeColor">Nächstes Thema<v-icon>chevron_right</v-icon></v-btn>-->
<!--    </div>-->
<!--  </v-card>-->
</template>
<script>
  export default {
    props:[
      'isHelperVisible'
    ],
    data() {
      return {
        // dialog: true,
        // appClassnames: '',
        // activeTab: 1
      }
    },
    mounted() {
      // let app = document.getElementById('mainApp')
      // console.log('---> ', app.className)
      // this.appClassnames = app.className
      // app.className += ' authArea'
    },
    destroyed() {
      // console.log('verlasse auth')
      // let app = document.getElementById('mainApp')
      // console.log('---> ', app.className)
      // app.className = this.appClassnames
    },
    watch: {
      isHelperVisible () {
        // console.log('route ändert siche:', this.$route)
      }
    }
  }
</script>
<style lang="scss">

</style>
