<template>
  <div class="red">
    <v-overlay
      z-index="2"
      :value="overlay"
    >
    </v-overlay>
    <div class="elevation-24 lxauth-login-container"
         style="z-index:4;position:fixed;left:50%;margin-left:-350px;top:50%;margin-top:-285px;width:700px;min-height:570px;">

      <div class="px-0 pt-2">
        <!--        style="background:rgba(0,0,0,0.1)">-->
        <v-toolbar
          color="transparent"
          dark
          flat
          height="80px"
        >
          <v-toolbar-title class="layout-row layout-align-center-center" style="width: 100%;">
            <v-subheader class="text-center px-0 display-1 font-weight-bold white--text my-3">Medi<span
              class="font-weight-thin white--text">Shop</span><sup><small>&copy;</small></sup>
              <span class="ml-6 font-weight-thin white--text" v-if="userRegister">  -  Registrierung</span>
              <span class="ml-6 font-weight-thin white--text" v-if="passwordReset">  -  Neues Passwort</span>
            </v-subheader>

          </v-toolbar-title>

          <template v-slot:extension
                    v-if="!passwordReset && !userRegister && !hideForAutologin">

            <v-tabs
              color="white"
              background-color="transparent"
              slider-color="green accent-3"
              class="pl-6"
            >
              <v-tab class="text-none px-5" to="/auth/login">Anmeldung</v-tab>
              <!--<v-tab class="text-none" to="/auth/register/new">Registrieren</v-tab>-->
              <!--<v-tab class="text-none" to="/auth/register/52ee111bcc7a271ef665dae6d3cc26a0d3798f06">REMOVE LIKE THIS</v-tab>-->
              <v-tab class="text-none" to="/auth/forgot">Passwort vergessen</v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
      </div>
      <router-view id="authContainer" style="background:rgba(0,0,0,0.1)"></router-view>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      overlay: true,
      dialog: true,
      appClassnames: '',
      activeTab: 1
    }
  },
  mounted() {
    let app = document.getElementById('mainApp');
    this.appClassnames = app.className;
    app.className += ' authArea'
  },
  computed: {
    passwordReset() {
      return this.$route.name === 'NewPassword' ? true : false
    },
    userRegister() {
      return this.$route.name === 'Register' ? true : false
    },
    hideForAutologin() {
      return this.$route.name === 'AutoLogin' || this.$route.name === 'AutoLogout' ? true : false
    }
  },
  destroyed() {
    let app = document.getElementById('mainApp');
    app.className = this.appClassnames
  }
}
</script>
<style lang="scss">

.v-dialog {
  /*border-left: 10px solid #c3185b;*/
}
</style>
