<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column"
       ref="infoBox">
    <lx-dashboard :editable="false" :widget-padding="6">

      <template #widgetContainer="{value}">
        <lx-widget-container :widget-get-value="value" @widgetSetValues="value.setValues">
          <template #widget>
            <empty v-if="value.item.type==='empty'"></empty>
            <activity v-if="value.item.type==='activity'"></activity>
            <downloads v-if="value.item.type==='downloads'"></downloads>
            <news v-if="value.item.type==='news'"></news>
            <advertisment v-if="value.item.type==='advertisment'"></advertisment>
            <orders v-if="value.item.type==='orders'"></orders>
            <deliveries v-if="value.item.type==='deliveries'"></deliveries>
            <shopping-carts v-if="value.item.type==='shoppingcarts'"></shopping-carts>
          </template>
        </lx-widget-container>
      </template>

    </lx-dashboard>
  </div>
</template>

<script>

import Empty from './widgets/Empty'
import Activity from './widgets/Activity'
import Downloads from './widgets/Downloads'
import News from './widgets/News'
import Advertisment from './widgets/Advertisment'
import Orders from './widgets/Orders'
import Deliveries from './widgets/Deliveries'
import ShoppingCarts from './widgets/ShoppingCarts'

export default {
  name: 'Dashboard',
  props: {
    msg: String
  },
  provide() {
    return {
      widgetTypesInject: this.getUserLocationType() === "pharmacy" ? this.widgetTypesApo : this.widgetTypesME,
      editable: false,
    }
  },
  components: {
    Empty,
    Activity,
    Downloads,
    News,
    Advertisment,
    Orders,
    Deliveries,
    ShoppingCarts,
  },
  mounted() {
  },
  data() {
    return {
      widgetTypesApo: [
        {
          type: 'empty',
          title: '',
          subtitle: '',
          // icon: ""
        },
        // {
        //   type: 'activity',
        //   subtitle: "Zusammenfassung personlicher Aktivitäten",
        //   title: "Aktivitäten",
        //   icon: "assignment"
        // },
        {
          type: 'downloads',
          icon: "cloud_download",
          subtitle: this.$t('dashboard.widget.downloads.value'),
          title: this.$t('dashboard.widget.downloads.title')
        },
        {
          type: 'news',
          icon: "speaker_notes",
          subtitle: this.$t('dashboard.widget.news.value'),
          title: this.$t('dashboard.widget.news.title')
        },
        // {
        //   type: 'advertisment',
        //   icon: "speaker_notes",
        //   subtitle: "ihrer betreuenden Apotheke",
        //   title: "News und Ankündigungen"
        // },
        {
          type: 'orders',
          icon: "assignment",
          subtitle: this.$t('dashboard.widget.orders.value'),
          title: this.$t('dashboard.widget.orders.title')
        }
      ],
      widgetTypesME: [
        {
          type: 'empty',
          title: '',
          subtitle: '',
          // icon: ""
        },
        // {
        //   type: 'activity',
        //   subtitle: "Zusammenfassung personlicher Aktivitäten",
        //   title: "Aktivitäten",
        //   icon: "assignment"
        // },
        {
          type: 'downloads',
          icon: "cloud_download",
          subtitle: this.$t('dashboard.widget.downloads.value'),
          title: this.$t('dashboard.widget.downloads.title')
        },
        {
          type: 'news',
          icon: "speaker_notes",
          subtitle: this.$t('dashboard.widget.news.value'),
          title: this.$t('dashboard.widget.news.title')
        },
        // {
        //   type: 'advertisment',
        //   icon: "speaker_notes",
        //   subtitle: "ihrer betreuenden Apotheke",
        //   title: "News und Ankündigungen"
        // },
        {
          type: 'orders',
          icon: "assignment",
          subtitle: this.$t('dashboard.widget.orders.value'),
          title: this.$t('dashboard.widget.orders.title')
        },
        {
          type: 'deliveries',
          icon: "local_shipping",
          subtitle: this.$t('dashboard.widget.deliveries.value'),
          title: this.$t('dashboard.widget.deliveries.title')
        },
        {
          type: 'shoppingcarts',
          icon: "local_shipping",
          subtitle: this.$t('dashboard.widget.shoppingcarts.value'),
          title: this.$t('dashboard.widget.shoppingcarts.title')
        }
      ],

      isPharmacy: (this.getUserLocationType() === "pharmacy"),
    }
  },
  created() {

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">


.lx-dashboard {
  .title {
    font-size: 1.1rem !important;
  }

  .subheading {
    font-size: 0.8rem !important;
  }
}

.lx-dash-list {
  .v-list-item {
    min-height: auto !important;

    .v-avatar.v-list-item__avatar {
      margin: 0;
    }

    .v-list-item__content {
      padding: 7px 0;
    }
  }
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}

.lx-delivery-background {
  background-image: url("../../assets/streetmap3.png") !important;
  background-size: cover;
}

.lx-storage-background {
  background-image: url("../../assets/apothekenlager2.png") !important;
  background-size: cover;
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}


</style>
