// The polling function
function Poll(Vue, DepartmentId) {
  const vue = Vue || {}
  const departmentId = DepartmentId || ''
  const maxWaitSteps = 80
  // lockcounter holds the
  let lockCounter = 0
  let idleCounter = 0
  let blocked = false
  let waitCounter = 0
  let state = null
  let autorefresh = true
  // Cart is outdated and user must be manuelly refresh
  let requireRefresh = false
  /*
    isUserReady === false 'gray'
    (autorefresh && waitCounter < 5) ? 'green' : 'orange'

   */
  let timer = setInterval(() => {
    // Check if *user* authenticated and session not expired
    const isUserReady = !requireRefresh && vue.authStore.getters.isLoggedIn && !vue.authStore.getters.showLoginPopup

    if (!isUserReady || lockCounter > 0 || blocked) {
      if (!isUserReady) {
        requireRefresh = true
        vue.outdatedHint = requireRefresh
        vue.updateState(0)
      }

      return
    }

    const idleStatus = vue.idleStatus

    if (idleStatus !== 'active') {
      idleCounter = Math.min(++idleCounter, maxWaitSteps)
    } else {
      idleCounter = 0
      waitCounter = 0
    }

    if (waitCounter > 0) {
      waitCounter--
      return
    }

    blocked = true
    waitCounter = Math.floor(5 * (idleCounter / 20))

    vue.$http.get('/shoppingcart/revision/' + departmentId.toString())
      .then(result => {
        const data = result.data || {}
        state = state || null

        if (!state) {
          state = data
        } else {
          // Check if outdated
          let isOutdated = (state.count !== data.count || state.timestamp !== data.timestamp)
          vue.updateState((waitCounter < 5) ? 1 : 2)

          if (isOutdated) {
            if (autorefresh && waitCounter < 5) {
              vue.getShoppingCartData()

              waitCounter = 0
              idleCounter = 0
            } else {
              if (waitCounter >= 5) {
                requireRefresh = true
                vue.outdatedHint = requireRefresh
              } else {
                waitCounter = 0
                idleCounter = 0
              }
            }
          }
        }
      })
      .finally(() => {
        blocked = false
      })
  }, 5000);

  return {
    Lock() {
      lockCounter++
    },
    Unlock() {
      lockCounter = Math.max(--lockCounter, 0)

      if (lockCounter === 0) {
        requireRefresh = false
        vue.outdatedHint = requireRefresh
        vue.updateState(1)
      }
    },
    Stop() {
      if (timer) {
        clearInterval(timer)
        timer = null
      }
    },
    UpdateRevision(data) {
      requireRefresh = false

      if (data && data.count && data.timestamp) {
        state = data
      } else {
        state = null
      }
    },
    SetAutoRefresh(state) {
      autorefresh = state === true
    }
  }
}

const colors = [
  'blue',
  'green',
  'orange',
  'red'
]

export default {
  data () {
    return {
      pollInstance: null,
      pollState: 'blue',
      pollStateNumber: 0,
      outdatedHint: false,
    }
  },
  mounted () {
    this.pollInstance = new Poll(this, this.departmentid)
  },
  destroyed () {
    this.pollInstance.Stop();
  },
  methods: {
    Lock() {
      this.pollInstance.Lock()
    },
    Unlock() {
      this.pollInstance.Unlock()
    },
    SetAutoRefresh(enabled) {
      this.pollInstance.SetAutoRefresh(enabled)
    },
    UpdateRevision(revdata) {
      this.pollInstance.UpdateRevision(revdata)
    },
    updateState(value) {
      if (value !== this.pollStateNumber) {
        this.pollStateNumber = isNaN(value) ? 0 : Number(value) % 4
        this.pollState = colors[this.pollStateNumber]
      }
    }
  }
}