<template>
  <div class="layout-column flex" style="position: relative;">
    <div style="position: absolute;top:8px;left:8px;bottom:8px;right:8px;z-index:2" v-if="enabled" class="layout-column">
      <slot name="obfuscation-text"></slot>
    </div>

    <div :class="enabled ? [this.$vnode.data.staticClass, 'ObfuscationClass'] : this.$vnode.data.staticClass">

<!--      :style="this.$vnode.data.staticStyle"-->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LxObfuscationPanel',
  props: {
    enabled: {
      type: Boolean,
      default () {
        return true
      }
    },
  }
 }
</script>

<style scoped>
  .ObfuscationClass {
    filter: blur(5px);
    /*opacity: 0.3;*/
    user-select: none;
    pointer-events: none;
    cursor: default
  }
</style>
