<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <ValidationObserver ref="ClinicalUnitDetailsForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">
      <div class="layout-column flex ma-2 elevation-5"
           style="border-radius:4px;position:relative;"
           v-bind:style="{background: compColor.contentFrame}">

        <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1" :class="hoverHeader">
          <div class="layout-row">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="back()"
                    icon="mdi-chevron-left"
                    :hide-label="true"
                    :tooltip="$t('buttons.back.tooltip')"
                    :label="$t('buttons.back.title')"></lx-btn>

            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="!valid || pristine && !hasChanged"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="save(reset)"
                    icon="save"
                    :tooltip="$t('buttons.save.tooltip')"
                    :label="$t('buttons.save.title')"></lx-btn>
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="pristine && !hasChanged"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="clear(reset)"
                    icon="cancel"
                    :tooltip="$t('buttons.revert.tooltip')"
                    :label="$t('buttons.revert.title')"></lx-btn>
          </div>
          <div class="flex layout-row"></div>
        </div>

        <v-divider v-if="!hoverHeader" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

        <div ref="scrollContainer" style="overflow-y: auto;" class="flex pa-1 layout-column">
          <lx-expansion-panels :single-open="false" v-model="panel">
            <lx-expansion-panel v-if="isPharmacy">
              <lx-expansion-panel-header icon="mdi-city"
                                         main-headline="Medizinische Einrichtung"
                                         sub-headline="Einrichtungszugehörigkeit der aktuellen Station">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="Medizinische_Einrichtung"
                                         name="Medizinische_Einrichtung"
                                         rules="required" v-slot="{ errors }">
                      <v-text-field
                        v-if="departmentid"
                        :dark="compColor.isInDarkMode"
                        :label="$t('inputs.titles.medicalfacilities') + ' *'"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        v-model="currentHospital"
                        :error-messages="errors[0]"
                        outlined
                        class="mb-1"
                        disabled
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">house</v-icon>
                        </template>
                      </v-text-field>
                      <v-select
                        v-if="!departmentid && medicalUnitData"
                        :dark="compColor.isInDarkMode"
                        :label="$t('inputs.titles.medicalfacilities') + ' *'"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        prepend-inner-icon="house"
                        v-model="model.location_id"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :error-messages="errors[0]"
                        :items="medicalUnitData"
                        item-value="_id"
                        item-text="name"
                        outlined
                        class="mb-1"
                        :dense="denseFields"
                      ></v-select>
                    </validation-provider>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-google-circles-communities"
                                         main-headline="Stationsdaten"
                                         sub-headline="Allgemeine Daten zur aktuellen Station">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="Abteilungsname"
                                         name="Abteilungsname"
                                         rules="required" v-slot="{ errors }">
                      <v-text-field
                        :dark="compColor.isInDarkMode"
                        :label="$t('inputs.titles.department') + ' *'"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        v-model="model.name"
                        :error-messages="errors[0]"
                        outlined
                        class="mb-1"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">house</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="email"
                                         name="email"
                                         rules="email" v-slot="{ errors }">
                      <v-text-field
                        :dark="compColor.isInDarkMode"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :label="$t('inputs.titles.email')"
                        v-model="model.email"
                        :error-messages="errors[0]"
                        required
                        outlined
                        class="mb-1"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">email</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="phone"
                                         name="phone" v-slot="{ errors }">
                      <v-text-field
                        :dark="compColor.isInDarkMode"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :label="$t('inputs.titles.phone')"
                        v-model="model.phone"
                        :error-messages="errors[0]"
                        outlined
                        class="mb-1"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">phone</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>

                  <div class="flex-100 pa-2" v-if="isPharmacy">
                    <validation-provider tag="div" ref="costcenter"
                                         name="costcenter" v-slot="{ errors }">
                      <v-text-field
                        :dark="compColor.isInDarkMode"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :label="$t('inputs.titles.costcentre')"
                        v-model="model.costcenter"
                        :error-messages="errors[0]"
                        outlined
                        class="mb-1"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">money</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>

                  <div class="flex-100 pa-2" v-if="medicalUnitData && medicalUnitData.corporate_group">
                    <validation-provider tag="div" ref="costcenter_ext"
                                         name="costcenter_ext" v-slot="{ errors }">
                      <v-text-field
                        :dark="compColor.isInDarkMode"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :error-messages="errors[0]"
                        :dense="denseFields"
                        :prefix="medicalUnitData.corporate_group.toUpperCase()"
                        label="Kostenstelle Kunde"
                        class="mb-1"
                        v-model="model.corporate_group_ident"
                        outlined
                        clearable
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">business</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>

                  <div class="flex-100 pa-2" v-if="!isPharmacy">
                    <v-alert
                      v-cloak
                      text
                      :dark="compColor.isInDarkMode"
                      border="top"
                      icon="warning"
                      type="warning"
                      v-if="!departmentid"
                    >Die Abteilung ist nach dem anlegen inaktiv und muss erst durch
                      ihre Apotheke freigeschaltet werden.
                    </v-alert>
                    <v-alert
                      v-cloak
                      text
                      :dark="compColor.isInDarkMode"
                      border="top"
                      icon="warning"
                      type="warning"
                      v-if="departmentid && is_active !== true"
                    >Die Abteilung ist momentan inaktiv und muss durch ihre Apotheke
                      freigeschaltet werden.
                    </v-alert>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
            <lx-expansion-panel v-if="!isPharmacy">
              <lx-expansion-panel-header icon="mdi-account-group"
                                         main-headline="Mitarbeiter"
                                         sub-headline="Ordnen Sie dieser Station Mitarbeiter zu oder entfernen Sie diese ">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2">
                    <div class="layout-row">
                      <fake-input-frame label="Zugeordnete Mitarbeiter"
                                        class="flex layout-column"
                                        :input-background="isDarkMode ? 'transparent':'#f0f0f0'">
                        <div class="layout-column pa-2">

                          <div class="pb-1">

                            <v-tooltip open-delay="550">
                              <template v-slot:activator="{ on }">
                                <v-btn :color="'#353a47'"
                                       v-on="on"
                                       block
                                       :height="denseFields ? '36' : '40'"
                                       elevation="0"
                                       class="white--text text-none"
                                       @click="selectAllEntries('addedEmployees')">
                                  <template #default>
                                    <div class="layout-row py-4" style="width:100%;">
                                      <div class="layout-column layout-align-start-center px-1">
                                        <v-icon
                                          :color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor">
                                          {{
                                            addedEmployeesAreSelected ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked-outline'
                                          }}
                                        </v-icon>
                                      </div>
                                      <div class="flex layout-row layout-align-start-center pl-5">
                                        {{ addedEmployeesAreSelected ? 'Alle abwählen' : 'Alle auswählen' }}
                                      </div>
                                    </div>
                                  </template>
                                </v-btn>
                              </template>
                              <span>Mitarbeiter zum entfernen aus der Station auswählen</span>
                            </v-tooltip>

                          </div>

                          <v-divider class="mb-2 mt-1"></v-divider>

                          <div v-for="(items, index) in addedEmployees"
                               :key="index"
                               class="px-2 py-1"
                               :class="index < addedEmployees.length - 1 ? 'mb-1' : ''"
                               :style="{'background': items.selected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
                               style="border-radius: 4px;">
                            <div class="layout-row">
                              <div class="pa-2 layout-column layout-align-center-center">
                                <v-simple-checkbox :ripple="false"
                                                   :dark="isDarkMode"
                                                   :color="compColor.prime"
                                                   v-model="items.selected"></v-simple-checkbox>
                              </div>
                              <v-divider vertical class="mr-2"></v-divider>
                              <div class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start">
                                <span
                                  style="line-height: 16px;"><strong>{{
                                    items.last_name
                                  }}</strong>, {{ items.first_name }}</span>
                                <small style="opacity: 0.6;line-height: 15px;">{{ items.email }}</small>
                              </div>
                              <div class="layout-column layout-align-center-center mx-1 ml-2 mr-2" v-if="items.unsaved">
                                <div class="py-1 px-1 font-weight-bold amber"
                                     style="font-size:9px;line-height: 14px;border-radius:3px;">
                                  unsaved
                                </div>
                              </div>
                              <div class="layout-column layout-align-center-center ml-2">
                                <v-icon
                                  :color="items.selected ? compColor.prime + ' lighten-3': (isDarkMode ? 'blue-grey darken-3' : 'blue-grey lighten-3')"
                                  size="30">mdi-account-circle
                                </v-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fake-input-frame>
                      <div class="pa-5 layout-column layout-align-center-center">
                        <lx-btn :color="'#353a47'"
                                :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                                small
                                :disabled="!hasSelectedToAdd"
                                iconSize="20"
                                width="auto"
                                classes="text-none my-1"
                                @click="addEmployeeToClinialUnit"
                                icon="mdi-arrow-left-bold"
                                :hide-label="true"
                                tooltip="Mitarbeiter in Station übernehmen"></lx-btn>
                        <lx-btn :color="'#353a47'"
                                :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                                small
                                :disabled="!hasSelectedToRemove"
                                iconSize="20"
                                width="auto"
                                classes="text-none my-1"
                                @click="removeEmployeeFromClinialUnit"
                                icon="mdi-arrow-right-bold"
                                :hide-label="true"
                                tooltip="Mitarbeiter aus Station entfernen"></lx-btn>
                      </div>
                      <fake-input-frame label="Mögliche Krankenhausmitarbeiter"
                                        class="flex layout-column"
                                        :input-background="isDarkMode ? 'transparent':'#f5f5f5'">
                        <div class="layout-column pa-2">

                          <div class="pb-1">
                            <v-tooltip bottom open-delay="550">
                              <template v-slot:activator="{ on }">
                                <v-btn :color="'#353a47'"
                                       v-on="on"
                                       block
                                       :height="denseFields ? '36' : '40'"
                                       elevation="0"
                                       class="white--text text-none"
                                       @click="selectAllEntries('allEmployees')">
                                  <template #default>
                                    <div class="layout-row py-4" style="width:100%;">
                                      <div class="layout-column layout-align-start-center px-1">
                                        <v-icon
                                          :color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor">
                                          {{
                                            allEmployeesAreSelected ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked-outline'
                                          }}
                                        </v-icon>
                                      </div>
                                      <div class="flex layout-row layout-align-start-center pl-5">
                                        {{ allEmployeesAreSelected ? 'Alle abwählen' : 'Alle auswählen' }}
                                      </div>
                                    </div>
                                  </template>
                                </v-btn>
                              </template>
                              <span>Mitarbeiter zum hinfügen für die Station auswählen</span>
                            </v-tooltip>

                          </div>

                          <v-divider class="mb-2 mt-1"></v-divider>

                          <div v-for="(items, index) in allEmployees"
                               :key="index"
                               class="px-2 py-1"
                               :class="index < allEmployees.length - 1 ? 'mb-1' : ''"
                               :style="{'background': items.selected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
                               style="border-radius: 4px;">
                            <div class="layout-row">
                              <div class="pa-2 layout-column layout-align-center-center">
                                <v-simple-checkbox :ripple="false"
                                                   :dark="isDarkMode"
                                                   :color="compColor.prime"
                                                   v-model="items.selected"></v-simple-checkbox>
                              </div>
                              <v-divider vertical class="mr-2"></v-divider>
                              <div class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start">
                                <span
                                  style="line-height: 16px;"><strong>{{
                                    items.last_name
                                  }}</strong>, {{ items.first_name }}</span>
                                <small style="opacity: 0.6;line-height: 15px;">{{ items.email }}</small>
                              </div>
                              <div class="layout-column layout-align-center-center mx-1 ml-2 mr-2" v-if="items.unsaved">
                                <div class="py-1 px-1 font-weight-bold amber"
                                     style="font-size:9px;line-height: 14px;border-radius:3px;">
                                  unsaved
                                </div>
                              </div>
                              <div class="layout-column layout-align-center-center ml-2">
                                <v-icon
                                  :color="items.selected ? compColor.prime + ' lighten-3': (isDarkMode ? 'blue-grey darken-3' : 'blue-grey lighten-3')"
                                  size="30">mdi-account-circle
                                </v-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fake-input-frame>
                    </div>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
            <lx-expansion-panel v-if="!isPharmacy">
              <lx-expansion-panel-header icon="mdi-warehouse"
                                         main-headline="Lagerorte"
                                         sub-headline="Zur besseren Strukturierung der Artikel können sie Lagerorte anlegen">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2" v-if="!departmentid">
                  <div class="flex-100 pa-2">
                    <v-alert
                      border="left"
                      prominent
                      style="font-size:15px;"
                      class="pa-3"
                      v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
                      <template slot="prepend">
                        <v-avatar>
                          <v-icon large>mdi-information-variant</v-icon>
                        </v-avatar>
                      </template>
                      Bitte speichern Sie zu erst Ihre neu angelegte Station, bevor Sie einen Lagerort anlegen.
                    </v-alert>
                  </div>
                </div>
                <div class="layout-row layout-wrap layout-align-start-center mt-3 pa-2" v-if="departmentid">
                  <div class="flex pa-2">
                    <v-text-field
                      label="Neuen Lagerort anlegen"
                      :color="compColor.primeColor"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      outlined
                      class="mb-0"
                      hide-details
                      :dense="denseFields"
                      :dark="compColor.isInDarkMode"
                      v-model="storageLocation.name"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">mdi-map-marker</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                  <div class="flex pa-2">
                    <v-text-field
                      label="neue Bemerkung"
                      :color="compColor.primeColor"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      outlined
                      class="mb-0"
                      hide-details
                      :dense="denseFields"
                      :dark="compColor.isInDarkMode"
                      v-model="storageLocation.comment"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">mdi-message-bulleted</v-icon>
                      </template>
                    </v-text-field>

                  </div>
                  <lx-btn :color="'#353a47'"
                          :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                          small
                          :disabled="!storageLocation.name || storageLocation.name.length <= 0"
                          iconSize="20"
                          width="auto"
                          classes="text-none mr-1"
                          @click="addStorageLocation"
                          icon="add_circle"
                          tooltip="Neuen Lagerort anlegen"
                          :label="'Anlegen'"></lx-btn>

                </div>
                <div class="layout-row layout-wrap layout-align-start-center pa-2"
                     v-if="departmentid && storage_locations.length > 0">
                  <div class="flex pa-2">
                    <fake-input-frame label="Liste der Stationslagerorte"
                                      :input-background="isDarkMode ? 'transparent':'#f0f0f0'">
                      <div class="layout-column pa-2">
                        <div v-for="(items, index) in storage_locations"
                             :key="index"
                             class="px-2"
                             :class="index < storage_locations.length - 1 ? 'mb-1' : ''"
                             :style="{'background': items.selected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
                             style="border-radius: 4px;">
                          <div class="layout-row">
                            <div class="layout-column layout-align-center-center mr-2">
                              <v-icon
                                :color="items.selected ? compColor.prime + ' lighten-3': (isDarkMode ? 'blue-grey darken-3' : 'blue-grey lighten-3')"
                                size="30">mdi-map-marker
                              </v-icon>
                            </div>
                            <v-divider vertical class="mr-2"></v-divider>
                            <div class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start">
                              <span style="line-height: 18px;"><strong>{{ items.name }}</strong><small class="ml-3"
                                                                                                       style="opacity: 0.6;line-height: 15px;">( erstellt: {{
                                  items.creation_date | formatDate
                                }} )</small></span>
                              <span style="opacity: 0.6;line-height: 18px;">{{ items.comment }}</span>

                            </div>
                            <div class="pa-2 layout-row layout-align-center-center">

                              <lx-btn :color="'#353a47'"
                                      :icon-color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                                      small
                                      :disabled="!items._id"
                                      as-icon
                                      as-text
                                      iconSize="20"
                                      width="auto"
                                      classes="text-none mr-1"
                                      @click="editStorageLocation(items)"
                                      icon="edit"
                                      :hide-label="true"
                                      tooltip="Lagerort bearbeiten"></lx-btn>

                              <lx-btn :color="'#353a47'"
                                      :icon-color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                                      small
                                      :disabled="!items._id"
                                      as-icon
                                      as-text
                                      iconSize="20"
                                      width="auto"
                                      classes="text-none mr-1"
                                      @click="deleteStorage(items._id)"
                                      icon="mdi-delete-forever"
                                      :hide-label="true"
                                      tooltip="Lagerort löschen"></lx-btn>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fake-input-frame>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
          </lx-expansion-panels>
        </div>
      </div>

    </ValidationObserver>

    <lx-dialog v-model="editStorage" :allow-drag="true" :overlay="true" :size="{x: '60%', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          Lagerort bearbeiten
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <div class="flex layout-column pa-1">
            <ValidationObserver tag="div" ref="editStoragePopupForm" class="layout-column flex">

              <div>
                <fake-input-frame label="Allgemeine Informationen"
                                  :input-background="isDarkMode ? 'transparent':'#f5f5f5'">
                  <div class="layout-column px-2 pb-1">
                    <!--                  <div class="layout-row layout-align-start-center">-->
                    <span class="blue-grey--text text--darken-2">
                      <small style="min-width:170px;display: inline-block;">Lagerort erstellt am:</small>
                      <strong>{{ storagePopupModel.creation_date | formatDate }}</strong>
                    </span>
                    <!--                  </div>-->
                    <!--                  <div class="layout-row layout-align-start-center">-->
                    <span class="blue-grey--text text--darken-2">
                      <small style="min-width:170px;display: inline-block;">Anzahl zugeordneter Artikel:</small>
                      <strong>{{ storagePopupModel.articles?.length }}</strong>
                    </span>
                    <!--                  </div>-->
                  </div>
                </fake-input-frame>
              </div>
              <v-divider class="my-5"></v-divider>
              <div>
                <validation-provider tag="div" ref="Name"
                                     name="Name"
                                     rules="required" v-slot="{ errors }">
                  <v-text-field
                    :color="compColor.primeColor"
                    :error-messages="errors[0]"
                    label="Name *"
                    :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                    class="mb-1"
                    v-model="storagePopupModel.name"
                    required
                    :dark="compColor.isInDarkMode"
                    outlined
                  >
                    <template #append v-if="!isDarkMode">
                      <v-icon color="grey lighten-2">mdi-map-marker</v-icon>
                    </template>
                  </v-text-field>
                </validation-provider>
              </div>
              <div>
                <validation-provider tag="div" ref="Bemerkung"
                                     name="Bemerkung"
                                     v-slot="{ errors }">
                  <v-text-field
                    :color="compColor.primeColor"
                    :error-messages="errors[0]"
                    label="Bemerkung"
                    :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                    class="mb-1"
                    v-model="storagePopupModel.comment"
                    required
                    height="50"
                    :dark="compColor.isInDarkMode"
                    outlined
                  >
                    <template #append v-if="!isDarkMode">
                      <v-icon color="grey lighten-2">mdi-message-bulleted</v-icon>
                    </template>
                  </v-text-field>
                </validation-provider>
              </div>
            </ValidationObserver>

          </div>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">

          <!-- CREATE INVOICE BUTTON -->
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  :disabled="!showSaveBtn"
                  classes="text-none mr-1 flex"
                  @click.stop="processButtonResult('yes')"
                  icon="save"
                  tooltip="Änderungen speichern"
                  :label="'Änderungen speichern'"></lx-btn>

          <!-- CANCEL BUTTON -->
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click.stop="processButtonResult('no')"
                  icon="cancel"
                  tooltip="Vorgang abbrechen und Fenster schließen"
                  :label="'Abbrechen'"></lx-btn>
        </div>
      </template>
    </lx-dialog>

  </div>
</template>
<script>
import lodash from 'lodash'
import router from '../../../router'

const StringCompare = function (a, b) {
  const nameA = a.last_name.toUpperCase(); // Groß-/Kleinschreibung ignorieren
  const nameB = b.last_name.toUpperCase(); // Groß-/Kleinschreibung ignorieren
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export default {
  name: 'ClinicalUnitDetails',
  props: {
    departmentid: {
      type: [String, null],
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      storagePopupModel: null,
      editStorage: false,
      oldStorageModel: {},
      hoverHeader: false,
      is_active: false,
      unsavedDataDialog: false,
      unsavedDataDialogNext: null,
      panel: [0, 1, 2, 3],
      search: null,
      e1: true,
      loading: 0,
      model: {
        users: []
      },
      oldModel: null,
      medicalUnitData: null,
      breadcrumbs: null,
      tabAction: null,
      error: {},
      errorDialog: false,
      isDirty: false,
      isPharmacy: (this.getUserLocationType() === "pharmacy"),
      states: [],
      selected: [],
      selectedToRemove: [],
      selectedToAdd: [],
      headers: [
        { text: 'Nachname', value: 'last_name', width: 120 },
        { text: 'Vorname', value: 'first_name', width: 120 },
        { text: 'E-Mail', value: 'email' },
        { text: 'ungesichert', value: 'unsaved' }
      ],
      allEmployees: [],
      oldAllEmployees: [],
      addedEmployees: [],
      headersLocations: [
        { text: '', value: '_id', width: 50 },
        { text: 'Lagerort', value: 'name' },
        { text: 'Bemerkung', value: 'comment' },
        { text: 'angelegt am', value: 'creation_date' }
      ],
      storageLocation: {},
      storage_locations: [],
      hasChanged: false
    }
  },
  // beforeRouteLeave (to, from, next) {
  //     if(this.hasChanged){
  //         this.unsavedDataDialog = true
  //         this.unsavedDataDialogNext = next
  //     } else {
  //         next()
  //     }
  // },
  computed: {
    addedEmployeesAreSelected() {
      let selected = this.addedEmployees.filter(item => {
        if (item.selected) return item
      })
      return selected.length === this.addedEmployees.length
    },
    allEmployeesAreSelected() {
      let selected = this.allEmployees.filter(item => {
        if (item.selected) return item
      })
      return selected.length === this.allEmployees.length
    },
    hasSelectedToAdd() {
      let selected = this.allEmployees.filter(item => {
        if (item.selected) return item
      })
      return selected.length > 0
    },
    hasSelectedToRemove() {
      let selected = this.addedEmployees.filter(item => {
        if (item.selected) return item
      })
      return selected.length > 0
    },
    currentHospital() {
      if (this.medicalUnitData && this.model) {
        const nn = this.medicalUnitData.filter((item) => item._id === this.model.location_id)
        return (nn.length > 0) ? nn[0].name : false
      } else {
        return false
      }
    },
    showSaveBtn() {
      if (lodash.isEqual(this.oldStorageModel, this.storagePopupModel)) {
        return false
      }
      let test = this.$refs['editStoragePopupForm']
      if (test) {
        return !test.flags.pristine && test.flags.valid
      }

      return true
    }
  },
  created() {
    if (this.isPharmacy) {
      const filter = { config: { opts: { sort: { name: 1 } } } }

      this.$http.get('/hospital', filter)
        .then(res => {
          this.medicalUnitData = (res.data || {}).data
        });
    } else {
      const params = {
        config: {
          opts: {
            fields: {
              name: 1,
              corporate_group: 1,
            }
          }
        }
      }
      this.$http.get('/mycompany', params)
        .then(res => {
          this.medicalUnitData = res.data || {}
        });
    }

    this.getEmployeesByLocation()

    this.$nextTick(() => {
      let scroller = this.$refs.scrollContainer
      scroller.addEventListener('scroll', () => {
        if (scroller.scrollTop > 0) {
          let number = scroller.scrollTop
          number = number > 4 ? 4 : number
          this.hoverHeader = 'elevation-' + number
        } else {
          this.hoverHeader = ''
        }
      })
    })
  },
  methods: {
    selectAllEntries(list) {
      let booleanValue = this[list + 'AreSelected']
      this[list].forEach(item => {
        this.$set(item, 'selected', !booleanValue)
      })
    },
    getEmployeesByLocation() {
      this.$http.get('/users/location')
        .then(res => {
          let tempData = res.data || []

          tempData.sort(StringCompare)

          this.allEmployees = tempData
          this.oldAllEmployees = lodash.cloneDeep(this.allEmployees)
        }).finally(() => {
        if (this.departmentid != null) {
          this.getData()
        } else {
          this.oldModel = lodash.cloneDeep(this.model)
        }
      })
    },
    leaveAnyway() {
      this.unsavedDataDialogNext()
    },
    openCloseExpansionPanel(index) {
      if (this.panel.indexOf(index) < 0) {
        this.panel.push(index)
      } else {
        this.panel.splice(this.panel.indexOf(index), 1)
      }
    },
    addStorageLocation() {
      this.storageLocation.department_id = this.departmentid

      this.$http.post('/storage', this.storageLocation)
        .then(this.NotifySuccess)
        .catch(this.NotifyError)
        .finally(() => {
          this.storageLocation = {}
          this.loading++

          this.getStorageLocation(() => {
            this.loading--
          });
        })
    },
    getStorageLocation(callback) {
      this.$http.get('/storages/' + this.departmentid)
        .then((res) => {
          this.storage_locations = res.data || []
        })
        .catch(this.NotifyError)
        .finally(callback)
    },

    addEmployeeToClinialUnit() {
      this.allEmployees = this.allEmployees.filter((item) => {
        // if (ids.indexOf(item["_id"]) === -1) return item;
        if (!item.selected) {
          return item
        }
        item.selected = false
        item.unsaved = true
        this.addedEmployees.push(item)
        return null;
      });

      this.addedEmployees.sort(StringCompare);

      this.model.users = [];
      this.addedEmployees.forEach((item) => {
        if (item._id) {
          this.model.users.push(item._id)
        }
      });

      if (!lodash.isEqual(this.model, this.oldModel)) {
        this.hasChanged = true
      }

    },
    removeEmployeeFromClinialUnit() {
      this.addedEmployees = this.addedEmployees.filter((item) => {
        // if (ids.indexOf(item["_id"]) === -1) return item;
        if (!item.selected) {
          return item
        }
        item.selected = false
        item.unsaved = true
        this.allEmployees.push(item)
        return null
      });

      this.allEmployees.sort(StringCompare);

      this.model.users = [];
      this.addedEmployees.forEach((item) => {
        if (item._id) {
          if (!this.model.users) {
            this.model.users = []
          }
          this.model.users.push(item._id)
        }
      });

      if (!lodash.isEqual(this.model, this.oldModel)) {
        this.hasChanged = true
      }
    },
    /**
     * wird aufgerufen, wenn man den zurück Button drückt
     *
     * */
    back() {
      window.history.back()
    },

    /**
     * Get all users, all rights, all rights of selected role and all users with selected role
     */
    getData() {
      if (this.loading) {
        return
      }

      this.loading++

      if (!this.isPharmacy) {
        this.loading++
      }

      this.$http.get('/department/' + this.departmentid)
        .then(res => {
          if (res.data) {
            this.model = res.data || {}

            if (this.model.is_active) {
              this.is_active = true
            }

            // Remove; not needed
            delete this.model.storage_locations
            delete this.model.order_comment

            if (this.model.users && this.model.users.length > 0) {
              let addedUsers = [];
              this.model.users.forEach((user) => {
                this.allEmployees.forEach((empl, index) => {
                  if (user === empl._id) {
                    addedUsers.push(empl)
                    this.allEmployees.splice(index, 1)
                  }
                })
              });

              addedUsers.sort(StringCompare)

              this.addedEmployees = addedUsers
            }
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.oldModel = lodash.cloneDeep(this.model);

          this.loading--
        });

      if (!this.isPharmacy) {
        this.getStorageLocation(
          () => {
            this.loading--
          }
        )
      }
    },
    save(reset) {
      reset()

      if (this.departmentid != null) {
        // let isActive = lodash.clone(this.model.is_active);
        delete this.model._id
        delete this.model.is_active

        this.model.email = this.model.email || null

        this.$http.put('/department/' + this.departmentid, this.model)
          .then(() => {
            this.NotifySystem('Änderungen gespeichert');
            this.oldModel = lodash.cloneDeep(this.model)
            this.getEmployeesByLocation()
          })
          .catch((error) => {
            this.HandleFormError(this.$refs.ClinicalUnitDetailsForm, error, 'validator.clinical_unit.')
              .then(this.NotifyError("Daten konnten nicht gespeichert werden"))
              .catch(this.NotifyError)
          })
          .finally(() => {
            this.hasChanged = false
          })
      } else {
        this.$http.post('/department', this.model)
          .then((res) => {
            this.NotifySystem('Änderungen gespeichert')
            this.oldModel = lodash.cloneDeep(this.model);
            if (res.data && res.data._id && this.$refs.ClinicalUnitDetailsForm) {
              this.$refs.ClinicalUnitDetailsForm.reset()
              router.replace('/administration/clinical_units/' + res.data._id + '/details')
            }
          })
          .catch((error) => {
            this.HandleFormError(this.$refs.ClinicalUnitDetailsForm, error, 'validator.clinical_unit.')
              .then(this.NotifyError("Daten konnten nicht gespeichert werden"))
              .catch(this.NotifyError)
          })
          .finally(() => {
            this.oldModel = lodash.cloneDeep(this.model)
            this.hasChanged = false
            this.getEmployeesByLocation()
          })
      }
    },
    clear(reset) {
      reset();

      this.model = lodash.cloneDeep(this.oldModel)
      this.hasChanged = false
      this.addedEmployees = []

      if (this.model.users) {
        this.allEmployees = this.oldAllEmployees.filter((item) => {
          if (this.model.users.indexOf(item["_id"]) === -1) return item
          this.addedEmployees.push(item)
          return null;
        })
      } else {
        this.allEmployees = lodash.cloneDeep(this.oldAllEmployees)
      }
    },

    deleteStorage(storageid) {
      this.DialogConfirm(
        'Löschen des Lagerortes',
        'Sind Sie sicher, dass Sie den ausgewählten Lagerort löschen wollen? Eine Wiederherstellung ist nicht möglich.',
        { ok: 'Löschen', cancel: this.$t('buttons.cancel.title') })
        .then(() => {
          this.$http.delete('/storage/' + this.departmentid + '/' + storageid)
            .then((res) => {
              this.NotifySuccess(res)
              this.getStorageLocation()
            })
            .catch(this.NotifyError)
        })
        .catch(() => {
        })
    },
    processButtonResult(btnResult) {
      if (btnResult === 'no') {
        this.editStorage = false
        this.storagePopupModel = lodash.cloneDeep(this.oldStorageModel)
      } else {
        this.saveStorage()
      }
    },
    editStorageLocation(item) {
      this.editStorage = true
      this.storagePopupModel = lodash.cloneDeep(item)
      this.oldStorageModel = lodash.cloneDeep(this.storagePopupModel)
      // router.push({path: `/administration/clinical_units/${this.departmentid.toString()}/storage/${id.toString()}`});
    },
    saveStorage() {
      const data = {
        storage_id: this.storagePopupModel._id,
        department_id: this.storagePopupModel.department_id,
        name: this.storagePopupModel.name,
        comment: this.storagePopupModel.comment
      };

      this.$http.patch('/storage', data)
        .then(() => {
          this.NotifySystem('Änderungen gespeichert')
          this.editStorage = false
          this.getStorageLocation()
        })
        .catch(this.NotifyError)
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


.dark_login {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  padding: 50px;
  min-width: 500px;
  min-height: 700px;
  background-image: linear-gradient(#3b4b65, #212c3e);
}

h1, h2 {
  font-weight: normal;
}

.subheader {
  height: 20px;
  font-size: 16px;
  padding: 0 16px 0 2px;
  font-weight: 300;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.tabs__div {
  text-transform: none;
  color: #575757;
}
</style>
