import {LxQueryHelper} from './LxQueryBuilder'
import LxQueryTooltip from './LxQueryTooltip'

let verbosity = false

export default {
    install: function (Vue, options) {
        options = options || {}
        let propertyKey = options.propertyKey || '$helper'

        verbosity = options.verbosity || verbosity

        Vue.component('lx-query-tooltip', LxQueryTooltip)

        Object.defineProperty(Vue.prototype, propertyKey, { value: LxQueryHelper })
    }
}
