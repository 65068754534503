import Vuex from 'vuex'
import IdleVue from 'idle-vue'
import auth_Store from './store/auth'
import LoginComponent from './views/LoginComponent'
import ForgotPasswordComponent from './views/ForgotPasswordComponent'
import ReLoginComponent from './components/ReLoginComponent'
import RegisterFromTokenComponent from './views/RegisterFromTokenComponent'
import NewPasswordComponent from './views/NewPasswordComponent'
import TwofactorEdit from './views/TwofactorEditComponent'
import TwofactorLogin from './views/TwofactorLoginComponent'
import AutoLoginComponent from './views/AutoLoginComponent'
import AutoLogoutComponent from './views/AutoLogoutComponent'

import router from '../../router'
import AuthContainer from './views/AuthContainer'
import axios from 'axios'

export default {
    install(Vue) {
        Vue.use(Vuex);

        let Store = new Vuex.Store({
            modules: {
                auth_Store
            }
        });

        Vue.component('auth-relogin', ReLoginComponent);

        function isRouteAllowed(rightList) {
            let arr1 = rightList;
            let arr2 = Store.getters.getAuthUserPolicies;

            let found = false;

            for (let i = 0; i < arr1.length; i++) {
                for (let j = 0; j < arr2.length; j++) {
                    if (arr1[i].act === arr2[j].act && arr1[i].obj === arr2[j].obj) {
                        found = true;
                        break;
                    }
                }
            }
            return found
        }

        router.addRoute(
            {
                path: '/auth',
                name: 'Auth',
                component: AuthContainer,
                children: [
                    {
                        path: 'login/shs/:token?',
                        name: 'AutoLogin',
                        component: AutoLoginComponent,
                        props: true,
                        // meta: {
                        //     component: 'Login'
                        // },
                    },
                    {
                        path: 'autologout',
                        name: 'AutoLogout',
                        component: AutoLogoutComponent,
                    },
                    {
                        path: 'login',
                        name: 'Login',
                        component: LoginComponent,
                        // meta: {
                        //     component: 'Login'
                        // },
                    },
                    {
                        path: 'twofactor',
                        name: 'TwoFactorLogin',
                        component: TwofactorLogin,
                        // meta: {
                        //     component: 'Login'
                        // },
                    },
                    {
                        path: 'newPassword/:token?',
                        name: 'NewPassword',
                        component: NewPasswordComponent,
                        props: true,
                        meta: {
                            requiresAuth: false
                        }
                    },
                    {
                        path: 'register/:token?',
                        name: 'Register',
                        component: RegisterFromTokenComponent,
                        props: true,
                        meta: {
                            requiresAuth: false
                        }
                    },
                    {
                        path: 'forgot',
                        name: 'ForgotPassword',
                        component: ForgotPasswordComponent,
                        // meta: {
                        //     component: 'Login'
                        // }
                    }
                ]
            });
        router.addRoute(
            {
                path: '/twofactor/:onOff?',
                name: 'TwoFactorEdit',
                component: TwofactorEdit,
                props: true,
                meta: {
                    requiresAuth: true
                }
            },
        );

        router.beforeEach((to, from, next) => {
            if (to.matched.some(record => record.meta.requiresAuth)) {
                // Load user from session storage

                const user = JSON.parse(window.sessionStorage.getItem(process.env.VUE_APP_BASE_NAME + '-frontend'));
                // get loginstate for user to logout that user if the sessions keys are not present
                const isLoggedIn = Store.getters.isLoggedIn;

                // Check user auth
                if (!user) {
                    if (isLoggedIn) {
                        Store.dispatch('logout')
                    }
                    next({path: '/auth/login'})
                } else {
                    // wenn nicht Systemadmin und rechteliste vorhanden für notwendige rechtezum anspringen der Route, dann hier rein sonst else next()
                    if (!Store.getters.userIsSystemadmin && to.meta && to.meta.rightsList && to.meta.rightsList.length > 0) {
                        //prüfen ob man route anspringen kann wenn nicht wird man zu seiner account seite geleitet
                        if (isRouteAllowed(to.meta.rightsList)) {
                            next()
                        } else {
                            next({path: '/account'})
                        }
                    } else {
                        if (Store.getters.userIsSystemadmin && to.meta.redirectRoot && to.meta.redirectRoot.to && to.meta.redirectRoot.from === to.path) {
                            next({path: to.meta.redirectRoot.to})
                        } else {
                            next()
                        }
                    }
                }
            } else {
                next()
            }
        });

        const eventsHub = new Vue();

        Vue.use(IdleVue, {
            eventEmitter: eventsHub,
            idleTime: 5000,
            startAtIdle: true
        });

        new Vue({
            onIdle() {
                Store.dispatch('setIdle')
            },
            onActive() {
                Store.dispatch('setActive')
            },
            created() {
                Store.dispatch('initAuth')
            }
        });

        Vue.mixin({
            created() {
            },
            computed: {
                authStore() {
                    return Store
                },
                authRouter() {
                    return router
                },
                idleStatus() {
                    return Store.getters.idleStatus
                },
                repeatCounterTime() {
                    return Store.getters.repeatCounterTime
                },
                authSystem(){
                    // auth_system_name: "median"
                    return Store.getters.getAuthUserData && Store.getters.getAuthUserData.auth_system_name ? Store.getters.getAuthUserData.auth_system_name : ''
                }
            },
            methods: {
                logout(reload) {
                    Store.dispatch('logout', reload)
                },
                showHTMLByRole(roles) {
                    // TODO Change
                    let authUser = Store.getters.getAuthUserData;

                    if (!roles || !authUser) {
                        return true
                    }

                    const rolesDomains = Object.keys(authUser.acl.domains_roles_acl[authUser.user.location_id] || {})
                    const filteredArray = rolesDomains.filter(value => roles.includes(value));

                    return (filteredArray.length > 0)

                },
                showHTMLByRight(right) {
                    let authUser = Store.getters.getAuthUserData;

                    if (!right || !authUser) {
                        return true
                    }

                    if (typeof right.is_system === "boolean") {
                        if (typeof authUser.user.is_system === "boolean") {
                            return (right.is_system && authUser.user.is_system)
                        } else {
                            if (right.is_system) {
                                return false
                            }
                        }
                    }

                    const perms = authUser.routes.filter(item => (item.method === right.act)); // acl.perm
                    let found = false;
                    const reCheck = new RegExp(right.obj, 'i')

                    for (let i = 0; i < perms.length; i++) {
                        // if (perms[i].method === right.act && perms[i].route === right.obj) {
                        if (reCheck.test(perms[i].route)) {
                            found = true;
                            break;
                        }
                    }

                    return found;
                },
                getUser() {
                    if (Store.getters.isLoggedIn === true) {
                        return Object.assign({}, Store.getters.getAuthUserData.user);
                    }

                    return {};
                },
                hasRole(value) {
                    if (Store.getters.isLoggedIn === true) {
                        const locationId = Store.getters.getCurrentLocationId
                        const domains = Store.getters.getAuthUserData?.acl.domains_roles_acl || {}

                        const roles = Object.keys(domains[locationId] || {})

                        if (typeof value === 'string') {
                            return roles.indexOf(value) !== -1
                        }
                    }

                    return false
                },
                getRoles() {
                    if (Store.getters.isLoggedIn === true) {
                        const locationId = Store.getters.getCurrentLocationId
                        const domains = Store.getters.getAuthUserData?.acl.domains_roles_acl || {}

                        return Object.keys(domains[locationId] || {})
                    }

                    return []
                },
                getUserAcl() {
                    if (Store.getters.isLoggedIn === true) {
                        return Object.assign({}, Store.getters.getAuthUserData);
                    }

                    return {};
                },
                getUserLocationType() {
                    if (Store.getters.isLoggedIn === true) {
                        return Store.getters.getAuthUserData.user.location_type;
                    }

                    return "";
                },
                getUserLocationName() {
                    if (Store.getters.isLoggedIn === true) {
                        return Store.getters.getAuthUserData.user.location_name;
                    }

                    return "";
                },
                // TODO NEW SETLOCATION
                setLocation(id) {
                    if (Store.getters.isLoggedIn === true) {
                        axios.defaults.headers.common['Auth-Domain'] = id

                        return new Promise((resolve, reject) => {
                            this.$http.get('/account/user')
                                .then(res => {
                                    Store.dispatch('updateLocation', res.data)
                                        .then(resolve)
                                        .catch(reject)

                                })
                                .catch(reject)
                        })
                    }
                }
            }
        })
    },

}
