<template>
  <div class="pa-0">
    <v-list two-line class="pa-0 lx-dash-list" color="transparent">
      <v-list-item class="pa-0 ma-2 elevation-2" v-bind:class="[compColor.contentFrameBack]"
                   v-if="!this.showHTMLByRight({act: 'GET', obj: '/orders'})"
                   style="border-radius:6px;">
        <v-list-item-content v-bind:class="[compColor.widget.text]" class="pa-0">
          <v-alert
            border="left"
            prominent
            style="font-size:15px;"
            class="pa-3 ma-0"
            v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
            <template slot="prepend">
              <v-avatar>
                <v-icon large>mdi-information-variant</v-icon>
              </v-avatar>
            </template>
            <div class="layout-column layout-align-center-start subtitle-2 font-weight-regular">
              Sie verfügen nicht über die Rechte um Bestellungen anschauen zu können.
            </div>
          </v-alert>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="py-1 px-6 ma-2 elevation-2" :style="{'background':compColor.context.background}"
                   v-if="orders.length === 0" style="border-radius:6px;">
        <v-list-item-content v-bind:class="[compColor.widget.text]">
          <div class="flex layout-column px-3" v-bind:style="{'border-radius': '5px'}">
            <div class="font-weight-bold subtitle-2 my-1 text-center" style="line-height: 1.0rem;">derzeit sind keine
              Bestellungen vorhanden
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>

      <div v-for="(order, index) in orders" :key="index" class="ma-2 mb-2 elevation-2">
        <v-list-item class="pa-1"
                     :style="{'background':compColor.context.background}" style="border-radius:6px;"
                     @click="openOrder(order)"
        >
          <v-list-item-avatar tile height="42" width="48" class="ma-0">
            <v-icon size="20"
                    v-bind:class="{'blue-grey--text text--lighten-4': !compColor.isInDarkMode, 'blue-grey--text text--darken-2': compColor.isInDarkMode}">
              assignment
            </v-icon>
          </v-list-item-avatar>
          <v-divider vertical v-bind:class="{'grey lighten-3': !compColor.isInDarkMode}"></v-divider>
          <v-list-item-content v-bind:class="['pl-2','py-2',compColor.widget.text]">
            <div class="flex layout-column ml-1 layout-align-center-start">
              <div class="font-weight-bold subtitle-1 mb-1" style="line-height: 1.0rem;">{{ order.medical_name }} -
                {{ order.department_name }}
              </div>
              <div class="font-weight-light subtitle-2 gree" style="line-height: 1.0rem;"><span
                style="width:110px;display: inline-block;">Bestellnummer:</span> {{ order.ordernr }}
              </div>
              <div class="font-weight-light subtitle-2" style="line-height: 1.0rem;"><span
                style="width:110px;display: inline-block;">Bestelldatum:</span>
                {{ order.timestamp | formatDate('DD.MM.YYYY') }}
              </div>

              <div class="font-weight-light subtitle-2 mt-1" style="line-height: 1.0rem;"><span
                  style="width:110px;display: inline-block;">Status:</span>
                <strong class="caption px-2 py-1 rounded mt-1" :class="{'white--text': isDarkMode}" v-bind:style="{'background': isDarkMode ? order.state.colorDark : order.state.color, 'border': '1px solid ' + order.state.colorBorder}">
                  <span v-if="order.state">{{ order.state.name }}</span>
                  <span v-else>unbekannt</span>
                </strong>
              </div>
            </div>
          </v-list-item-content>
          <v-divider vertical v-bind:class="{'grey lighten-3': !compColor.isInDarkMode}"></v-divider>
          <v-list-item-avatar tile height="42" width="48" class="ma-0">
            <div class="rounded-circle" style="width:16px;height:16px;" :style="{'border': '1px solid ' + order.state.colorBorder}">
            <div class="rounded-circle" style="width:14px;height:14px;" :style="{'background': isDarkMode ? order.state.colorDark : order.state.color}"></div>
<!--            <v-icon-->
<!--                size="18"-->
<!--              v-bind:style="{color: order.state.color}"-->
<!--            >-->
<!--              fiber_manual_record-->
<!--            </v-icon>-->
            </div>
          </v-list-item-avatar>
        </v-list-item>
        <!--                <v-divider v-bind:class="[compColor.divider]"></v-divider>-->
      </div>
    </v-list>
  </div>
</template>

<script>

import OrderStates from "@/components/common/order_states"
import router from "@/router";

export default {
  name: 'Orders',
  mixins: [OrderStates],
  data() {
    return {
      orders: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      const params = {
        config: {
          opts: {
            limit: 50,
            sort: {
              ordernr: -1
            }
          },
          query: {
            '$or': [
              {'state': {'$in': [8, 10, 15, 20]}},
              {'state': {'$exists': false}}
            ]
          },
          count: false
        }
      }

      //die showHTMLByRight Methode kommt aus dem lx_auth plugin als mixin und prüft den Benutzer, ob das Recht vorhanden ist
      if (this.showHTMLByRight({ act: 'GET', obj: '/orders' })) {
        this.$http.get('/orders', params)
            .then(res => {
              this.orders = res.data.result || []

              for (let i = 0; i < this.orders.length; i++) {
                this.orders[i].state = this.getStatusForCode(this.orders[i].state)
              }
            })
      }
    },
    openOrder(order) {
      const roles = this.getRoles()
      const showControllerView = order?.state?.code === 8 && Array.isArray(roles) && roles.indexOf('controller') !== -1

      if (showControllerView) {
        router.push({ path: `/orders/${order._id}/check` })
      } else {
        router.push({ path: `/orders/${order._id}/details` })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
