<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">
      <ValidationObserver tag="div" ref="AccountDetailForm" v-slot="{ valid, pristine, reset }"
                          class="layout-column flex">

        <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1" :class="hoverHeader">
          <div class="layout-row">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="!valid || pristine"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="saveAccountData"
                    icon="save"
                    tooltip="Accountdaten speichern"
                    :label="$t('buttons.save.title')"></lx-btn>
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="pristine"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="clearForm(reset)"
                    icon="cancel"
                    tooltip="Formularfelder zurücksetzen auf letzten Speicherstand"
                    :label="$t('buttons.revert.title')"></lx-btn>
          </div>

          <div class="flex layout-row"></div>
        </div>

        <v-divider v-if="!hoverHeader" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

        <form class="flex layout-column" ref="form">
          <div ref="scrollContainer" class="flex layout-column" style="overflow-y: auto;">
            <v-progress-linear :indeterminate="loading" v-if="loading" color="red accent-2"
                               background-color="red lighten-4" class="ma-0"
                               style="border-radius:0;height:4px;position: absolute;top:56px;left:0; width:100%;"></v-progress-linear>

            <div v-if="isSystemAdmin" class="layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
              <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px;"
                   :style="{'background': compColor.lxExpansion.background}">
                <div class="ma-0">
                  <v-alert
                    type="warning"
                    prominent
                    :value="true"
                    icon="error"
                    color="orange lighten-4"
                    class="ma-0 account blue-grey--text text--darken-2">Beim angemeldeten User
                    <strong>"{{ authUserName }}"</strong> handelt es sich um einen nicht änderbaren
                    Systemaccount. Lediglich das Zugangspasswort ist änderbar.
                  </v-alert>
                </div>
              </div>
            </div>


            <lx-expansion-panels :single-open="false" v-model="panel" class="pa-1">
              <lx-expansion-panel v-if="!isSystemAdmin">
                <lx-expansion-panel-header icon="mdi-account-circle"
                                           :main-headline="$t('account.expansion.data.title')"
                                           :sub-headline="$t('account.expansion.data.value')">
                </lx-expansion-panel-header>
                <lx-expansion-panel-content class="pa-3 pr-2">

                  <div class="layout-row layout-wrap">
                    <div class="flex-gt-sm-50 flex-100 pl-1 pr-3">
                      <fake-input-frame :label="$t('inputs.titles.salutation')"
                                        :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                        class="mb-6">
                        <div class="layout-row layout-align-start-center px-2 mt-n2"
                             :style="{height: denseFields ? '40px':'50px'}">
                          <validation-provider tag="div" ref="Salutation"
                                               name="Salutation">

                            <v-radio-group v-model="model.salutation" row hide-details
                                           class="ma-0 px-1 pt-1 pb-1 layout-row layout-align-start-center">
                              <!--                                style="min-height: 50px;"-->
                              <v-radio :label="$t('gender.male.salutation')" value="mr"
                                       :color="compColor.primeColor"
                                       :dark="compColor.isInDarkMode"></v-radio>
                              <v-radio :label="$t('gender.female.salutation')" value="ms"
                                       :color="compColor.primeColor"
                                       :dark="compColor.isInDarkMode"></v-radio>
                              <v-radio :label="$t('gender.divers.salutation')" value="u"
                                       :color="compColor.primeColor"
                                       :dark="compColor.isInDarkMode"></v-radio>
                            </v-radio-group>
                          </validation-provider>
                        </div>
                      </fake-input-frame>
                    </div>
                    <div class="flex-gt-sm-50 flex-100 pl-1 pr-3">
                      <fake-input-frame :label="$t('inputs.titles.carriertitle')"
                                        :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                        class="mb-6">

                        <div class="layout-row layout-align-start-center px-2 mt-n2"
                             :style="{height: denseFields ? '40px':'50px'}">

                          <validation-provider tag="div" ref="Title Prof" name="title1" class="ma-0 pa-0 layout-row">
                            <v-checkbox :color="compColor.primeColor"
                                        v-model="model.title1" value="Prof." hide-details
                                        class="ma-0 pa-0" :label="$t('carriertitle.professor')"
                                        :dark="compColor.isInDarkMode"></v-checkbox>
                          </validation-provider>

                          <v-spacer style="max-width:10px;"></v-spacer>

                          <validation-provider tag="div" ref="Title Doc" name="title2" class="ma-0 pa-0 layout-row">
                            <v-checkbox :color="compColor.primeColor"
                                        v-model="model.title2" value="Dr." hide-details
                                        class="ma-0 pa-0" :label="$t('carriertitle.doctor')"
                                        :dark="compColor.isInDarkMode"></v-checkbox>
                          </validation-provider>
                        </div>
                      </fake-input-frame>
                      <!--                          -->
                      <!--                          -->
                    </div>

                    <div class="flex-gt-sm-50 flex-100 pl-1 pr-3 pt-3">
                      <validation-provider tag="div" ref="Vorname" name="Vorname"
                                           rules="required" v-slot="{ errors }">
                        <v-text-field
                          :label="$t('inputs.titles.firstname') + ' *'"
                          :color="compColor.primeColor"
                          :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                          v-model="model.first_name"
                          outlined
                          class="mb-0"
                          :dark="compColor.isInDarkMode"
                          :error-messages="errors[0]"
                          :dense="denseFields"
                        >
                          <template #append v-if="!isDarkMode">
                            <v-icon color="grey lighten-2">person</v-icon>
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </div>
                    <div class="flex-gt-sm-50 flex-100 pl-1 pr-3 pt-3">
                      <validation-provider tag="div" ref="Nachname" name="Nachname"
                                           rules="required" v-slot="{ errors }">
                        <v-text-field
                          :label="$t('inputs.titles.lastname') + ' *'"
                          :color="compColor.primeColor"
                          :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                          v-model="model.last_name"
                          outlined
                          class="mb-0"
                          :dark="compColor.isInDarkMode"
                          :error-messages="errors[0]"
                          :dense="denseFields"
                        >
                          <template #append v-if="!isDarkMode">
                            <v-icon color="grey lighten-2">person</v-icon>
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </div>

                    <div class="flex-gt-sm-50 flex-100 pl-1 pr-3 pt-3">

                      <v-text-field
                        :label="$t('inputs.titles.username') + ' *'"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        v-model="model.username"
                        outlined
                        class="mb-0"
                        disabled
                        :dark="compColor.isInDarkMode"
                        :dense="denseFields">
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">mdi-account-circle</v-icon>
                        </template>
                      </v-text-field>
                    </div>
                    <div class="flex-gt-sm-50 flex-100 pl-1 pr-3 pt-3">
                      <v-text-field
                        :label="$t('inputs.titles.email') + ' *'"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        v-model="model.email"
                        outlined
                        class="mb-0"
                        disabled
                        :dark="compColor.isInDarkMode"
                        :dense="denseFields">
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">email</v-icon>
                        </template>
                      </v-text-field>
                    </div>
                  </div>
                </lx-expansion-panel-content>
              </lx-expansion-panel>
              <lx-expansion-panel>
                <lx-expansion-panel-header icon="mdi-lock"
                                           :main-headline="$t('account.expansion.security.title')"
                                           :sub-headline="$t('account.expansion.security.value')">
                </lx-expansion-panel-header>
                <lx-expansion-panel-content>

                  <div class="layout-row layout-wrap mt-3 mb-3">
                    <div class="flex-100 pa-4">

                      <fake-input-frame :label="$t('inputs.titles.password')"
                                        :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                        :data-fake-height="50">
                        <password-edit-component></password-edit-component>
                      </fake-input-frame>

                    </div>

                    <!--                        <v-divider class="mx-6"></v-divider>-->
                    <div class="flex-100 pa-4">
                      <fake-input-frame :label="$t('inputs.titles.twofactor')"
                                        :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                        :data-fake-height="50">
                        <twofactor-edit-component></twofactor-edit-component>
                      </fake-input-frame>
                    </div>
                  </div>
                </lx-expansion-panel-content>
              </lx-expansion-panel>
            </lx-expansion-panels>

          </div>
        </form>
      </ValidationObserver>


      <v-dialog v-model="errorDialog" width="700" persistent class="red pa-4">
        <v-card>
          <v-card-text class="pa-4">
            <div class="layout-row">
              <div class="layout-column layout-align-center-center">
                <img src="../../assets/bot_03.png" style="width:250px;height:250px;"/>
              </div>
              <v-divider vertical></v-divider>
              <div class="flex layout-column pa-5">
                <div class="display-3 font-weight-black pink--text">Oops!</div>
                <div class="headline font-weight-light mb-3 mt-1">Da ist wohl etwas schief gelaufen.</div>
                <div class="flex">Wir entschuldigen uns für die Unanehmlichkeiten, doch leider hat der
                  letzte Vorgang zu einem Fehler geführt. Bitte versuchen Sie es noch einmal und wenden
                  Sie sich bei wiederholten Auftreten des Fehlers an den Support.
                </div>
                <div class="mt-3 text-right">
                  <v-btn color="pink" text @click.stop="errorDialog = false">Ok</v-btn>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

    </div>
  </div>
</template>

<script>
import lodash from 'lodash'
import router from '../../router'

import TwofactorEditComponent from '@/components/lx_auth/components/TwofactorEditComponent'
import PasswordEditComponent from '@/components/lx_auth/components/PasswordEditComponent'

export default {
  name: 'AccountDetails',
  // provide () {
  //   return { parentValidator: this.$validator }
  // },
  // mixins: [utils],
  components: {
    TwofactorEditComponent,
    PasswordEditComponent
  },

  data() {
    return {
      hoverHeader: false,
      checkbox: false,
      //denseFields: false,
      toggle_multiple: null,
      panel: [0, 1],
      loading: false,
      model: {
        title: []
      },
      oldModel: null,
      // breadcrumbs: null,
      error: {},
      errorDialog: false,
      snackbar: false,

      showPW: true,
      showPW2: true
    }
  },
  created() {
    this.getAccountData()

    this.$nextTick(() => {
      let scroller = this.$refs.scrollContainer
      scroller.addEventListener('scroll', () => {
        if (scroller.scrollTop > 0) {
          let number = scroller.scrollTop
          number = number > 4 ? 4 : number
          this.hoverHeader = 'elevation-' + number
        } else {
          this.hoverHeader = ''
        }
      })
    })
  },
  watch: {
    checkbox(newVal) {
      router.push({ path: '/twofactor/' + newVal })
    }
  },
  methods: {
    changeTwoFactor() {
      // this.checkbox = !this.checkbox
    },
    openCloseExpansionPanel(index) {
      if (this.panel.indexOf(index) < 0) {
        this.panel.push(index)
      } else {
        this.panel.splice(this.panel.indexOf(index), 1)
      }
    },
    clearForm(reset) {
      reset();
      this.model = Object.assign({}, this.oldModel)
    },
    clear() {
      this.model = lodash.cloneDeep(this.oldModel)
    },
    back() {
      window.history.back()
    },
    saveAccountData() {
      if (this.loading) {
        return
      }

      this.loading = true;

      const data = {
        password: this.model.password,
        old_password: this.model.old_password,
        first_name: this.model.first_name,
        last_name: this.model.last_name,
        email: this.model.email,
        salutation: this.model.salutation,
        // title: this.model.title,
        // phone: this.model.phone
      };

      if (this.model.phone && this.model.phone.length > 3) {
        data.phone = this.model.phone
      }
      if (this.model.title1 || this.model.title2) {
        let joined = ''
        if(this.model.title1 && !this.model.title2){
          joined = this.model.title1
        } else if(!this.model.title1 && this.model.title2){
          joined = this.model.title2
        } else if(this.model.title1 && this.model.title2) {
          joined = this.model.title1 + ' ' + this.model.title2; // sortieren wichtig da auf "Prof. Dr." gepürft wird und "Dr. Prof." einen Fehler liefern würde, somit nun immer "Prof. Dr."
        }
        data.title = joined
      }

      this.$http.put('/account/user/update', data)
        .then(() => {
          this.loading = false;
          this.authStore.dispatch('updateUser', this.model);
          this.getAccountData();
          if (this.$refs.AccountDetailForm) {
            this.$refs.AccountDetailForm.reset()
          }
          this.NotifySystem("Einstellungen gespeichert");
        })
        .catch((error) => {
          this.HandleFormError(this.$refs.AccountDetailForm, error)
            .catch(this.NotifyError)
        })
        .finally(() => {
          this.loading = false;
        })
    },
    getAccountData() {
      if (this.loading) return;

      this.loading = true;

      this.$http.get('/account/user/token')
        .then(res => {
          if (res.data) {
            if (res.data.title) {
              const titles = res.data.title.split(' ')
              if(titles[0].indexOf('Dr.') >= 0){
                res.data.title2 = titles[0]
              } else {
                res.data.title1 = titles[0]
                res.data.title2 = titles[1]
              }

            } else {
              res.data.title = []
            }
            this.model = res.data;
            this.oldModel = Object.assign({}, this.model)
          }
          this.loading = false
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading = false;
        })
    }
  },
  computed: {
    userData: {
      get() {
        return this.authStore.getters.getAuthUserData.is_active_2fa
      },
      set(value) {
        router.push({ path: '/twofactor/' + value })
      }
    },
    authUserName() {
      return this.authStore.getters.getAuthUserName
    },
    isSystemAdmin() {
      return this.authStore.getters.userIsSystemadmin
    },
    isDirty() {
      // if (!this.oldModel) {
      //   return false
      // }
      // return lodash.isEqual(this.oldModel, this.model)
      return false
    },
    isFormInvalid() {
      // return Object.keys(this.fields).some(key => this.fields[key].invalid)
      return false
    }
  }
}
</script>
<style lang="scss">

</style>
