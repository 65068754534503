<template>

  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <ValidationObserver ref="DeliveryDetailForm" v-slot="{ valid, pristine, errors, reset }" class="layout-column flex">
      <div class="layout-column flex ma-2 elevation-5"
           style="border-radius:4px;position:relative;"
           v-bind:style="{background: compColor.contentFrame}">

        <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1" :class="hoverHeader">
          <div class="layout-row">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="back()"
                    icon="mdi-chevron-left"
                    :hide-label="true"
                    :tooltip="$t('buttons.back.tooltip')"
                    :label="$t('buttons.back.title')"></lx-btn>

            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="pristine && !hasChanges || !valid"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="saveConfirm(reset)"
                    icon="save"
                    :tooltip="$t('buttons.save.tooltip')"
                    :label="$t('buttons.save.title')"></lx-btn>

            <lx-btn
                v-if="hospitalid"
                small
                iconSize="20"
                width="auto"
                classes="text-none mr-1"
                @click="clearForm(reset)"
                icon="cancel"
                :color="'#353a47'"
                :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                :disabled="pristine && !hasChanges"
                :tooltip="$t('buttons.revert.tooltip')"
                :label="$t('buttons.revert.title')"></lx-btn>

            <v-badge bordered overlap color="error" :content="conflicts.length" :value="conflicts.length">
              <lx-btn
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="showConflictDialog(reset)"
                  icon="mdi-calendar-question"
                  :color="hasConflicts ? 'error lighten-1' : '#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  :disabled="!hasConflicts"
                  :tooltip="$t('buttons.conflict_resolver.tooltip')"
                  :label="$t('buttons.conflict_resolver.title')"></lx-btn>
            </v-badge>
          </div>
          <div class="flex layout-row"></div>
        </div>

        <v-divider v-if="!hoverHeader" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

        <div ref="scrollContainer" style="overflow-y: auto;" class="flex pa-1 layout-column">

          <lx-expansion-panels :single-open="false" v-model="panel">
            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-truck-delivery"
                                         main-headline="Lieferplandaten"
                                         sub-headline="Allgemeine Daten zum aktuellen Lieferplan">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2">
                    <ValidationProvider :name="$t('inputs.titles.deliveryplanname')" rules="required"
                                        v-slot="{ errors }">
                      <v-text-field
                          :dark="compColor.isInDarkMode"
                          :color="compColor.primeColor"
                          :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                          :dense="denseFields"
                          :label="$t('inputs.titles.deliveryplanname') + ' *'"
                          :error-messages="errors[0]"
                          outlined
                          class="mb-1"
                          v-model="model.name"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">assignment</v-icon>
                        </template>
                      </v-text-field>
                    </ValidationProvider>
                  </div>
                  <div class="flex-100 pa-2">
                    <ValidationProvider :name="$t('inputs.titles.regionalstate')" rules="required" v-slot="{ errors }">
                      <v-select
                          v-model="model.state"
                          item-text="name"
                          item-value="statecode"
                          hide-no-data
                          outlined
                          class="mb-1"
                          clearable
                          :color="compColor.primeColor"
                          :dark="compColor.isInDarkMode"
                          :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                          :label="$t('inputs.titles.regionalstate') + ' *'"
                          :items="states"
                          :menu-props="{ bottom: true, offsetY: true }"
                          :error-messages="errors[0]"
                          :dense="denseFields"
                          @change="selectState"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">location_on</v-icon>
                        </template>
                      </v-select>
                    </ValidationProvider>
                  </div>
                  <div class="flex-100 pa-2">
                    <ValidationProvider :name="$t('inputs.titles.comments')" v-slot="{ errors }">
                      <v-textarea
                          :label="$t('inputs.titles.comments')"
                          :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                          :dark="compColor.isInDarkMode"
                          :color="compColor.primeColor"
                          outlined
                          hide-details
                          name="input-7-4"
                          class="mb-1"
                          v-model="model.comment"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">comment</v-icon>
                        </template>
                      </v-textarea>
                    </ValidationProvider>
                  </div>
                  <div class="flex-100 pa-2">
                    <fake-input-frame :label="$t('inputs.titles.deliveryweekend')"
                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                      class="mb-6">
                      <div class="layout-row layout-align-start-center px-2 mt-n2"
                           :style="{height: denseFields ? '40px':'50px'}">
                        <ValidationProvider :name="$t('inputs.titles.deliveryweekend')" v-slot="{ errors }">
                          <v-checkbox class="my-2" hide-details
                                      :dark="compColor.isInDarkMode"
                                      :color="compColor.primeColor"
                                      v-model="model.with_weekend"
                                      @change="calculateDays"
                                      label="Lieferung auch am Wochenende möglich"></v-checkbox>
                        </ValidationProvider>
                      </div>
                    </fake-input-frame>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>

            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-sort-clock-ascending"
                                         main-headline="Kalender"
                                         sub-headline="Planen Sie all ihre Liefertage">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">

                  <div class="flex-100 pa-2" v-show="showSchedulerHint">
                    <v-alert
                        :dark="compColor.isInDarkMode"
                        prominent
                        outlined
                        text
                        border="left"
                        type="info"
                        elevation="2"
                    >
                      <v-row align="center">
                        <v-col class="grow">
                          Sie haben noch keine Termine für das Kalenderjahr {{ thisyear + 1 }}. Möchten Sie diese aus dem Kalenderjahr {{ thisyear }} übernehmen?
                        </v-col>
                        <v-col class="shrink">
                          <v-btn
                              class="white--text text-none mx-1"
                              :dark="compColor.isInDarkMode"
                              :color="compColor.primeColor"
                              @click.stop="copyCalendarDialog"
                          >Termine übernehmen</v-btn>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </div>

                  <div class="flex-100 pa-2">
                    <fake-input-frame :label="$t('inputs.titles.deliveryplancreate')"
                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                      class="mb-6">

                      <div class="layout-row mx-1">
                        <v-toolbar class="flex" flat color="transparent">
                          <v-spacer></v-spacer>
                          <v-btn fab text small @click="prev" color="pink">
                            <v-icon>mdi-chevron-left</v-icon>
                          </v-btn>
                          <v-toolbar-title class="blue-grey--text text-center" style="min-width:180px;">
                            {{ title }}
                          </v-toolbar-title>
                          <v-btn fab text small @click="next" color="pink">
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </div>

                      <div class="flex lx-calendar pa-1">

                        <div class="layout-column">
                          <div class="layout-row mb-1">
                            <div v-show="index <= 4 || index > 4 && model.with_weekend"
                                 v-for="(item,index) in daysInWeek" :key="index"
                                 class="flex layout-row layout-row layout-align-center-center pa-1"
                                 :class="{'ml-1': index > 0,'blue-grey lighten-5': !compColor.isInDarkMode,'blue-grey darken-3': compColor.isInDarkMode}"

                                 style="border-radius: 3px;">
                              <div class="flex pa-1 layout-row" v-bind:class="[compColor.textInput, 'lighten-1']"
                                   style="border-radius:3px;">
                                <div class="flex pl-1 py-1 text-truncate">
                                  <span
                                      class="body-1 font-weight-light white--text">{{ daysInMonthHeader[index] }}</span>
                                </div>

                                <v-btn text icon small
                                       @click="bulkAddDeliveryDate($event, index, item)">
                                  <v-icon :dark="true" color="white">add_box</v-icon>
                                </v-btn>

                                <v-btn text icon small
                                       v-if="filledWeekdays[index]"
                                       @click="bulkDeleteDeliveryDate($event, index)">
                                  <v-icon :dark="true" color="white">indeterminate_check_box</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </div>

                          <div class="layout-column" id="square2">
                            <div class="layout-row"
                                 v-for="(week, indexWeek) in daysInMonth"
                                 :key="indexWeek">
                              <div class="flex layout-column mb-1"
                                   v-for="(day, indexDay) in week" :key="indexDay"
                                   style="min-height:120px;"
                                   v-show="indexDay <= 4 || indexDay > 4 && model.with_weekend"
                                   v-bind:id="'field-'+ indexWeek + '-'+ indexDay"
                                   v-bind:class="{'ml-1': indexDay > 0,'green lighten-5 schraffiert': day.holiday, 'amber lighten-4': indexDay > 4 && !compColor.isInDarkMode, 'blue-grey darken-2': indexDay > 4 && compColor.isInDarkMode, 'blue-grey lighten-5': indexDay <= 4 && !compColor.isInDarkMode && !day.holiday, 'blue-grey darken-3': indexDay <= 4 && compColor.isInDarkMode}">

                                <div class="blue-grey lighten-4 ma-1 flex layout-column"
                                     style="border-radius: 4px;">
                                  <!-- Normaler Tag label -->
                                  <div class="layout-row py-1 px-2 blue-grey--text text--lighten-1"
                                       v-if="!day.holiday" style="height:38px;">
                                    <div>
                                      <span class="font-weight-black headline">{{ day.shortDay }}</span>
                                      <sup class="font-weight-regular body-2 ml-1 mt-2">{{ day.shortMonth }}</sup>
                                    </div>
                                  </div>

                                  <div class="py-1 px-2 text-truncate green lighten-4 blue-grey--text text--lighten-1"
                                       style="height:44px;border-radius:4px;"
                                       v-if="day.holiday">
                                    <span class="font-weight-regular body-2">{{ day.holiday }}</span>
                                  </div>

                                  <div class="flex layout-column layout-align-center-center">
                                    <v-btn text icon large
                                           @click="singleAddDeliveryDate(indexDay, day.fullDate)"
                                           v-if="!model || !model.dates || !model.dates[day.fullDate]">
                                      <v-icon color="blue-grey lighten-3">mdi-plus-thick</v-icon>
                                    </v-btn>
                                  </div>
                                </div>
                                <div
                                    class="mx-1 mb-1 draggable-event white--text caption font-weight-bold layout-column elevation-2"
                                    style="border-radius:3px;"
                                    v-if="model && model.dates && model.dates[day.fullDate]"
                                    v-bind:class="{'blue-grey darken-1 ': !day.holiday || model.dates[day.fullDate].ignore_conflict, 'orange lighten-3 ': day.holiday}"
                                    v-bind:id="'event-'+ indexWeek + '-'+ indexDay">
                                  <div class="text-center"
                                       style="cursor: pointer;"
                                       @mousedown="dragThatThing('event-'+ indexWeek + '-'+ indexDay, null,  model.dates[day.fullDate], day.fullDate)">
                                    <v-icon>mdi-drag-horizontal</v-icon>
                                  </div>
                                  <v-divider></v-divider>
                                  <div class="px-2 pt-1">
                                    <span class="font-weight-bold" style="line-height:14px;">Bestellung bis</span>
                                  </div>
                                  <div class="flex layout-column px-2 py-1">
                                    <span class="font-weight-regular" style="line-height:14px;">
                                      <v-icon size="12" class="mr-1" :color="compColor.primeHighlightColor">mdi-calendar-month</v-icon>{{
                                        formatDateWithMoment(model.dates[day.fullDate].order_date, 'DD.MM.YYYY')
                                      }}<small
                                        class="ml-1">({{
                                        formatDateWithMoment(model.dates[day.fullDate].order_date, 'ddd')
                                      }})</small>
                                    </span>
                                    <span class="font-weight-regular" style="line-height:14px;">
                                      <v-icon size="12"
                                              :color="compColor.primeHighlightColor">mdi-clock</v-icon> {{
                                        formatDateWithMoment(model.dates[day.fullDate].order_date, 'HH:mm')
                                      }}<small
                                        class="ml-1">Uhr</small>
                                    </span>
                                  </div>
                                  <v-divider></v-divider>
                                  <div v-if="day.holiday && !model.dates[day.fullDate].ignore_conflict"
                                       class="layout-row pa-1">
                                    <v-btn small block depressed dark color="green lighten-1"
                                           @click="ignoreConflict(day.fullDate)">Termin bestätigen
                                    </v-btn>
                                  </div>
                                  <div class="layout-row pa-1">
                                    <div class="flex mr-1">
                                      <v-btn small
                                             dark
                                             block
                                             depressed
                                             class="text-none"
                                             color="blue-grey lighten-2"
                                             @click.stop="changeSingleDeliverDate(indexDay, day.fullDate, model.dates[day.fullDate])">
                                        Ändern
                                      </v-btn>
                                    </div>
                                    <v-btn small
                                           dark
                                           depressed
                                           class="text-none"
                                           color="red lighten-2"
                                           @click.stop="singleDeleteDeliveryDate(day)">
                                      Löschen
                                    </v-btn>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fake-input-frame>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
          </lx-expansion-panels>
        </div>
      </div>
    </ValidationObserver>

    <lx-dialog v-model="editDialog" :allow-drag="true" :overlay="true" :size="{x: '500px', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          <span v-if="editDialogModel.bulk">Multiple Termine</span>
          <span v-else>Einzeltermin</span>
          <span class="ml-2">{{ editDialogModel.title }}</span>
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;"
             :style="{'background': compColor.lxExpansion.background}">
          <div class="flex layout-row layout-wrap mt-1">
            <div class="flex-100 pa-2">
              <v-text-field
                  :dark="compColor.isInDarkMode"
                  :color="compColor.primeColor"
                  :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                  :dense="denseFields"
                  label="Ausgewählte(r) Liefertag(e)"
                  outlined
                  class="mb-1"
                  readonly
                  hide-details
                  v-model="editDialogModel.clickedDayString"
              >
                <template #append v-if="!isDarkMode">
                  <v-icon color="grey lighten-2">today</v-icon>
                </template>
              </v-text-field>
            </div>
            <div class="pa-2 layout-column">
              <fake-input-frame :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                class="flex"
                                label="Spätester Bestelltag">
                <div class="layout-column layout-align-center-center px-5">
                  <v-radio-group v-model="editDialogModel.weekday">
                    <v-radio
                        v-for="(item, index) in daysInMonthHeader"
                        :key="index"
                        :label="item"
                        :value="index"
                        :dark="isDarkMode"
                        :color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </fake-input-frame>
            </div>
            <div class="flex pa-2 layout-column">
              <fake-input-frame :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                class="flex"
                                label="Späteste Bestellzeit">
                <div class="flex layout-row layout-align-start-center pt-3 px-3 pb-1">
                  <vue-timepicker class="flex" :minute-interval="5" style="font-size:50px !important;"
                                  v-model="editDialogModel.time"></vue-timepicker>
                </div>
              </fake-input-frame>
            </div>
          </div>
          <div class="layout-row" v-if="typeof editDialogModel.oldDate !== 'string'">
            <v-checkbox :color="compColor.primeColor" v-model="editDialogModel.bulk"
                        :disabled="typeof editDialogModel.oldDate === 'string'">
              <template #label>
                <span>Alle Werte werden auf die folgenden Termine angewendet!<br><small>(Bei Abwahl der Option werden die Änderungen nur für den ausgewählten Termin übernommen)</small></span>
              </template>
            </v-checkbox>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="layout-column">
          <div class="layout-row layout-align-center-center pa-1">
            <lx-btn :color="'#353a47'"
                    v-if="editDialogModel.bulk && editDialogModel.edit" :dark="compColor.isInDarkMode"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    :disabled="(!editDialogModel.weekday && editDialogModel.weekday !== 0) || !editDialogModel.time"
                    classes="text-none mr-1 flex"
                    @click.stop="bulkUpdateDeliveryDate()"
                    icon="save"
                    tooltip="Änderungen speichern"
                    :label="'Änderungen speichern'"></lx-btn>
            <lx-btn :color="'#353a47'"
                    v-if="editDialogModel.bulk && !editDialogModel.edit" :dark="compColor.isInDarkMode"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    :disabled="(!editDialogModel.weekday && editDialogModel.weekday !== 0) || !editDialogModel.time"
                    classes="text-none mr-1 flex"
                    @click.stop="bulkCreateDeliveryDate()"
                    icon="save"
                    tooltip="Änderungen speichern"
                    :label="'Änderungen speichern'"></lx-btn>
            <lx-btn :color="'#353a47'"
                    v-if="!editDialogModel.bulk && editDialogModel.edit || !editDialogModel.bulk && !editDialogModel.edit"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    :disabled="(!editDialogModel.weekday && editDialogModel.weekday !== 0) || !editDialogModel.time"
                    classes="text-none mr-1 flex"
                    @click.stop="singleCreateDeliveryDate()"
                    icon="save"
                    tooltip="Änderungen speichern"
                    :label="'Änderungen speichern'"></lx-btn>

            <!-- CANCEL BUTTON -->
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click.stop="closeEditDialog()"
                    icon="cancel"
                    tooltip="Vorgang abbrechen und Fenster schließen"
                    :label="'Abbrechen'"></lx-btn>
          </div>
        </div>
      </template>
    </lx-dialog>

    <lx-dialog v-model="conflictDialog" :allow-drag="true" :overlay="true" :size="{x: '640px', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode ? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          <span class="ml-2">Terminkonflikte</span>
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;"
             :style="{'background': compColor.lxExpansion.background}">
          <v-list v-bind:class="{'blue-grey darken-3': compColor.isInDarkMode}" class="py-0" :dark="compColor.isInDarkMode" dense style="overflow-y: auto">
<!--            <v-subheader>Konflikte</v-subheader>-->
            <v-list-item-group>
              <v-list-item
                  v-for="(item, index) in conflicts"
                  :key="index"
                  :dark="compColor.isInDarkMode"
              >
<!--                <v-list-item-icon>-->
                <v-list-item-avatar>
                  <v-icon :color="compColor.primeColor" :dark="compColor.isInDarkMode" size="32">mdi-help-circle-outline</v-icon>
                </v-list-item-avatar>
<!--                </v-list-item-icon>-->
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.date"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn v-on="{ ...tooltip}" icon depressed @click="resolveConflict(index, 'ignore')"
                               class="ml-2" color="#66cc66">
                          <v-icon color="#66cc66">mdi-calendar-check</v-icon>
                        </v-btn>
                      </template>

                      <span>Konflikt ignorieren</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn v-on="{ ...tooltip}" icon depressed @click="resolveConflict(index, 'remove')"
                               class="ml-2" color="#cc6666">
                          <v-icon color="#cc6666">mdi-calendar-remove</v-icon>
                        </v-btn>
                      </template>

                      <span>Termin entfernen</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn v-on="{ ...tooltip}" icon depressed @click="resolveConflict(index, 'show')" class="ml-2"
                               color="#6666cc">
                          <v-icon color="#6666cc">mdi-calendar-today</v-icon>
                        </v-btn>
                      </template>

                      <span>Termin anzeigen</span>
                    </v-tooltip>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </div>
      </template>
      <template #actions>
        <div class="layout-column">
          <div class="layout-row layout-align-end pa-1">
            <!-- CANCEL BUTTON -->
            <lx-btn
                small
                :color="'#353a47'"
                :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                :label="'Schließen'"
                iconSize="20"
                width="auto"
                classes="text-none mr-1"
                @click.stop="conflictDialog = false"
                icon="cancel"
                tooltip="Fenster schließen"
            ></lx-btn>
          </div>
        </div>
      </template>
    </lx-dialog>
  </div>
</template>

<script>
import lodash from 'lodash'
import moment from 'moment'
import 'moment/locale/de'

import Draggable from 'gsap/dist/Draggable'
import gsap from 'gsap/dist/gsap'

import router from '../../../router'
import lxDatepicker from '../../../components/lx_datepicker/LxDatepicker2'

import vue from 'vue'
import VueTimepicker from "vue2-timepicker";
import 'vue2-timepicker/dist/VueTimepicker.css'

const MsecPerDay = 24 * 3600 * 1000

function IncYear(source) {
  let target = new Date(source)
  let sourceDay = target.getUTCDay()

  target.setUTCFullYear(target.getUTCFullYear() + 1)

  while (target.getUTCDay() !== sourceDay) {
    target.setUTCDate(target.getUTCDate() - 1)
  }

  return target
}

function DayOfTheYear(date) {
  return Math.floor((date - new Date(date.getFullYear(), 0, 0)) / MsecPerDay)
}

function CreateDateTime(year, month, day, hour, minute, second, msec) {
  let tmp = new Date()

  tmp.setFullYear(year, month - 1, day)
  tmp.setHours(hour, minute, second, msec)

  return tmp
}

function CopyDelivery(fromYear, toYear, sourceData) {
  const deliverDayYear = (new Array(7)).fill(0)
  const orderTimeDays = new Array(7)
  let tmpDeliverDates = []
  let dates = lodash.cloneDeep(sourceData)

  Object.keys(dates).filter((k) => k.startsWith(fromYear.toString())).forEach((tag) => {
    const source = dates[tag]
    const srcDate = new Date(source.deliver_date)
    let deliver_date = IncYear(source.deliver_date)
    let order_date = IncYear(source.order_date)
    let day_index = srcDate.getDay()


    deliverDayYear[day_index]++
    orderTimeDays[day_index] = {
      day: order_date.getDay(),
      hour: order_date.getHours(),
      minute: order_date.getMinutes()
    }

    tmpDeliverDates.push({
      id: deliver_date.getTime(),
      day_of_year: DayOfTheYear(deliver_date),
      deliver_date: new Date(deliver_date),
      order_date: new Date(order_date),
      text: source.text
    })
  })

  /**
   * Sort dates from first day of year to last day of the year
   */
  tmpDeliverDates.sort((left, right) => {
    const v = left.id - right.id

    if (v === 0) {
      return 0
    }

    if (v < 0) {
      return -1
    } else {
      return 1
    }
  })

  let currentDay = CreateDateTime(toYear, 1, 1, 12, 0, 0, 0)
  const lastDayOfYear = CreateDateTime(toYear, 12, 31, 12, 0, 0, 0)
  let currentIndex = 0
  let currentDatesIndex = 0
  // indicator for delivery plan series
  let breakEvenPoint = 365 / 12

  /*
    iterate over the year day by day
   */
  while (currentDay <= lastDayOfYear) {
    currentIndex = DayOfTheYear(currentDay)
    // if delivery day?
    if (deliverDayYear[currentIndex % 7] >= breakEvenPoint) {
      let searchIndex = currentDatesIndex
      let searchValue = DayOfTheYear(currentDay)

      while (searchIndex < tmpDeliverDates.length) {
        // Value found; just copy
        if (tmpDeliverDates[searchIndex].day_of_year === searchValue) {
          currentDatesIndex = searchIndex
          let keyName = currentDay.toISOString().substring(0, 10)
          dates[keyName] = {
            "text": tmpDeliverDates[searchIndex].text,
            "deliver_date": new Date(tmpDeliverDates[searchIndex].deliver_date),
            "order_date": new Date(tmpDeliverDates[searchIndex].order_date),
            "is_generated": true,
          }

          break
        }

        // Maybe; no value found
        if (tmpDeliverDates[searchIndex].day_of_year > searchValue) {
          const order_info_data = orderTimeDays[currentIndex % 7] || {
            day: currentDay.getDay(),
            hour: 18,
            minute: 0,
          }

          let tmp_order_date = new Date(currentDay)
          tmp_order_date.setHours(order_info_data.hour, order_info_data.minute, 0, 0)

          const diff = currentDay.getDay() - order_info_data.day
          tmp_order_date.setDate(tmp_order_date.getDate() - diff)

          // append
          let keyName = currentDay.toISOString().substring(0, 10)
          dates[keyName] = {
            "deliver_date": new Date(currentDay),
            "order_date": new Date(tmp_order_date),
            "is_generated": true,
            "is_filled": true,
          }

          break
        }

        // INC Value
        searchIndex++
      }
    }

    currentDay.setDate(currentDay.getDate() + 1.0)
  }

  return dates
}

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

export default {
  name: 'DeliveryDateDetails',
  props: {
    hospitalid: {
      type: [String, null],
      default: () => {
        return null
      }
    }
  },
  components: {
    lxDatepicker,
    VueTimepicker
  },
  data() {
    return {
      hoverHeader: false,
      conflictDialog: false,
      newDate: new Date(),
      editDialog: false,
      // dialog: false,
      weekdayConditions: {
        monday: {
          text: '',
          time: null,
          dialog: false
        },
        tuesday: {
          text: '',
          time: null,
          dialog: false
        },
        wednesday: {
          text: '',
          time: null,
          dialog: false
        },
        thursday: {
          text: '',
          time: null,
          dialog: false
        },
        friday: {
          text: '',
          time: null,
          dialog: false
        },
        saturday: {
          text: '',
          time: null,
          dialog: false
        },
        sunday: {
          text: '',
          time: null,
          dialog: false
        },
      },
      weekdayCheckboxes: {},
      daysInWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
      panel: [0, 1],
      search: null,
      e1: true,
      loading: 0,
      model: {
        dates: {},
      },
      oldModel: {
        dates: {},
      },
      breadcrumbs: null,
      tabAction: null,
      error: {},
      snackbar: false,
      isDirty: false,
      states: [],
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
      },
      today: new Date(),
      thisyear: null,
      // events: {},
      daysInMonth: null,
      daysInMonthHeader: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
      filledWeekdays: [false, false, false, false, false, false, false],
      conflicts: [],
      holidaysInYear: null,
      draggableObj: null,
      newDropField: null,
      editDialogModel: {},
      bulkDeleteDay: null,
      hoverDraggable: null,
      holidaysYearTable: {},
      showSchedulerHint: false,
      createDialog: this.hospitalid === null
    }
  },
  created() {
    gsap.registerPlugin(Draggable);

    moment.locale('de');

    this.getCountryStates();

    this.today = moment(this.today);
    this.title = this.today.format('MMMM YYYY');
    this.thisyear = this.today.year()

    if (this.hospitalid != null) {
      this.getData()
    } else {
      this.calculateDays(this.today)
    }

    this.$nextTick(() => {
      let scroller = this.$refs.scrollContainer
      scroller.addEventListener('scroll', () => {
        if (scroller.scrollTop > 0) {
          let number = scroller.scrollTop
          number = number > 4 ? 4 : number
          this.hoverHeader = 'elevation-' + number
        } else {
          this.hoverHeader = ''
        }
      })
    })
  },
  computed: {
    isBusy() {
      return this.loading > 0
    },
    // pingIcon() {
    //   return this.showSchedulerHint ? 'anim_icon_pulse' : ''
    // },
    hasChanges: {
      get() {
        let something = !lodash.isEqual(this.model, this.oldModel);
        return something
      },
      set() {

      }
    },
    hasConflicts() {
      return this.conflicts.length > 0
    }
  },
  methods: {

    /**
     * Check conflicts between delivery and holiday dates
     */
    CheckConflicts() {
      let conflicts = []
      const todayTime = (new Date()).getTime()

      if (!this.model || !this.model.dates || !this.holidaysInYear) {
        return
      }

      Object.keys(this.holidaysInYear).forEach((name) => {
        const dateItem = (new Date(name + 'T12:00:00Z'))

        if (this.model.dates[name] && this.model.dates[name].ignore_conflict !== true && todayTime < dateItem.getTime()) {
          conflicts.push({
            'date': dateItem.toLocaleDateString('de-DE', {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            }),
            'name': this.holidaysInYear[name],
            'key': name,
            'item': this.model.dates[name],
            'timestamp': dateItem.getTime()
          })
        }
      })

      // Sort conflicts
      conflicts.sort((left, right) => {
        if (left.timestamp === right.timestamp) {
          return 0
        }

        if (left.timestamp < right.timestamp) {
          return -1
        }

        return 1
      })

      this.conflicts = conflicts
    },

    closeEditDialog() {
      this.editDialog = false
      this.hoverDraggable = false
    },

    formatDateWithMoment(date, format) {
      return moment(date).format(format)
    },

    singleDeleteDeliveryDate(tag) {
      if (tag && tag.fullDate) {
        this.DialogConfirm(
            'Löschung des Termins',
            'Soll der Termin für den "' + moment(tag.fullDate).format('DD.MM.YYYY') + '" gelöscht werden?',
            {ok: 'Löschen', cancel: this.$t('buttons.cancel.title')}
        )
            .then(() => {
              let events = {
                ...this.model?.dates
              }

              delete events[tag.fullDate]
              this.model.dates = events
              this.calculateDays(this.today)
              this.checkWeekdaysIfFilled()
              this.refreshConflicts()
            })
            .catch(() => {
            })
      }
    },

    checkWeekdaysIfFilled() {
      // let events = lodash.cloneDeep(this.model.dates)
      let events = {
        ...this.model?.dates
      }

      let tempDays = [false, false, false, false, false, false, false]
      for (let item in events) {
        let found = moment(item).day() - 1
        tempDays[found] = true
      }
      this.filledWeekdays = tempDays
    },

    singleAddDeliveryDate(clickedDay, date) {
      this.editDialog = true
      this.editDialogModel = {}

      let obj = {
        edit: false,
        bulk: false,
        title: 'hinzufügen',
        clickedDay: this.daysInMonthHeader[clickedDay],
        clickedDayString: this.daysInMonthHeader[clickedDay] + ', ' + moment(date).format('DD.MM.YYYY'),
        clickedDate: date
      }

      this.editDialogModel = lodash.cloneDeep(obj)
    },

    singleCreateDeliveryDate() {
      this.editDialog = false;

      let dateObj = this.calculateEventByDate(this.editDialogModel.clickedDate, this.editDialogModel.time, this.editDialogModel.weekday, this.editDialogModel.text, this.editDialogModel.oldDate);
      let events = {
        ...this.model?.dates
      }

      events[this.editDialogModel.clickedDate] = dateObj

      if (this.editDialogModel.oldDate) {
        delete events[this.editDialogModel.oldDate]
      }

      this.model.dates = events
      this.CheckConflicts()
      this.checkWeekdaysIfFilled()
    },

    changeSingleDeliverDate(clickedDay, date, item, oldDate) {
      this.editDialog = true
      this.editDialogModel = {}

      let obj = {
        edit: true,
        oldDate: oldDate,
        time: {
          "HH": moment(item.order_date).format('HH'),
          "mm": moment(item.order_date).format('mm')
        },
        weekday: moment(item.order_date).weekday(), // item.weekday
        text: item.text,
        bulk: false,
        title: oldDate ? 'verschieben' : 'ändern',
        clickedDay: this.daysInMonthHeader[clickedDay],
        clickedDayString: this.daysInMonthHeader[clickedDay] + ', ' + moment(date).format('DD.MM.YYYY'),
        clickedDate: date
      }

      this.editDialogModel = lodash.cloneDeep(obj)
    },

    calculateEventByDate(clickedDate, time, weekday, text) {
      let deliverDate = moment(clickedDate)
      let deliverWeekDay = deliverDate.weekday()
      let orderTime = time //.split(':');
      let lastOrderWeekDay = weekday

      let diff = deliverWeekDay < lastOrderWeekDay ? 7 - lastOrderWeekDay + deliverWeekDay : deliverWeekDay - lastOrderWeekDay
      let subt = lodash.cloneDeep(deliverDate)
      subt.subtract(diff, 'days')
      subt.hour(orderTime['HH'])
      subt.minute(orderTime['mm'])

      deliverDate.set('hour', 23);
      deliverDate.set('minute', 59)

      return {
        text: text,
        deliver_date: deliverDate.toISOString(),
        order_date: subt.toISOString()
      }
    },

    bulkAddDeliveryDate(ev, clickedDay) {
      this.editDialog = true
      this.editDialogModel = {}

      let obj = {}
      obj.edit = false
      obj.bulk = true
      obj.title = 'hinzufügen'
      obj.clickedDay = clickedDay
      obj.clickedDayString = this.daysInMonthHeader[clickedDay]

      this.editDialogModel = lodash.cloneDeep(obj)
    },

    bulkCreateDeliveryDate() {
      this.editDialog = false
      let yearBreak = lodash.cloneDeep(this.today).year()
      let mom = lodash.cloneDeep(this.today)

      if (typeof this.editDialogModel.clickedDay === 'string' && this.editDialogModel.clickedDay.length > 3) {
        this.editDialogModel.clickedDay = this.daysInMonthHeader.indexOf(this.editDialogModel.clickedDay)
      }

      let firstDayToInsert = mom.startOf('year').weekday(this.editDialogModel.clickedDay)

      let events = {
        ...this.model?.dates
      }

      let counter = 0

      while (counter < 60) {
        let dateObj = this.calculateEventByDate(firstDayToInsert, this.editDialogModel.time, this.editDialogModel.weekday, this.editDialogModel.text, this.editDialogModel.oldDate)
        let keyDate = firstDayToInsert.format('YYYY-MM-DD')
        firstDayToInsert.add(7, 'd')

        if (moment(dateObj.deliver_date).isSameOrBefore(moment(yearBreak + '-12-31'))) {
          events[keyDate] = dateObj
          counter++
        } else {
          counter = 60
        }
      }

      this.$set(this.model, 'dates', events)

      this.CheckConflicts()
      this.checkWeekdaysIfFilled()
    },

    bulkUpdateDeliveryDate() {
      this.editDialog = false
      // let events = {}
      let clickedWeekday = this.daysInMonthHeader.indexOf(this.editDialogModel.clickedDay)
      let orderWeekday = this.editDialogModel.weekday

      let orderTime = this.editDialogModel.time
      let events = {
        ...this.model?.dates
      }

      for (let item in events) {
        let event = moment(events[item].deliver_date)
        let deliverWeekday = event.weekday()
        let difference = 0

        if (clickedWeekday === deliverWeekday) {
          if (orderWeekday < deliverWeekday) {
            difference = deliverWeekday - orderWeekday
          } else if (orderWeekday === deliverWeekday) {
            difference = 0
          } else {
            difference = 7 - orderWeekday + deliverWeekday
          }

          let subt = event.subtract(difference, 'days')
          subt.hour(orderTime['HH'])
          subt.minute(orderTime['mm'])
          events[item].order_date = subt.toISOString()

          if (this.editDialogModel.text) {
            events[item].text = this.editDialogModel.text
          }
        }
      }

      this.$set(this.model, 'dates', lodash.cloneDeep(events))
      this.CheckConflicts()

      this.checkWeekdaysIfFilled()
    },

    bulkDeleteDeliveryDate(ev, tag) {
      this.DialogConfirm(
          'Löschung der Terminserie',
          'Sollen sämtliche nachfolgenden Termine für den Wochentag "' + this.daysInMonthHeader[tag] + '" wieder gelöscht werden? (Wenn ja, können auf andere Wochentage verschobene Termine nicht berücksichtigt werden und verbleiben.)',
          {ok: 'Löschen', cancel: this.$t('buttons.cancel.title')}
      )
          .then(() => {
            let yearBreak = lodash.cloneDeep(this.today).year();
            let events = {
              ...this.model?.dates
            }

            // let events = lodash.cloneDeep(this.model.dates);
            let tempEvents = {};
            for (let item in events) {


              if (moment(item).isAfter(moment(yearBreak + '-12-31')) || moment(item).isBefore(moment(yearBreak + '-01-01')) || moment(item).day() !== tag + 1) {
                tempEvents[item] = events[item]
              }
            }

            this.model.dates = tempEvents;
            this.CheckConflicts()
            this.checkWeekdaysIfFilled()
          })
          .catch(() => {
          })
    },

    openCloseExpansionPanel(index) {
      if (this.panel.indexOf(index) < 0) {
        this.panel.push(index)
      } else {
        this.panel.splice(this.panel.indexOf(index), 1)
      }
    },
    /**
     * Wird aufgerufen wenn man das popup draggen möchte, dabei klickt man auf den Header des Popups
     * - es wird der Body aus dem HTML geholt über die ID 'app'
     * - dann die Größe des Fenster bestimmt mit getBoundingClientRect, da Body auf 100% in beide Richtung stretcht
     * - dann wird Popup zu Draggable Obejct gemacht in den Grenzen des Body (bounds)
     * */
    dragThatThing(id, hovered, item, oldDate) {
      let self = this;
      if (hovered && this.hoverDraggable) {
        return;
      } else if (hovered) {
        this.hoverDraggable = true
      }

      Draggable.create('#' + id, {
        // type: 'x,y',
        bounds: '#square2',
        onDragStart: function () {
          this.target.style.opacity = 0.6
        },
        onDrag: function () {
          let daysPerWeek = self.model.with_weekend ? 7 : 5;
          for (let x = 0; x < daysPerWeek; x++) {
            for (let y = 0; y < 5; y++) {
              if (Draggable.hitTest('#' + id, '#field-' + y + '-' + x, "40%")) {
                self.newDropField = {
                  x: x,
                  y: y
                }
              }
            }
          }
        },
        onDragEnd: function () {
          this.target.style.opacity = 1.0;
          this.target.style.zIndex = '1';
          gsap.set(this.target, {clearProps: "transform"});
          self.leftThatThing(id, item, oldDate)
        }
      })
    },
    /**
     * Wird aufgerufen wenn man beim popup draggen loslässt
     * - killt das Draggable Object
     * */
    leftThatThing(id, item, oldDate) {
      this.hoverDraggable = false;
      Draggable.get('#' + id).kill();
      setTimeout(() => {
        this.changeSingleDeliverDate(this.newDropField.x, this.daysInMonth[this.newDropField.y][this.newDropField.x].fullDate, item, oldDate)
      }, 500)
    },

    calculateDays(_today) {
      const today = moment(Object.assign({}, _today))
      this.daysInMonth = []

      let firstDayInMonth = parseInt(today.startOf('month').format('d'), 10)
      if (firstDayInMonth === 0) {
        firstDayInMonth = 7
      }

      let endOfLastMonth = today.subtract(1, 'months').endOf('month')
      let beginn = endOfLastMonth.subtract(firstDayInMonth - 1, 'days')

      for (let i = 0; i < 5; i++) {
        let week = []

        for (let j = 0; j < 7; j++) {
          beginn = beginn.add(1, 'days')

          if (this.model.with_weekend || j < 5) {
            let dayObj = {
              shortDay: beginn.format('DD'),
              shortMonth: beginn.format('MMM'),
              fullDate: beginn.format('YYYY-MM-DD')
            }

            if (this.holidaysInYear) {
              let holiday = this.holidaysInYear[beginn.format('YYYY-MM-DD')]

              if (holiday) {
                dayObj['holiday'] = holiday
              }
            }

            week.push(dayObj)
          }
        }

        this.daysInMonth.push(week)
      }

      // enable "copy calendar" only in future date, without data AND holidays
      const october = 9
      const now = new Date()
      const thisYear = now.getFullYear()
      const nextYear = now.getFullYear() + 1

      const schedulerStatistics = Object.keys(this.model.dates).reduce((obj, value) => {
        const key = value.substring(0, 4)

        if (!obj[key]) {
          obj[key] = 1
        } else {
          obj[key]++
        }

        return obj
      }, {})

      this.showSchedulerHint = this.hospitalid && this.holidaysYearTable[nextYear] === true && now.getMonth() > october && schedulerStatistics.hasOwnProperty(thisYear) && !schedulerStatistics.hasOwnProperty(nextYear)
    },

    setDate(value) {
      this.today = moment(value)
      this.calculateDays(this.today)
      this.title = this.today.format('MMMM YYYY')
    },

    prev() {
      this.setDate(this.today.subtract(1, 'months'))
    },

    next() {
      this.setDate(this.today.add(1, 'months'))
    },
    /**
     * wird aufgerufen, wenn man den zurück Button drückt
     *
     * */
    back() {
      window.history.back()
    },

    /**
     * Get all users, all rights, all rights of selected role and all users with selected role
     */
    getData() {
      if (this.isBusy) {
        return
      }

      this.loading++;
      // get all pharmacies
      this.$http.get('/deliveryplan/' + this.hospitalid)
          .then(res => {
            if (res.data) {
              this.loading++
              this.model = res.data;

              this.checkWeekdaysIfFilled();
              this.oldModel = lodash.cloneDeep(this.model)

              this.conflicts = []
              this.holidaysYearTable = {}

              this.$http.get('/calendar/holidays/GER/' + res.data.state)
                  .then(res => {
                    const holis = res.data[0].holidays

                    this.holidaysInYear = holis.reduce((obj, item) => {
                      let keyname = (new Date(item.date)).toISOString().substring(0, 10)
                      let yearKey = keyname.substring(0, 4)
                      this.holidaysYearTable[yearKey] = true
                      obj[keyname] = item.name
                      return obj
                    }, {})

                    this.CheckConflicts()
                    this.calculateDays(this.today)
                  })
                  .catch(this.NotifyError)
                  .finally(this.loading--)
            }
          })
          .catch(this.NotifyError)
          .finally(this.loading--)
    },

    getCountryStates() {
      this.$http.get('/calendar/states/ger')
          .then((result) => {
            let stateResult = result.data || []
            this.states = stateResult.sort(compare)
          })
          .catch(this.NotifyError)
    },

    saveConfirm(reset) {
      if (!this.hasConflicts) {
        return this.save(reset)
      }

      this.DialogConfirm(
        'Konflikte',
        'Ihr Terminplan hat ungelöste Konflikte. Möchten Sie trotzdem speichern?',
          {ok: 'Ja', cancel: this.$t('buttons.cancel.title')}
      )
        .then(() => {
          this.save(reset)
        })
        .catch(() => {})

    },

    save(reset) {
      if (!this.model._id) {
        this.$http.post('/deliveryplan', this.model)
            .then((res) => {
              this.NotifySystem('Änderungen gespeichert')

              if (res.data && res.data._id) {
                if (typeof reset === 'function') {
                  reset()
                }

                this.model._id = res.data._id
                this.oldModel = lodash.cloneDeep(this.model)
                router.replace('/administration/delivery_dates/' + res.data._id + '/details')
              }
            })
            .catch(this.NotifyError)
      } else {
        this.$http.put('/deliveryplan/' + this.model._id, this.model)
            .then(() => {
              this.NotifySystem('Änderungen gespeichert')
              this.oldModel = lodash.cloneDeep(this.model)

              this.clearForm(reset)
            })
            .catch(this.NotifyError)
            .finally(this.getData)
      }
    },

    /**
     * kopiert einen bestehenden Lieferplan, übernimmt alles und löscht _id, location_id und den namen raus damit man neu anlegen kann *
     **/
    copy() {
      if (this.model._id) {
        vue.delete(this.model, '_id')
        vue.delete(this.model, 'location_id')
        vue.delete(this.model, 'name')
        this.oldModel = lodash.cloneDeep(this.model)
        router.replace('/administration/delivery_dates/new')
      }
    },

    clearForm(reset) {
      reset()
      this.model = Object.assign({}, this.oldModel)
      this.checkWeekdaysIfFilled()
      this.CheckConflicts()
    },

    showConflictDialog() {
      this.conflictDialog = Array.isArray(this.conflicts) && this.conflicts.length > 0
    },

    refreshConflicts() {
      this.conflicts = this.conflicts.filter((v) => !v.resolved)
      this.conflictDialog = this.conflictDialog && this.conflicts.length > 0
    },

    /**
     * Resolves a Delivery Date Conflict
     * @param index
     * @param cmd
     */
    resolveConflict(index, cmd) {
      if (!this.conflicts[index] || !this.conflicts[index].key) {
        return
      }


      const key = this.conflicts[index].key

      /**
       * Ignore delivery date conflict
       */
      if (cmd === 'ignore') {
        this.model.dates[key].ignore_conflict = true
        this.conflicts[index].resolved = true

        return this.refreshConflicts()
      }

      /**
       * Remove delivery date from list
       */
      if (cmd === 'remove') {
        let events = lodash.cloneDeep(this.model.dates)
        delete events[key]
        this.model.dates = events

        // delete this.model.dates[key]
        this.conflicts[index].resolved = true

        return this.refreshConflicts()
      }

      /**
       * Jump directly to calendar entry and close modal
       */
      if (cmd === 'show') {
        const deliverDate = new Date(this.model.dates[key].deliver_date)
        this.setDate(deliverDate)
        this.conflictDialog = false
        return
      }

      this.refreshConflicts()
    },

    ignoreConflict(key) {
      const conflictIndex = this.conflicts.findLastIndex((x) => x.key === key)

      if (conflictIndex === -1) {
        return
      }

      this.model.dates[key].ignore_conflict = true
      this.conflicts[conflictIndex].resolved = true
      this.calculateDays(this.today)
      this.conflicts = this.conflicts.filter((v) => !v.resolved)
    },

    copyCalendarDialog() {
      const thisYear = (new Date()).getFullYear()
      const nextYear = thisYear + 1

      this.DialogConfirm(
          'Terminübernahme',
          `Möchten Sie die Termine des Jahres ${thisYear} auf das Jahr ${nextYear} übertragen?`,
          {hideCancelButton: false, ok: 'Ja', cancel: 'Nein'}
      )
          .then(() => {
            this.copyCalendar(thisYear, nextYear)
          })
          .catch(() => {
          })

    },

    copyCalendar(thisYear, nextYear) {
      let events = {}
      try {
        events = CopyDelivery(thisYear , nextYear, this.model.dates)
      } catch (e) {
        console.error(e)
        return
      }

      this.$set(this.model, 'dates', events)

      this.setDate(CreateDateTime(nextYear, 1, 1, 12, 0, 0, 0))
      this.checkWeekdaysIfFilled()
      this.CheckConflicts()
    },

    selectState (state) {
      this.conflicts = []
      this.holidaysYearTable = {}

      this.$http.get('/calendar/holidays/GER/' + this.model.state)
        .then(res => {
          const holis = res.data[0].holidays

          this.holidaysInYear = holis.reduce((obj, item) => {
            let keyname = (new Date(item.date)).toISOString().substring(0, 10)
            let yearKey = keyname.substring(0, 4)
            this.holidaysYearTable[yearKey] = true
            obj[keyname] = item.name
            return obj
          }, {})

          this.CheckConflicts()
          this.calculateDays(this.today)
        })
        .catch(this.NotifyError)
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.scheduler-container {
  /*border-bottom: 1px solid #ddd !important;*/
  /*border-right: 1px solid #eee !important;*/
  .scheduler-header {
    /*border-top: 1px solid #eee !important;*/
    /*border-left: 1px solid #eee !important;*/
  }

  .scheduler-field {
    border-bottom: 1px solid #f0f0f0 !important;
    border-left: 1px solid #f0f0f0 !important;
  }
}

.dark_login {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  padding: 50px;
  min-width: 500px;
  min-height: 700px;
  background-image: linear-gradient(#3b4b65, #212c3e);
}

h1, h2 {
  font-weight: normal;
}

.subheader {
  height: 20px;
  font-size: 16px;
  padding: 0 16px 0 2px;
  font-weight: 300;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.tabs__div {
  text-transform: none;
  color: #575757;
}


.schraffiert {
  /*position: relative;*/
  /*overflow: hidden;*/

  //background-image: url("../../../assets/doodle_medishop3_opac.png") !important;
  background-repeat: repeat;
  background-size: 300px 300px;

  /*> div {*/
  /*    z-index: 2;*/
  /*}*/

}

.lx-dark-app {
  .schraffiert {
    /*position: relative;*/
    /*overflow: hidden;*/

    /*> div {*/
    /*    z-index: 2;*/
    /*}*/

    &:before {

    }
  }
}

.border-anima {
  .box {
    box-sizing: border-box;
    /*padding: 15px;*/
    position: relative;
    overflow: hidden;
  }

  .box::before {
    content: '';
    position: absolute;
    width: 150%;
    height: 150%;
    background: repeating-linear-gradient(
            white 0%,
            white 7.5px,
            hotpink 7.5px,
            hotpink 15px,
            white 15px,
            white 22.5px,
            hotpink 22.5px,
            hotpink 30px);
    transform: translateX(-20%) translateY(-20%) rotate(-45deg);
    animation: animate 15s linear infinite;
  }

  .box .content {
    position: relative;
    /*background-color: white;*/
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px;
    text-align: center;
    font-family: sans-serif;
    z-index: 2;
  }

  .box,
  .box .content {
    box-shadow: 0 0 2px deeppink,
    0 0 5px rgba(0, 0, 0, 1),
    inset 0 0 5px rgba(0, 0, 0, 1);
    border-radius: 10px;
  }

  .box .content h2 {
    color: deeppink;
  }

  .box .content p {
    color: dimgray;
  }

  @keyframes animate {
    from {
      background-position: 0;
    }

    to {
      background-position: 0 450px;
    }
  }
}

.anim_icon_pulse {
  animation: zoom-in-zoom-out 1500ms ease 0s 4;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
    color: inherit;
  }
  50% {
    transform: scale(2.0, 2.0);
    //color: #709487;
    //color: #d4ac7a;
    color: red;
  }
  100% {
    transform: scale(1, 1);
    color: inherit;
  }
}

</style>
