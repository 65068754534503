<template>
  <div class="flex layout-column layout-align-center-center">

    <v-alert class="ma-6" prominent type="info">
      <h2>Sie wurden erfolgreich abgemeldet.</h2>Bitte schließen Sie dieses Browsertab und kehren Sie zu Ihrem SHS
      zurück.<br>Vielen Dank, dass Sie Medishop nutzen. Bis zum nächsten Mal.<br><br>Ihr Medishop Team
    </v-alert>

  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.component('AutoLogoutComponent', {
  name: 'AutoLogoutComponent',
  components: {},
  data() {
    return {}
  },
  created() {
  },
  watch: {},
  computed: {},
  methods: {}
})
</script>
