import Vue from 'vue'
import Vuex from 'vuex'
import agGrids from './modules/agGrid'
import appSize from './modules/appSize'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    agGrids,
    appSize
  }
})