<template>
  <v-dialog
      persistent
      v-model="visible"
      overlay-opacity="0.99"
      max-width="600"
      :dark="compColor.isInDarkMode"
      :color="compColor.primeColor"
      style="overflow:hidden;z-index: 9999 !important;"
  >
    <div class="elevation-10 layout-column" style="border-radius: 6px;"
         v-bind:style="{background: compColor.contentFrame}">
      <!-- HEADER -->

      <!-- CONTENT (default slot) -->
      <div class="ma-1 px-7 py-5 layout-row elevation-3" :style="{'background': compColor.lxExpansion.background}">
        <div class=" pa-6 layout-row">
          <div class="layout-column layout-align-center-center px-5">
            <v-progress-circular
                :size="80"
                :width="5"
                :color="compColor.primeHighlightColor"
                indeterminate
            ></v-progress-circular>
          </div>
          <div class="ml-5 layout-column blue-grey--text text--darken-2">
            <span class="pa-0 font-weight-light" style="font-size:1.2em"><span class="font-weight-bold">Es dauert ein wenig</span></span>
            <span class="pa-0 font-weight-light" style="font-size:1.2em">Standortwechsel wird durchgeführt</span>
<!--            <span class="pa-0 font-weight-light" style="font-size:1.2em"><span class="font-weight-bold">automatisch</span> neugeladen</span>-->
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'LxChangeLocation',
  props: {
    visible: {
      type: Boolean,
      default() {
        return true
      }
    }
  }
}
</script>

<style scoped>

</style>