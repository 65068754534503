<template>
  <div>
    <v-snackbar top color="warning" :timeout="3000" v-model="snackbar" :multi-line="true" class="mt-12">
      <v-icon class="mr-3">warning</v-icon>
      Artikel nicht gefunden
      <v-btn color="gray darken-3" text @click="snackbar = false">Ok</v-btn>
    </v-snackbar>

    <lx-dialog v-model="visible" :allow-drag="true" :overlay="true" :size="{x: '70', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          Suchfelder anpassen
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <ValidationObserver tag="div" ref="editSearchEditorPopupForm" class="layout-column flex">
            <div class="flex-100 pa-2 layout-row" v-for="(value, index) in items" :key="index">

              <validation-provider tag="div" class="flex" :ref="'searchedit' + index" :name="'Suchfeld' + index"
                                   rules="required" v-slot="{ errors }">

                <v-text-field
                  outlined
                  class="mb-0"
                  :dark="compColor.isInDarkMode"
                  :color="compColor.primeColor"
                  :dense="denseFields"
                  label="Suchfeld*"
                  v-model="items[index]"
                  :error-messages="errors[0]"
                  hint="Ein definierter Suchtext"
                ></v-text-field>

              </validation-provider>

              <v-btn text icon @click="changed(index)" :color="compColor.prime" :dark="compColor.isInDarkMode"
                     class="mt-1">
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
            </div>
            <v-divider></v-divider>
            <v-btn @click="changed(items.length)" :color="compColor.prime" :dark="isDarkMode"
                   class="white--text text-none">Neues Suchfeld hinzufügen
            </v-btn>
          </ValidationObserver>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <div class="flex mr-1">
            <lx-btn :color="'#353a47'"
                    block
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    class="flex"
                    :disabled="!showSaveBtn"
                    iconSize="20"
                    width="auto"
                    classes="text-none"
                    @click.stop="save()"
                    icon="save"
                    tooltip="Speichern"
                    :label="'Speichern'"></lx-btn>
            <!--            @click.stop="addItem(articleData, true)"-->
          </div>
          <div class="mr-1">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    class="flex"
                    iconSize="20"
                    :disabled="!showReverseBtn"
                    width="auto"
                    classes="text-none"
                    @click.stop="reverseChanges"
                    icon="mdi-backup-restore"
                    tooltip="Änderungen rückgängig machen"
                    :label="'Rückgängig'"></lx-btn>
          </div>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  class="flex"
                  iconSize="20"
                  width="auto"
                  :disabled="lookupPending"
                  classes="text-none"
                  @click.stop="visible = false"
                  icon="cancel"
                  tooltip="Editor Schließen"
                  :label="'Schließen'"></lx-btn>
        </div>
      </template>
    </lx-dialog>

  </div>
</template>

<script>
import lodash from "lodash";

export default {
  name: 'CatalogSearchEditorDialog',
  props: {
    onchange: [null, Function]
  },
  data() {
    return {
      items: [],
      lookupPending: false,
      visible: false,
      articleid: null,
      snackbar: false,
      errors: [],
      oldModel: null,
    }
  },
  created() {

  },
  computed: {
    showSaveBtn() {
      if (lodash.isEqual(this.oldModel, this.items)) {
        return false
      }
      let test = this.$refs['editSearchEditorPopupForm']

      if (test) {
        return !test.flags.pristine && test.flags.valid
      }

      return true
    },
    showReverseBtn() {
      if (lodash.isEqual(this.oldModel, this.items)) {
        return false
      }
      let test = this.$refs['editSearchEditorPopupForm']

      if (test) {
        return !test.flags.pristine
      }

      return true
    }
  },
  methods: {
    reverseChanges() {
      this.items = lodash.cloneDeep(this.oldModel)
      let test = this.$refs['editSearchEditorPopupForm']
      if (test) {
        test.reset()
      }
    },
    changed(index) {
      if (index === this.items.length) {
        this.items.push('');
      } else {
        this.items.splice(index, 1);
      }
    },
    save() {
      if (this.lookupPending) return;

      this.lookupPending = true;

      let searchingmodel = this.items.join('-');

      this.$http.put('/abda/patchsearching/' + this.articleid.toString(), searchingmodel)
        .then(() => {
          this.visible = false;
          this.$emit('success');
        })
        .catch((error) => {
          this.HandleFormError(this.$refs.EditAbdaSearchField, error)
            .catch(this.NotifyError)
        })
        .finally(() => {
          this.lookupPending = false
        })
    },
    getData() {
      if (!this.articleid || this.lookupPending) return;

      this.lookupPending = true;

      this.$http.get('/abda/get/' + this.articleid.toString())
        .then(res => {
          this.items = ((res.data || {}).searching || '').split('-') || [];
          this.oldModel = lodash.cloneDeep(this.items)
        })
        .catch(error => {
          if (error && error.response && error.response.status === 404) {
            this.snackbar = true;
          } else {
            console.error(error)
          }

          this.visible = false;
        })
        .finally(() => {
          this.lookupPending = false;
        })
    },
    Show(id) {
      this.articleid = id;
      this.items = [];
      this.visible = true;
      this.getData();
    }
  }
}
</script>

<style scoped>

</style>
