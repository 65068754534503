const APP_FONT_SIZE = 'LOGIN'

const state = {
    appFontSize: 16
}

const mutations = {
    [APP_FONT_SIZE](state, value) {
        // console.log('APP_FONT_SIZE2: ', value)
        state.appFontSize = value
    },
}

const actions = {
    setAppFontSize({commit}, value) {
        // console.log('APP_FONT_SIZE1: ', value)
        commit(APP_FONT_SIZE, value)
    }
}

const getters = {
    getAppFontSize: state => {
        return state.appFontSize
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
