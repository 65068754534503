<template>
    <v-dialog v-model="showLoginPopup" persistent max-width="900">
        <v-form @submit="relogin" id="re_login_fields">
            <ValidationObserver ref="ReLoginForm" v-slot="{ valid, pristine, reset }">
                <div class="d-flex white elevation-6 flex-column"
                     style="border-radius: 3px;max-width: 900px;min-height:550px;">
                    <div class="grow d-flex flex-column pa-3 d-flex">
                        <v-subheader class="display-1 font-weight-bold pink--text text--darken-2 mt-4">Medi<span
                                class="font-weight-thin blue-grey--text text--darken-4">Shop</span></v-subheader>
                        <v-subheader class="title font-weight-bold blue-grey--text text--darken-2 mt-n3">
                            Neuauthentifizierung
                        </v-subheader>
                        <v-divider class="mx-5"></v-divider>

                        <div class="layout-row mx-5 my-3 pa-3 red accent-2 white--text layout-align-start-center"
                             style="border-left: 8px solid #d83b3b !important;border-radius: 3px;"
                             v-if="loginError && pristine">
                            <v-avatar>
                                <v-icon dark large>error_outline</v-icon>
                            </v-avatar>
                            <div class="flex layout-column layout-align-start-center ml-2">
                                <p class="ma-0">Ihre Anmeldedaten sind nicht korrekt. Bitte versuchen Sie es erneut.</p>
                            </div>
                        </div>

                        <div v-if="authSystem !== 'median' && !loginError || authSystem !== 'median' && (loginError && !pristine)"
                             class="blue lighten-5 blue--text text--darken-1 d-flex flex-row pa-3 my-3 mx-5">
                            <div class="d-flex flex-column justify-center mr-4">
                                <v-avatar color="blue lighten-4" size="40">
                                    <v-icon color="blue">security</v-icon>
                                </v-avatar>
                            </div>
                            <div class="justify-center">
                                <small>Nach längerer Inaktivität wurden Sie aus Sicherheitsgründen automatisch
                                    ausgeloggt. Um Ihre Arbeit fortzusetzen, authentifizieren Sie sich bitte
                                    erneut.</small>
                            </div>
                        </div>

                        <div v-if="authSystem === 'median'"
                             class="blue lighten-5 blue--text text--darken-1 d-flex flex-row pa-3 my-3 mx-5">
                            <div class="d-flex flex-column justify-center mr-4">
                                <v-avatar color="blue lighten-4" size="40">
                                    <v-icon color="blue">security</v-icon>
                                </v-avatar>
                            </div>
                            <div class="justify-center">
                              Nach längerer Inaktivität wurden Sie aus Sicherheitsgründen automatisch
                              abgemeldet. Um Ihre Arbeit fortzusetzen, müssen Sie dieses Browsertab schließen und den Medishop erneut aus Ihrem SHS heraus starten.
                              Ihr bereits gefüllter Warenkorb bleibt erhalten.
                            </div>
                        </div>

                        <v-divider class="mx-5" v-if="authSystem !== 'median'"></v-divider>

                        <div class="grow mt-5 pa-5" v-if="authSystem !== 'median'">

                            <div v-show="!twoFA">

                                <v-text-field
                                        v-model="userName"
                                        color="blue-grey darken-4"
                                        prepend-inner-icon="supervised_user_circle"
                                        label="Benutzername"
                                        outlined
                                        class="mx-2"
                                        :disabled="true"
                                ></v-text-field>

                                <!--                      :error-messages="errors[0]"-->

                                <!--            </validation-provider>-->
                                <validation-provider ref="Passwort" name="Passwort" rules="required"
                                                     v-slot="{ errors }">
                                    <v-text-field
                                            v-model="model.password"
                                            :error-messages="errors[0]"
                                            color="blue-grey darken-4"
                                            prepend-inner-icon="fingerprint"
                                            label="Passwort"
                                            placeholder=" "
                                            autofocus
                                            ref="passwordInput"
                                            outlined
                                            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                            :type="showPassword ? 'text' : 'password'"
                                            hint="Wir empfehlen mindestens 8 Zeichen zu verwenden."
                                            @click:append="showPassword = !showPassword"
                                            class="mx-2"
                                    ></v-text-field>
                                </validation-provider>


                            </div>

                            <div class="px-2" v-if="twoFA">
                                <fake-input-frame data-fake-label="Zwei-Faktor-Authentisierung" :data-fake-padding="'pa-4'">

                                    <code-input v-model="model.passcode" ref="codeInputRelogin" :enter-func="relogin" :error-reset-func="resetErrors" :style-version="0"></code-input>

                                </fake-input-frame>

                            </div>

                        </div>

                        <v-divider class="mx-5" v-if="authSystem !== 'median'"></v-divider>

                        <div class="pt-2 px-4 d-flex flex-row" v-if="authSystem !== 'median'">
                            <div class="grow mr-2" v-if="twoFA">
                                <v-btn block class="pink darken-2 text-none white--text" type="submit"
                                       :disabled="!model || !model.passcode || model.passcode.length < 6"
                                       :loading="loading">Zugriffscode bestätigen
                                </v-btn>
                            </div>
                            <div class="grow mr-2" v-else>
                                <div class="grow mr-2">
                                    <v-btn block class="pink darken-2 text-none white--text" type="submit"
                                           :loading="loading"
                                           :disabled="!valid">Anmelden
                                    </v-btn>
                                </div>
                            </div>
                            <v-btn class="pink darken-2 text-none white--text mr-2" @click="changeUser()"
                                   title="Derzeitigen Nutzer abmelden und als neuer Nutzer anmelden">
                                <span>anderen Benutzer anmelden</span>
                            </v-btn>
                        </div>
                    </div>
                </div>

            </ValidationObserver>
        </v-form>
    </v-dialog>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import CodeInput from './CodeInput'
import moment from 'moment'

const LOCAL_STORE = process.env.VUE_APP_BASE_NAME + '-trust-token';

    export default Vue.component('ReLoginComponent', {
        name: 'ReLoginComponent',

        // props: ['visible'],
        // mixins: [utils],
        components: {
            CodeInput
        },
        data() {
            return {
                twoFA: false,

                loginError: false,
                model: {},
                showPassword: false,

                username: '',
                loading: false,
                error: '',
            }
        },
        created() {

        },
        computed: {
            showLoginPopup() {
                this.resetErrors();
                return this.authStore.getters.showLoginPopup
            },
            userName() {
                return this.authStore.getters.getAuthUserName
            }
        },
        watch: {},
        methods: {
            relogin(ev) {
                ev.preventDefault(); // wichtig damit Formular mit Enter abgeschickt werden kann

                this.loading = true;

                let userFromStorage = this.authStore.getters.getAuthUserData;
                this.model.login = userFromStorage.user.email;

                let trusted = JSON.parse(window.localStorage.getItem(LOCAL_STORE));

                if (trusted && trusted.expire && trusted.token) {
                    let timeNow = moment();
                    let expireTime = moment(trusted.expire);
                    let isafter = expireTime.isAfter(timeNow);
                    if(isafter){
                        this.model.trust_token_id = trusted.token
                    } else {
                        window.localStorage.removeItem(LOCAL_STORE)
                    }
                }

                // Multi Location
                // TODO: not used; given from http-header
                // if (userFromStorage.user.has_multi_location === true) {
                //   // Send current location_id to backend
                //   this.model.location_id = userFromStorage.user.location_id
                // }

                axios.post('/login', this.model)
                    .then(resLogin => {
                        this.authStore.dispatch('login', {data: resLogin.data, type: 'relogin'});
                        this.model = {}; // falls man sich in der anwendung erneut einloggen muss damit die name und pwd nicht vom letzten versuich drin stehen
                        this.twoFA = false;
                        this.resetErrors();
                        this.$refs['codeInputRelogin'].resetSingleFields();

                        if (this.$refs.ReLoginForm) {
                            this.$refs.ReLoginForm.reset()
                        }
                    })
                    .catch(error => {
                        let errorMsg = error.message;

                        if (errorMsg.indexOf('406') >= 0) {
                            this.twoFA = true;
                            this.resetErrors()
                        } else {
                            this.error = errorMsg;
                            this.loginError = true;
                            if (this.$refs.ReLoginForm) {
                                this.$refs.ReLoginForm.reset()
                            }
                            if (this.twoFA) {
                                this.model.passcode = null;
                                this.$refs['codeInputRelogin'].resetSingleFields()
                            } else {
                                this.model = {}
                            }
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })

            },
            resetErrors(){
                this.loginError = false;
            },
            changeUser(){
                this.twoFA = false;
                this.logout()
            },
            clearForm(reset) {
                reset();
                this.twoFA = false;
                this.resetErrors();
                this.model = {};

                this.$refs['codeInputRelogin'].resetSingleFields()
            }
        }
    })
</script>
