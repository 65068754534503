<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <ValidationObserver ref="HospitalDetailForm" v-slot="{ valid, pristine, reset, errors }" class="layout-column flex">
      <div class="layout-column flex ma-2 elevation-5"
           style="border-radius:4px;position:relative;"
           v-bind:style="{background: compColor.contentFrame}">

        <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1" :class="hoverHeader">
          <div class="layout-row">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="back()"
                    icon="mdi-chevron-left"
                    :hide-label="true"
                    tooltip="zurück zur medizinischen Einrichtungsübersicht"
                    :label="$t('buttons.back.title')"></lx-btn>

            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="!valid || pristine"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="save(reset)"
                    icon="save"
                    :tooltip="$t('buttons.save.tooltip')"
                    :label="$t('buttons.save.title')"></lx-btn>

            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="pristine"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="clearForm(reset)"
                    icon="cancel"
                    :tooltip="$t('buttons.revert.tooltip')"
                    :label="$t('buttons.revert.title')"></lx-btn>
          </div>
          <div class="flex layout-row"></div>
        </div>

        <v-divider v-if="!hoverHeader" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

        <div ref="scrollContainer" style="overflow-y: auto;" class="flex pa-1 layout-column">
          <lx-expansion-panels :single-open="false" v-model="panel">
            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-city"
                                         main-headline="Einrichtungsdaten"
                                         sub-headline="Allgemeine Einrichtungsdaten">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="Krankenhausname"
                                         name="Krankenhausname" rules="required"
                                         v-slot="{ errors }">
                      <v-text-field
                        :label="$t('inputs.titles.facilityname') + ' *'"
                        :dark="compColor.isInDarkMode"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        v-model="model.name"
                        outlined
                        class="mb-1"
                        :error-messages="errors[0]"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">house</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>

                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="corporate_group" name="corporate_group" v-slot="{ errors }">
                      <v-select
                        :dark="compColor.isInDarkMode"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent' : 'grey lighten-4'"
                        :dense="denseFields"
                        :items="corporateGroups"
                        :disabled="!corporateGroups"
                        :error-messages="errors[0]"
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-model="model.corporate_group"
                        :label="$t('inputs.titles.groupofcompanies')"
                        placeholder="Wählen Sie eine Unternehmensgruppe"
                        item-text="name"
                        item-value="prefix"
                        class="mb-1"
                        outlined
                        clearable
                      ></v-select>
                    </validation-provider>
                  </div>

                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="email" name="email"
                                         rules="email" v-slot="{ errors }">
                      <v-text-field
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :error-messages="errors[0]"
                        :dense="denseFields"
                        :dark="compColor.isInDarkMode"
                        :label="$t('inputs.titles.locationemail')"
                        v-model="model.email"
                        outlined
                        class="mb-1"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">email</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>

                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="statecode"
                                         name="statecode"
                                         rules="required"
                                         v-slot="{ errors }">
                      <v-select
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :dark="compColor.isInDarkMode"
                        :label="$t('inputs.titles.regionalstate') + ' *'"
                        v-model="model.state"
                        :items="states"
                        item-text="name"
                        item-value="statecode"
                        hide-no-data
                        :menu-props="{ bottom: true, offsetY: true }"
                        outlined
                        class="mb-1"
                        clearable
                        :error-messages="errors[0]"
                        :dense="denseFields"
                      ></v-select>
                    </validation-provider>
                  </div>
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="Lieferplan"
                                         name="Lieferplan"
                                         v-slot="{ errors }">
                      <v-select
                        :color="compColor.primeColor"
                        :dark="compColor.isInDarkMode"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :label="$t('inputs.titles.deliveryplanselection')"
                        v-model="model.deliverplan_id"
                        :items="deliveryplans"
                        :menu-props="{ bottom: true, offsetY: true }"
                        item-text="name"
                        item-value="_id"
                        hide-no-data
                        outlined
                        class="mb-1"
                        clearable
                        :disabled="!deliveryplans"
                        :error-messages="errors[0]"
                        :dense="denseFields"
                      ></v-select>
                    </validation-provider>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>

            <lx-expansion-panel v-if="hospitalid == null">
              <lx-expansion-panel-header icon="mdi-account"
                                         main-headline="Standort Verwalter"
                                         sub-headline="Geben Sie eine Benutzer E-Mail ein welche der Einrichtung als Administrator hinzugefügt werden soll">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="E-Mail" name="E-Mail"
                                         rules="required|email|unique_email:pharmacy" v-slot="{ errors }">
                      <v-text-field
                          :color="compColor.primeColor"
                          :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                          :error-messages="errors[0]"
                          :dense="denseFields"
                          :dark="compColor.isInDarkMode"
                          :label="$t('inputs.titles.email') + ' *'"
                          v-model="model.invites_email"
                          outlined
                          class="mb-1"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">email</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>

                  <div class="flex-100 pa-2">
                    <v-alert
                        border="left"
                        prominent
                        style="font-size:15px;"
                        class="pa-3"
                        v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
                      <template slot="prepend">
                        <v-avatar>
                          <v-icon large>mdi-information-variant</v-icon>
                        </v-avatar>
                      </template>
                      An die im Feld <strong>E-Mail</strong> angegebene Adresse wird eine Einladung zum Beitritt zu diesem Standort versendet. Dieser Benutzer wird automatisch die Rolle
                      <strong>Administrator</strong> hinzugefügt um den Standort zu verwalten und weitere Nutzer einzuladen. Nutzer welche bereits zu einer Apotheke hinzugefügt wurden
                      können <strong>nicht</strong> verwendet werden.
                    </v-alert>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>

            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-city"
                                         main-headline="Adressdaten"
                                         sub-headline="Allgemeine Adressdaten">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-gt-sm-80 flex-100 pa-2">
                    <validation-provider tag="div" ref="Strasse" name="Strasse"
                                         v-slot="{ errors }">
                      <v-text-field
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :label="$t('inputs.titles.street')"
                        v-model="model.address.street"
                        outlined
                        class="mb-1"
                        :dark="compColor.isInDarkMode"
                        :error-messages="errors[0]"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">mdi-road</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div class="flex-gt-sm-20 flex-100 pa-2">
                    <validation-provider tag="div" ref="Hausnummer"
                                         name="Hausnummer"
                                         v-slot="{ errors }">
                      <v-text-field
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :label="$t('inputs.titles.number')"
                        v-model="model.address.number"
                        outlined
                        class="mb-1"
                        :dark="compColor.isInDarkMode"
                        :error-messages="errors[0]"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">mdi-home-floor-2</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div class="flex-gt-sm-30 flex-100 pa-2">
                    <validation-provider tag="div" ref="Postleitzahl"
                                         name="Postleitzahl"
                                         v-slot="{ errors }">
                      <v-text-field
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :label="$t('inputs.titles.zipcode')"
                        v-model="model.address.zipcode"
                        outlined
                        class="mb-1"
                        :dark="compColor.isInDarkMode"
                        :error-messages="errors[0]"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">email</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div class="flex-gt-sm-70 flex-100 pa-2">
                    <validation-provider tag="div" ref="Stadt" name="Stadt"
                                         v-slot="{ errors }">
                      <v-text-field
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :label="$t('inputs.titles.city')"
                        v-model="model.address.city"
                        outlined
                        class="mb-1"
                        :dark="compColor.isInDarkMode"
                        :error-messages="errors[0]"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">mdi-city-variant</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>

            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-city"
                                         main-headline="Einstellungen"
                                         sub-headline="Alle wichtige Programmeinstellungen">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap pl-2 pr-2 pt-4 mb-6">
                  <div class="flex-100 pl-2 pr-2">
                        <validation-provider tag="div" ref="specialarticlelists"
                                             name="specialarticlelists">
                          <v-select
                            v-model="model.abdata_id"
                            :items="specialarticlelists"
                            :label="$t('inputs.titles.dataset')"
                            :dark="compColor.isInDarkMode"
                            :color="compColor.primeColor"
                            :menu-props="{ bottom: true, offsetY: true }"
                            class="mb-0"
                            item-text="name"
                            item-value="_id"
                            hide-no-data
                            hide-details
                            outlined
                          >
                            <template slot="prepend-inner">
                              <div class="mr-1 layout-column layout-align-center-center" style="height:25px;">
                                <v-icon>mdi-hospital-building</v-icon>
                              </div>
                            </template>
                          </v-select>
                        </validation-provider>
                  </div>
                </div>

                <div class="layout-row layout-wrap pl-2 pr-2">
                  <div class="flex-100 pl-2 pr-2">
                    <fake-input-frame :label="$t('inputs.titles.specialfeearticle')"
                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                      class="mb-6">
                      <div class="layout-row layout-align-start-center px-2 mt-n2"
                           :style="{height: denseFields ? '40px':'50px'}">
                        <validation-provider tag="div" ref="use_special_fee_ze"
                                             name="use_special_fee_ze">
                          <v-switch
                            :color="compColor.primeColor"
                            v-model="model.use_special_fee"
                            :dark="compColor.isInDarkMode"
                            :disabled="!isActiveModuleSpecialFee"
                            hide-details
                            inset
                            dense
                            class="ma-0 ml-2"
                            label="ZE-Artikel für diese Einrichtung aktivieren"
                          ></v-switch>
                        </validation-provider>

                      </div>

                      <div class="layout-row layout-align-start-center px-2 mt-n2"
                           :style="{height: denseFields ? '40px':'50px'}">
                        <v-switch
                          :color="compColor.primeColor"
                          :dark="compColor.isInDarkMode"
                          :value="nubInfo && nubInfo.active === true"
                          :disabled="!nubInfo || nubInfo.active !== true"
                          @click="deleteNub"
                          hide-details
                          inset
                          dense
                          class="ma-0 ml-2"
                          :label="(nubInfo && nubInfo.active) ? nubInfo.count + ' NUB-Artikel sind für diese Einrichtung aktiv' : 'Um die NUB-Artikel zu aktivieren, importieren Sie eine Artikelliste'"
                        ></v-switch>
                      </div>
                    </fake-input-frame>
                  </div>
                </div>

                <div class="layout-row layout-wrap pl-2 pr-2">
                  <div class="flex-100 pl-2 pr-2">
                    <fake-input-frame :label="$t('inputs.titles.orders_options')"
                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                      class="mb-6">
                      <div class="layout-row layout-align-start-center px-2 mt-n2"
                           :style="{height: denseFields ? '40px':'50px'}">
                        <validation-provider tag="div" ref="use_module_barcode"
                                             name="use_module_barcode">
                          <v-switch
                            :color="compColor.primeColor"
                            :dark="compColor.isInDarkMode"
                            :disabled="!isActiveModuleBarcodeScan"
                            v-model="model.use_barcode_scan"
                            hide-details
                            inset
                            dense
                            class="ma-0 ml-2"
                            label="Artikelscan im Warenkorb für diese Einrichtung aktivieren"
                          ></v-switch>
                        </validation-provider>

                      </div>

                      <div class="layout-row layout-align-start-center px-2 mt-n2"
                           :style="{height: denseFields ? '40px':'50px'}">
                        <validation-provider tag="div" ref="disable_order_comment"
                                             name="disable_order_comment">
                          <v-switch
                            :color="compColor.primeColor"
                            :dark="compColor.isInDarkMode"
                            v-model="model.disable_order_comment"
                            hide-details
                            inset
                            dense
                            class="ma-0 ml-2"
                            label="Bestellkommentar für diese Einrichtung deaktivieren"
                          ></v-switch>
                        </validation-provider>

                      </div>

                      <div class="layout-row layout-align-start-center px-2 mt-n2"
                           :style="{height: denseFields ? '40px':'50px'}">
                        <validation-provider tag="div" ref="enable_controller_role"
                                             name="enable_controller_role">
                          <v-switch
                            :color="compColor.primeColor"
                            :dark="compColor.isInDarkMode"
                            :disabled="!isActiveModuleController"
                            v-model="model.enable_controller_role"
                            hide-details
                            inset
                            dense
                            class="ma-0 ml-2"
                            label="Zweite Freigabe (Controller Rolle) für diese Einrichtung aktivieren"
                          ></v-switch>
                        </validation-provider>

                      </div>
                    </fake-input-frame>
                  </div>
                </div>

              </lx-expansion-panel-content>
            </lx-expansion-panel>

          </lx-expansion-panels>
        </div>
      </div>

    </ValidationObserver>
  </div>
</template>
<script>
import lodash from 'lodash'
import router from '../../../router'

const DefaultSpecialArticleArray = [
    {_id: null, name: '*** MediShop Sonderartikel-Liste ***'}
]

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

export default {
  name: 'HospitalDetails',
  props: {
    hospitalid: {
      type: [String, null],
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      hoverHeader: false,
      panel: [0, 1, 2, 3],
      loading: 0,
      model: {
        address: {}
      },
      oldModel: {
        address: {}
      },
      states: [],
      specialarticlelists: [],
      corporateGroups: [],
      deliveryplans: [],
      nubInfo: null,
      isActiveModuleBarcodeScan: false,
      isActiveModuleSpecialFee: false,
      isActiveModuleController: false

    }
  },
  created() {
    this.$http.get('/calendar/states/ger')
      .then((result) => {
        let stateResult = result.data || []
        this.states = stateResult.sort(compare)
      })
      .catch(this.NotifyError)

    this.$http.get('/deliveryplans')
      .then((result) => {
        let deliverResult = result.data.result || []
        this.deliveryplans = deliverResult.sort(compare)
      })
      .catch(this.NotifyError)

    this.$http.get('/settings/modules')
      .then((result) => {
        const modules = result.data?.modules || []

        this.isActiveModuleBarcodeScan = (modules.indexOf('barcode') !== -1)
        this.isActiveModuleSpecialFee = (modules.indexOf('ze_item') !== -1)
        this.isActiveModuleController = (modules.indexOf('controller') !== -1)
      })
      .catch(this.NotifyError)

    this.$http.get('/mycompany')
      .then((result) => {
        if (result.data && result.data.additionally) {
          this.corporateGroups = Object.keys(result.data.additionally).reduce((prev, prefix) => {
            const obj = result.data.additionally[prefix]

            prev.push({
              prefix: prefix.toUpperCase(),
              name: obj.name || [prefix, obj.vendor_number].join(' '),
              vendor_number: obj.vendor_number
            })

            return prev
          }, [])
        }
      })

    /**
     * optain abda settings
     */
    this.$http.get('/abda/settings/list')
        .then((result) => {
          this.specialarticlelists = DefaultSpecialArticleArray.concat((Array.isArray(result.data)) ? result.data : [])
        })
        .catch(this.NotifyError)

    if (this.hospitalid != null) {
      this.getData()
    }

    this.$nextTick(() => {
      let scroller = this.$refs.scrollContainer
      scroller.addEventListener('scroll', () => {
        if (scroller.scrollTop > 0) {
          let number = scroller.scrollTop
          number = number > 4 ? 4 : number
          this.hoverHeader = 'elevation-' + number
        } else {
          this.hoverHeader = ''
        }
      })
    })
  },
  computed: {
    isBusy() {
      return this.loading > 0
    },
  },
  methods: {
    getSpecialFeeInfo() {
      this.loading++
      this.nubInfo = null

      this.$http.get('/abda/specialarticles/info/' + this.hospitalid)
        .then((res) => {
          if (res.data) {
            this.nubInfo = {
              active: res.data.type === 'nub_item',
              count: res.data.count
            }
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading--
        })
    },
    openCloseExpansionPanel(index) {
      if (this.panel.indexOf(index) < 0) {
        this.panel.push(index)
      } else {
        this.panel.splice(this.panel.indexOf(index), 1)
      }
    },
    back() {
      window.history.back()
    },
    getData() {
      if (this.isBusy) {
        return
      }

      this.loading++
      this.getSpecialFeeInfo()

      this.$http.get('/hospital/' + this.hospitalid)
        .then(res => {
          this.loading = false;

          if (res.data) {
            res.data["abdata_id"] = res.data["abdata_id"] || null;
            this.model = res.data
            this.oldModel = Object.assign({}, this.model)
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading--
        })
    },
    save() {
      if (this.hospitalid != null) {
        let data = lodash.cloneDeep(this.model)

        // Set empty String to NULL value
        Object.keys(data).forEach((keyname) => {
          if (typeof data[keyname] === "string" && !data[keyname]) {
            data[keyname] = null
          }
        })

        delete data.location_id
        delete data.is_active

        const SaveFunc = () => {
          this.$http.put('/hospital/' + this.hospitalid, data)
            .then(() => {
              this.NotifySystem('Änderungen gespeichert');
              if (this.$refs.HospitalDetailForm) {
                this.$refs.HospitalDetailForm.reset()
              }
            })
            .catch((error) => {
              this.HandleFormError(this.$refs.HospitalDetailForm, error)
                .then(this.NotifyError("Daten konnten nicht gespeichert werden"))
                .catch(this.NotifyError)
            })
        }

        const isCorporateGroupChanged = this.oldModel.corporate_group && (this.oldModel.corporate_group !== data.corporate_group)

        if (isCorporateGroupChanged) {
          this.DialogConfirm(
            'Ändern der Unternehmensgruppe',
            'Ein ändern der Unternehemensgruppe setzt in allen Stationen der Einrichtung die Kundenkostenstellen zurück. Wirklich ändern?',
            { ok: 'Ändern', cancel: this.$t('buttons.cancel.title') }
          )
            .then(SaveFunc)
        } else {
          if (this.oldModel.corporate_group) {
            delete data.corporate_group; // Delete for safty
          }

          SaveFunc()
        }
      } else {
        this.$http.post('/hospital', this.model)
          .then((result) => {
            this.NotifySystem('Änderungen gespeichert');
            if (result && result.data && this.$refs.HospitalDetailForm) {
              this.$refs.HospitalDetailForm.reset();
              router.replace('/administration/hospitals/' + result.data + '/details')
            }
          })
          .catch((error) => {
            const bQuotaExceeded = (error && error.response && error.response.status === 402)

            if (bQuotaExceeded) {
              this.DialogConfirm(
                  this.$t('LICENSES.QUOTA_MEDICALUNIT.TITLE'),
                  this.$t('LICENSES.QUOTA_MEDICALUNIT.TEXT'),
                  { ok: 'Schließen', cancel: this.$t('buttons.cancel.title'), hideCancelButton: true, icon: 'mdi-cash-lock' }
              )

              return
            }

            this.HandleFormError(this.$refs.HospitalDetailForm, error)
              .then(this.NotifyError("Daten konnten nicht gespeichert werden"))
              .catch(this.NotifyError)
          })
      }
    },
    clearForm(reset) {
      reset();
      this.model = Object.assign({}, this.oldModel)
    },
    deleteNub() {
      this.DialogConfirm(
        'Entfernen der NUB-Artikel',
        'Möchten Sie wirklich die Referenz der NUB-Artikel für diese Einrichtung entfernen? Die Artikel selben verbleiben im Datenstamm.',
        { ok: 'Entfernen', cancel: this.$t('buttons.cancel.title') }
      )
        .then(() => {
          this.loading++

          this.$http.delete('/abda/specialarticles/list/' + this.hospitalid)
            .then(this.NotifySuccess)
            .catch(this.NotifyError)
            .finally(() => {
              this.loading--
              this.getData()
            })
        })
        .catch(() => {
          this.getData()
        })
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss"></style>
