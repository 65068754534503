<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="refresh"
                  :hide-label="true"
                  :tooltip="$t('buttons.refresh.tooltip')"
                  :label="$t('buttons.refresh.title')"></lx-btn>
          <lx-btn :color="'#353a47'"
                  v-if="isPharmacy"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="!tableObj.selection || tableObj.selection.length === 0"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="dialog = true"
                  icon="mdi-clipboard-check"
                  :tooltip="$t('buttons.setorderstate.tooltip')"
                  :label="$t('buttons.setorderstate.title')"></lx-btn>
          <lx-btn :color="'#353a47'"
                  v-if="isController"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="!tableObj.selection || tableObj.selection.length === 0"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="approveBatch"
                  icon="mdi-check"
                  :tooltip="$t('buttons.order_batch_approve.tooltip')"
                  :label="$t('buttons.order_batch_approve.title')"></lx-btn>
        </div>
        <div class="flex layout-row"></div>

      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="pt-0 pb-2 px-1 layout-row">
        <div class="flex mr-2">
          <v-text-field
            :dark="isDarkMode"
            :color="compColor.primeColor"
            :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
            class="mt-2 mb-0"
            label="Suchbegriff(e) eingeben"
            hide-details
            outlined
            :dense="denseFields"
            prepend-inner-icon="mdi-magnify"
            clearable
            v-model="searchString"
          >
            <template v-slot:append>
              <v-tooltip
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" style="cursor: help">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <lx-query-tooltip :fields="searchFields"></lx-query-tooltip>
              </v-tooltip>
            </template>
          </v-text-field>
        </div>

        <div class="pt-2 layout-column">
          <div :class="{'grey': !isFilterActive && !isDarkMode,'transparent': !isFilterActive && isDarkMode, 'green': isFilterActive}"
               class="lighten-3 flex layout-row layout-align-center-center"
               :style="{ 'border-color': isDarkMode ? '#5c7c8a !important' : '#939393 !important' }"
               style="border: 1px solid !important;border-radius: 4px;">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div class="layout-row">
                  <v-btn
                    tile
                    elevation="0"
                    :dark="isDarkMode"
                    v-bind="attrs"
                    v-on="on"
                    style="width:200px;"
                    class="transparent blue-grey--text text-none layout-row"
                    :class="{'text--darken-2': !isDarkMode || isFilterActive, 'text--lighten-3': isDarkMode && !isFilterActive}"
                    :style="{height: denseFields ? '37px':'47px'}"
                  >
                    <v-icon
                      left
                      :dark="isDarkMode"
                      small
                      style="opacity:0.3;"
                    >
                      mdi-filter
                    </v-icon>
                    <div class="flex text-left">Filtern</div>
                    <v-icon
                      right
                      :dark="isDarkMode"
                      style="opacity:0.5;"
                    >
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                  <div v-if="isFilterActive" style="width:1px;background-color: #939393;"></div>

                  <v-tooltip bottom v-if="isFilterActive">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        tile
                        elevation="0"
                        :dark="isDarkMode"
                        :dense="denseFields"
                        :style="{height: denseFields ? '37px':'47px'}"
                        :class="{'text--darken-3': !isDarkMode, 'text--lighten-3': isDarkMode && !isFilterActive}"
                        @click="resetFilter"
                        class="transparent blue-grey--text"
                      >
                        <v-icon style="opacity:0.6;">mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span>Alle Filter löschen</span>
                  </v-tooltip>

                </div>
              </template>
              <v-list class="filter-list" v-bind:class="{'blue-grey darken-3': compColor.isInDarkMode}">
                <template>
                  <v-list-item v-for="(item, index) in availableStates" :key="index">
                    <v-list-item-action>
                      <v-switch
                        class="ml-1 mr-0 my-0"
                        hide-details
                        :dark="isDarkMode"
                        :color="isDarkMode ? compColor.primeHighlightColor : compColor.prime"
                        v-model="item.active"
                        @click.stop="getData" inset>
                      </v-switch>
                      <!--                      <v-simple-checkbox-->
                      <!--                        :ripple="false"-->
                      <!--                        class="ml-1 mr-0 my-0"-->
                      <!--                        hide-details-->
                      <!--                        :dark="compColor.isInDarkMode"-->
                      <!--                        :color="compColor.primeColor"-->
                      <!--                        :dense="denseFields"-->
                      <!--                        v-model="item.active"-->
                      <!--                        @click="defaultClick"-->
                      <!--                        @change="getData"-->
                      <!--                      >{{ item.name }}</v-simple-checkbox>-->
                    </v-list-item-action>
                    <v-list-item-title>
                      <div class="layout-row layout-align-center-center px-2"
                           :class="{'blue-grey--text text--darken-1': !isDarkMode,'white--text': isDarkMode}"
                           style="border-radius:3px;height:35px;border:1px solid rgba(0,0,0,0.2)"
                           :style="{'background': isDarkMode ? item.colorDark : item.color}">
                        {{ item.name }}
                      </div>
                      <!--                      {{ item.name }}-->
                    </v-list-item-title>
                  </v-list-item>
                  <!--                  <v-divider v-if="index < statusList.length - 1"></v-divider>-->
                </template>
              </v-list>
            </v-menu>
          </div>
        </div>

<!--        <fake-input-frame label="Nach Status filtern" style="margin-top:-2px;">-->
<!--          <div class="layout-row layout-align-start-center px-1 mt-n2 grey lighten-3" :style="{height: denseFields ? '37px':'47px'}">-->
<!--            <v-menu offset-y>-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                  <v-btn-->
<!--                      tile-->
<!--                      elevation="0"-->
<!--                      :dark="isDarkMode"-->
<!--                      v-bind="attrs"-->
<!--                      v-on="on"-->
<!--                      class="grey lighten-3"-->
<!--                  >-->
<!--                    <v-icon-->
<!--                        left-->
<!--                        :dark="isDarkMode"-->
<!--                        small-->
<!--                    >-->
<!--                      mdi-filter-->
<!--                    </v-icon>Filtern-->
<!--                  </v-btn>-->
<!--                <v-btn-->
<!--                  tile-->
<!--                  elevation="0"-->
<!--                  color="#ff7675"-->
<!--                  :dark="isDarkMode"-->
<!--                  :disabled="!isFilterActive"-->
<!--                  :dense="denseFields"-->
<!--                  @click="resetFilter"-->
<!--                >-->
<!--                  <v-icon>mdi-close</v-icon>-->
<!--                </v-btn>-->
<!--              </template>-->
<!--              <v-list class="filter-list">-->
<!--                <template v-for="(item, index) in statusList">-->
<!--                  <v-list-item>-->
<!--                    <v-list-item-action>-->
<!--                      <v-switch-->
<!--                        class="ml-1 mr-0 my-0"-->
<!--                        hide-details-->
<!--                        :dark="isDarkMode"-->
<!--                        :color="compColor.prime"-->
<!--                        v-model="item.active"-->
<!--                        @change.stop="getData"-->
<!--                        @click.stop inset>-->
<!--                      </v-switch>-->
<!--&lt;!&ndash;                      <v-simple-checkbox&ndash;&gt;-->
<!--&lt;!&ndash;                        :ripple="false"&ndash;&gt;-->
<!--&lt;!&ndash;                        class="ml-1 mr-0 my-0"&ndash;&gt;-->
<!--&lt;!&ndash;                        hide-details&ndash;&gt;-->
<!--&lt;!&ndash;                        :dark="compColor.isInDarkMode"&ndash;&gt;-->
<!--&lt;!&ndash;                        :color="compColor.primeColor"&ndash;&gt;-->
<!--&lt;!&ndash;                        :dense="denseFields"&ndash;&gt;-->
<!--&lt;!&ndash;                        v-model="item.active"&ndash;&gt;-->
<!--&lt;!&ndash;                        @click="defaultClick"&ndash;&gt;-->
<!--&lt;!&ndash;                        @change="getData"&ndash;&gt;-->
<!--&lt;!&ndash;                      >{{ item.name }}</v-simple-checkbox>&ndash;&gt;-->
<!--                    </v-list-item-action>-->
<!--                    <v-list-item-title>-->
<!--                      <div class="layout-row layout-align-center-center blue-grey&#45;&#45;text px-2"-->
<!--                           :class="{'text&#45;&#45;darken-3': isDarkMode}"-->
<!--                           style="border-radius:3px;height:31px;border:1px solid rgba(0,0,0,0.2)"-->
<!--                           :style="{'background': isDarkMode ? item.colorDark : item.color}">-->
<!--                        {{ item.name }}-->
<!--                      </div>-->
<!--&lt;!&ndash;                      {{ item.name }}&ndash;&gt;-->
<!--                    </v-list-item-title>-->
<!--                  </v-list-item>-->
<!--&lt;!&ndash;                  <v-divider v-if="index < statusList.length - 1"></v-divider>&ndash;&gt;-->
<!--                </template>-->
<!--              </v-list>-->
<!--            </v-menu>-->
<!--          </div>-->
<!--        </fake-input-frame>-->
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px;"
             :style="{'background': compColor.lxExpansion.background}">


          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1);" class="flex layout-column">
            <lx-data-table
              v-model="tableObj.selection"
              tableName="OrderList"
              :items="items"
              :headers="tableObj.headers"
              :loading="tableObj.loading"
              :tableOptions="tableObj.options"
              :hideContextFilter="true"
              @changeOptions="changeTableOptions"
              @changeLayout="changeTableLayout"
              @rowClicked="openListDetails"
              @openContextOnRowClick="openContextOnRowClick">

              <template v-slot:masterdetail="{ item }">
                <diagnoses-list :patientId="item._id"></diagnoses-list>
              </template>

              <template v-slot:cellrenderer="{ value }">

                <div v-if="value.f.renderer === 'order_numbers'" style="padding:1px;height:35px;">
                  <div class="layout-row layout-align-start-center" style="border-radius:3px;height:33px;" v-if="value.i['order_ref_number']">
                    {{ value.i[value.f.value] }}<strong class="mx-1" style="color: #d83b3b">/</strong>{{ value.i['order_ref_number'] }}
                  </div>
                  <div class="layout-row layout-align-start-center" style="border-radius:3px;height:33px;" v-else>
                    {{ getValueByObjectPath(value.i, value.f.value) }}
                  </div>
                </div>
                <div v-if="value.f.renderer === 'currency_ger'" style="padding:1px;height:35px;">
                  <div class="layout-row layout-align-end-center" style="border-radius:3px;height:33px;">
                    {{ getValueByObjectPath(value.i, value.f.value) | currency }}
                  </div>
                </div>
                <div v-if="value.f.renderer === 'order_state'" style="padding:2px;height:35px;">
                  <div class="layout-row layout-align-center-center"
                       :class="{'blue-grey--text text--darken-1': !isDarkMode,'white--text': isDarkMode}"
                       style="border-radius:3px;height:31px;"
                       :style="{'border': '1px solid ' + getStatusForCode(value.i.state).colorBorder, 'background': isDarkMode ? getStatusForCode(value.i.state).colorDark : getStatusForCode(value.i.state).color}">
                    {{ getStatusForCode(value.i.state).name }}
                  </div>
                </div>
              </template>
            </lx-data-table>
          </div>
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="layout-row pa-0">
        <lx-pagination :page-name="'orderList'"
                       :page-options="pagingOptions"
                       :page-change="getData">
        </lx-pagination>
      </div>

    </div>


    <lx-dialog v-model="dialog" :allow-drag="true" :overlay="true" :size="{x: '40', y: '30'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          Bestellungen - Status setzen
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <div class="flex layout-row pa-1">
            <ValidationObserver tag="div" ref="setStatusForm" class="layout-column flex">
              <!--              v-slot="{ valid, pristine, reset }"-->
              <validation-provider tag="div" ref="Status"
                                   class="flex dark-v-list"
                                   name="Status"
                                   v-slot="{ errors }">
                <v-select
                  :color="compColor.primeColor"
                  label="Status auswählen"
                  placeholder=" "
                  :dark="isDarkMode"
                  v-model="status"
                  :items="availableStates"
                  item-text="name"
                  item-value="code"
                  :error-messages="errors[0]"
                  :menu-props="{ bottom: true, offsetY: true }"
                  outlined
                  :dense="true"
                  item-disabled="disabled"
                  class="blue-grey--text"
                >
                  <template #item="{item}" :class="{'blue-grey darken-3': compColor.isInDarkMode}">
                    <div class="flex layout-row ma-1 pa-2" style="border-radius: 4px;"
                         :class="{'blue-grey--text text--darken-2' : !isDarkMode, 'white--text': isDarkMode}"
                         :style="{background: isDarkMode ? item.colorDark : item.color}">
                      <div class="flex">{{ item.name }}</div>
                      <div class="caption layout-row layout-align-end-end" style="opacity: 0.3;line-height: 12px;">
                        <small class="mr-1">code</small>{{ item.code }}
                      </div>
                    </div>
                  </template>
                </v-select>
                <!--                                    @change="changeArticleList()"-->
              </validation-provider>
            </ValidationObserver>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <!-- CREATE INVOICE BUTTON -->

          <!--          :disabled="!validator.v || validator.p"-->
          <v-btn :color="compColor.prime" class="flex white--text text-none mr-1"
                 dense depressed title="Speichern"
                 :disabled="!showSaveBtn"
                 @click.stop="processButtonResult('yes')">
            <v-icon left>save</v-icon>
            Status setzen
          </v-btn>
          <!-- CANCEL BUTTON -->
          <v-btn :dark="compColor.isInDarkMode" :color="compColor.prime" class="white--text text-none"
                 dense depressed title="Abbrechen" @click.stop="processButtonResult('no')">
            <v-icon left>cancel</v-icon>
            Abbrechen
          </v-btn>
        </div>
      </template>
    </lx-dialog>

    <!-- PRINT DIALOG -->
    <lx-dialog v-model="pdfDialog" :size="{x: '95', y: '95'}">
      <template #actions>
        <div class="layout-row layout-align-center-center px-1 pb-1">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  block
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click="pdfDialog = false"
                  icon="mdi-close"
                  tooltip="PDF Ansicht schließen"
                  :label="'Schließen'"></lx-btn>

        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-1 flex layout-column lx-delivery-background" :class="{'elevation-3': !loadingPDF}"
             style="border-radius: 5px;position: relative;"
             :style="{'background': loadingPDF ? 'transparent' : compColor.lxExpansion.background}">

          <lx-pdf-viewer :value="pdfObject" :loading="loadingPDF"></lx-pdf-viewer>

          <lx-loading-animation :loading="loadingPDF">
            <span class="mb-n3 pa-0 font-weight-light" style="font-size:1em">lade</span>
            <span class="mb-n3 pa-0 font-weight-bold" style="font-size:3.5em;">Druck</span>
            <span class="mt-n3 pa-0 font-weight-light" style="font-size:2.1em">Vorschau</span>
          </lx-loading-animation>

        </div>
      </template>
    </lx-dialog>

  </div>
</template>
<script>

import router from '../../router'
import LxPdfViewer from '../../components/lx_pdf_viewer/LxPdfViewer'
import LxLoadingAnimation from '../../components/lx_loading_animation/LxLoadingAnimation'
import {NumberInt} from '@/components/lx-query-builder/LxDataTypes'
import OrderStates from "@/components/common/order_states";
// import LxQueryTooltip from '../../lib/LxQueryTooltip'

export default {
  name: 'UserList',
  mixins: [OrderStates],
  components: {
    LxPdfViewer,
    LxLoadingAnimation
    // LxQueryTooltip
  },
  data() {
    const isController = this.hasRole('controller') === true

    return {
      pdfObject: null,
      loadingPDF: false,
      pdfDialog: false,

      searchString: null,
      searchDelayTimer: null,
      tableObj: {
        loading: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
        options: {
          sortBy: ['ordernr'],
          sortDesc: [true],
          getClassForItem: (row) => {
            if (row.item.controller_changes === true) {
              return this.isDarkMode ? 'yellow darken-2 blue-grey--text text--darken-3' : 'yellow lighten-4'
            }

              return ''
            }
          },
        filterValue: null,
        selection: null,
        headers: [
          {
            text: '',
            value: 'data-table-select',
            sticky: 'left',
            width: '30px',
            hideRowByKeyValue: 'reported',
            noResize: true,
            alternateText: 'Zeilenauswahl'
          },
          {
            text: 'Bestellnummer',
            value: 'ordernr',
            width: '160px',
            sortable: true,
            renderer: 'order_numbers'
          },
          {
            text: 'Medizinische Einrichtungen',
            value: 'medical_name',
            sortable: true
          },
          {
            text: 'Station',
            value: 'department_name',
            width: '200px',
            sortable: true
          },
          {
            text: 'Bestelldatum',
            value: 'timestamp',
            width: '90px',
            renderer: 'date'
          },
          {
            text: 'Lieferdatum',
            value: 'deliverydate',
            width: '90px',
            renderer: 'date'
          },
          {
            text: 'Status',
            value: 'state',
            width: '150px',
            sticky: 'right',
            class: 'text-center',
            sortable: true,
            renderer: 'order_state'
          },
          {
            text: 'Pos.',
            value: 'total_positions',
            width: '40px',
            sticky: 'right',
            class: 'text-center',
            bodyClass: 'text-center',
            sortable: false
          },
          {
            text: 'Summe',
            value: 'total_amount',
            width: '125px',
            sticky: 'right',
            class: 'text-right pr-4',
            sortable: false,
            renderer: 'currency_ger'
          },
        ]
      },
      items: [],
      isPharmacy: (this.getUserLocationType() === "pharmacy"),
      isController: isController,
      dialog: false,
      itemFromContext: null,
      isFilterActive: isController,
      status: null,
      allowedFields: ['ordernr', 'medical_name', 'department_name', 'timestamp', 'deliverydate', 'state', 'total_count', 'total_amount'],
      searchFields: [
        'medical_name',
        'department_name',
        'ordernr',
        'order_ref_number',
        { key: 'total_amount', keyword: 'preis', isNumber: true, default: true, type: Number, title: 'Gesamtpreis' },
        { key: 'timestamp', keyword: 'datum', type: Date, title: 'Bestelldatum' },
        // {key: 'deliverydate', keyword: 'ld', type: Date, title: 'Lieferdatum'},
        { key: 'total_positions', keyword: 'pos', type: NumberInt, title: 'Artikelpositionen' }
      ]
    }
  },
  mounted() {
    if (this.isPharmacy && typeof this.createCategory === "function" && typeof this.addEntryToMenu === "function") {
      this.createCategory('OrderListDataTable')
      this.addEntryToMenu({
        type: 'OrderListDataTable',
        value: [
          {
            icon: true,
            iconName: 'mdi-clipboard-check',
            title: 'Bestellstatus setzen',
            action: this.openOrderStatusByContext
          },
          {
            icon: true,
            iconName: 'mdi-printer',
            title: 'Bestellschein drucken',
            action: this.printOrderAsPDF
          }
        ]
      })
    }
  },
  created() {
    /** ein Krankenhaus soll keine Zeile selektieren dürfen deshalb einfach ersten Header aus dem Array shiften **/
    if (!this.isPharmacy && !this.isController) {
      this.tableObj.headers.shift()
    }

    /** Apotheke kann festelgen welche Felder ein oder ausgescchaltet werden sollen **/
    this.tableObj.headers = this.tableObj.headers.filter((item) => {
      if (item.field && this.allowedFields.includes(item.field)) {
        return item
      } else if (!item.field) {
        return item
      }
    })


    let opt = this.$store.getters.pagingOptions;
    if (opt && opt.limit) {
      this.pagingOptions = this.$store.getters.pagingOptions
    }
  },
  computed: {
    showSaveBtn() {
      if (this.status) {
        let test = this.$refs['setStatusForm']
        return !test.flags.pristine && test.flags.valid
      }
      return false
    }
  },
  watch: {
    statusList: {
      handler() {
        this.getData()
      },
      deep: true
    },
    searchString() {
      clearInterval(this.searchDelayTimer);

      this.searchDelayTimer = setInterval(() => {
        clearInterval(this.searchDelayTimer);
        this.getData()
      }, 750)
    }
  },
  methods: {
    printOrderAsPDF(value) {
      if (value && value._id) {
        this.loadingPDF = true;
        this.pdfDialog = true;
        this.$http.getAsBlob('/order/generatepdf/' + value._id)
          .then((res) => {
            if (res.data instanceof Blob) {
              this.pdfObject = res.data
            }
          })
          .catch(this.NotifyError)
          .finally(() => {
            this.loadingPDF = false;
          })
      }
    },

    openContextOnRowClick(event, item) {
      if (this.isPharmacy && item) {
        this.$lxContext.open(event, 'OrderListDataTable', item, this.isLxContextDisabled)
      }
    },

    processButtonResult(btnResult) {
      this.btnResult = btnResult
      if (btnResult === 'no') {
        this.status = null
        this.dialog = false
      } else {
        this.setOrderStatus()
      }
    },

    /**
     *
     * @param obj The complete object
     * @param path The path as dot notation to the field value (e.g. 'address.street.number')
     * @returns {*}
     */
    getValueByObjectPath(obj, path) {
      if (path.indexOf('.') <= 0) {
        return obj[path]
      }

      const objectPath = path.split('.')
      for (let i = 0; i < objectPath.length; i++) {
        if (!obj[objectPath[i]]) {
          return;
        }
        obj = obj[objectPath[i]]
      }
      return obj
    },

    /**
     * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
     */
    changeTableLayout() {
      // changeTableLayout(value) {
      //console.log('changeTableLayout: ', value)

    },
    /**
     * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
     */
    changeTableOptions(value) {
      if (value) {
        this.tableObj.options = value
      }
      this.getData()
    },

    openOrderStatusByContext(value) {
      this.itemFromContext = value
      this.dialog = true
    },

    setOrderStatus() {
      this.dialog = false;
      let ids = [];
      if (this.itemFromContext && this.itemFromContext._id) {
        ids.push(this.itemFromContext._id)
        this.itemFromContext = null
      } else {
        let count = this.tableObj.selection //this.gridApi.selectionController.selectedNodes;
        Object.keys(count).map(function (key) {
          if (count[key] && count[key].data && count[key].data._id) {
            ids.push(count[key].data._id)
          }
        });

        count.forEach((item) => {
          if (item?._id) {
            ids.push(item._id)
          }
        })
      }
      if (ids.length > 0 && this.status) {

        this.$http.patch('/orders/update', { ids: ids, status: this.status })
          .then(() => {
            this.getData();
            this.status = null
            this.NotifySystem("Status erfolgreich geändert.");
          })
          .catch(this.NotifyError)
      }
    },

    openListDetails(params) {
      if (params?._id) {
        const roles = this.getRoles()
        const showControllerView = params?.state === 8 && Array.isArray(roles) && roles.indexOf('controller') !== -1

        if (showControllerView) {
          router.push({ path: `/orders/${params._id}/check` })
        } else {
          router.push({ path: `/orders/${params._id}/details` })
        }

      }
    },

    /**
     * Wird verschiedenen Stellen aus aufgerufen und soll die Daten vom Backend abholen. Mittels axios....bla bla bla
     * Besonderheit:
     * "this.pagingOptions.count = res.data.count"
     * - Anzahl der Datensätze vom Server wird auch in den pagingOptions gespeichert
     *
     * "this.$store.dispatch('setPagingOptions', this.pagingOptions)"
     * - nach der Speicherung der Anzahl werden die pagingOptions wieder in den Store geschrieben
     *
     * "this.gridOptions.api.ensureIndexVisible(this.$store.getters.gridScrollIndex, 'top')"
     * - sorgt dafür das nach dem neuladen der Daten das Grid wieder an die Stelle scrollt wo es war. notwendig z.B. bei der Rückkehr zum Grid
     * **/
    getData(options) {
      if (this.tableObj.loading) return

      this.tableObj.loading = true

      if (options && typeof options.skip === 'number') {
        this.pagingOptions.skip = options.skip
      }

      if (options && options.limit) {
        this.pagingOptions.limit = options.limit
      }


      let query = {}

      let statesToFilter = this.statusList.filter(v => v.active).map(v => v.code)
      this.isFilterActive = (statesToFilter.length > 0) && (statesToFilter.length < this.statusList.length)

      // Add state filter ONLY if one or more selected; but NOT *ALL*
      if (this.isFilterActive) {
        query = { $or: [{ 'state': { '$in': statesToFilter } }, { 'state': { '$exists': false } }] }
      }

      const params = this.$helper.GetQueryParam({
        ...this.tableObj.options,
        skip: this.pagingOptions.skip,
        limit: this.pagingOptions.limit,
        query: query,
        count: true,
        searchString: this.searchString,
        searchFields: this.searchFields
      })

      this.$http.get('/orders', params)
        .then(res => {
          if (res.data) {
            if (res.data) {
              this.items = res.data.result || []
              this.pagingOptions.count = res.data.count || 0
            }
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.tableObj.loading = false
        })
    },
    defaultClick (e) {
      e.stopPropagation()
    },
    resetFilter () {
      const l = this.statusList.length

      for (let i = 0; i < l; i++) {
        this.statusList[i].active = false
      }

      this.getData()
    },

    approveBatch () {
      const selectedItemCount = this.tableObj.selection.length
      const items = this.tableObj.selection.filter((e) => e.state === 8).map((e) => e._id)
      const itemCount = items.length
      const bHasInvalidItems = (itemCount !== selectedItemCount)

      if (itemCount === 0) {
        return this.NotifySystem({
          title: "Misslungen",
          text: "Die gewählten Elemente können nicht freigegeben werden",
          type: "warning",
          duration: -1
        })
      }

      const dialogMessage = (bHasInvalidItems) ? `${selectedItemCount - itemCount} ausgewählte Bestellung(en) können nicht freigeben werden` : ''

      this.DialogConfirm(
          'Freigabe von Bestellungen',
          'Möchten Sie den ausgewählten Bestellungen wirklich freigeben?\n' + dialogMessage,
          {ok: 'Freigeben', cancel: this.$t('buttons.cancel.title')}
      )
          .then(() => {
            async function approveOrder(self, orderid) {
              let data = {order_id: orderid}
              return await self.$http.post('/orders/approve', data)
            }

            this.tableObj.loading = true

            let success = 0
            let failed = 0

            items.forEach(async orderid => {
              let response = await approveOrder(this, orderid);

              success += Number(response.status === 200)
              failed += Number(response.status !== 200)
            })

            // Trick to hold refresh
            setTimeout(() => {
              const successed = failed === 0

              this.tableObj.loading = false

              this.NotifySystem({
                title: (successed) ? "Erfolgreich" : "Misslungen",
                text: `Es wurde(n) ${success} von ${success + failed} Bestellung(en) freigegeben` ,
                type:  (successed) ? "success" : "warning",
                duration: (successed) ? 10000 : -1
              })

              this.getData()
            }, 250 * items.length)
          })
    }
  },
}
</script>
<!-- Add 'scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
