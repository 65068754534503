//* eslint no-console: ["error", { allow: ["warn", "error", "info"] }] */
import axios from 'axios'
import JSUtils from './bsonutils'

const TRANSPORT_CONTENTTYPE_TEXT = 'text/plain';
const TRANSPORT_CONTENTTYPE_JSON = 'application/json';
const TRANSPORT_CONTENTTYPE_BSON = 'application/x-bson';

let defTransportContentType = TRANSPORT_CONTENTTYPE_JSON;
let verbosity = false;

const fnTransformRequest = function (data, headers) {
  let contentType = (headers || {})['Content-Type'];

  if (!data) {
    console.info('No data to transform');
    return null
  }

  try {
    switch (contentType) {
      case TRANSPORT_CONTENTTYPE_BSON:
        return JSUtils.serialize(data, { promoteValues: false });

      case TRANSPORT_CONTENTTYPE_JSON:
        return JSON.stringify(data);

      default:
        return data.toString()
    }
  } catch (e) {
    console.error(e);
    return null
  }
};

const bsonDeserializeExtended = function (data) {
  // If no data or length is 0. Then exist
  if (!data || data.length === 0) {
    return
  }

  if (data.length < 6) {
    console.error('bson size must be >= 5, is', (data || []).length);
    return null
  }

  let objIndex = 0;
  let objLen;

  objLen = (data[objIndex + 3] << 24) + (data[objIndex + 2] << 16) + (data[objIndex + 1] << 8) + data[objIndex];

  if (objLen > data.length) {
    console.error('bson size expected', objLen, 'given', data.length);
    return null;
  }

  // Single bson object
  if (objLen === data.length) {
    // let result = JSUtils.deserialize(data, { promoteValues: false })

    // if (Array.isArray(result)) {
    //   return result
    // }

    // return [result]
    return JSUtils.deserialize(data, { promoteValues: false });
  }

  // Multiple bson objects
  // First 4 Bytes are bson object length
  if (objLen < data.length) {
    let result = [];
    let single;

    while (objIndex < data.length) {
      // Build Length Value UINT32
      objLen = (data[objIndex + 3] << 24) + (data[objIndex + 2] << 16) + (data[objIndex + 1] << 8) + data[objIndex];
      single = data.slice(objIndex, objIndex + objLen);

      result.push(
        JSUtils.deserialize(
          single, { promoteValues: false }
        )
      );

      objIndex += objLen
    }

    return result
  }
};

const fnTransformResponse = function (data, headers) {
  let header = (headers || {});
  let contentTypeRegEx = (header['Content-Type'] || header['content-type'] || '').match(/(application|text)\/(json|x-bson|plain)/i);
  let contentType = (contentTypeRegEx && contentTypeRegEx.length > 0) ? contentTypeRegEx[0].toString() : '';

  if (data instanceof ArrayBuffer) {
    // If no date (byteLength is zero) then return null
    if (data.byteLength === 0) {
      return null
    }

    try {
      switch (contentType) {
        case TRANSPORT_CONTENTTYPE_BSON:
          return bsonDeserializeExtended(Buffer.from(data));

        case TRANSPORT_CONTENTTYPE_JSON:
          return JSON.parse(Buffer.from(data).toString());

        case TRANSPORT_CONTENTTYPE_TEXT:
          return Buffer.from(data).toString();

        default:
          return data;
          // console.warn('Unknow Content Type "', contentType, '" to serialize, send as string');
          // return Buffer.from(data).toString()
      }
    } catch (e) {
      console.error(e);
      return null
    }
  }

  console.error('Unknow data structure', data, 'with content type', contentType);
  return data
};

let transport = {
  post: function httpPost(url, data, contentType) {
    contentType = contentType || defTransportContentType; // default

    return axios.request({
      method: 'post',
      url: url,
      data: data,
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': contentType
      },
      transformRequest: fnTransformRequest,
      transformResponse: fnTransformResponse
    })
  },
  get: function httpGet(url, params) {
    return axios.request({
      method: 'get',
      url: url,
      params: params,
      responseType: 'arraybuffer',
      transformResponse: fnTransformResponse
    })
  },
  put: function httpPut(url, data, contentType) {
    contentType = contentType || defTransportContentType; // default

    return axios.request({
      method: 'put',
      url: url,
      data: data,
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': contentType
      },
      transformRequest: fnTransformRequest,
      transformResponse: fnTransformResponse
    })
  },
  patch: function httpPatch(url, data, contentType) {
    contentType = contentType || defTransportContentType; // default

    return axios.request({
      method: 'patch',
      url: url,
      data: data,
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': contentType
      },
      transformRequest: fnTransformRequest,
      transformResponse: fnTransformResponse
    })
  },
  delete: function httpDelete(url, params) {
    return axios.request({
      method: 'delete',
      url: url,
      params: params,
      responseType: 'arraybuffer',
      transformResponse: fnTransformResponse
    })
  },
  postBson: function postBson(url, data) {
    return this.post(url, data, TRANSPORT_CONTENTTYPE_BSON);
  },
  postJson: function postJson(url, data) {
    return this.post(url, data, TRANSPORT_CONTENTTYPE_JSON);
  },
  putBson: function putBson(url, data) {
    return this.put(url, data, TRANSPORT_CONTENTTYPE_BSON);
  },
  putJson: function putJson(url, data) {
    return this.put(url, data, TRANSPORT_CONTENTTYPE_JSON);
  },
  patchBson: function patchBson(url, data) {
    return this.patch(url, data, TRANSPORT_CONTENTTYPE_BSON);
  },
  patchJson: function patchJson(url, data) {
    return this.patch(url, data, TRANSPORT_CONTENTTYPE_JSON);
  },
  getAsBlob: function httpGetAsBlob(url, params) {
    return axios.request({
      method: 'get',
      url: url,
      params: params,
      responseType: 'blob'
    })
  },
};

// export default transport
export default {
  install: function (Vue, options) {
    options = options || {};
    let propertyKey = options.propertyKey || '$transport';
    defTransportContentType = options.forceBsonTransport === true ? TRANSPORT_CONTENTTYPE_BSON : TRANSPORT_CONTENTTYPE_JSON;

    if (!options.quiet) {
      console.log('Install Transport');
      console.log('Default Content-Type is >>', defTransportContentType, '<<');
    }

    verbosity = options.verbosity || verbosity;

    Object.defineProperty(Vue.prototype, propertyKey, { value: transport })
  }
}
