const MESSAGING_SET_MESSAGES = 'MESSAGING_SET_MESSAGES';
const MESSAGING_ADD_MESSAGES = 'MESSAGING_ADD_MESSAGES';
const MESSAGING_DEL_MESSAGES = 'MESSAGING_DEL_MESSAGES';
const MESSAGING_CHECK_MESSAGES = 'MESSAGING_CHECK_MESSAGES';
const MESSAGING_CLEAR_MESSAGES = 'MESSAGING_CLEAR_MESSAGES';
const MESSAGING_VISIBLE_SIDEBAR = 'MESSAGING_VISIBLE_SIDEBAR';
const MESSAGING_VISIBLE_SETTINGS = 'MESSAGING_VISIBLE_SETTINGS';
const MESSAGING_NOTIFICATION_PERMISSION = 'MESSAGING_NOTIFICATION_PERMISSION';
const MESSAGING_ENABLED = 'MESSAGING_ENABLED';
const RESET_NEW_MESSAGES = 'RESET_NEW_MESSAGES';

// const LOCAL_STORE = 'clinicshop-frontend'

let state = {
  messages: [],
  total: 0,
  unread: 0,
  listeners: {},
  sidebarVisible: false,
  settingsVisible: false,
  permissionState: null,
  enabled: true,
  announcement: false,
  newmsg: false,
};

function SortAndClean(input) {
  const now = new Date();

  for (let index = 0; index < input.length; index++) {
    if (input[index].expiry && input[index].expiry < now) {
      input.splice(index, 1);
    }
  }

  input.sort((left, right) => {
    const a = Number(left.type === 'system' || left.flag_pinned === true);
    const b = Number(right.type === 'system' || right.flag_pinned === true);

    return b - a;
  });

  return input
}

const mutations = {
  [MESSAGING_SET_MESSAGES](state, payload) {
    let unreadCounter = 0;
    state.announcement = false

    if (Array.isArray(payload)) {
      payload.forEach((message) => {
        if (typeof message.timestamp === 'string') {
          message.timestamp = new Date(message.timestamp);
        }

        if (typeof message.expiry === 'string') {
          message.expiry = new Date(message.expiry);
        }

        if (message.sender === 'system') {
          state.announcement = (message.confirmed !== true) || state.announcement
        }

        if (message && message.confirmed !== true) {
          unreadCounter++
        }

        // console.log(message)
      });

      state.messages = SortAndClean(payload);
      state.total = Array.isArray(state.messages) ? state.messages.length : 0;
      state.unread = unreadCounter;

      state.newmsg = unreadCounter > 0
    }
  },
  [MESSAGING_ADD_MESSAGES](state, payload) {
    let unreadCounter = 0;

    if (Array.isArray(payload)) {
      state.announcement = false

      payload.forEach((message) => {
        if (typeof message.timestamp === 'string') {
          message.timestamp = new Date(message.timestamp);
        }

        if (typeof message.expiry === 'string') {
          message.expiry = new Date(message.expiry);
        }

        if (message && message.confirmed !== true) {
          unreadCounter++
        }

        if (message.sender === 'system') {
          state.announcement = (message.confirmed !== true) || state.announcement
        }
      });

      state.messages = SortAndClean([...payload, ...state.messages]);
      state.total = Array.isArray(state.messages) ? state.messages.length : 0;
      state.unread += unreadCounter;

      state.newmsg = unreadCounter > 0

    }
  },
  [MESSAGING_DEL_MESSAGES](state, payload) {
      for (let index = 0; index < state.messages.length; index++) {
        if (state.messages[index]._id === payload) {
          state.unread--;
          state.messages.splice(index, 1);
          return
        }
      }
  },
  [MESSAGING_CHECK_MESSAGES](state) {
    state.messages = SortAndClean(state.messages);
  },
  [MESSAGING_CLEAR_MESSAGES](state, payload) {
    if (!isNaN(payload)) {
      state.messages = state.messages.filter((e) => (e.sender === 'system' || Math.floor(e.timestamp / 1000) > payload))
    } else {
      state.messages = [];
    }

    state.total = 0;
    state.unread = 0;
  },
  [MESSAGING_VISIBLE_SIDEBAR](state, payload) {
    state.sidebarVisible = payload;
    state.announcement = false;
  },
  [MESSAGING_VISIBLE_SETTINGS](state, payload) {
    state.settingsVisible = payload;
  },
  [MESSAGING_NOTIFICATION_PERMISSION](state, payload) {
    state.permissionState = payload;
  },
  [MESSAGING_ENABLED](state, payload) {
    state.enabled = payload;
  },
  [RESET_NEW_MESSAGES](state) {
    state.newmsg = false;
  },
};

const actions = {
  setMessages({commit}, payload) {
    commit(MESSAGING_SET_MESSAGES, payload)
  },
  pushMessages({commit}, payload) {
    commit(MESSAGING_ADD_MESSAGES, payload)
  },
  removeMessage({commit}, payload) {
    commit(MESSAGING_DEL_MESSAGES, payload)
  },
  setNotificationPermission({commit}, payload) {
    commit(MESSAGING_NOTIFICATION_PERMISSION, payload)
  },
  setSideBarVisibility({commit}, payload) {
    commit(MESSAGING_VISIBLE_SIDEBAR, payload)
  },
  setSettingsVisibility({commit}, payload) {
    commit(MESSAGING_VISIBLE_SETTINGS, payload)
  },
  setMessagingEnabled({commit}, payload) {
    commit(MESSAGING_ENABLED, payload)
  },
  checkMessages({commit}) {
    commit(MESSAGING_CHECK_MESSAGES)
  },
  clearMessages({commit}) {
    commit(MESSAGING_CLEAR_MESSAGES)
  },
  clearMessagesWithTimstamp({commit}, payload) {
    commit(MESSAGING_CLEAR_MESSAGES, payload)
  },
  resetNewMessages({commit}) {
    commit(RESET_NEW_MESSAGES)
  }
};

const getters = {
  getMessages: state => {
    return state.messages
  },
  getMessagesCount: state => {
    return state.total;
  },
  getHasSystemMessages: state => {
    return state.announcement;
  },
  getMessagesUnreadCount: state => {
    return state.unread;
  },
  getSideBarVisibility: state => {
    return state.sidebarVisible;
  },
  getSettingsVisibility: state => {
    return state.settingsVisible;
  },
  getNotificationPermission: state => {
    return state.permissionState;
  },
  getMessagingEnabled: state => {
    return state.enabled;
  },
  hasNewMessages: state => {
    return state.newmsg;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
}
