<template>
  <div>
    <component
      v-if="!loading && tokenValid && !tokenExpired"
      v-bind:is="component"
      :company="invitecompany"
      :email="email"
      :token="token"
      :redirect="redirect"
    ></component>

    <div class="flex layout-column" style="max-width: 700px;min-height: 250px;" v-else>
      <div class="layout-column flex">
        <!--            <v-divider class="mx-5"></v-divider>-->
        <div class="px-10 pt-5 flex" style="overflow-y: auto;" v-if="!loading">
          <v-alert
            v-if="!tokenValid"
            border="left"
            prominent
            color="red"
            style="font-size:15px;"
            class="px-5 py-3 mt-2 red white--text"
          >
            <!--                            v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}"-->
            <template slot="prepend">
              <v-avatar>
                <v-icon large>mdi-information-variant</v-icon>
              </v-avatar>
            </template>
            <!--                        <v-alert type="error" border="left" class="ma-0 elevation-3" v-if="!tokenValid">-->
            Dies ist kein gültiger Registrierungsschlüssel!
          </v-alert>
          <v-alert
            v-if="!tokenValid"
            border="left"
            prominent
            style="font-size:15px;"
            class="pa-3 mt-2 mb-0 pl-7"
            v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">

            <div>Der Registrierungsschlüssel befindet sich am Ende des aufgerufenen Links, nach dem letzten Backslash.
              Er
              sollte eine Länge von <strong class="green--text text--darken-1 text-decoration-underline">40
                Zeichen</strong> aufweisen und ausschließlich aus Zahlen und Buchstaben
              bestehen.
            </div>

            <div style="background: #acdcf2;border-radius: 2px;" class="py-2 px-2 my-3 layout-column">
              <div class="mb-1 text-decoration-underline"><small>Korrektes Beispiel:</small></div>
              <small>https://medishop.lxcloud.de/auth/register/<strong
                class="green--text text--darken-1 text-decoration-underline">de46bb6b8b9753635947530e1553a4oedf83e78f</strong></small>
            </div>
            <div style="background: #acdcf2;border-radius: 2px;" class="py-2 px-2 my-3 layout-column">
              <div class="mb-1 text-decoration-underline"><small>Ihr Registrierungslink:</small></div>
              <small>https://medishop.lxcloud.de/auth/register/<strong
                class="red--text text--darken-1 text-decoration-underline">{{ token }}</strong></small>
            </div>
            <div>Folgende Ursachen können dazu führen:</div>
            <ul class="mt-2 mb-5">
              <li>
                <div class="layout-column">
                  <strong>dieser Link wurde manuell im Browser eingefügt, zuvor aber nur teilweise in der E-Mail
                    markiert und damit unvollständig kopiert</strong>
                  <!--                                        <small class="blue-grey&#45;&#45;text text&#45;&#45;lighten-1">dabei kann es häufig zum zu kurzen markieren und kopieren des Links kommen</small>-->
                </div>
              </li>
            </ul>
            <v-divider class="mb-3"></v-divider>
            <strong>Sollten Sie weiterhin Probleme bei der Registrierung haben oder Fragen,
              wenden Sie sich bitte an Ihren Krankenhaus-Administrator oder einen Ansprechpartner aus
              Ihrer beliefernden Apotheke.</strong>
          </v-alert>
          <v-alert
            v-if="tokenExpired"
            border="left"
            prominent
            style="font-size:15px;"
            class="pa-3 pr-6 mt-2 mb-0"
            v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
            <div class="layout-column pl-4">
              <div><strong>Der von Ihnen übermittelte Registrierungsschlüssel ist <span
                class="text-decoration-underline">ungültig</span> oder <span class="text-decoration-underline">abgelaufen</span>!
                Dieser Schlüssel entspricht den letzten <span
                  class="green--text text--darken-1 text-decoration-underline">40 Zeichen</span> des aufgerufenen Links.</strong>
              </div>
              <div style="background: #acdcf2;border-radius: 2px;" class="py-2 px-2 my-1 mx-n1">
                <div class="mb-1 text-decoration-underline"><small>Ihr Registrierungslink:</small></div>
                <small>https://medishop.lxcloud.de/auth/register/<strong
                  class="green--text text--darken-1 text-decoration-underline">{{ token ? token : 'de46bb6b8b9753635947530e1553a4oedf83e78f' }}</strong></small>
              </div>

            </div>
            <div class="layout-column pl-4 mt-2">
              <!--                                <div>Der von Ihnen übermittelte Registrierungstoken ist <strong>ungültig</strong> oder <strong>abgelaufen</strong>!</div>-->
              <!--                                <div style="background: rgba(255,255,255,0.3);border-radius: 5px;" class="py-2 px-2 my-2 mx-0">-->
              <!--                                    <small>https://medishop.lxcloud.de/auth/register/<strong class="green--text text--darken-2">de46bb6b8b9753635947530e1553a4oedf83e78f</strong></small>-->
              <!--                                </div>-->
              <div>Folgende Ursachen können dazu führen:</div>
              <ul class="mt-2">
                <li>
                  <div class="layout-column">
                    <strong>die Zeit wurde überschritten in der eine Registrierung möglich ist</strong>
                    <small class="blue-grey--text text--lighten-1">Der Registrierungsschlüssel ist nur 7 Tage gültig um
                      Missbrauch zu verhindern.</small>
                  </div>
                </li>
                <li>
                  <div class="layout-column">
                    <strong>eine Registrierung wurde bereits durchgeführt</strong>
                    <small class="blue-grey--text text--lighten-1">Beim verwenden von gemeinsamen Stationszugängen
                      sprechen Sie
                      bitte mit Ihren Kollegen ob einer von Ihnen bereits die Registrierung
                      durchgeführt hat.</small>
                  </div>
                </li>
                <li>
                  <div class="layout-column">
                    <strong>die Einladung wurde widerrufen</strong>
                    <small class="blue-grey--text text--lighten-1">Der Administrator hat die Einladung
                      zurückgezogen.</small>
                  </div>
                </li>
              </ul>
              <v-divider class="my-3"></v-divider>
              <strong>Sollten Sie weiterhin Probleme bei der Registrierung haben oder Fragen,
                wenden Sie sich bitte an Ihren Krankenhaus-Administrator oder einen Ansprechpartner aus
                Ihrer beliefernden Apotheke.</strong>
              <!--                                <v-divider class="my-3"></v-divider>-->
              <!--                                <div>-->
              <!--                                    <strong>Sollten Sie weiterhin Probleme bei der Registrierung haben oder Fragen,-->
              <!--                                        wenden Sie sich bitte an Ihren Krankenhaus-Administrator oder einen-->
              <!--                                        Ansprechpartner aus Ihrer beliefernden Apotheke.</strong>-->
              <!--                                </div>-->
            </div>
          </v-alert>
          <v-alert
            v-if="tokenExpired"
            border="left"
            prominent
            color="red"
            style="font-size:15px;"
            class="px-5 py-3 mt-2 red white--text"
          >
            <!--                            v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}"-->
            <template slot="prepend">
              <v-avatar>
                <v-icon large>mdi-information-variant</v-icon>
              </v-avatar>
            </template>
            <span style="line-height: 12px;">Sollten Sie sich bereits erfolgreich registriert haben und wollen sich nur einloggen, so nutzen Sie dafür bitte die folgende Adresse oder klicken den unten stehenden Button:<br><strong
              class="my-2" style="display: block;"><a class="white--text"
                                                      href="/auth/login">https://medishop.lxcloud.de</a></strong></span>
          </v-alert>
          <!--                </div>-->
          <!--                    </v-col>-->
          <!--                </v-row>-->
        </div>
        <div class="pa-2 d-flex flex-row" v-else>
          <div class="grow mr-2 text-center">
            <v-progress-circular
              :size="70"
              :width="7"
              color="red"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>

        <v-divider class="mx-5"></v-divider>

        <div class="pa-2" v-if="!loading">
          <!--                <div class="grow mr-2 yellow">-->
          <!--                    <div class="grow mr-2 ">-->
          <v-btn @click="$router.push('/auth/login')" block class="text-none green white--text">
            Zum Login
          </v-btn>
          <!--                    </div>-->
          <!--                </div>-->
        </div>
      </div>
    </div>


    <v-dialog
      persistent
      v-model="redirectDialog"
      overlay-opacity="0.99"
      max-width="600"
      :dark="compColor.isInDarkMode"
      :color="compColor.primeColor"
      style="overflow:hidden;z-index: 9999 !important;"
    >
      <div class="elevation-10 layout-column" style="border-radius: 6px;"
           v-bind:style="{background: compColor.contentFrame}">
        <!-- HEADER -->

        <!-- CONTENT (default slot) -->
        <div class="ma-1 px-7 py-5 layout-row elevation-3" :style="{'background': compColor.lxExpansion.background}">
          <div class=" pa-6 layout-row">
            <div class="layout-column layout-align-center-center px-5">
              <v-progress-circular
                :size="80"
                :width="5"
                :color="compColor.primeHighlightColor"
                indeterminate
              ></v-progress-circular>
            </div>
            <div class="ml-5 layout-column blue-grey--text text--darken-2">
              <span class="pa-0 font-weight-light" style="font-size:1.2em"><span class="font-weight-bold">Es dauert ein wenig</span></span>
              <span class="pa-0 font-weight-light" style="font-size:1.2em">Sie werden nun zur Benutzeranmeldung weitergeleitet</span>
              <!--            <span class="pa-0 font-weight-light" style="font-size:1.2em"><span class="font-weight-bold">automatisch</span> neugeladen</span>-->
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import RegistrationNewUserComponent from '@/components/lx_auth/components/RegistrationNewUserComponent';
import RegistrationExistsUserComponent from '@/components/lx_auth/components/RegistrationExistsUserComponent';
import RegistrationJoinUserComponent from '@/components/lx_auth/components/RegistrationJoinUserComponent';
import router from "@/router";

export default Vue.component('RegisterFromTokenComponent', {
  props: {
    token: {
      type: String,
      default: function () {
        return false;
      },
      // validator: function (token) {
      //   return token.match(/\b[0-9a-f]{40}\b/i) != null;
      // }
    },
  },
  components: {
    RegistrationNewUserComponent,
    RegistrationJoinUserComponent,
    RegistrationExistsUserComponent
  },
  data() {
    return {
      loading: false,
      serveremailsent: false,
      servererror: false,
      email: '',
      tokenValid: false,
      tokenExpired: false,
      invitecompany: "",
      component: null,
      redirectDialog: false
    }
  },
  created() {
    this.tokenValid = (this.token.match(/\b[0-9a-f]{40}\b/i) != null);
    this.getTokenData();
  },
  methods: {
    getTokenData() {
      // No backend communication if no valid token format
      if (!this.tokenValid || this.loading) {
        return
      }

      this.loading = true;
      this.component = null;
      this.tokenExpired = true;

      this.$http.get('/users/invite/' + this.token)
        .then((result) => {
          const isJoin = result.data.action === 'joined';
          const isNewUser = result.data.registered === false && !isJoin
          this.invitecompany = result.data.name;
          this.email = result.data.email;
          this.tokenExpired = false;

          this.component = RegistrationExistsUserComponent;
          if (isJoin === true) this.component = RegistrationJoinUserComponent;
          if (isNewUser === true) this.component = RegistrationNewUserComponent;
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading = false;
        })
    },
    redirect() {
      this.redirectDialog = true

      setTimeout(() => {
        this.redirectDialog = false
        router.push({ path: '/' }).catch(() => {
        })
      }, 2500)
    }
  }
})
</script>
