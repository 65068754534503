<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">

        <div class="layout-row">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="refresh"
                  :hide-label="true"
                  tooltip="Ansicht aktualisieren"
                  :label="'Aktualisieren'"></lx-btn>
        </div>

        <div class="flex layout-row"></div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="pt-0 pb-2 px-1 layout-row">
        <v-text-field
          :dark="isDarkMode"
          :color="compColor.primeColor"
          :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
          class="mt-2 mb-0"
          label="Suchbegriff(e) eingeben"
          hide-details
          outlined
          :dense="denseFields"
          prepend-inner-icon="mdi-magnify"
          clearable
          v-model="searchString"
        ></v-text-field>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px;"
             :style="{'background': compColor.lxExpansion.background}">

          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1);" class="flex layout-column">

            <lx-data-table
              v-model="tableObj.selection"
              tableName="AbdaEditorList"
              :items="items"
              :headers="tableObj.headers"
              :loading="tableObj.loading"
              :tableOptions="tableObj.options"
              :hideContextFilter="true"
              @changeOptions="changeTableOptions"
              @rowClicked="openEditorDetails">

              <template v-slot:cellrenderer="{ value }">
                <div v-if="value.f.renderer === 'icon'" style="padding:1px;height:35px;" class="text-center">
                  <v-icon v-if="value.i[value.f.value]" :color="compColor.prime">mdi-check</v-icon>
                </div>

                <div v-if="value.f.renderer === 'delete'" style="padding:2px;height:35px;">
                  <lx-btn :color="'#353a47'"
                          :icon-color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                          :disabled="tableObj.loading"
                          iconSize="22"
                          height="30"
                          width="auto"
                          as-text
                          as-icon
                          classes="text-none mr-1"
                          @click.stop="askForDeletion(value.i)"
                          icon="mdi-delete-forever"
                          :hide-label="true"
                          tooltip="Daten neu abholen um die Ansicht zu aktualisieren"
                          :label="'Aktualisieren'"></lx-btn>
                  <!--                  <v-icon :color="compColor.prime" @click.stop="askForDeletion(value.i)">mdi-delete-forever</v-icon>-->
                </div>

                <div v-if="value.f.renderer === 'clickToActivate'" style="padding:1px;height:35px;line-height:35px;">
                  <div class="layout-row layout-align-end-center" style="border-radius:3px;height:33px;">
                    <v-btn
                        v-if="!value.i[value.f.value]"
                        :color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                        block
                        outlined
                        small
                        text
                        @click.stop="countArticles(value.i._id, value.i.internalIndex)"
                    >{{ $t('buttons.countarticles.title') }}</v-btn>
                    <span v-else>{{ value.i[value.f.value] }}</span>
                  </div>
                </div>
              </template>

            </lx-data-table>
          </div>
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="layout-row pa-0">
        <lx-pagination :page-name="'orderList'"
                       :page-options="pagingOptions"
                       :page-change="getData">
        </lx-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import router from '../../../router'

export default {
  name: 'CatalogMedicineList',
  data() {
    return {
      searchString: null,
      searchDelayTimer: null,
      tableObj: {
        loading: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
        options: {
          sortBy: ['name'],
          sortDesc: [true],
          // getClassForItem: (row) => {
          //   if (row.isExpanded) {
          //     return 'row-bold'
          //   }
          //   return ''
          // }
        },
        filterValue: null,
        selection: null,
        headers: [
          {
            text: 'Medizinische Einrichtung',
            value: 'name',
            sortable: true
          },
          {
            text: 'Aktiv',
            value: 'is_active',
            width: '60px',
            class: 'text-center',
            renderer: 'icon'
          },
          {
            text: 'Artikelanzahl',
            value: 'article_count',
            class: 'text-right pr-2',
            width: '120px',
            renderer: 'clickToActivate'
          },
        ]
      },
      items: []
    }
  },
  watch: {
    searchString() {
      clearInterval(this.searchDelayTimer);

      this.searchDelayTimer = setInterval(() => {
        clearInterval(this.searchDelayTimer);
        this.getData()
      }, 500)
    }
  },
  methods: {
    /**
     * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
     */
    changeTableOptions(value) {
      if (value) {
        this.tableObj.options = value
      }
      this.getData()
    },
    /**
     * Methode wird vom Grid angesprungen wenn man doppelt auf eine Zeile klickt. Dann wird die Detailansicht geöffent.
     * Kann auch an einen Editbutton in einer Spalte gehangen werden aber Doppelklick ist komfortabler.
     * **/
    openEditorDetails(params) {
      if (params?._id) {
        router.push({ path: `/administration/catalog/details/aml/${params._id.toString()}` });
      }
    },
    /**
     * Wird verschiedenen Stellen aus aufgerufen und soll die Daten vom Backend abholen. Mittels axios....bla bla bla
     * Besonderheit:
     * "this.pagingOptions.count = res.data.count"
     * - Anzahl der Datensätze vom Server wird auch in den pagingOptions gespeichert
     *
     * "this.$store.dispatch('setPagingOptions', this.pagingOptions)"
     * - nach der Speicherung der Anzahl werden die pagingOptions wieder in den Store geschrieben
     *
     * "this.gridOptions.api.ensureIndexVisible(this.$store.getters.gridScrollIndex, 'top')"
     * - sorgt dafür das nach dem neuladen der Daten das Grid wieder an die Stelle scrollt wo es war. notwendig z.B. bei der Rückkehr zum Grid
     * **/
    getData(options) {
      if (this.tableObj.loading) return
      this.tableObj.loading = true

      if (options && typeof options.skip === 'number') {
        this.pagingOptions.skip = options.skip
      }
      if (options && options.limit) {
        this.pagingOptions.limit = options.limit
      }

      const params = this.$helper.GetQueryParam({
        ...this.tableObj.options,
        skip: this.pagingOptions.skip,
        limit: this.pagingOptions.limit,
        query: {},
        count: true,
        searchString: this.searchString,
        searchFields: ['name']
      })

      this.$http.get('/hospital', params)
        .then(res => {
          if (res.data) {
            this.items = res.data.data || []
            this.pagingOptions.count = res.data.count || 0
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.tableObj.loading = false
        })
    },

    countArticles (_id, index) {
      this.isLoading++
      this.$http.get(`/abda/count/${_id}`)
          .then((res) => {
            let val = "0"

            if (!isNaN(res.data["count"])) {
              val = res.data["count"].toLocaleString( 'de-DE' )
            }

            let data = {
              ...this.items[index],
              article_count: val
            }
            this.$set(this.items, index, data)

          })
          .catch(this.NotifyError)
          .finally(() => {
            this.isLoading--
          })
    }

  },
  computed: {}
}
</script>
<!-- Add 'scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.view-top-toolbar {
  .btn {
    min-width: 40px;
  }

  .btn__content {
    padding: 0 5px !important;
  }
}
</style>
