import Vuex from 'vuex';
import shopping_cart from './store/shopping_cart'

export default {
    install(Vue) {

        Vue.use(Vuex)

        let Store = new Vuex.Store({
            modules: {
                shopping_cart
            }
        });

        Vue.mixin({
            methods: {
            },
            computed: {
                StorageLocationsForShoppingCart: {
                    get() {
                        return Store.getters.getStorageLocations
                    },
                    set(value) {
                        Store.dispatch('setStorageLocations', value)
                    }
                }
            }
        })
    },

}
