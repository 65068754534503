<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row layout-wrap">

          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  height="40"
                  classes="text-none mr-1"
                  @click="back()"
                  icon="chevron_left"
                  :hide-label="true"
                  tooltip="zurück zur Übersicht"
                  :label="'Zurück'"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="!ifBarcodeModuleEnabled || tableObj.loading"
                  iconSize="20"
                  width="auto"
                  height="40"
                  classes="text-none mr-1"
                  @click="openbarcodeDialog"
                  :hide-label="shortenButtons"
                  icon="mdi-barcode-scan"
                  tooltip="Artikel per Barcode in den Warenkorb legen"
                  :label="'Barcodescan'"></lx-btn>
          <v-menu offset-y  max-height="95%">
            <template v-slot:activator="{ on }">
              <div>
                <lx-btn :color="'#353a47'"
                        :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                        small
                        :disabled="tableObj.loading"
                        iconSize="20"
                        v-on="on"
                        width="auto"
                        height="40"
                        classes="text-none mr-1"
                        :loading="loadingPDF"
                        isMenu
                        :hide-label="shortenButtons"
                        icon="print"
                        tooltip="Listendruckmenu"
                        :label="'Drucken'"></lx-btn>
              </div>
            </template>
            <v-list v-bind:class="{'blue-grey darken-3': compColor.isInDarkMode}" class="py-0" style="max-height:95%;overflow-y: auto">
              <v-list-item :dark="compColor.isInDarkMode" class="pa-2">
                <v-list-item-content>
                  <v-alert
                      :dark="compColor.isInDarkMode"
                      :icon="false"
                      class="ma-0 my-n3"
                      v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}"
                      border="left" dense
                      text><small>Erzeugen Sie aus ihren Listen PDFs,<br>um diese
                    auszudrucken oder abzuspeichern.</small></v-alert>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item :dark="compColor.isInDarkMode" @click="generateListPDF(null)">
                <v-list-item-icon>
                  <v-icon :color="compColor.primeColor" :dark="compColor.isInDarkMode"
                          size="18">print
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Stationsliste <strong>(kombiniert)</strong>
                  </v-list-item-title>
                  <!--                                            <v-list-item-subtitle>alle Artikel aus allen Schränken</v-list-item-subtitle>-->
                </v-list-item-content>
              </v-list-item>
              <v-subheader inset :dark="compColor.isInDarkMode">oder einzeln</v-subheader>
              <v-list-item
                  :dark="compColor.isInDarkMode"
                  v-for="(item, index) in StorageLocationsForShoppingCart"
                  :key="index"
                  @click="generateListPDF(item._id)"
              >
                <v-list-item-icon>
                  <v-icon :color="compColor.primeColor" :dark="compColor.isInDarkMode"
                          size="22">subdirectory_arrow_right
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <!--                                            <v-list-item-subtitle>als Liste ausdrucken</v-list-item-subtitle>-->
                </v-list-item-content>

                <!--                                        <v-list-item-title></v-list-item-title>-->
              </v-list-item>

              <v-divider></v-divider>
              <v-list-item :disabled="shoppingCartEmpty"
                           @click="generateShoppingCartPDF" :dark="compColor.isInDarkMode">
                <v-list-item-icon>
                  <v-icon :disabled="shoppingCartEmpty" :color="compColor.primeColor"
                          :dark="compColor.isInDarkMode" size="18">print
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Warenkorb</v-list-item-title>
                  <!--                                            <v-list-item-subtitle>alle derzeit hinzugefügten Artikel</v-list-item-subtitle>-->
                </v-list-item-content>
              </v-list-item>

            </v-list>
          </v-menu>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="tableObj.loading || disableOrderComment"
                  iconSize="20"
                  width="auto"
                  height="40"
                  classes="text-none mr-1"
                  @click="editComment"
                  :hide-label="shortenButtons"
                  icon="comment"
                  tooltip="Bestellkommentar eingeben"
                  :label="'Kommentar'"></lx-btn>

          <lx-btn v-if="!showHTMLByRight({obj: '/orders/complete/*', act: 'POST'})"
                  :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="shoppingCartEmpty || usersNotified"
                  iconSize="20"
                  width="auto"
                  height="40"
                  classes="text-none mr-1"
                  @click="notifyOrderComplete"
                  icon="mdi-cart-arrow-right"
                  tooltip="Warenkorb zum bestellen markieren"
                  :label="'Zum bestellen markieren'"></lx-btn>
          <lx-btn v-if="showHTMLByRight({obj: '/orders/complete/*', act: 'POST'})"
                  :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="shoppingCartEmpty || !hasPermissionLevel"
                  iconSize="20"
                  width="auto"
                  height="40"
                  classes="text-none mr-1"
                  @click="orderShoppingCart"
                  icon="mdi-cart-arrow-right"
                  tooltip="Warenkorb bestellen"
                  :label="'Bestellen'"></lx-btn>

          <div class="layout-row ml-2 d-none d-sm-none d-md-flex pr-3" v-if="!isMobileDevice">
            <v-divider vertical class="mx-0 mr-1"></v-divider>
            <!--                                <v-icon color="grey lighten-1">mdi-chevron-double-left</v-icon>-->
            <div class="layout-column layout-align-center-center ml-1"
                 v-bind:class="{'blue-grey--text text--lighten-3': compColor.isInDarkMode, 'grey--text text--darken-1': !compColor.isInDarkMode}">
              <span style="line-height: 14px;"><strong>{{ locationName }}</strong></span>
            </div>
            <div class="layout-row" v-if="duration">
              <v-icon color="grey lighten-1">mdi-chevron-double-left</v-icon>
              <div class="layout-column layout-align-center-center ml-1"
                   v-bind:class="{'blue-grey--text text--lighten-3': compColor.isInDarkMode}">
                                    <span style="line-height: 14px;"><small>Lieferung am {{ deliverDateMessage }} wenn sie<br>in den nächsten <strong
                                        v-bind:class="[compColor.textInput + '--text']">
                                      <span v-if="duration.days > 0">{{ duration.days }}d</span>
                                        {{ duration.hours }}h</strong> und <strong
                                        v-bind:class="[compColor.textInput + '--text']">{{
                                        duration.minutes
                                      }}min</strong> bestellen</small></span>
              </div>
            </div>
          </div>

        </div>

        <div class="flex layout-row"></div>

        <v-tooltip bottom open-delay="350">
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on" class="text-none" :dark="compColor.isInDarkMode"
                   @click="shortenButtons = !shortenButtons">
              <v-icon size="22" :color="shortenButtons ? 'blue-grey':'blue-grey lighten-3'">mdi-gesture-tap-button
              </v-icon>
            </v-btn>
          </template>
          <span>Buttons verkürzt darstellen</span>
        </v-tooltip>

        <v-tooltip bottom open-delay="350">
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on" class="text-none" :dark="compColor.isInDarkMode"
                   @click="hideShoppingCart = !hideShoppingCart;activateResize=!activateResize;">
              <div class="pa-2 layout-column">
                <div style="margin-bottom:1px;width:17px;height:5px;"
                     v-bind:class="{'blue-grey': hideShoppingCart, 'blue-grey lighten-3': !hideShoppingCart}"></div>
                <div style="margin-top:1px;width:17px;height:5px;background:transparent !important;border:1px solid;"
                     v-bind:class="{'blue-grey': hideShoppingCart, 'blue-grey lighten-3': !hideShoppingCart}"></div>
              </div>
            </v-btn>
          </template>
          <span>Warenkorb ausblenden</span>
        </v-tooltip>

        <v-tooltip bottom open-delay="350">
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on" class="text-none" :dark="compColor.isInDarkMode"
                   @click="hideItemSearch = !hideItemSearch;activateResize=!activateResize;">
              <div class="pa-2 layout-column">
                <div style="margin-bottom:1px;width:17px;height:5px;background:transparent !important;border:1px solid;"
                     v-bind:class="{'blue-grey': hideItemSearch, 'blue-grey lighten-3': !hideItemSearch}"></div>
                <div style="margin-top:1px;width:17px;height:5px;"
                     v-bind:class="{'blue-grey': hideItemSearch, 'blue-grey lighten-3': !hideItemSearch}"></div>
              </div>
            </v-btn>
          </template>
          <span>Suchergebnisse ausblenden</span>
        </v-tooltip>

        <v-tooltip bottom open-delay="350">
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on" class="text-none" :dark="compColor.isInDarkMode"
                   @click="getSetLayoutOrientation = true;activateResize=!activateResize;">
              <div class="pa-2 layout-row">
                <div style="margin-right:1px;width:5px;height:17px;"
                     v-bind:class="{'blue-grey': getSetLayoutOrientation, 'blue-grey lighten-3': !getSetLayoutOrientation}"></div>
                <div style="margin-left:1px;width:5px;height:17px;"
                     v-bind:class="{'blue-grey': getSetLayoutOrientation, 'blue-grey lighten-3': !getSetLayoutOrientation}"></div>
              </div>
            </v-btn>
          </template>
          <span>Suchergebnisse und Warenkorb nebeneinander anordnen</span>
        </v-tooltip>

        <v-tooltip bottom open-delay="350">
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on" class="text-none" :dark="compColor.isInDarkMode"
                   @click="getSetLayoutOrientation = false;activateResize=!activateResize;">
              <div class="pa-2 layout-column">
                <div style="margin-bottom:1px;width:17px;height:5px;"
                     v-bind:class="{'blue-grey': !getSetLayoutOrientation, 'blue-grey lighten-3': getSetLayoutOrientation}"></div>
                <div style="margin-top:1px;width:17px;height:5px;"
                     v-bind:class="{'blue-grey': !getSetLayoutOrientation, 'blue-grey lighten-3': getSetLayoutOrientation}"></div>
              </div>
            </v-btn>
          </template>
          <span>Suchergebnisse und Warenkorb übereinander anordnen</span>
        </v-tooltip>

      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="px-0 layout-row pt-1">
        <div class="layout-column layout-align-center-center ml-1 pt-0"
             style="max-width: 200px;">
          <!--          <fake-input-frame label="Listenauswahl" class="px-1 pb-1">-->
          <v-select
              :background-color="isDarkMode ? 'transparent':getBackgroundColorForList()"
              :dark="compColor.isInDarkMode"
              :color="compColor.primeColor"
              placeholder="Listenauswahl"
              :item-color="compColor.primeColor"
              label="Listenauswahl"
              v-model="tab"
              :items="listSelectionList"
              item-text="name"
              item-value="id"
              hide-no-data
              hide-details
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              :dense="denseFields"
              item-disabled="disabled"
              class="blue-grey--text ma-0 mt-2"
              @change="changeArticleList()"
          >
          </v-select>
          <!--          </fake-input-frame>-->
          <!--                                                            <v-divider class="grey lighten-3"></v-divider>-->
        </div>
        <div class="layout-column layout-align-center-center ml-1"
             v-if="tab === 'storage'">
          <v-icon>double_arrow</v-icon>
        </div>
        <div class="layout-column layout-align-center-center ml-1 pt-0"
             style="max-width: 200px;" v-if="tab === 'storage'">
          <!--          <fake-input-frame label="Schrankauswahl" class="px-1 pb-1">-->
          <v-select
              :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
              :color="compColor.primeColor"
              :dark="compColor.isInDarkMode"
              placeholder="Lagerortauswahl"
              label="Lagerortauswahl"
              v-model="storage"
              :items="StorageLocationsForShoppingCart"
              item-text="name"
              item-value="_id"
              :menu-props="{ bottom: true, offsetY: true }"
              hide-no-data
              hide-details
              outlined
              :dense="denseFields"
              :clearable="true"
              item-disabled="disabled"
              class="blue-grey--text ma-0 mt-2"
              @change="changeStorageList()"
          ></v-select>
          <!--          </fake-input-frame>-->
        </div>
        <v-divider vertical class="mx-1 transparent"></v-divider>
        <div class="px-1 flex pt-0">
          <!--          <fake-input-frame label="Suchbegriff(e) eingeben" class="px-0 pb-1">-->
          <lx-abda-search-fields
              :data-layout="getSetLayoutOrientation"
              :data-method="getAbdaData"
              ref="abdaSearchField"
          ></lx-abda-search-fields>
          <!--          </fake-input-frame>-->
        </div>
      </div>

      <div class="flex px-1 pb-1 mt-1" style="overflow-y: hidden"
           v-bind:class="{'layout-column': !getSetLayoutOrientation, 'layout-row': getSetLayoutOrientation}">

        <div class="layout-row flex" v-show="!hideItemSearch">
          <fake-input-frame label="Suchergebnisse" class="layout-column flex px-1 pb-0">

            <div class="elevation-3 flex layout-column" style="border-radius: 6px;"
                 :style="{'background': compColor.lxExpansion.background}">

              <v-divider></v-divider>
              <div class="pa-1 layout-column flex">

                <lx-data-table
                    v-model="tableObj.selection"
                    tableName="ShoppingCartDetailsSearch"
                    :items="items"
                    :headers="tableObj.headers"
                    :loading="tableObj.loading"
                    :tableOptions="tableObj.options"
                    :setResize="activateResize"
                    :setVirtualScroll="false"
                    :hideContextFilter="true"
                    :stayAtScrollPosition="true"
                    @changeOptions="changeTableOptions">
                  <!--              @rowClicked="openUserDetails"-->
                  <template v-slot:cellrenderer="{ value }">
                    <div v-if="value.f.renderer === 'currency_ger'" style="padding:1px;height:35px;">
                      <div class="layout-row layout-align-end-center" style="border-radius:3px;height:33px;">
                        {{ getValueByObjectPath(value.i, value.f.value) | currency }}
                      </div>
                    </div>
                    <div v-else-if="value.f.renderer === 'in_stock'" style="padding:1px;height:35px;">
                      <div class="layout-row layout-align-center-center" style="border-radius:3px;height:33px;">
                        <div v-if="!hasValue(value.i, value.f.value)">
                          <v-icon color="grey lighten-2">mdi-ambulance</v-icon>
                        </div>
                        <div v-else>
                          <div v-if="getValueByObjectPath(value.i, value.f.value) > 0">
                            <v-icon color="green">mdi-ambulance</v-icon>
                          </div>
                          <div v-else>
                            <v-icon color="orange lighten-2">mdi-ambulance</v-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="value.f.renderer === 'packaging'" style="padding:1px;height:35px;">
                      <div class="layout-row layout-align-end-center" style="border-radius:3px;height:33px;"
                           v-if="getValueByObjectPath(value.i, 'Zahl') && getValueByObjectPath(value.i, 'Einheit')">
                        {{ getValueByObjectPath(value.i, 'Zahl') + ' ' + getValueByObjectPath(value.i, 'Einheit') }}
                      </div>
                    </div>
                    <div v-else-if="value.f.renderer === 'article_info'" style="padding:1px;height:35px;">
                      <div class="layout-row layout-align-center-center" style="border-radius:3px;height:33px">
                          <v-tooltip right class="pa-0 ma-0 elevation-16" content-class="detailInfo" open-delay="350"
                                     max-width="50%" min-width="300" open-on-click :open-on-hover="false">
                            <template v-slot:activator="{ on, attr }">
                              <div>
                                <v-icon :dark="compColor.isInDarkMode" v-on="on" v-bind="attr" @click.stop.prevent="getHoverDetailValues(value.i)" color="blue-grey" style="cursor: pointer;">
                                  mdi-magnify-plus-outline
                                </v-icon>
                              </div>
                            </template>

                            <template v-slot:default>
                              <div class="elevation-1 layout-column" style="border-radius: 6px;" v-bind:style="{background: compColor.contentFrame}">
                                <!-- HEADER -->
                                <div class="body-1 font-weight-light px-2 py-1 layout-row layout-align-center-center"
                                     :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
                                  Detailinformationen
                                </div>

                                <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

                                <!-- CONTENT (default slot) -->
                                <div class="ma-1 pa-2 layout-column elevation-3"
                                     style="border-radius: 5px;position: relative;"
                                     :style="{'background': compColor.lxExpansion.background}">

                                  <div v-if="!articleInfoPending">
                                    <div v-for="(item, index) in articleInfoCache" :key="index">
                                      <div class="layout-colum pt-0 px-1">
                                        <div class="caption blue-grey--text text--lighten-3">{{ item.label }}</div>
                                        <div class="body-1 font-weight-bold blue-grey--text mt-n1 mb-0">
                                          {{ item.value ? item.value : '-' }}
                                        </div>
                                        <v-divider></v-divider>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-else>
                                    <v-progress-circular></v-progress-circular>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </v-tooltip>
                      </div>
                    </div>
                    <div v-else-if="value.f.renderer === 'highlighter'"
                         style="padding:1px;height:35px;width:100%;max-width:100%;min-width:100%;"
                         class="layout-row flex">
                      <div v-if="!value.i.pzn && typeof value.i.referer_pzn === 'string' && value.f.value === 'Produktname'" class="red--text" style="white-space: nowrap;line-height: 2.5em">*** Artikel nicht länger gelistet ***</div>

                      <lx-text-highlight v-else :text="getValueByObjectPath(value.i, value.f.value)"
                                         :search-string="searchModel" :color="compColor.prime"></lx-text-highlight>
                      <!--                  </div>-->
                    </div>
                    <div v-else-if="value.f.renderer === 'addItem'" style="padding:1px;height:35px;">
                      <div class="layout-row layout-align-center-center" style="border-radius:3px;height:33px;">
                        <!--                    v-if="action && allowed"-->
                        <v-tooltip bottom open-delay="350" v-show="hideAddButtonIfBTM(value.i)">
                          <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="red" v-show="hideAddButtonIfBTM(value.i)">mdi-alert-circle-outline</v-icon>
                          </template>
                          <span>Artikel kann nicht in den Warenkorb gelegt werden, da es sich um ein BTM handelt</span>
                        </v-tooltip>
                        <v-tooltip bottom open-delay="350" v-show="!hideAddButtonIfBTM(value.i)">
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" :color="compColor.primeColor" @click="addItem(value.i)"  v-show="!hideAddButtonIfBTM(value.i)"
                                   height="25" style="min-width: 3.3em;"
                                   :disabled="!value.i.pzn && typeof value.i.referer_pzn === 'string'"
                                   :dark="isDarkMode" class="elevation-0 px-0">
                              <v-icon color="white">mdi-cart-arrow-down</v-icon>
                            </v-btn>
                          </template>
                          <span>Artikel in den Warenkorb legen</span>
                        </v-tooltip>
                        <!--                    {{ getValueByObjectPath(value.i, value.f.value) | currency }}-->

                      </div>
                    </div>
                    <div v-else-if="value.f.renderer === 'changeNumber'" style="padding:1px;height:35px;">
                      <div v-if="value.i.pzn" class="layout-row layout-align-center-center px-1" style="border-radius:3px;height:33px;">
                        <v-tooltip bottom open-delay="750">
                          <template v-slot:activator="{ on }">
                            <div v-on="on">
                              <agGridComponentInputCellEditor :field="value.f.value"
                                                              v-model="value.i"></agGridComponentInputCellEditor>
                            </div>
                          </template>
                          <span>Artikelanzahl ändern</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div v-else-if="value.f.renderer === 'list-type'" style="padding:1px;height:35px;">
                      <div class="layout-row" style="margin:2px;"
                           v-bind:class="{'blue-grey--text text--darken-3': compColor.isInDarkMode}">
                        <div class="flex pa-1 layout-row layout-align-center-center"
                             v-bind:class="getListTypeValues(value.i).color">
                        <span
                            class="caption blue-grey--text text--darken-2">{{ getListTypeValues(value.i).text }}</span>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="value.f.renderer === 'storageLocation'"
                         class="layout-column layout-align-center-center" style="padding:1px;height:35px;">

                            <agGridComponentStoreageLocationCellRenderer
                                :value="value.i"
                                :field="value.f.value"
                                @clickBtn="addItemToStorage"
                                @clickBtnEdit="editItemInStorage"
                                @clickBtnDelete="deleteItemFromStorage"
                            ></agGridComponentStoreageLocationCellRenderer>

                    </div>
                  </template>
                </lx-data-table>
              </div>
            </div>
            <!--              <v-divider></v-divider>-->
            <div class="layout-row pa-0 pt-1" style="position: relative">
              <lx-pagination :page-name="'articleSearch'"
                             :page-options="pagingOptions"
                             :page-change="getData">
              </lx-pagination>
            </div>
          </fake-input-frame>
        </div>

        <fake-input-frame class="layout-row px-1 pb-1"
                          v-if="hideItemSearch"
                          label="Suchergebnisse">
          <v-btn class="white--text text-none flex"
                 depressed
                 :color="compColor.isInDarkMode ? 'blue-grey darken-3' : 'grey'"
                 :dark="compColor.isInDarkMode" @click="hideItemSearch = !hideItemSearch">
            Artikelsuche ausgeblendet
          </v-btn>
        </fake-input-frame>

        <div style="width:8px;height:8px;"></div>

        <div class="layout-row flex" v-show="!hideShoppingCart">
          <fake-input-frame label="Warenkorb" class="layout-column flex px-1 pb-0">


            <div class="elevation-3 flex layout-column px-1 pt-1" style="border-radius: 6px;"
                 @mouseenter="SetAutoRefresh(false)"
                 @mouseleave="SetAutoRefresh(true)"
                 :style="{'background': compColor.lxExpansion.background}">
<!--              -->
              <div
                class="layout-column flex layout-align-center-center"
                v-show="outdatedHint"
                style="
                  background-color: rgba(0.4, 0.4, 0.4, 0.75);
                  z-index: 99;
                  position: absolute;
                  border-radius: 4px;
                  top:0;
                  left:4px;
                  bottom:4px;
                  right:4px;
                "
              >
                  <v-btn x-large :color="compColor.prime" class="white--text text-none" @click="getShoppingCartData">
                    <v-icon size="38px" class="mr-4">mdi-cached</v-icon>
                    Es gab Änderungen im Warenkorb, klicken Sie hier zum aktualisieren.
                  </v-btn>
              </div>

              <lx-data-table
                v-model="tableObjShoppingCart.selection"
                tableName="ShoppingCartDetailsShoppingcart"
                :items="itemsShoppingCart"
                :headers="tableObjShoppingCart.headers"
                :loading="tableObjShoppingCart.loading"
                :tableOptions="tableObjShoppingCart.options"
                :setResize="activateResize"
                :hideContextFilter="true"
                :stayAtScrollPosition="true"
                @changeOptions="changeTableOptionsShoppingCart">
                <template v-slot:cellrenderer="{ value }">
                  <div v-if="value.f.renderer === 'currency_ger'" style="padding:1px;height:35px;">
                    <div class="layout-row layout-align-end-center" style="border-radius:3px;height:33px;">
                      {{ getValueByObjectPath(value.i, value.f.value) | currency }}
                    </div>
                  </div>
                  <div v-else-if="value.f.renderer === 'changeNumber'" style="padding:1px;height:35px;">
                    <v-tooltip bottom open-delay="750" v-if="!value.i.ze_item">
                      <template v-slot:activator="{ on }">
                        <div v-on="on" class="layout-row layout-align-center-center px-1"
                             style="border-radius:3px;height:33px;">
                          <agGridComponentInputCellEditor :field="value.f.value" v-model="value.i"
                                                          @changeEvent="changeArticleCount"></agGridComponentInputCellEditor>
                        </div>
                      </template>
                      <span>Artikelanzahl ändern</span>
                    </v-tooltip>

                    <v-tooltip bottom open-delay="750" v-if="value.i.ze_item">
                      <template v-slot:activator="{ on }">
                        <div v-on="on" class="layout-row layout-align-center-center px-1"
                             style="border-radius:3px;height:33px;">
                          {{ getZeNubValues(value.i.ze_item) }}
                        </div>
                      </template>
                      <span>Artikelanzahl wird durch die ZE/NUB Eingabe bestimmt und  ist nicht direkt änderbar</span>
                    </v-tooltip>
                  </div>
                  <div v-else-if="value.f.renderer === 'ze_item'" style="padding:1px;height:35px;">

                    <v-tooltip bottom open-delay="750">
                      <template v-slot:activator="{ on }">

                        <div v-on="on" class="layout-row layout-align-center-center"
                             style="border-radius:3px;height:33px;">

                          <div class="blue-grey lighten-4 layout-row" v-if="value.i.ze_item"
                               @click="openZeItemDialog(value.i)" style="border-radius: 4px;">
                            <div class="px-1 layout-column layout-align-center-center">
                              <v-icon small color="blue-grey darken-2">mdi-account</v-icon>
                            </div>
                            <span v-if="getValueByObjectPath(value.i, 'ze_item')"
                                  class="px-1 white--text font-weight-bold layout-row layout-align-center-center blue-grey"
                                  style="border-radius:0 4px 4px 0;max-width:23px;min-width:23px;height:25px;">
                        {{ getValueByObjectPath(value.i, 'ze_item').length }}
                      </span>
                          </div>

                        </div>
                      </template>
                      <span>ZE/NUB Dateneingabe</span>
                    </v-tooltip>
                  </div>
                  <div v-else-if="value.f.renderer === 'dateTime'" style="padding:1px;height:35px;">
                    <div class="layout-row layout-align-start-center" style="border-radius:3px;height:33px;">
                      {{ value.i[value.f.value] | formatDate('dd, DD.MM.YYYY HH:mm:ss') }}
                    </div>
                  </div>
                  <div v-else-if="value.f.renderer === 'article_info'" style="padding:1px;height:35px;">
                    <div class="layout-row layout-align-center-center" style="border-radius:3px;height:33px;">
                      <v-tooltip right class="pa-0 ma-0 elevation-16" content-class="detailInfo" open-delay="350"
                                 max-width="50%" min-width="300" open-on-click :open-on-hover="false">
                        <template v-slot:activator="{ on, attr }">
                          <div>
                            <v-icon :dark="compColor.isInDarkMode" v-on="on" v-bind="attr" @click.stop.prevent="getHoverDetailValues(value.i)" color="blue-grey" style="cursor: pointer;">
                              mdi-magnify-plus-outline
                            </v-icon>
                          </div>
                        </template>

                        <template v-slot:default>
                          <div class="elevation-10 layout-column" style="border-radius: 6px;"
                               v-bind:style="{background: compColor.contentFrame}">
                            <!-- HEADER -->
                            <div class="body-1 font-weight-light px-2 py-1 layout-row layout-align-center-center"
                                 :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
                              Detailinformationen
                            </div>

                            <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

                            <!-- CONTENT (default slot) -->
                            <div class="ma-1 pa-2 layout-column elevation-3"
                                 style="border-radius: 5px;position: relative;"
                                 :style="{'background': compColor.lxExpansion.background}">

                              <div v-if="!articleInfoPending">
                                <div v-for="(item, index) in articleInfoCache" :key="index">
                                  <div class="layout-colum pt-0 px-1">
                                    <div class="caption blue-grey--text text--lighten-3">{{ item.label }}</div>
                                    <div class="body-1 font-weight-bold blue-grey--text mt-n1 mb-0">
                                      {{ item.value ? item.value : '-' }}
                                    </div>
                                    <v-divider></v-divider>
                                  </div>
                                </div>
                              </div>
                              <div v-else>
                                <v-progress-circular></v-progress-circular>
                              </div>


                            </div>
                          </div>
                        </template>
                      </v-tooltip>
                    </div>
                  </div>
                  <div v-else-if="value.f.renderer === 'removeItem'" style="padding:1px;height:35px;">
                    <div class="layout-row layout-align-center-center" style="border-radius:3px;height:33px;">
                      <v-tooltip bottom open-delay="750">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" text icon :color="compColor.primeColor" @click="removeItem(value.i)"
                                 height="25"
                                 :dark="!compColor.isInDarkMode" class="elevation-0 px-0">
                            <v-icon>mdi-delete-forever</v-icon>
                          </v-btn>
                        </template>
                        <span>Artikel aus dem Warenkorb entfernen</span>
                      </v-tooltip>
                      <!--                    {{ getValueByObjectPath(value.i, value.f.value) | currency }}-->

                    </div>
                  </div>
                  <div v-else-if="value.f.renderer === 'articleComment'" style="padding:1px;height:35px;"
                       class="layout-column flex">
                    <v-tooltip bottom open-delay="750">
                      <template v-slot:activator="{ on }">

                        <div v-on="on" @click="changeArticleComment(value.i)"
                             class="layout-column layout-align-center-center flex">


                          <div style="width:25px;height:25px;border:1px solid #cfd8db;border-radius: 3px;"
                               class="layout-column layout-align-center-center">
                            <v-icon v-if="getValueByObjectPath(value.i, value.f.value)" v-bind:class="{
                    'blue-grey--text': getValueByObjectPath(value.i, value.f.value) && !compColor.isInDarkMode ,
                    'blue-grey--text text--lighten-3': getValueByObjectPath(value.i, value.f.value) && compColor.isInDarkMode }">
                              mdi-close
                            </v-icon>
                            <div v-else class="text-center font-weight-bold"
                                 style="margin-top:2px; opacity: 0.2;line-height: 7px;font-size:12px;">Aa
                            </div>
                            <!--                          <div v-else class="text-center" style="opacity: 0.3;line-height: 7px;font-size:10px;"><small>Kommen<br>tar</small></div>-->
                          </div>
                        </div>
                      </template>
                      <span>Artikelkommentar
                      <span v-if="!getValueByObjectPath(value.i, value.f.value)">hinzufügen</span>
                      <span v-else>anzeigen</span>
                    </span>
                    </v-tooltip>

                  </div>
                  <div v-else-if="value.f.renderer === 'autidem'" style="padding:1px;height:35px;"
                       class="layout-column flex">
                    <v-tooltip bottom open-delay="750">
                      <template v-slot:activator="{ on }">

                        <div v-on="on" class="flex layout-column layout-align-center-center" style="margin:2px;"
                             @click="changeAutIdem(value.i)"
                             v-bind:class="{'blue-grey--text text--darken-3': compColor.isInDarkMode}">

                          <div style="width:25px;height:25px;border:1px solid #cfd8db;border-radius: 3px;"
                               class="layout-column layout-align-center-center">
                            <v-icon color="blue-grey" v-if="getValueByObjectPath(value.i, value.f.value)">mdi-close
                            </v-icon>
                            <div v-else class="text-center" style="opacity: 0.3;line-height: 7px;font-size:10px;">
                              <small>aut<br>idem</small></div>
                          </div>
                        </div>
                      </template>
                      <span><strong style="text-decoration: underline;">Aut Idem</strong> für Artikel
                      <span v-if="!getValueByObjectPath(value.i, value.f.value)">hinzufügen</span>
                      <span v-else>entfernen</span>
                    </span>
                    </v-tooltip>
                  </div>
                  <div v-else-if="value.f.renderer === 'list-type'" style="padding:1px;height:35px;">
                    <div class="layout-row" style="margin:2px;"
                         v-bind:class="{'blue-grey--text text--darken-3': compColor.isInDarkMode}">
                      <div class="flex pa-1 layout-row layout-align-center-center"
                           v-bind:class="getListTypeValues(value.i).color">
                          <span
                            class="caption blue-grey--text text--darken-2">{{
                              getListTypeValues(value.i).text
                            }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="value.f.renderer === 'packaging'" style="padding:1px;height:35px;">
                    <div class="layout-row layout-align-end-center" style="border-radius:3px;height:33px;">
                      {{ getValueByObjectPath(value.i, 'unit') }}
                    </div>
                  </div>
                </template>
              </lx-data-table>

              <v-divider></v-divider>

              <div class="layout-row layout-align-start pr-4 py-1 blue-grey--text body-2">
                <!--
                  green   polling active and autorefresh enabled
                  orange  polling active and hint message will be displayed
                  gray    polling inactive no action will happends
                  red     errored

                -->
                <div class="ml-2" :class="pollState"
                     style="width: 10px; height: 10px; border-radius: 50%; margin-top: 5px"></div>

                <v-spacer></v-spacer>
                <!--                <v-divider></v-divider>-->
                <small class="mr-2">Gesamtpreis:</small><strong><span
                class="blue-grey--text"
                v-bind:class="{'text--darken-2': !compColor.isInDarkMode}">{{
                  gesamtPreis | currency
                }}</span></strong>
              </div>

            </div>

            <div class="layout-row pa-0" style="position: relative">
              <lx-pagination :page-name="'shoppingCart'"
                             :page-options="pagingOptionsShoppingCart"
                             :page-change="getShoppingCartData">
              </lx-pagination>
            </div>

          </fake-input-frame>
        </div>

        <fake-input-frame class="layout-row px-1 pb-1"
                          v-if="hideShoppingCart"
                          label="Warenkorb"

        >
          <v-btn class="white--text text-none flex ma-0"
                 depressed
                 :color="compColor.isInDarkMode ? 'blue-grey darken-3' : 'grey'"
                 :dark="compColor.isInDarkMode" @click="hideShoppingCart = !hideShoppingCart">
            Warenkorb ausgeblendet (<strong class="mr-1"
                                            v-if="typeof shoppingCartModel === 'number'">{{
              shoppingCartModel
            }}</strong>
            <span v-else>0</span> Artikel)
          </v-btn>
        </fake-input-frame>
      </div>
    </div>

    <lx-dialog v-model="dialog" :allow-drag="true" :overlay="true" :size="{x: '70', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          Warenkorb bestellen
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <div class="flex layout-column pa-1" v-if="btmItem || globalItem">
            <v-alert border="left"
                     prominent
                     style="font-size:15px;"
                     class="px-3 py-2"
                     v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
              <template slot="prepend">
                <v-avatar>
                  <v-icon large>mdi-information-variant</v-icon>
                </v-avatar>
              </template>
              Im Warenkorb befinden sich <strong>Sonderartikel
              <span v-if="btmItem">und Betäubungsmittel</span></strong> für die sie eine höhere
              Freigabestufe
              benötigen als die, welche sie besitzen. Wenden Sie sich an ihren Vorgesetzten oder den
              Administrator falls Sie formal das Recht besitzen und dieses im Bestellsystem noch
              benötigen.<br><br><strong>Sie können den Bestellvorgang fortsetzen</strong>, es werden
              jedoch nur die Stationsartikel bestellt, Sonderartikel und BTMs verbleiben im Warenkorb.
            </v-alert>
          </div>

          <lx-obfuscation-panel
              :enabled="disableOrderComment"
              class="flex layout-column pa-1">

            <template v-slot:obfuscation-text>
              <v-alert border="left"
                       prominent
                       style="font-size:15px;"
                       class="px-3 py-2"
                       v-bind:class="{
                         'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode,
                         'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode
                       }">
                <template slot="prepend">
                  <v-avatar>
                    <v-icon large>mdi-information-variant</v-icon>
                  </v-avatar>
                </template>
                Der Bestellkommentar ist deaktiviert
              </v-alert>
            </template>

            <validation-provider tag="div" ref="csvColumnIdent"
                                 name="CSV-Spaltenindex/Name" rules="required"
                                 v-slot="{ errors }">
              <v-textarea
                :dark="compColor.isInDarkMode"
                :color="compColor.primeColor"
                :disabled="disableOrderComment"
                v-model="orderComment"
                outlined
                autofocus
                height="90"
                label="Bestellkommentar"
                :dense="denseFields"
                rows="3"
                :placeholder="(disableOrderComment) ? 'Der Bestellkommentar ist deaktiviert' : 'Sie möchten uns noch etwas wichtiges mitteilen?'"
                counter="160"
                maxlength="160"
              ></v-textarea>
            </validation-provider>
          </lx-obfuscation-panel>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <div class="flex mr-1">
            <lx-btn :color="'#353a47'"
                    block
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    class="flex"
                    iconSize="20"
                    width="auto"
                    classes="text-none"
                    @click.stop="completeOrder()"
                    icon="save"
                    tooltip="Warenkorb verbindlich bestellen"
                    :label="'Warenkorb verbindlich bestellen'"></lx-btn>
          </div>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  class="flex"
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click.stop="dialog = false"
                  icon="cancel"
                  tooltip="Vorgang abbrechen"
                  :label="'Abbrechen'"></lx-btn>
        </div>
      </template>
    </lx-dialog>

    <lx-dialog v-model="addStorageDialog" :allow-drag="true" :overlay="true" :size="{x: '70', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          Lagerort(e) zuweisen
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <div class="flex layout-column pa-1">
            <fake-input-frame label="Artikelinformationen" class="mb-3"
                              :input-background="isDarkMode ? 'transparent':'#f5f5f5'">
              <div class="layout-column pa-3 blue-grey--text">
                <div class="layout-row"><span class="flex">{{ addStorageData.Produktname }}</span><small
                  class="grey--text text--lighten-1">Produktname</small></div>
                <!--                <v-divider class="mb-4"></v-divider>-->
                <div class="layout-row layout-align-end-center"><span class="flex">{{ addStorageData.pzn }}</span><small
                  class="grey--text text--lighten-1">PZN</small></div>
                <!--                <v-divider class="mb-4"></v-divider>-->
                <div class="layout-row"><span
                  class="flex">{{ addStorageData.manufacturer_Langname }}</span><small
                  class="grey--text text--lighten-1">Hersteller</small></div>
                <!--                <v-divider></v-divider>-->
              </div>
            </fake-input-frame>
          </div>
          <div class="flex layout-column pa-1">
            <fake-input-frame label="Mögliche Lagerorte" class="mb-3"
                              :input-background="isDarkMode ? 'transparent':'#f5f5f5'">
              <div class="pa-2">
                <v-alert
                  v-if="StorageLocationsForShoppingCart.length >0"
                  border="left"
                  prominent
                  style="font-size:15px;"
                  class="px-3 py-2"
                  v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
                  <template slot="prepend">
                    <v-avatar>
                      <v-icon large>mdi-information-variant</v-icon>
                    </v-avatar>
                  </template>
                  Haken Sie bitte die Lagerorte an, in denen der ausgewählte Artikel lagert. Mehrfaches anhaken ist
                  möglich.
                </v-alert>
                <v-alert
                  v-else
                  border="left"
                  prominent
                  style="font-size:15px;"
                  class="px-3 py-2"
                  v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
                  <template slot="prepend">
                    <v-avatar>
                      <v-icon large>mdi-information-variant</v-icon>
                    </v-avatar>
                  </template>
                  Es sind keine Lagerorte hinterlegt wurden, wenden Sie sich bitte an den Administrator ihrer
                  Medizinischen Einrichtung oder an Ihre beliefernde Apotheke.
                </v-alert>
                <div v-for="(items, index) in StorageLocationsForShoppingCart"
                     :key="index"
                     class="px-2 py-1"
                     :class="index < StorageLocationsForShoppingCart.length - 1 ? 'mb-1' : ''"
                     :style="{'background': items.isSelected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
                     style="border-radius: 4px;">
                  <div class="layout-row">
                    <div class="pa-2 pl-1 layout-column layout-align-center-center">
                      <v-simple-checkbox :ripple="false"
                                         :dark="isDarkMode"
                                         :color="compColor.prime"
                                         v-model="items.isSelected"></v-simple-checkbox>
                    </div>
                    <v-divider vertical class="mr-2"></v-divider>
                    <div class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start">
                      <span style="line-height: 16px;"><strong>{{ items.name }}</strong></span>
                      <small>{{ items.comment }}</small>
                    </div>
                    <div class="layout-column layout-align-center-center ml-2">
                      <v-icon
                        :color="items.isSelected ? compColor.prime + ' lighten-3': (isDarkMode ? 'blue-grey darken-3' : 'blue-grey lighten-3')"
                        size="25">mdi-account-supervisor-circle
                      </v-icon>
                    </div>
                  </div>
                </div>
              </div>
            </fake-input-frame>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <div class="flex mr-1">
            <lx-btn :color="'#353a47'"
                    block
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="showAddStoragePopupSaveBtn"
                    class="flex"
                    iconSize="20"
                    width="auto"
                    classes="text-none"
                    @click.stop="addItemToStorage()"
                    icon="save"
                    tooltip="Artikelkommentarfenster schließen"
                    :label="'Speichern'"></lx-btn>
          </div>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  class="flex"
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click.stop="addStorageDialog = null"
                  icon="cancel"
                  tooltip="Vorgang abbrechen"
                  :label="'Abbrechen'"></lx-btn>
        </div>
      </template>
    </lx-dialog>

    <lx-dialog v-model="showPdfDialog" :size="{x: '95', y: '95'}">
      <template #actions>
        <div class="layout-row layout-align-center-center px-1 pb-1">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  block
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click="showPdfDialog = false"
                  icon="mdi-close"
                  tooltip="PDF Ansicht schließen"
                  :label="'Schließen'"></lx-btn>

        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-1 flex layout-column lx-delivery-background" :class="{'elevation-3': !loadingPDF}"
             style="border-radius: 5px;position: relative;"
             :style="{'background': loadingPDF ? 'transparent' : compColor.lxExpansion.background}">

          <lx-pdf-viewer :value="pdfObject" :loading="loadingPDF"></lx-pdf-viewer>

          <lx-loading-animation :loading="loadingPDF">
            <span class="mb-n3 pa-0 font-weight-light" style="font-size:1em">lade</span>
            <span class="mb-n3 pa-0 font-weight-bold" style="font-size:3.5em;">Druck</span>
            <span class="mt-n3 pa-0 font-weight-light" style="font-size:2.1em">Vorschau</span>
          </lx-loading-animation>

        </div>
      </template>
    </lx-dialog>

    <lx-dialog v-model="articleCommentDialog" :allow-drag="true" :overlay="true" :size="{x: '70', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          Artikelkommentar hinzufügen
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <div class="flex layout-column pa-1">
            <v-textarea
              :dark="compColor.isInDarkMode"
              :color="compColor.primeColor"
              outlined
              label="Kommentar"
              rows="3"
              height="80"
              clearable
              autofocus
              placeholder="Sie möchten uns noch etwas wichtiges mitteilen?"
              counter="160"
              maxlength="160"
              v-model="articleComment.comment"
            ></v-textarea>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <div class="flex mr-1">
            <lx-btn :color="'#353a47'"
                    block
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    class="flex"
                    iconSize="20"
                    width="auto"
                    classes="text-none"
                    @click.stop="saveArticleComment()"
                    icon="save"
                    tooltip="Artikelkommentarfenster schließen"
                    :label="'Speichern'"></lx-btn>
          </div>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  class="flex"
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click.stop="articleCommentDialog = false"
                  icon="cancel"
                  tooltip="Vorgang abbrechen"
                  :label="'Abbrechen'"></lx-btn>
        </div>
      </template>
    </lx-dialog>

    <lx-dialog v-model="commentDialog" :allow-drag="true" :overlay="true" :size="{x: '70', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          Bestellkommentar
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <div class="flex layout-column pa-1">
            <ValidationObserver ref="editOrderCommentPopupForm" class="layout-column flex">
              <validation-provider tag="div" ref="commentEditField"
                                   name="Kommentar">
                <v-textarea
                  :dark="compColor.isInDarkMode"
                  :color="compColor.primeColor"
                  v-model="orderComment"
                  outlined
                  autofocus
                  height="90"
                  label="Kommentar"
                  :dense="denseFields"
                  rows="3"
                  placeholder="Sie möchten uns noch etwas wichtiges mitteilen?"
                  counter="160"
                  maxlength="160"
                ></v-textarea>
              </validation-provider>
            </ValidationObserver>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <div class="flex mr-1">
            <lx-btn :color="'#353a47'"
                    block
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    class="flex"
                    :disabled="!showSaveBtn"
                    iconSize="20"
                    width="auto"
                    classes="text-none"
                    @click.stop="saveComment()"
                    icon="save"
                    tooltip="Bestellkommentarfenster schließen"
                    :label="'Speichern'"></lx-btn>
          </div>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  class="flex"
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click.stop="commentDialog = false"
                  icon="cancel"
                  tooltip="Vorgang abbrechen"
                  :label="'Abbrechen'"></lx-btn>

        </div>
      </template>
    </lx-dialog>

    <lx-dialog v-model="barcodeDialog" :allow-drag="true" :overlay="true" :size="{x: '80', y: '85'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          Artikel per Barcode in den Warenkorb einscannen
        </div>
      </template>
      <template #default>
        <div class="ma-1 flex layout-column elevation-3 scanpopup"
             id="myDiv"
             tabindex="0"
             style="border-radius: 5px;position: relative;/*overflow-y: auto;*/"
             :style="{'background': compColor.lxExpansion.background}">
          <div class=" flex layout-column blured">
                  <div class="layout-row">
                    <div class="flex-75 pa-3">
                      <validation-provider tag="div" ref="commentEditField"
                                           class="layout-row"
                                           name="Kommentar"
                                           v-slot="{ errors }">

                        <v-text-field
                            :dark="compColor.isInDarkMode"
                            :color="compColor.primeColor"
                            outlined
                            hide-details
                            readonly
                            disabled
                            class="mb-0"
                            ref="barcodeArea"
                            :value="barcodeTextfield"
                            label="Eingescannte PZN"
                            placeholder="Bitte scannen Sie den Barcode des benötigten Produkts!"
                        >
<!--                          @keyup="scanBarcode"-->
                        </v-text-field>
                      </validation-provider>
                    </div>
                    <div class="flex pa-3">
                      <v-text-field
                          :dark="compColor.isInDarkMode"
                          :color="compColor.primeColor"
                          outlined
                          :value="anzahl ? anzahl : '1'"
                          label="Artikel Anzahl"
                          readonly
                          hide-details
                          class="mb-0"
                          disabled
                      >
                      </v-text-field>
                    </div>
                  </div>
                  <div class="px-2" v-if="scanError">
                    <v-alert
                      border="left"
                      prominent
                      style="font-size:15px;"
                      class="pa-3"
                      v-bind:class="{'red lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 red lighten-5': !compColor.isInDarkMode}">
                      <template slot="prepend">
                        <v-avatar>
                          <v-icon>mdi-barcode-off</v-icon>
                        </v-avatar>
                      </template>
                      <div class="layout-row">
                        <div class="px-5 flex layout-column layout-align-center-start">
                          Der gescannte Artikel konnte nicht im Artikelstamm gefunden werden. Bitte suchen Sie die Pzn manuell oder wenden Sie sich an ihre beliefernde Apotheke.
                        </div>
                        <div class="layout-column layout-align-center-center">
                          <v-progress-circular
                          indeterminate
                          color="red lighten-3"
                        ></v-progress-circular>
                        </div>
                      </div>
                    </v-alert>
                  </div>

                  <div class="layout-row flex">
                        <div class="layout-column flex pa-3 pt-0">
                    <fake-input-frame label="In Warenkorb übernomme PZN" class="pa-2 flex" style="overflow-y: auto;">
                      <div style="width:100%;border-radius: 4px;" v-for="(item, index) in barCodeCartList" :key="index"
                           class="green lighten-1 elevation-2 white--text py-2 px-3 layout-row mt-1">
                        <div class="layout-column layout-align-center-center">
                          <strong>{{ item.pzn }}</strong>
                        </div>
                        <v-divider vertical class="mx-2"></v-divider>
                        <div class="layout-column layout-align-center-center text-h6 font-weight-bold">
                          {{ item.number_for_visualization }}x
                        </div>
                        <v-divider vertical class="mx-2"></v-divider>
                        <div class="flex layout-column layout-align-center-start">
                          <div class="layout-column">
                            <strong>{{ item.Produktname }}</strong>
                            <span class="body-2">{{ item.manufacturer_Langname }}</span>
                          </div>
                        </div>
                        <v-divider vertical class="mx-2"></v-divider>
                        <div class="layout-column layout-align-center-start">
                          <div class="body-2 layout-row" style="line-height: 12px">
                            <strong>{{ item.timestamp | formatDate('HH:mm') }}</strong>
                            <strong style="opacity: 0.5">:{{ item.timestamp | formatDate('ss') }}</strong>
                            <small class="ml-1"><strong style="opacity:0.5"> Uhr</strong></small>
                          </div>
                          <v-divider></v-divider>
                          <div class="caption"><strong>{{ item.timestamp | formatDate('DD.MM.YYYY') }}</strong></div>
                        </div>

                      </div>

                    </fake-input-frame>
                  </div>
                        <div v-if="barCodeAllKeysObjectVisibility" @click="barCodeAllKeysObjectVisibility = false" class="layout-column flex-25 pa-3 pt-0">
                          <fake-input-frame label="Key-Log" class="pa-2 flex" style="overflow-y: auto;">

                            <div class="layout-row layout-wrap blue-grey--text">
                              <span class="mr-1" v-for="(item, index) in barCodeAllKeysObject" :key="index">
                                <v-icon color="pink"  v-if="item === '||||'">mdi-drag</v-icon>
                                <span v-else>{{item}}</span>
                              </span>
                            </div>

                          </fake-input-frame>
                        </div>
                        <div v-else @click="barCodeAllKeysObjectVisibility = true" class="mx-2 my-3 grey white--text caption layout-column layout-align-center-center pa-2">Key<br>Log</div>
                  </div>
                  <div class="layout-column pa-3 pt-0">
                    <fake-input-frame label="Nicht übernommene Artikel (ZE-/NUB-Artikel)" class="pa-2 flex" style="min-height:90px;overflow-y: auto;">

                      <div style="width:100%;border-radius: 4px;" v-for="(item, index) in barCodeList" :key="index"
                           class="amber elevation-2 white--text py-2 px-3 layout-row mt-1">
                        <div class="layout-column layout-align-center-center">
                          <strong>{{ item.pzn }}</strong>
                        </div>
                        <v-divider vertical class="mx-2"></v-divider>
                        <div class="flex layout-column layout-align-center-start">
                          <div class="layout-column">
                            <strong>{{ item.Produktname }}</strong>
                            <span class="body-2">{{ item.manufacturer_Langname }}</span>
                          </div>
                        </div>
                        <v-divider vertical class="mx-2"></v-divider>
                        <div class="layout-column layout-align-center-start">
                          <div class="caption"><strong>{{ item.timestamp | formatDate('DD.MM.YYYY') }}</strong></div>
                          <div class="caption layout-row">
                            <strong>{{ item.timestamp | formatDate('HH:mm') }}</strong>
                            <small
                                style="padding-top: 5px;line-height: 12px;margin-right:3px;">{{ item.timestamp | formatDate('ss') }}</small>
                            <strong style="opacity:0.5">Uhr</strong>
                          </div>
                        </div>

                      </div>

                    </fake-input-frame>
                  </div>
          </div>
          <div class="scanhint layout-column layout-align-center-center" style="position:absolute;top:0;left:0;right:0;bottom:0;">
<!--            <img src="../../assets/qr-code.png" style="width:90px;" />-->
            <img src="../../assets/qr-scan.png" style="width:90px;" />
            <h3 class="mt-5 font-weight-light text-center blue-grey--text text--darken-2">Bitte klicken Sie innerhalb dieses Popup-Fensters<br>um mit dem Scannen zu beginnen bzw. fortzufahren.</h3>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <lx-btn :color="'#353a47'"
                  block
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  class="flex"
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click.stop="barcodeDialog = false"
                  icon="save"
                  tooltip="Barcodeeingabefenster schließen"
                  :label="'Schließen'"></lx-btn>
        </div>
      </template>
    </lx-dialog>

    <ze-dialog :departmentid="departmentid.toString()" v-model="zeModelItem" @onsave="addItem"></ze-dialog>
  </div>
</template>

<script>
import LxPdfViewer from '@/components/lx_pdf_viewer/LxPdfViewer'
import LxLoadingAnimation from '@/components/lx_loading_animation/LxLoadingAnimation'
import agGridComponentInputCellEditor from '@/components/lx_ag-grid/components/cellRenderer/numberUpDownCell'
import agGridComponentStoreageLocationCellRenderer
  from '@/components/lx_ag-grid/components/cellRenderer/storageLocationCell'
import moment from 'moment'
import router from '../../router'
import lodash from 'lodash'
import lxPagination from '../../components/lx_pagination/LxPagination'
import LxTextHighlight from "@/components/lx_text_highlighter/LxTextHighlight";
import {isMobile} from 'mobile-device-detect';
import ShoppingCartRevisionPolling from './ShoppingCartRevisionPolling'
import LxObfuscationPanel from '@/components/lx_obfuscation_panel/LxObfuscationPanel'
import zeDialog from './dialogs/zeDialog'
import sound from '../../assets/no-article-found.wav'
import {LockEvent} from './ShoppingCartHelpers'

const OrderRights = {
  'nurse': 0,
  'doctor': 1,
  'seniordoctor': 2,
}

function blockEvent(e){
  if (e && e.target.id === 'unique') {
    e.preventDefault();
    e.stopPropagation()
  }
}

export default {
  name: 'ShoppingCart',
  components: {
    zeDialog,
    LxPdfViewer,
    LxLoadingAnimation,
    // lxNumberfield,
    LxTextHighlight,
    LxObfuscationPanel,
    lxPagination,
    agGridComponentInputCellEditor,
    agGridComponentStoreageLocationCellRenderer
  },
  mixins: [ShoppingCartRevisionPolling],
  props: {
    departmentid: {
      type: String,
    }
  },
  data: () => {
    return {
    syncEvent: null, //new LockEvent(() => { console.log('Hello World')}),
    oldStorageLocationsForShoppingCart: null,
    activateResize: false,

    pdfObject: null,

    shortenButtons: true,
    articleComment: null,
    articleCommentDialog: false,

    searchString: null,
    tableObj: {
      loading: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
      options: {
        sortBy: ['Produktname'],
        sortDesc: [false],
        getClassForItem: (row) => {
          //console.log(lockObject.HasKey(row.item.pzn))
          if (row.item.flag === true) {
            return 'row-bold green lighten-5'
          }

          return ''
        }
      },
      filterValue: null,
      selection: null,
      headers: [
        // {
        //   text: '',
        //   value: 'data-table-select',
        //   sticky: 'left',
        //   width: '30px',
        //   hideRowByKeyValue: 'reported'
        // },
        {
          width: '25px',
          class: 'text-center',
          sticky: 'left',
          preventPl3Header: true,
          bodyClass: 'pa-0',
          sortable: true,
          renderer: 'article_info',
          noResize: true,
          alternateText: 'Artikel Info'
        },
        {
          text: 'Typ',
          value: 'list_Type',
          preventPl3Header: true,
          width: '35px',
          class: 'text-center',
          bodyClass: 'pa-0',
          sortable: true,
          renderer: 'list-type',
        },
        {
          text: 'Artikel',
          value: 'Produktname',
          sortable: true,
          renderer: 'highlighter',
          // filterable: true,
        },
        {
          text: 'DF',
          value: 'dosage_form',
          width: '40px',
          sortable: true,
        },
        {
          text: 'Packungsgröße',
          width: '95px',
          // value: 'Zahl',
          // sortable: true
          class: 'text-right pr-2',
          renderer: 'packaging'
        },
        {
          text: 'Wirkstoff',
          width: '150px',
          value: 'agent',
          sortable: true,
          renderer: 'highlighter',
        },
        {
          text: 'Auf Lager',
          value: 'on_stock',
          width: '70px',
          sortable: true,
          class: 'text-center',
          renderer: 'in_stock'
        },
        // {
        //   text: 'Bestand',
        //   value: 'Apo_Bestand',
        //   width: '70px',
        //   sortable: true,
        //   class: 'text-right pr-3'
        // },
        {
          text: 'Preis',
          value: 'Apo_Vk',
          width: '85px',
          class: 'text-right pr-3',
          sortable: true,
          renderer: 'currency_ger'
        },
        {
          text: 'Hersteller',
          value: 'manufacturer_Kurzname',
          sortable: true,
          renderer: 'highlighter',
        },
        {
          text: 'PZN',
          value: 'pzn',
          width: '70px',
          sortable: true,
          renderer: 'highlighter',
        },
        {
          text: 'Indikation',
          value: 'RoteListe',
          sortable: true
        },
        {
          text: 'Hinweis',
          value: 'Hinweis',
          width: '250px',
          sortable: true
        },
        {
          text: 'Hinweise (Alternativ)',
          value: 'HinweisAlternativ',
          width: '250px',
          sortable: true
        },
        {
          headerIcon: 'mdi-wardrobe',
          value: 'storage_location',
          preventPl3Header: true,
          sticky: 'right',
          class: 'text-center',
          sortable: false,
          renderer: 'storageLocation',
          noResize: false,
          bodyClass: 'px-0',
          width: '35px',
          alternateText: 'Artikel in Schrankliste einfügen'
        },
        {
          text: 'Anzahl',
          value: 'number',
          preventPl3Header: true,
          sticky: 'right',
          class: 'text-center',
          sortable: false,
          renderer: 'changeNumber',
          noResize: false,
          bodyClass: 'px-0',
          width: '60px',
        },
        {
          // text: 'Anzahl',
          value: 'age',
          width: '25px',
          sticky: 'right',
          sortable: false,
          renderer: 'addItem',
          noResize: false,
          bodyClass: 'px-0',
          alternateText: 'Artikel in Korb legen'
        },
      ],
    },
    items: [],
    tableObjShoppingCart: {
      loading: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
      options: {
        sortBy: ['_id'],
        sortDesc: [true]
      },
      filterValue: null,
      selection: null,
      headers: [
        {
          width: '25px',
          class: 'text-center',
          sticky: 'left',
          preventPl3Header: true,
          bodyClass: 'pa-0',
          sortable: true,
          renderer: 'article_info',
          noResize: true,
          alternateText: 'Artikel Info'
        },
        {
          text: 'Typ',
          value: 'ref_list',
          preventPl3Header: true,
          width: '35px',
          class: 'text-center',
          bodyClass: 'pa-0',
          sortable: true,
          renderer: 'list-type',
        },
        {
          text: 'Artikel',
          value: 'name',
          width: '250px',
          sortable: true
        },
        {
          text: 'DF',
          value: 'dosage_form',
          width: '40px',
          // class: 'text-center',
          sortable: true,
          // renderer: 'order_state'
        },
        {
          text: 'Packungsgröße',
          width: '95px',
          // value: 'Zahl',
          // sortable: true
          class: 'text-right pr-2',
          renderer: 'packaging'
        },
        {
          text: 'Hersteller',
          value: 'manufacturer_Kurzname',
          sortable: true
        },
        {
          text: 'PZN',
          value: 'pzn',
          sortable: true
        },
        {
          text: 'Preis',
          value: 'amount',
          width: '85px',
          class: 'text-right pr-3',
          sortable: true,
          renderer: 'currency_ger'
        },
        {
          text: 'Bearbeiter',
          value: 'username',
          sortable: false
        },
        {
          text: 'Letzte Änderung',
          value: 'modified',
          sortable: true,
          renderer: 'dateTime'
        },
        // {
        //   text: 'Wirkstoff',
        //   width: '150px',
        //   value: 'agent',
        //   sortable: true,
        // },
        {
          text: 'ZE / NUB',
          value: 'ze_item',
          preventPl3Header: true,
          sticky: 'right',
          class: 'text-center',
          sortable: false,
          renderer: 'ze_item',
          bodyClass: 'px-0',
          width: '40px',
          noResize: true,
        },
        {
          headerIcon: 'mdi-message-reply-text',
          value: 'comment',
          preventPl3Header: true,
          sticky: 'right',
          class: 'text-center',
          sortable: true,
          renderer: 'articleComment',
          bodyClass: 'px-0',
          width: '20px',
          noResize: true,
          alternateText: 'Artikelkommentar'
        },
        {
          text: 'aut idem',
          value: 'autidem',
          preventPl3Header: true,
          sticky: 'right',
          class: 'text-center',
          sortable: false,
          renderer: 'autidem',
          bodyClass: 'px-0',
          width: '30px',
          noResize: true,
        },
        {
          text: 'Anzahl',
          value: 'count',
          preventPl3Header: true,
          sticky: 'right',
          class: 'text-center',
          sortable: false,
          renderer: 'changeNumber',
          bodyClass: 'px-0',
          width: '60px',
          noResize: true,
        },
        {
          // text: 'Anzahl',
          value: 'age',
          sticky: 'right',
          width: '20px',
          class: 'px-0',
          bodyClass: 'px-0',
          renderer: 'removeItem',
          noResize: true,
          alternateText: 'Löschen'
        },
      ]
    },
    itemsShoppingCart: [],
    hideShoppingCart: false,
    hideItemSearch: false,
    isMobileDevice: isMobile,
    showPdfDialog: false,
    loca: null,
    zeModelItem: null,
    commentDialog: false,
    barcodeTextfield: '',
    barcodeSearchLoading: false,
    barcodeDialog: false,
    barCodeObject: [],
    barCodeAllKeysObject: [],
    barCodeAllKeysObjectVisibility: false,
    barCodeList: [],
    barCodeCartList: [],
    getSetLayoutOrientation: false,
    addStorageDialog: false,
    addStorageData: null,
    duration: null,
    intervalCounterTimer: null,
    deliverDate: null,
    userRightsLevel: 0,
    reqPermissionLevel: 0,
    btmItem: false,
    globalItem: false,
    dialog: false,
    switch2: false,
    searchDialog: false,
    articleData: null,
    verticalCols: false, //variable ob taballen nebeneinander oder übereinander angezeigt werden
    searchModel: {},  //enthält model aller Suchbegriffe
    loadingSearchGlobal: false, //Zustand ob Daten geladen werden in ABDA Liste
    loadingShoppingCart: false,  //Zustand ob Daten geladen werden im Warenkorb
    searchDelayTimer: null, //intervall timer zum warten nach Texteingabe im Suchfeld
    shoppingCartEmpty: true,

    // tab: 0, //welches tab gerade aktiv ist, stationsliste oder abda
    tab: 'location', //welches tab gerade aktiv ist, stationsliste oder abda
    storage: null,

    loading: false, // laden wenn bestellt wird
    loadingPDF: false, // laden wenn bestellt wird
    allowBlurOnLoading: true, //ob grid unscharf wird wenn man daten läd

    // frameworkComponents: null,

    defaultSortOpts: {
      colId: 'Produktname',
      sort: 'asc'
    },

    defaultSortOptsShoppingCart: {
      colId: 'name',  //<----- BITTE SO LASSEN
      sort: 'asc'
    },
    pagingOptions: {
      limit: 50,
      skip: 0,
      count: 0,
    },

    pagingOptionsGlobal: {
      limit: 50,
      skip: 0,
      count: 0,
      filter: {}
    },
    pagingOptionsShoppingCart: {
      limit: 50,
      skip: 0,
      count: 0,
      filter: {}
    },
    listSelectionList: [{id: 'location', name: 'Krankenhaus'}, {id: 'storage', name: 'Station'}, {
      id: 'global',
      name: 'Sonderartikel'
    }, {id: 'hitlist', name: 'Hitliste'}],
    locationList: [],
    locationName: null,
    location: null,
    arcticleID: null,
    usersNotified: false,
    shoppingCartModel: null,
    showPriceInformation: false,
    orderComment: null,
    oldOrderComment: null,
    hitlistenartikel: null,
    gesamtPreis: 0.0,
    modules: [],
    anzahl: null,
    disableOrderComment: true,
    articleInfoCache: [],
    articleInfoPending: false,
    scanError: false,
  }},
  beforeMount() {
    //Todo diese Liste sollte aus dem Backend kommen damit man sie nicht doppelt pflegen muss, am besten am Userobjet zum login
    let allRemoveableFields = [
      'pzn',
      'productname',
      'contents',
      'on_stock',
      'manufacturer',
      'agent',
      'retail_price',
      'dosage_form',
      'storage_location',
      'Hinweis',
      'HinweisAlternativ',
      'RoteListe',
      'Apo_Bestand'
    ]

    let user = this.getUser()
    let cols = []

    if (user && user.abdafields) {
      for (let i = 0; i < this.tableObj.headers.length; i++) {
        let all = allRemoveableFields.indexOf(this.tableObj.headers[i].value)
        let some = user.abdafields.indexOf(this.tableObj.headers[i].value)

        if (all < 0 || some >= 0) {
          cols.push(this.tableObj.headers[i])
        }
      }

      this.tableObj.headers = cols
    }

    /**
     * entferne alle Preise wenn Recht zur Ansicht nicht vorhanden
     * **/
    if (!this.showPriceInformation) {
      for (let i = 0; i < this.tableObj.headers.length; i++) {
        if (this.tableObj.headers[i].value === 'Apo_Vk') {
          this.tableObj.headers.splice(i, 1)
        }
      }

      for (let i = 0; i < this.tableObjShoppingCart.headers.length; i++) {
        if (this.tableObjShoppingCart.headers[i].value === 'amount') {
          this.tableObjShoppingCart.headers.splice(i, 1)
        }
      }
    }
  },
  created() {
    /**
     * Diese Klasse reagiert auf die Abfragen des Warenkorbs und der Artikelliste. Sind beide abgeschlossen werden die
     * Artikel in der Artikelliste markiert welche im Warenkorb vorhanden sind
     */
    this.syncEvent = new LockEvent(() => {
      // Konvertiert die Warenkorbartikel in ein Objekt, indem die PZN der Schlüssel ist
      let marker = this.itemsShoppingCart.reduce((a, v) => ({ ...a, [v.pzn]: true}), {})

      for (let i = 0; i < this.items.length; i++) {
        const v = marker[this.items[i].pzn] || false

        if (v !== this.items[i].flag) {
          this.$set(this.items[i], 'flag', v)
        }
      }
    })

    let user = this.getUser()

    if (user && user.abdafields) {
      this.showPriceInformation = (user.abdafields.indexOf('retail_price') >= 0)
    }

    this.getRoles().forEach((item) => {
      if (!isNaN(OrderRights[item]) && (this.userRightsLevel < OrderRights[item])) {
        this.userRightsLevel = OrderRights[item]
      }
    })

    this.location = this.departmentid

    let todayTemp = new Date()
    let today = moment(todayTemp).format('YYYYMMDD')

    this.$http.get('/deliveryplan/dates/' + today)
      .then((res) => {
        if (res && res.data && res.data.OrderDate) {
          let today = moment();
          let orderDate = moment(res.data.OrderDate)
          this.duration = moment.duration(orderDate.diff(today))._data;
          this.deliverDateMessage = moment(res.data.DeliverDate).format('dddd DD.MM.YYYY')
        }
      })
      .catch(this.NotifyError)

    this.$http.get('/settings/modules')
      .then((res) => {
        const data = res.data || {}

        this.modules = data.modules || []
        this.disableOrderComment = data.options?.disable_order_comment === true
      })

    this.$http.get('/department')
      .then((res) => {
        if (res.data && res.data.data) {
          this.locationList = res.data.data
        }
      })
      .catch(this.NotifyError)
      .finally(() => {
        this.StorageLocationsForShoppingCart = []
        //this.oldStorageLocationsForShoppingCart = lodash.cloneDeep(this.StorageLocationsForShoppingCart)

        if (this.locationList && this.location) {
          const getFruit = this.locationList.find(location => location._id === this.location.toString())

          if (getFruit) {
            this.locationName = getFruit.name
            this.$http.get('/storages/' + getFruit._id)
                .then((res) => {
                  this.StorageLocationsForShoppingCart = (res.data || [])
                })
                .finally(() => {

                })
          }
        }
      })
  },
  mounted () {
    document.addEventListener('keydown', blockEvent)
    document.addEventListener('keyup', blockEvent)
    document.addEventListener('keypress', blockEvent)
  },
  beforeDestroy() {
    document.removeEventListener('keydown',blockEvent)
    document.removeEventListener('keyup',blockEvent)
    document.removeEventListener('keypress',blockEvent)
  },
  methods: {
    openbarcodeDialog() {
      this.barcodeTextfield = ''
      this.barCodeList = []
      this.barCodeCartList = []
      this.barcodeDialog = this.ifBarcodeModuleEnabled

      this.$nextTick(()=>{
        let el = document.getElementById("myDiv");

        el.onkeydown = this.scanBarcode //()
        // function(evt) {
        //   evt = evt || window.event;
        //   console.log("keydown: " + evt.keyCode);
        // };

      })
    },
    setStorageActiveInactive(item) {
      if (item.isSelected) {
        this.$set(item, 'isSelected', false)
      } else {
        this.$set(item, 'isSelected', true)
      }

    },
    getHoverDetailValues (itemOriginal) {
      if (!itemOriginal || !itemOriginal["pzn"]) {
        return
      }

      this.articleInfoPending = true
      this.$http.get("/abda/article/info/" + this.departmentid.toString() + '/' + itemOriginal["pzn"])
          .then((result) => {
            let abdaItem = []

            let item = lodash.cloneDeep(itemOriginal)

            // for (let i in item) {
            if (!item['unit'] && item['Zahl'] && item['Einheit']) {
              item['unit'] = item['Zahl'] + '' + item['Einheit']
            }
            // }

            delete item.count

            for (let i in item) {
              if (Object.prototype.hasOwnProperty.call(item, i)) {
                switch (i) {
                  case 'pzn':
                    abdaItem.push({
                      label: 'PZN',
                      index: 1,
                      value: item[i]
                    });
                    break;
                  case 'Produktname':
                    abdaItem.push({
                      label: 'Artikel',
                      index: 2,
                      value: item[i]
                    });
                    break;
                  case 'agent':
                    abdaItem.push({
                      label: 'Wirkstoff',
                      index: 3,
                      value: item[i]
                    });
                    break;
                  case 'dosage_form':
                    abdaItem.push({
                      label: 'Darreichungsform',
                      index: 4,
                      value: (result?.data?.darr_long) ? item[i] + ' - ' +result?.data?.darr_long : item[i]
                    });
                    break;
                  case 'unit':
                    abdaItem.push({
                      label: 'Packungsgröße',
                      index: 4,
                      value: item[i]
                    });
                    break;
                  case 'manufacturer_Langname':
                    abdaItem.push({
                      label: 'Hersteller',
                      index: 5,
                      value: item[i]
                    });
                    break;
                  case 'Hinweis':
                    abdaItem.push({
                      label: 'Hinweis',
                      index: 6,
                      value: item[i]
                    });
                    break;
                  case 'HinweisAlternativ':
                    abdaItem.push({
                      label: 'Hinweis (Alternativ)',
                      index: 7,
                      value: item[i]
                    });
                    break;
                  case 'username':
                    abdaItem.push({
                      label: 'Bearbeiter',
                      index: 8,
                      value: item[i]
                    });
                    break;
                  case 'modified':
                    abdaItem.push({
                      label: 'Letzte Änderung',
                      index: 9,
                      value: this.$options.filters['formatDate'](item[i], 'DD.MM.YYYY HH:mm')
                    });
                    break;
                  case 'RoteListe':
                    abdaItem.push({
                      label: 'Indikation',
                      index: 10,
                      value: item[i]
                    });
                    break;
                  case 'count':
                    abdaItem.push({
                      label: 'Anzahl',
                      index: 11,
                      value: item[i]
                    });
                    break;
                  case 'Apo_Vk':
                    abdaItem.push({
                      label: 'Preis',
                      index: 12,
                      value: this.$options.filters['currency_ger'](item[i]) + ' €'
                    });
                    break;
                  case 'amount':
                    abdaItem.push({
                      label: 'Preis',
                      index: 13,
                      value: this.$options.filters['currency_ger'](item[i]) + ' €'
                    });
                    break;
                  case 'Apo_Bestand':
                    if (item[i] > 0) {
                      abdaItem.push({
                        label: 'Apothekenbestand',
                        index: 14,
                        color: 'green lighten-2',
                        icon: 'done',
                        value: 'lagernd'
                      })
                    } else if (item[i] <= 0) {
                      abdaItem.push({
                        label: 'Apothekenbestand',
                        index: 14,
                        color: 'red lighten-2',
                        icon: 'clear',
                        value: 'nicht lagernd'
                      })
                    }
                    break;
                  default:
                    break;
                }
              }
            }

            // Sortieren nach Wert
            abdaItem.sort(function (a, b) {
              return a.index - b.index;
            });

            this.articleInfoCache = abdaItem
          })
          .catch(() => {})
          .finally(() => {
            this.articleInfoPending = false
          })

    },

    hideAddButtonIfBTM(value){
      return value.Abgabebestimmung === "3" || value.levy_regulation === "3"
    },

    getListTypeValues(value) {
      if (value.Abgabebestimmung === "3" || value.levy_regulation === "3") {
        return { text: 'BTM', color: this.compColor.isInDarkMode ? 'red lighten-2' : 'red lighten-4' }
      }

      if ((value.location_id && value.location_id !== value.owner_id) || value.ref_list === 10) {
        return { text: 'KH', color: this.compColor.isInDarkMode ? 'green lighten-2' : 'green lighten-4' }
      } else {
        return { text: 'SA', color: this.compColor.isInDarkMode ? 'amber lighten-2' : 'amber lighten-4' }
      }
    },
    /**
     *
     * @param obj The complete object
     * @param path The path as dot notation to the field value (e.g. 'address.street.number')
     * @returns {*}
     */
    getValueByObjectPath(obj, path) {
      if (path.indexOf('.') <= 0) {
        return obj[path]
      }
    },

    /**
     * Check if objet has property
     * @param obj Object
     * @param path String
     * @returns {boolean}
     */
    hasValue(obj, path) {
      if (typeof obj === 'object') {
        return obj.hasOwnProperty(path)
      }

      return false
    },

    /**
     * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
     */
    changeTableOptions(value) {
      if (value) {
        this.tableObj.options = value
      }

      this.getData()
    },
    /**
     * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
     */
    changeTableOptionsShoppingCart(value) {
      if (value) {
        this.tableObjShoppingCart.options = value
      }

      this.getShoppingCartData()
    },

    scanBarcode(event) {

      event.preventDefault()
      event.stopPropagation()

      this.scanError = false

      let pzn = null

      //this.barCodeAllKeysObject.push(event.key + '(' + event.keyCode + '),')
      this.barCodeAllKeysObject.push(event.key + ', ')
      // this.barCodeAllKeysObject.push(event.keyCode + ', ')

      const keys = ['0','1','2','3','4','5','6','7','8','9','n','N']
      const keysNumbersOnly = ['0','1','2','3','4','5','6','7','8','9']
      const enter = ['Enter']
      const ppnPruefCodes = [48,49,50,51,52,53,54,55,56,57] // 0 - 9

      if (event.keyCode === 45 || event.keyCode === 43) {
        this.barCodeObject = []
      } else if (keys.includes(event.key)) {
      // } else if (event.keyCode !== 13 && event.keyCode > 47 && event.keyCode < 58 || event.keyCode === 78) {
        /** keycodes: 48 - 57 sind die zahlen 0 - 9  **/
        /** keycodes: 78 ist der buchstabe n um später die n11 zu bekommen  **/
        /** durch die starke einschränkung kommen nur zahlen durch, und der buchstabe n **/
        this.barCodeObject.push(event.key)
        // console.log('pushit: ', this.barCodeObject)
      }

      if (enter.includes(event.key)) {
        this.barcodeTextfield = ''

        if (this.barCodeObject.length < 10 && this.barCodeObject.length > 6) {
          this.barcodeSearchLoading = true
          let tempPzn = []

          this.barCodeObject.forEach(item=>{
            if (keysNumbersOnly.includes(item)){
              tempPzn.push(item)
            }
          })

          pzn = tempPzn.join('')
          //TODO wenn nur 7 stellen dann 0 davorhängen, sollte auch ohne gehen aber es könnte ja ausversehen eine 1-1234567 oder 2-1234567 gefunden werden.
          while(pzn.length < 8){
            pzn = '0' + pzn
          }

          this.barCodeAllKeysObject.push('[Format - Barcode][PZN: '+pzn+']')
        } else if(this.barCodeObject.length > 8){
          const dmc = this.barCodeObject.join('').toLowerCase() //
          let found = false

          if(dmc.indexOf('04150') >= 0 && !found) {
            let index = dmc.indexOf('04150')
            let tempPzn = dmc.slice(index + 5, index + 5 + 8)
            let pruefZiffer = dmc.slice(index + 13, index + 13 + 1)
            let elevenPzn = '04150' + tempPzn
            let summe = 0

            for (let i = 0; i < elevenPzn.length; i++) {
              let digit = elevenPzn.charAt(i)
              let gewicht = i % 2 > 0 ? 1 : 3
              summe += (gewicht * parseInt(digit, 10))
            }

            let rest = (Math.ceil(summe / 10) * 10) - summe

            if (rest.toString() === pruefZiffer) {
              pzn = tempPzn
              found = true
              this.barCodeAllKeysObject.push('[Format - 04150][PZN: ' + pzn + '][' + rest + '][' + pruefZiffer + ']')
            }
          }
          if(dmc.indexOf('n11') >= 0 && !found) {
            let indexPPN = dmc.indexOf('n11')
            let tempPzn = dmc.slice(indexPPN + 3, indexPPN + 3 + 8)
            let pruefZiffer = dmc.slice(indexPPN + 11, indexPPN + 11 + 2)
            let elevenPzn = '11' + tempPzn
            let summe = 0

            for (let i = 0; i < elevenPzn.length; i++) {
              let digit = elevenPzn.charAt(i)
              summe += ((i + 2) * ppnPruefCodes[parseInt(digit,10)])
            }

            let rest = summe % 97

            if(rest <= 9) {
              rest = '0' + rest
            }

            if(rest.toString() === pruefZiffer) {
              pzn = tempPzn
              found = true
              this.barCodeAllKeysObject.push('[Format - n11][PZN: '+pzn+']['+rest+']['+pruefZiffer+']')
            }
          }

          if(dmc.indexOf('710') >= 0 && !found) {
            let indexPPN = dmc.indexOf('710')
            pzn = dmc.slice(indexPPN + 3, indexPPN + 3 + 8)
            this.barCodeAllKeysObject.push('[Format - 710][PZN: '+pzn+']')
          }
        } else {
          this.anzahl = this.barCodeObject.join('')

          if(this.anzahl.length > 2){
            this.anzahl = '99'
          }
        }

        this.barCodeObject = [] //resete die eingabe
        this.barCodeAllKeysObject.push('||||')

        if(pzn){
          let param = '/abda/article/info/' + this.location.toString() + '/' + pzn

          this.$http.get(param)
              .then((res) => {
                let obj = res.data
                let count = 1
                if(this.anzahl){
                  count = this.anzahl
                }
                obj.number = parseInt(count, 10)
                obj.number_for_visualization = count
                obj.timestamp = new Date()

                if(obj.zeitem_type){
                  this.barCodeList.push(obj)
                } else {
                  this.addItem(obj)
                  this.barCodeCartList.unshift(obj)
                }
                this.anzahl = null
              })
              .catch((err)=>{
                if(err?.response?.status === 404){
                  this.scanError = true
                  let audio = new Audio(sound);
                  audio.play();
                  setTimeout(()=>{
                    this.scanError = false
                  },10000)
                } else {
                  this.NotifyError(err)
                }
              })
              .finally(() => {
                this.barcodeSearchLoading = false
              })
        } else {
          if (!this.anzahl) {
            this.NotifySystem({
              title: 'Scan-Datenformat nicht erkannt',
              text: 'Das Datenformat des gescannten Artikel konnte nicht erkannt werden. Bitte scannen Sie ihn erneut oder fügen suche Sie ihn manuell.',
              type: 'warning',
              duration: 3000
            });
          }
        }
      }
    },

    back() {
      router.push('/shopping')
    },

    generateListPDF(storageid) {
      if (this.departmentid) {
        this.loadingPDF = true;
        this.showPdfDialog = true;

        let param = this.departmentid;

        if (storageid) {
          param += '/' + storageid;
        }

        this.$http.getAsBlob('/shoppingcart/generatelistpdf/' + param)
          .then((res) => {
            if (res.data instanceof Blob) {
              this.pdfObject = res.data
            }
          })
          .catch(this.NotifyError)
          .finally(() => {
            this.loadingPDF = false;
          })
      }
    },

    generateShoppingCartPDF() {
      if (this.departmentid) {
        this.loadingPDF = true
        this.showPdfDialog = true

        this.$http.getAsBlob('/shoppingcart/generatepdf/' + this.departmentid)
          .then((res) => {
            if (res.data instanceof Blob) {
              this.pdfObject = res.data
            }
          })
          .catch(this.NotifyError)
          .finally(() => {
            this.loadingPDF = false
          })
      }
    },

    changeArticleList() {
      // if (this.tab === 'global' || this.tab === 'location' || this.tab === 'hitlist' || this.tab === 'storage') {
      this.pagingOptions.skip = 0 //<-- Wichtig sonst sieht man eventuell keine Artikel, pager zeigt zwar seite 1 an aber skip ist noch x
      this.storage = null
      this.getData()
      // }
    },

    /**
     *
     * berechnet die Farben für das Dropdown Menu der Listen z.b. Stationsliste grün
     *
     * **/
    getBackgroundColorForList() {
      let color = 'green lighten-4'

      if (this.tab === 'location') {
        color = this.compColor.isInDarkMode ? 'green lighten-2' : 'green lighten-4'
      } else if (this.tab === 'global') {
        color = this.compColor.isInDarkMode ? 'amber lighten-3' : 'amber lighten-4'
      }

      return color
    },

    removeItem(data) {
      this.deleteItem(data.articleid)
    },

    addItemToStorage(articleData) {
      if (!this.addStorageDialog) {
        this.addStorageData = articleData
        this.addStorageDialog = true

        this.StorageLocationsForShoppingCart.map(item => {
          let found = false;

          if (this.addStorageData.storage_location && Array.isArray(this.addStorageData.storage_location))
            for (let i = 0; i < this.addStorageData.storage_location.length; i++) {
              if (item._id === this.addStorageData.storage_location[i].id) {
                found = true
              }
            }

            this.$set(item, 'isSelected', found)
        })
        this.oldStorageLocationsForShoppingCart = lodash.cloneDeep(this.StorageLocationsForShoppingCart)
      } else {
        this.addStorageDialog = false

        let storage_ids_in = []
        let storage_ids_out = []

        storage_ids_out = this.StorageLocationsForShoppingCart.filter(item => !item.isSelected).map(item => {
          return item._id
        })

        storage_ids_in = this.StorageLocationsForShoppingCart.filter(item => item.isSelected).map(item => {
          return item._id
        })

        let query = {
          'storage_ids_in': storage_ids_in,
          'storage_ids_out': storage_ids_out,
          'department_id': this.departmentid,
          'pzn': this.addStorageData.pzn
        };

        this.$http.put('/storage/push ', query)
          .then(() => {
            this.NotifySystem('Änderungen gespeichert')
            this.StorageLocationsForShoppingCart = lodash.cloneDeep(this.oldStorageLocationsForShoppingCart)
            this.getData()
          })
          .catch(this.NotifyError)
      }
    },

    editItemInStorage(articleData) {
      let param = `/storage/findbypzn/${this.departmentid.toString()}/${articleData.pzn}`

      this.$http.get(param)
          .then((res) => {
            this.addStorageData = {
              ...articleData,
              storage_location: res.data
            }
            this.addStorageDialog = true

            this.StorageLocationsForShoppingCart.map(item => {
              let found = false

              if (this.addStorageData.storage_location && Array.isArray(this.addStorageData.storage_location))
                for (let i = 0; i < this.addStorageData.storage_location.length; i++) {
                  if (item._id === this.addStorageData.storage_location[i].id) {
                    found = true
                    break
                  }
                }

              this.$set(item, 'isSelected', found)
            })

            this.oldStorageLocationsForShoppingCart = lodash.cloneDeep(this.StorageLocationsForShoppingCart)

          })
          .catch(this.NotifyError)
    },

    deleteItemFromStorage(articleData) {
      if (!Array.isArray(articleData.storage_location) && articleData.storage_location.id) {
        let storageId = articleData.storage_location.id

        if (articleData.pzn || articleData.referer_pzn) {

          /**
           * wenn artikel aus der AML liste oder der abda entfernt werden können diese noch in stationslisten oder hitlisten
           * stehen aber nicht mehr aufgelöst werden, in diesen Fällen wird keine pzn zurückgegeben sondern nur die referer_pzn
           * also die pzn welche in den stations oder hitlisten steht.
           * 1 Fall) wenn keine pzn und eine referer_pzn dann Artikel nicht mehr auflösbar
           * 2 Fall) wenn pzn und referer_pzn dann Artikel noch auflösbar
           *
           * mit pzn und referer_pzn kann jeder Artikel aus Stationsliste gelöscht werden
           * */
          let query = {
            'storage_id': storageId,
            'department_id': this.departmentid,
            'pzn': !articleData.pzn ? articleData.referer_pzn : articleData.pzn
          };

          this.$http.put('/storage/pull ', query)
            .then(() => {
              this.NotifySystem('Änderungen gespeichert')
              this.getData()
            })
            .catch(this.NotifyError)
        }
      }
    },

    addItem(articleData, forceAdd) {
      let data

      if (forceAdd) {
        data = {
          pzn: this.zeModelItem.pzn,
          count: 4,
          ze_item: articleData
        }
      } else {
        data = {
          pzn: articleData.pzn,
          count: (!articleData.number) ? 1 : articleData.number,
        }

        this.articleData = articleData;

        if (!articleData.number) {
          articleData.number = 1
        }
      }

      this.loadingShoppingCart = true
      this.usersNotified = false

      this.$http.post('/shoppingcart/' + this.departmentid.toString(), data)
        .then(() => {
          if (this.tab === 'global') {
            this.searchModel = {}

            let child = this.$refs.abdaSearchField
            child.resetFields()

            this.tab = this.listSelectionList[0].id //automatisch auf Stationsliste zurückspringen
          }

          this.getShoppingCartData();

          articleData.number = 1
        })
        .catch(error => {
          // let errorMsg = error.message;

          if (error.response?.status === 403) {
          // if (errorMsg.indexOf('403') >= 0) {
            this.openZeItemDialog(articleData)
          } else {
            this.NotifyError(error)
          }
        })
        .finally(() => {
          this.loadingShoppingCart = false;
          this.zeModelItem = null
        })
    },

    openZeItemDialog(articleData) {
      const pzn = articleData.pzn
      this.zeModelItem = null

      this.$http.get('/shoppingcart/article/' + this.departmentid.toString() + '/' + pzn)
        .then((result) => {
          this.zeModelItem = result.data
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.zeModelItem = articleData
            return
          }

          // this.zeArray = []
          this.NotifyError(error)
        })
        .finally(() => {})
    },

    changeArticleCount(newCount) {
      if (newCount.value && newCount.value.articleid && newCount.value.count > 0) {
        this.patchItem(newCount.value.articleid, {
          count: newCount.value.count
        })
      }
    },

    changeStorageList() {
      if (this.tab === 'global' || this.tab === 'location' || this.tab === 'hitlist' || this.tab === 'storage') {
        this.pagingOptionsGlobal.skip = 0; //<-- Wichtig sonst sieht man eventuell keine Artikel, pager zeigt zwar seite 1 an aber skip ist noch x
        this.getData()
      }
    },

    getZeNubValues(zeItem) {
      // const articles = zeItem.length
      let article_count = 0
      zeItem.forEach(item => {
        article_count += item.article_count
      })
      return article_count // + '(' + articles + ' Artikel)'
    },

    changeStorageLocation(storage_location, data) {
      this.patchItem(data.articleid, {
        storage_location: storage_location.name || '',
        storage_location_id: storage_location._id || ''
      });
    },

    changeAutIdem(data) {
      this.patchItem(data.articleid, {
        autidem: !data.autidem
      });
    },

    saveArticleComment() {
      this.articleCommentDialog = false
      this.patchItem(this.articleComment.articleid, {
        comment: this.articleComment.comment
      });
    },

    changeArticleComment(data) {
      this.articleCommentDialog = true
      this.articleComment = data
    },

    deleteItem(articleId) {
      if (!articleId) return

      this.loadingShoppingCart = true
      this.usersNotified = false

      this.$http.delete('/shoppingcart/' + this.departmentid.toString() + '/' + articleId.toString())
        .then(() => {
          this.getShoppingCartData();
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loadingShoppingCart = false;
        })
    },

    patchItem(articleId, data) {
      if (!articleId || !data) return

      this.loadingShoppingCart = true
      this.usersNotified = false

      this.$http.patch('/shoppingcart/' + this.departmentid.toString() + '/' + articleId.toString(), data)
        .then(() => {
          this.getShoppingCartData();
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loadingShoppingCart = false;
        })
    },

    /**
     * wird von der unter componenten lx-abda-search aufgerufen um nach eine 700ms verzögern den eingegebenen suchbegriff zu suchen
     * gleichzeitig in den stationslisten und abda listen schauen
     * **/
    getAbdaData(value) {
      let opts = { skip: 0 };

      this.searchModel = {};
      Object.assign(this.searchModel, {}, value);

      this.getData(opts);
    },

    /**
     * getData Methode die über ABDA Liste sucht
     * wird beim sortieren oder suchbegriff schreiben ausgeführt, dann mit verzögerung
     * **/
    getData(options) {
      if (this.tableObj.loading) return
      this.tableObj.loading = true
      this.syncEvent.Lock()

      this.loadingSearchGlobal = true
      let param = this.tab

      if (options && typeof options.skip === 'number') {
        this.pagingOptions.skip = options.skip
      }

      if (options && options.limit) {
        this.pagingOptions.limit = options.limit
      }

      const params = this.$helper.GetQueryParam({
        ...this.tableObj.options,
        skip: this.pagingOptions.skip,
        limit: this.pagingOptions.limit,
        query: this.searchModel,
        count: true,
      })

      if (param === 'hitlist' || param === 'location' || param === 'global' || param === 'storage') {
        param += '/' + this.location.toString();
        if (this.tab === 'storage' && this.storage) {
          param += '/' + this.storage;
        }
      }

      // Sort on_stock descent also ONLY if query defined
      if (!lodash.isEmpty(params.config.query)) {
        params.config.opts.sort = [
          { 'on_stock': -1 },
          params.config.opts.sort
        ]
      }

      this.$http.get('/abda/search/' + param, params)
        .then(res => {
          if (res.data) {
            this.items = res.data.result || []
            this.pagingOptions.count = res.data.count || 0
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.tableObj.loading = false
          this.syncEvent.UnLock()
        })
    },

    getShoppingCartData(options) {
      if (this.tableObjShoppingCart.loading) return
      this.tableObjShoppingCart.loading = true
      this.Lock();
      this.syncEvent.Lock()
      this.gesamtPreis = 0.0;

      if (options && typeof options.skip === 'number') {
        this.pagingOptionsShoppingCart.skip = options.skip;
      }

      if (options && options.limit) {
        this.pagingOptionsShoppingCart.limit = options.limit;
      }

      const params = this.$helper.GetQueryParam({
        ...this.tableObjShoppingCart.options,
        skip: this.pagingOptionsShoppingCart.skip,
        limit: this.pagingOptionsShoppingCart.limit,
        query: {},
        count: true,
        // searchString: this.searchString,
        // searchFields: ['medical_name', 'department_name', 'ordernr']
      })

      this.$http.get('/shoppingcart/' + this.location.toString(), params)
        .then((res) => {
          if (res.data) {
            this.UpdateRevision(res.data.revision)

            this.itemsShoppingCart = res.data.result || []
            this.pagingOptionsShoppingCart.count = res.data.count || 0

            this.shoppingCartModel = res.data.count
            this.shoppingCartEmpty = !Array.isArray((res.data || {}).result) || (res.data.result.length === 0)

            this.reqPermissionLevel = isNaN(res.data.pem_level) ? 0 : res.data.pem_level
            this.gesamtPreis = isNaN(res.data.price_total) ? 0 : res.data.price_total
          }

        })
        .catch(this.NotifyError)
        .finally(() => {
          this.tableObjShoppingCart.loading = false
          this.Unlock()
          this.syncEvent.UnLock()
        })
    },

    orderShoppingCart() {
      if (!this.departmentid) return

      let roles = this.getRoles()

      if (roles.indexOf('seniordoctor') < 0) {
        // this.gridOptionsShoppingCart.api.forEachNode(node => {
        this.itemsShoppingCart.forEach(node => {
          let data = node;
          if (data.Abgabebestimmung === "3" || data.levy_regulation === "3") {
            this.btmItem = true
          }
          if (!data.location_id && data.ref_list !== 10) {
            this.globalItem = true
          }
        });
      }

      this.$http.get('/department/' + this.departmentid.toString())
        .then((res) => {
          if (res && res.data) {
            this.orderComment = res.data.order_comment;
            this.oldOrderComment = lodash.cloneDeep(this.orderComment)
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.dialog = true
        })
    },

    completeOrder() {
      this.dialog = false

      this.$http.post('/orders/complete/' + this.departmentid.toString(), { comment: this.orderComment })
        .then(() => {
          this.NotifySystem({
            title: 'Bestellung',
            text: 'Die Bestellung wurde erfolgreich plaziert.',
            type: 'success'
          });
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.orderComment = null;
          this.oldOrderComment = lodash.cloneDeep(this.orderComment)
          this.getShoppingCartData()
        })
    },

    notifyOrderComplete() {
      this.dialog = false;
      this.$http.post('/orders/notify/' + this.departmentid.toString(), null)
        .then(() => {
          this.NotifySystem({
            title: 'Bestellung',
            text: 'Der Warenkorb wurde erfoglreich zum "Bestellen bereit" markiert.',
            type: 'success'
          });
          this.usersNotified = true;
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.getShoppingCartData()
        })
    },

    editComment() {
      if (!this.departmentid) return

      this.$http.get('/department/' + this.departmentid.toString())
        .then((res) => {
          if (res && res.data) {
            this.orderComment = res.data.order_comment;
            this.oldOrderComment = lodash.cloneDeep(this.orderComment)
            this.commentDialog = true
          }
        })
        .catch(this.NotifyError)
    },

    saveComment() {
      this.commentDialog = false

      if (!this.departmentid) return

      const model = {
        order_comment: this.orderComment
      }

      this.$http.put('/department/ordercomment/' + this.departmentid.toString(), model)
        .then(this.NotifySuccess)
        .catch(this.NotifyError)
    },
  },
  computed: {
    showAddStoragePopupSaveBtn() {
      return lodash.isEqual(this.oldStorageLocationsForShoppingCart, this.StorageLocationsForShoppingCart)
    },
    showSaveBtn() {
      if (lodash.isEqual(this.oldOrderComment, this.orderComment)) {
        return false
      }

      let test = this.$refs['editOrderCommentPopupForm']

      if (test) {
        return !test.flags.pristine && test.flags.valid
      }

      return true
    },
    possibleStorageLocationsForShoppingCart() {
      let rest = [];

      if (!this.addStorageData || this.addStorageData.length <= 0) {
        rest = this.StorageLocationsForShoppingCart
      } else {
        rest = lodash.differenceBy(this.addStorageData, this.StorageLocationsForShoppingCart, '_id')
      }

      return rest
    },
    hasPermissionLevel: function () {
      return this.userRightsLevel >= this.reqPermissionLevel;
    },
    ifBarcodeModuleEnabled: function () {
      return Array.isArray(this.modules) && (this.modules.indexOf('barcode') !== -1)
    }
  }
}//)
</script>

<style lang="scss">
.scanpopup{

  &:not(:focus){
    background: rgb(255, 216, 101) !important;
    .blured{
      filter: blur(5px) !important;
    }
    .scanhint{
      display:flex;
    }
  }
  &:focus{
    outline: none;
    //border:2px solid lime;
    .blured {
      filter: none;
    }
    //background: rgba(255,255,255,1) !important;

    .scanhint{
      display:none;
    }
  }
}
.ag-header-cell-label {
  text-align: center;
}

.v-navigation-drawer__content {
  -webkit-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
}

.ag-header-cell-label {
  /*justify-content: right;*/
  text-align: right;
}

.lx-search-field.v-text-field.v-text-field--solo .v-input__control {
  min-height: 44px;
  padding: 0;
}

.lx-search-container {
  .v-expansion-panel-header {
    padding: 4px;
    min-height: 50px !important;

    &.v-expansion-panel--active {
      min-height: 50px !important;
    }
  }

  .v-expansion-panel {
    border-radius: 0 !important;

    &:before {
      box-shadow: none;
    }
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }

  .v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 45px;
  }

}

.paging-shadow,
.ag-header {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5) !important;
  z-index: 1 !important;
}

td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}


</style>
