<template>
  <v-menu
      offset-y
      transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <div>
        <lx-btn
            :color="color"
            :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
            small
            is-menu
            iconSize="20"
            width="auto"
            classes="text-none mr-1"
            :icon="icon"
            :tooltip="tooltip"
            :label="label"
            :loading="loading > 0"
            :disabled="disabled"
            v-bind="attrs"
            v-on="on"
        ></lx-btn>
      </div>
    </template>
    <v-list
        :color="compColor.primary"
        :dark="isDarkMode"
        :dense="denseFields"
    >
      <v-list-item
          v-for="(item, index) in exportTypes"
          :key="index"
          :disabled="!item.enabled"
          @click="generate(item.type)"
      >
        <v-list-item-icon>
          <v-icon
              small
              v-text="item.icon"
          ></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
              style="cursor: pointer"
              v-text="item.caption"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import moment from "moment";

const reContentDisposition = /filename="(.*)"/gmi

function getFilenameFromResponse(request, defaultFilename) {
  if (request) {
    let gg = reContentDisposition.exec(request.getResponseHeader('Content-Disposition'))

    if (Array.isArray(gg) && gg.length > 1) {
      return gg[1]
    }
  }

  return defaultFilename
}

export default {
  name: 'LxReport',
  props: {
    options: Object,
    url: String,
    color: {
      type: String,
      default () {
        return '#353a47'
      }
    },
    label: String,
    tooltip: String,
    disabled: {
      type: Boolean,
      default () {
        return false
      },
    },
    icon: {
      type: String,
      default () {
        return 'mdi-file-chart-outline'
      }
    }
  },
  data () {
    return {
      loading: 0,
      exportTypes: [
        {caption: 'JSON', icon: 'mdi-code-json', type: 'json', enabled: true, extension: '.json'},
        {caption: 'CSV', icon: 'mdi-file-delimited', type: 'csv', enabled: true, extension: '.csv'},
        {caption: 'Excel', icon: 'mdi-file-excel', type: 'xlsx', enabled: true, extension: '.xlsx'},
        {caption: 'XML', icon: 'mdi-xml', type: 'xml', enabled: true, extension: '.xml'}
      ],
    }
  },
  methods: {
    generate(type) {
      this.loading++

      const params = {
        options: {
          ...this.options,
          // filename: 'dependencies',
          // type: 'medicalunit',
          output: type
        }
      }

      this.$http.getAsBlob(this.url, params)
          .then((res) => {
            if (res.status === 202) {
              return this.NotifySystem({
                title: "Keine Daten",
                text: "Anfrage erfolgreich aber keine Daten gefunden",
                type: "warning"
              })
            }

            const filename = getFilenameFromResponse(
                res.request,
                params.options.filename + '_' + moment().format('YYYYMMDD_HHmmss') + '.' + type
            )

            let objectURL = URL.createObjectURL(res.data)
            const hiddenElement = document.createElement('a')
            hiddenElement.style.display = 'none'
            hiddenElement.href = objectURL
            hiddenElement.setAttribute('download', filename)
            document.body.appendChild(hiddenElement)
            hiddenElement.click()
            document.body.removeChild(hiddenElement)

            setTimeout(() => {
              // For Firefox it is necessary to delay revoking the ObjectURL
              URL.revokeObjectURL(objectURL)
            }, 100)
          })
          .catch(this.NotifyError)
          .finally(() => {
            this.loading--
          })
    }
  }
}
</script>

<style scoped>

</style>