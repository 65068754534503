<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">

          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="refresh"
                  :hide-label="true"
                  tooltip="Ansicht aktualisieren"
                  :label="'Aktualisieren'"></lx-btn>
        </div>
        <div class="flex layout-row"></div>

      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="pt-0 pb-2 px-1 layout-row">
        <v-text-field
          :dark="isDarkMode"
          :color="compColor.primeColor"
          :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
          class="mt-2 mb-0"
          label="Suchbegriff(e) eingeben"
          hide-details
          outlined
          :dense="denseFields"
          prepend-inner-icon="mdi-magnify"
          clearable
          v-model="searchString"
        ></v-text-field>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px;"
             :style="{'background': compColor.lxExpansion.background}">


          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1);" class="flex layout-column">
            <lx-data-table
              v-model="tableObj.selection"
              tableName="ShoppingCartList"
              :items="items"
              :headers="tableObj.headers"
              :loading="tableObj.loading"
              :tableOptions="tableObj.options"
              :hideContextFilter="true"
              @changeOptions="changeTableOptions"
              @rowClicked="openListDetails">

              <template v-slot:masterdetail="{ item }">
                <diagnoses-list :patientId="item._id"></diagnoses-list>
              </template>

              <template v-slot:cellrenderer="{ value }">
                <div v-if="value.f.renderer === 'order_state'" style="padding:1px;height:35px;">
                  <div class="layout-row layout-align-start-center" style="border-radius:3px;height:33px;">
                    <span
                      v-if="getValueByObjectPath(value.i, value.f.value) === 1 && getValueByObjectPath(value.i, 'articles') > 0"
                      class="green--text">zum
                      bestellen markiert</span>
                    <span
                      v-if="getValueByObjectPath(value.i, value.f.value) === 0 && getValueByObjectPath(value.i, 'articles') > 0"
                      class="orange--text">in
                      Bearbeitung</span>
                    <span
                      v-if="getValueByObjectPath(value.i, value.f.value) === 0 && getValueByObjectPath(value.i, 'articles') <= 0">leer</span>
                  </div>
                </div>
              </template>
            </lx-data-table>
          </div>
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="layout-row pa-0">
        <lx-pagination :page-name="'orderList'"
                       :page-options="pagingOptions"
                       :page-change="getData">
        </lx-pagination>
      </div>

    </div>

    <v-dialog v-model="dialog" scrollable persistent max-width="550px">
      <ValidationObserver v-slot="{ invalid, pristine }">
        <v-card>
          <v-card-title v-bind:class="[compColor.textInput]" class="white--text layout-row">
            <span>Neuen Warenkorb/Schrank anlegen</span>
            <div class="flex"></div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon color="white" dark v-on="on" class="text-right">info</v-icon>
              </template>
              <span>Achtung: Sie können nur neue Warenkörbe für Stadionen anlegen für welche Sie auch die Berechtigung haben.</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-4 mt-2">
            <ValidationProvider name="Stationsauswahl" rules="required" v-slot="{ errors }">
              <v-select
                :items="items"
                label="Stationsauswahl *"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                :error-messages="errors[0]"
                v-model="newClosetModel.station"
                :color="compColor.primeColor"
              ></v-select>
            </ValidationProvider>
            <div>
              <ValidationProvider name="Warenkorb-/Schrankname" rules="required" v-slot="{ errors }">
                <v-text-field
                  :color="compColor.primeColor"
                  label="Warenkorb-/Schrankname *"
                  outlined
                  :error-messages="errors[0]"
                  dense
                  v-model="newClosetModel.name"
                ></v-text-field>
              </ValidationProvider>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="layout-row layout-align-end-center">
            <v-btn depressed :disabled="pristine || invalid" :color="compColor.primeColor" @click="dialog = false"
                   class="white--text text-none">Erstellen
            </v-btn>
            <v-btn depressed color="blue-grey" @click="dialog = false" class="white--text text-none">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>

    <!--    </div>-->

<!--    <lx-dialog v-model="dialog" :allow-drag="true" :overlay="true" :size="{x: '40', y: '30'}">-->
<!--      <template #header>-->
<!--        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"-->
<!--             :class="isDarkMode? 'blue-grey&#45;&#45;text text&#45;&#45;lighten-2' : ' grey&#45;&#45;text text&#45;&#45;darken-2'">-->
<!--          Bestellungen - Status setzen-->
<!--        </div>-->
<!--      </template>-->
<!--      <template #default>-->
<!--        <div class="ma-1 pa-4 flex layout-column elevation-3"-->
<!--             style="border-radius: 5px;position: relative;overflow-y: auto;"-->
<!--             :style="{'background': compColor.lxExpansion.background}">-->
<!--          <div class="flex layout-row pa-1">-->
<!--            <ValidationObserver tag="div" ref="setStatusForm" class="layout-column flex">-->
<!--              &lt;!&ndash;              v-slot="{ valid, pristine, reset }"&ndash;&gt;-->
<!--              <validation-provider tag="div" ref="csvColumnIdent"-->
<!--                                   class="flex"-->
<!--                                   name="CSV Spalten Index/Name" rules="required"-->
<!--                                   v-slot="{ errors }">-->
<!--                <v-select-->
<!--                  :color="compColor.primeColor"-->
<!--                  label="Status auswählen"-->
<!--                  placeholder=" "-->
<!--                  v-model="status"-->
<!--                  :items="statusList"-->
<!--                  item-text="name"-->
<!--                  item-value="code"-->
<!--                  :error-messages="errors[0]"-->
<!--                  outlined-->
<!--                  :menu-props="{ bottom: true, offsetY: true }"-->
<!--                  :dense="true"-->
<!--                  item-disabled="disabled"-->
<!--                  class="blue-grey&#45;&#45;text"-->
<!--                >-->
<!--                  <template #item="{item}">-->
<!--                    <div class="flex layout-row ma-1 pa-2 blue-grey&#45;&#45;text text&#45;&#45;darken-2" style="border-radius: 4px;"-->
<!--                         :style="{background: item.color}">-->
<!--                      <div class="flex">{{ item.name }}</div>-->
<!--                      <div class="caption layout-row layout-align-end-end" style="opacity: 0.3;line-height: 12px;">-->
<!--                        <small class="mr-1">code</small>{{ item.code }}-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </v-select>-->
<!--                &lt;!&ndash;                                    @change="changeArticleList()"&ndash;&gt;-->
<!--              </validation-provider>-->
<!--            </ValidationObserver>-->
<!--          </div>-->
<!--        </div>-->
<!--      </template>-->
<!--      <template #actions>-->
<!--        <div class="layout-row layout-align-center-center pa-1">-->
<!--          &lt;!&ndash; CREATE INVOICE BUTTON &ndash;&gt;-->

<!--          &lt;!&ndash;          :disabled="!validator.v || validator.p"&ndash;&gt;-->
<!--          <v-btn :color="compColor.prime" class="flex white&#45;&#45;text text-none mr-1"-->
<!--                 dense depressed title="Speichern"-->
<!--                 :disabled="!showSaveBtn"-->
<!--                 @click.stop="processButtonResult('yes')">-->
<!--            <v-icon left>save</v-icon>-->
<!--            Status setzen-->
<!--          </v-btn>-->
<!--          &lt;!&ndash; CANCEL BUTTON &ndash;&gt;-->
<!--          <v-btn :dark="compColor.isInDarkMode" :color="compColor.prime" class="white&#45;&#45;text text-none"-->
<!--                 dense depressed title="Abbrechen" @click.stop="processButtonResult('no')">-->
<!--            <v-icon left>cancel</v-icon>-->
<!--            Abbrechen-->
<!--          </v-btn>-->
<!--        </div>-->
<!--      </template>-->
<!--    </lx-dialog>-->
  </div>

</template>
<script>
// import {AgGridVue} from 'ag-grid-vue'
import router from '../../router'
// import CheckIconCellRenderer from '../../components/lx_ag-grid/components/cellRenderer/booleanCheckIconCell'

export default {
  name: 'ShoppingList',
  // mixins: [utils],
  data() {
    return {
      searchString: null,
      searchDelayTimer: null,
      tableObj: {
        loading: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
        options: {
          sortBy: ['name'],
          sortDesc: [false],
          // getClassForItem: (row) => {
          //   if (row.isExpanded) {
          //     return 'row-bold'
          //   }
          //   return ''
          // }
        },
        filterValue: null,
        selection: null,
        headers: [
          // {
          //   text: '',
          //   value: 'data-table-select',
          //   sticky: 'left',
          //   width: '30px',
          //   hideRowByKeyValue: 'reported'
          // },
          {
            text: 'Abteilung',
            value: 'name',
            width: '160px',
            sortable: true
          },
          {
            text: 'Produktanzahl',
            value: 'articles',
            width: '200px',
            sortable: true
          },
          {
            text: 'Status',
            value: 'flag',
            sortable: true,
            renderer: 'order_state'
          },
          // {
          //   text: 'E-Mail',
          //   value: 'email',
          //   sortable: true
          // },
        ]
      },
      items: [],

      dialogm1: '',
      dialog: false,
      newClosetModel: {},
      // items: [],

      absolute: true,
      overlay: true,

      isLoadingData: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
      gridOptions: {
        rowHeight: 35,
        headerHeight: 40,
        rowSelection: 'multiple',
        defaultColDef: {
          sortable: true,
          filter: true,
          resizable: true
        },
        overlayLoadingTemplate: '<span class="ag-overlay-loading-center elevation-0">Daten werden geladen...</span>',
      },
      frameworkComponents: null,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultSortOpts: {
        colId: 'name',
        sort: 'asc'
      },
      pagingOptions: {
        limit: 50,
        skip: 0,
        count: 0,
        filter: {}
      },
      FilterAreFloating: false,
      notEnoughSelected: true,
      shortButtons: true,
      hideTooltips: false,
      allowBlurOnLoading: true,
      breadcrumbs: null,
      tempScrollIndex: null,
      alert: false,
      alertMsg: '',
      error: {},
      errorDialog: false,
      showDeleteDialog: false,
      // gridLocalText: {
      //   noRowsToShow: this.$t('AG_GRID.NO_ROWS'),
      //   loadingOoo: this.$t('AG_GRID.LOADING')
      // }
    }
  },
  watch: {
    searchString() {
      clearInterval(this.searchDelayTimer);

      this.searchDelayTimer = setInterval(() => {
        clearInterval(this.searchDelayTimer);
        this.getData()
      }, 500)
    }
  },
  methods: {
    /**
     *
     * @param obj The complete object
     * @param path The path as dot notation to the field value (e.g. 'address.street.number')
     * @returns {*}
     */
    getValueByObjectPath(obj, path) {
      if (path.indexOf('.') <= 0) {
        return obj[path]
      }

      const objectPath = path.split('.')
      for (let i = 0; i < objectPath.length; i++) {
        if (!obj[objectPath[i]]) {
          return;
        }
        obj = obj[objectPath[i]]
      }
      return obj
    },
    /**
     * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
     */
    changeTableOptions(value) {
      if (value) {
        this.tableObj.options = value
      }
      this.getData()
    },
    /**
     * Methode wird vom Grid angesprungen wenn man doppelt auf eine Zeile klickt. Dann wird die Detailansicht geöffent.
     * Kann auch an einen Editbutton in einer Spalte gehangen werden aber Doppelklick ist komfortabler.
     * **/
    openListDetails(params) {
      if (params?._id) {
        router.push({ path: `/shopping/${params._id.toString()}/details` });
      }
      //
      // if (!item || !item.data || !item.data._id) return;
      // router.push({path: `/shopping/${item.data._id.toString()}/details`})
    },

    createColumnDefs() {
      this.columnDefs = [
        // {
        //   headerName: '',
        //   cellStyle: {'background': 'transparent'},
        //   width: 60,
        //   headerCheckboxSelection: true,
        //   headerCheckboxSelectionFilteredOnly: false,
        //   checkboxSelection: true,
        //   pinned: 'left',
        //   sortable: false,
        //   filter: false
        // },
        {
          headerName: 'Abteilung',
          field: 'name',
          filter: false,
          filterParams: {
            newRowsAction: 'keep'
          },
          headerComponentParams: { sortChangedAction: this.sortChanged }
        },
        {
          headerName: 'E-Mail',
          field: 'email',
          filter: false,
          filterParams: {
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'Produktanzahl',
          field: 'articles',
          sortable: false,
          filter: false,
          width: 120,
          filterParams: {
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'Status',
          field: 'flag',
          sortable: false,
          filter: false,
          width: 250,
          valueFormatter: function (params) {
            let str = 'leer';
            if (params.data.flag === 1) {
              str = 'Warenkorb zum bestellen bereit'
            } else if (params.data.flag === 0 && params.data.articles > 0) {
              str = 'in Bearbeitung'
            }
            return str
          },
          filterParams: {
            newRowsAction: 'keep'
          }
        }
      ]
    },

    getData(options) {
      if (this.isLoadingData) {
        return
      }

      this.isLoadingData = true;

      // let query = '{"is_active": true}';

      if (options && typeof options.skip === 'number') {
        this.pagingOptions.skip = options.skip
      }
      if (options && options.limit) {
        this.pagingOptions.limit = options.limit
      }

      const params = this.$helper.GetQueryParam({
        ...this.tableObj.options,
        skip: this.pagingOptions.skip,
        limit: this.pagingOptions.limit,
        query: { is_active: true },
        count: true,
        searchString: this.searchString,
        searchFields: ['name', 'email']
      })

      // Exlude Admin Permission in list
      params.excludeAdmin = 1

      this.$http.get('/department', params)
        .then(res => {
          if (res.data) {
            if (res.data) {
              this.items = res.data.data || []
              this.pagingOptions.count = res.data.count || 0
            }
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.isLoadingData = false
        })
    },
  },
}
</script>
<!-- Add 'scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
