<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">

          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="refresh"
                  :hide-label="true"
                  tooltip="Ansicht aktualisieren"
                  :label="'Aktualisieren'"></lx-btn>

        </div>
        <div class="flex layout-row"></div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="pt-0 pb-2 px-1 layout-row">
        <v-text-field
          :dark="isDarkMode"
          :color="compColor.primeColor"
          :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
          class="mt-2 mb-0"
          label="Suchbegriff(e) eingeben"
          hide-details
          outlined
          :dense="denseFields"
          prepend-inner-icon="mdi-magnify"
          clearable
          v-model="searchString"
        >
          <template v-slot:append>
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" style="cursor: help">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <lx-query-tooltip :fields="searchFields"></lx-query-tooltip>
            </v-tooltip>
          </template>
        </v-text-field>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px;"
             :style="{'background': compColor.lxExpansion.background}">

          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1);" class="flex layout-column">
            <lx-data-table
              v-model="tableObj.selection"
              tableName="UserList"
              :items="items"
              :headers="tableObj.headers"
              :loading="tableObj.loading"
              :tableOptions="tableObj.options"
              :hideContextFilter="true"
              @changeOptions="changeTableOptions"
              @rowClicked="openUserDetails">

              <template v-slot:cellrenderer="{ value }">
                <div v-if="value.f.renderer === 'icon'" style="padding:1px;height:35px;line-height:35px;">
                  <v-icon v-if="value.i[value.f.value]" :color="compColor.prime">mdi-check</v-icon>
                </div>
                <div v-if="value.f.renderer === 'array'" style="padding:1px;height:35px;line-height:35px;">
                  <span class="mr-1" v-for="(item,index) in value.i[value.f.value]" :key="index">{{ item }}<span
                    v-if="index < value.i[value.f.value].length - 1">,</span></span>
                </div>
                <div v-if="value.f.renderer === 'password'" style="padding:1px;height:35px;line-height:35px;"
                     class="text-center">
                  <!--                <v-icon :color="compColor.prime">mdi-lock-reset</v-icon>-->
                  <lx-btn :color="'#353a47'"
                          :icon-color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                          :disabled="tableObj.loading"
                          iconSize="22"
                          height="30"
                          width="auto"
                          as-text
                          as-icon
                          classes="text-none mr-1"
                          @click.stop="sendPasswordForgetEmail(value.i)"
                          icon="mdi-lock-reset"
                          :hide-label="true"
                          tooltip="Neues Passwort für Nutzer anfordern"
                          :label="'Neues Passwort anfordern'"></lx-btn>
                </div>
                <div v-if="value.f.renderer === 'delete'" style="padding:1px;height:35px;line-height:35px;">
                  <lx-btn :color="'#353a47'"
                          :icon-color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                          :disabled="tableObj.loading"
                          iconSize="22"
                          height="30"
                          width="auto"
                          as-text
                          as-icon
                          classes="text-none mr-1"
                          @click.stop="askForDeletion(value.i)"
                          icon="mdi-delete-forever"
                          :hide-label="true"
                          tooltip="Nutzer löschen"
                          :label="'Löschen'"></lx-btn>
                  <!--                  <v-icon :color="compColor.prime" @click.stop="askForDeletion(value.i)">mdi-delete-forever</v-icon>-->
                </div>
              </template>

            </lx-data-table>
          </div>
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="layout-row pa-0">
        <lx-pagination :page-name="'orderList'"
                       :page-options="pagingOptions"
                       :page-change="getData">
        </lx-pagination>
      </div>

    </div>
  </div>
</template>
<script>

import axios from 'axios'
import router from '../../../router'

export default {
  name: 'UserList',
  data() {
    return {
      searchString: null,
      searchDelayTimer: null,
      searchFields: [
        'username',
        'first_name',
        'last_name',
        'email',
        'auth_user_id',
        { key: 'last_login', keyword: 'login', type: Date, title: 'Letze Anmeldung' }
      ],
      tableObj: {
        loading: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
        options: {
          sortBy: ['last_name'],
          sortDesc: [false]
        },
        filterValue: null,
        selection: null,
        headers: [
          {
            width: '30px',
            sortable: true,
            sticky: 'left',
            renderer: 'delete',
            noResize: true,
            alternateText: 'Löschen'
          },
          {
            text: 'Pwd',
            value: 'password',
            width: '30px',
            sortable: true,
            sticky: 'left',
            renderer: 'password',
            noResize: true,
          },
          {
            text: 'Benutzername',
            value: 'username',
            width: '150px',
            sortable: true
          },
          {
            text: 'Nachname',
            value: 'last_name',
            width: '200px',
            sortable: true
          },
          {
            text: 'Vorname',
            value: 'first_name',
            width: '200px',
            sortable: true
          },
          {
            text: 'E-Mail',
            value: 'email',
            sortable: true,
            width: '300px',
          },
          {
            text: 'Rollen',
            value: 'roles',
            sortable: true,
            renderer: 'array'
          },
          {
            text: 'Aktiv',
            value: 'is_active',
            sortable: true,
            width: '40px',
            renderer: 'icon'
          },
          {
            text: 'Letzter Login',
            value: 'last_login',
            sortable: true,
            width: '150px',
            renderer: 'date'
          },
        ]
      },
      items: [],

      isLoadingData: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
      frameworkComponents: null,
      columnDefs: null,
      rowData: null,
      FilterAreFloating: false,
      allowBlurOnLoading: true,
      deleteDialog: false,
      authUserToDelete: null,
      defaultSortOpts: {
        colId: 'last_name',
        sort: 'asc'
      },
    }
  },
  watch: {
    searchString() {
      clearInterval(this.searchDelayTimer);

      this.searchDelayTimer = setInterval(() => {
        clearInterval(this.searchDelayTimer);
        this.getData()
      }, 500)
    }
  },
  methods: {
    /**
     * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
     */
    changeTableOptions(value) {
      if (value) {
        this.tableObj.options = value
      }
      this.getData()
    },

    /**
     * Methode wird vom Grid angesprungen wenn man doppelt auf eine Zeile klickt. Dann wird die Detailansicht geöffent.
     * Kann auch an einen Editbutton in einer Spalte gehangen werden aber Doppelklick ist komfortabler.
     * **/
    openHospitalDetails(params) {
      if (params?._id) {
        router.push({ path: `/administration/hospitals/${params._id.toString()}/details` });
      }
    },
    /**
     * da manche Nutzer sich ihr passwort und benutzer name nicht merken können und keine email eintragen können
     * kann der admin hier die passwort vergessen e-mail von hand auslösen.
     * **/
    sendPasswordForgetEmail(params) {
      if (params?.email) {
        let query = { login: params.email };

        axios.post('/forgotPassword', query)
          .then(this.NotifySystem('Passwort E-Mail versandt'))
          .catch(this.NotifyError)
      }
    },
    /**
     * Methode wird vom Grid angesprungen wenn man doppelt auf eine Zeile klickt. Dann wird die Detailansicht geöffent.
     * Kann auch an einen Editbutton in einer Spalte gehangen werden aber Doppelklick ist komfortabler.
     * **/
    openUserDetails(params) {
      if (params?.id) {
        router.push({ path: `/administration/users/${params.id.toString()}/details` });
      }
      // if (params && params.data) {
      //     router.push({path: `/administration/users/${params.data._id}/details`})
      // }
    },


    askForDeletion(val) {

      if (val && val.id) {
        this.authUserToDelete = val;
        //
        // if (!this.isPharmacy && val.is_active) {
        //   this.noDeletionPossibleDialog = true;
        // } else {
        this.DialogConfirm(
          'Löschen des Nutzers',
          'Möchten Sie den ausgewählten Nutzer mit der E-Mail-Adresse "' + this.authUserToDelete.email + '" wirklich löschen? Eine Wiederherstellung ist nicht möglich.',
          { ok: 'Löschen', cancel: this.$t('buttons.cancel.title') }
        )
          .then(() => {
            this.$http.delete('/users/' + this.authUserToDelete.auth_user_id)
              .then(this.NotifySuccess)
              .catch(this.NotifyError)
              .finally(this.getData)
          })
          .catch(() => {
          })
      }
      // }
    },

    /**
     * Wird verschiedenen Stellen aus aufgerufen und soll die Daten vom Backend abholen. Mittels axios....bla bla bla
     * Besonderheit:
     * "this.pagingOptions.count = res.data.count"
     * - Anzahl der Datensätze vom Server wird auch in den pagingOptions gespeichert
     *
     * "this.$store.dispatch('setPagingOptions', this.pagingOptions)"
     * - nach der Speicherung der Anzahl werden die pagingOptions wieder in den Store geschrieben
     *
     * "this.gridOptions.api.ensureIndexVisible(this.$store.getters.gridScrollIndex, 'top')"
     * - sorgt dafür das nach dem neuladen der Daten das Grid wieder an die Stelle scrollt wo es war. notwendig z.B. bei der Rückkehr zum Grid
     * **/
    getData(options) {
      if (this.tableObj.loading) return
      this.tableObj.loading = true

      if (options && typeof options.skip === 'number') {
        this.pagingOptions.skip = options.skip
      }
      if (options && options.limit) {
        this.pagingOptions.limit = options.limit
      }

      const params = this.$helper.GetQueryParam({
        ...this.tableObj.options,
        skip: this.pagingOptions.skip,
        limit: this.pagingOptions.limit,
        query: {},
        count: true,
        searchString: this.searchString,
        searchFields: this.searchFields
      })

      this.$http.get('/users/getWithRoles', params)
        .then(res => {
          if (res.data) {
            this.items = res.data.result || []
            this.pagingOptions.count = res.data.count || 0
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.tableObj.loading = false
        })
    },

  }
}
</script>
<!-- Add 'scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
