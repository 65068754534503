<template>
  <div v-if="loading" class="layout-column layout-align-center-center"
       style="position: absolute;top:0;left:0;bottom:0;right:0;">
    <v-progress-circular
        :size="Math.round(9.375 * getFontSize)"
        :width="Math.round(0.125 * getFontSize)"
        rotate="90"
        :color="compColor.prime"
        indeterminate
        value="10"
    >
      <v-progress-circular
          :size="Math.round(8.5 * getFontSize)"
          :width="Math.round(0.625 * getFontSize)"
          :color="compColor.prime"
          indeterminate
          value="10"
      >
        <div class="layout-column layout-align-center-center">
          <slot></slot>
        </div>
      </v-progress-circular>
    </v-progress-circular>
  </div>
</template>

<script>

export default {
  name: 'LxLoadingAnimation',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      getFontSize: 30,
    }
  },
  mounted() {
  },
  watch: {},
  methods: {}
}
</script>

<style scoped>

</style>
