<template>
  <div>
    <v-snackbar top color="warning" :timeout="3000" v-model="snackbar" :multi-line="true" class="mt-12">
      <v-icon class="mr-3">warning</v-icon>
      Artikel nicht gefunden
      <v-btn color="gray darken-3" text @click="snackbar = false">Ok</v-btn>
    </v-snackbar>

    <lx-dialog v-model="visible" :allow-drag="true" :overlay="true" :size="{x: '70', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          <span v-show="!articleid">Artikel erstellen</span>
          <span v-show="!!articleid">Artikel editieren</span>
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <ValidationObserver tag="div" ref="editFormEditorPopupForm" class="layout-column flex">
            <div class="flex-100 pa-2">
              <validation-provider tag="div" ref="pzn" name="pzn" rules="required" v-slot="{ errors }">
                <v-text-field
                  label="PZN - Pharmazentralnummer *"
                  type="text"
                  v-model="model.pzn"
                  persistent-hint
                  required
                  outlined
                  class="mb-0"
                  :dense="denseFields"
                  :readonly="lookupPending || !!articleid"
                  :loading="lookupPending"
                  :error-messages="errors[0]"
                >
                  <template v-slot:append>
                    <v-btn
                        :color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                        :disabled="lookupPending || !model.pzn"
                        :loading="lookupPending"
                        elevation="2"
                        small
                        @click="lookupPzn"
                    >Suchen</v-btn>
                  </template>

                </v-text-field>
              </validation-provider>
            </div>
            <div class="flex-100 pa-2">
              <validation-provider tag="div" ref="Hersteller" name="Hersteller" rules="required" v-slot="{ errors }">
                <v-text-field
                  label="Hersteller *"
                  v-model="model.manufacturer_Kurzname"
                  required
                  outlined
                  class="mb-0"
                  :dense="denseFields"
                  :disabled="lookupPending"
                  :error-messages="errors[0]"
                />
              </validation-provider>
            </div>
            <div class="flex-100 pa-2">
              <validation-provider tag="div" ref="Produktname" name="Produktname" rules="required" v-slot="{ errors }">
                <v-text-field
                  label="Produktname / Artikelname *"
                  v-model="model.Produktname"
                  :error-messages="errors[0]"
                  persistent-hint
                  required
                  outlined
                  class="mb-0"
                  :dense="denseFields"
                />
              </validation-provider>
            </div>
            <div class="flex-100 pa-2">
              <validation-provider tag="div" ref="Wirkstoff" name="Wirkstoff" rules="" v-slot="{ errors }">
                <v-text-field
                  label="Wirkstoff"
                  v-model="model.agent"
                  :disabled="lookupPending"
                  :error-messages="errors[0]"
                  outlined
                  class="mb-0"
                  :dense="denseFields"
                />
              </validation-provider>
            </div>
            <div class="flex-100 pa-2">
              <validation-provider tag="div" ref="Darreichungsform" name="Darreichungsform" rules=""
                                   v-slot="{ errors }">
                <v-text-field
                  label="Darreichungsform"
                  v-model="model.dosage_form"
                  :disabled="lookupPending"
                  :error-messages="errors[0]"
                  outlined
                  class="mb-0"
                  :dense="denseFields"
                />
              </validation-provider>
            </div>
            <div class="flex-100 pa-2">
              <validation-provider tag="div" ref="Zahl" name="Zahl" rules="required" v-slot="{ errors }">
                <v-text-field
                  label="Packungsinhalt *"
                  v-model="model.Zahl"
                  required
                  :disabled="lookupPending"
                  :error-messages="errors[0]"
                  outlined
                  class="mb-0"
                  :dense="denseFields"
                />
              </validation-provider>
            </div>
            <div class="flex-100 pa-2">
              <validation-provider tag="div" ref="Einheit" name="Einheit" rules="required" v-slot="{ errors }">
                <v-text-field
                  label="Packungseinheit *"
                  v-model="model.Einheit"
                  required
                  :disabled="lookupPending"
                  :error-messages="errors[0]"
                  outlined
                  class="mb-0"
                  :dense="denseFields"
                />
              </validation-provider>
            </div>
            <div class="flex-100 pa-2">
              <validation-provider tag="div" ref="Apo_Vk" name="Apo_Vk" rules="required" v-slot="{ errors }">
                <v-text-field
                  label="Verkaufspreis"
                  prefix="€"
                  type="number"
                  v-model.number="model.Apo_Vk"
                  :disabled="lookupPending"
                  :error-messages="errors[0]"
                  outlined
                  class="mb-0"
                  :dense="denseFields"
                />
              </validation-provider>
            </div>
            <div class="flex-100 pa-2">
              <validation-provider tag="div" ref="Apo_Bestand" name="Apo_Bestand" rules="" v-slot="{ errors }">
                <v-text-field
                  label="Bestand"
                  type="number"
                  v-model.number="model.Apo_Bestand"
                  :disabled="lookupPending"
                  :error-messages="errors[0]"
                  outlined
                  class="mb-0"
                  :dense="denseFields"
                />
              </validation-provider>
            </div>
            <div class="flex-100 pa-2">
              <validation-provider tag="div" ref="Abgabebestimmung" name="Abgabebestimmung" rules="required"
                                   v-slot="{ errors }">
                <v-select
                  :items="ABitems"
                  label="Abgabebestimmung*"
                  required
                  item-text="name"
                  item-value="id"
                  v-model="model.Abgabebestimmung"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :disabled="lookupPending"
                  :error-messages="errors[0]"
                  outlined
                  class="mb-0"
                  :dense="denseFields"
                />
              </validation-provider>
            </div>
            <div class="flex-100 pa-2">
              <validation-provider tag="div" ref="Apo_Storage" name="Apo_Storage" v-slot="{ errors }">
                <v-text-field
                  label="Apotheke Lagerort"
                  type="text"
                  v-model.number="model.Apo_Storage"
                  :disabled="lookupPending"
                  :error-messages="errors[0]"
                  outlined
                  class="mb-0"
                  :dense="denseFields"
                />
              </validation-provider>
            </div>
          </ValidationObserver>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <div class="flex mr-1">
            <lx-btn :color="'#353a47'"
                    block
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    class="flex"
                    iconSize="20"
                    width="auto"
                    :disabled="!showSaveBtn"
                    classes="text-none"
                    @click.stop="save()"
                    icon="save"
                    tooltip="Speichern"
                    :label="'Speichern'"></lx-btn>
            <!--            @click.stop="addItem(articleData, true)"-->
          </div>
          <div class="mr-1">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    class="flex"
                    iconSize="20"
                    :disabled="!showReverseBtn"
                    v-if="articleid"
                    width="auto"
                    classes="text-none"
                    @click.stop="reverseChanges"
                    icon="mdi-backup-restore"
                    tooltip="Änderungen rückgängig machen"
                    :label="'Rückgängig'"></lx-btn>
          </div>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  class="flex"
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click.stop="visible = false"
                  icon="cancel"
                  tooltip="Editor Schließen"
                  :label="'Schließen'"></lx-btn>
        </div>
      </template>
    </lx-dialog>

  </div>
</template>

<script>
import lodash from "lodash";

/**
 * Removes Empty String Values from Object
 *
 * @param input object
 * @returns object
 */
function hlpRemoveEmptyStrings (input) {
  return Object.fromEntries(Object.entries(input).filter(([_, v]) => (typeof v !== 'string' || v !== '')))
}

/**
 * Set EMPTY Object string type values to null
 *
 * @param input object
 * @returns object
 */
function hlpSetEmptyStringsToNull(input) {
  return Object.fromEntries(
      Object.entries(input).map(([k, v]) => {
        if (typeof v === 'string' && v === '') {
          v = null
        }

        return [k, v]
      })
  )
}

export default {
  name: 'CatalogFormEdit',
  props: {
    onchange: [null, Function],
    locationid: {
      type: [String, null],
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      errors: null,
      model: {},
      lookupPending: false,
      visible: false,
      articleid: null,
      snackbar: false,
      ABitems: [
        { id: null, name: '--fehlt--' },
        { id: '0', name: 'nicht verschreibungspflichtig' },
        { id: '1', name: 'verschreibungspflichtig' },
        { id: '3', name: 'Betäubungsmittel' }
      ]
    }
  },
  created() {

  },
  computed: {
    showSaveBtn() {
      if (lodash.isEqual(this.oldModel, this.model)) {
        return false
      }
      let test = this.$refs['editFormEditorPopupForm']

      if (test) {
        return !test.flags.pristine && test.flags.valid
      }

      return true
    },
    showReverseBtn() {
      if (lodash.isEqual(this.oldModel, this.model)) {
        return false
      }
      let test = this.$refs['editFormEditorPopupForm']

      if (test) {
        return !test.flags.pristine
      }

      return true
    }
  },
  methods: {
    reverseChanges() {
      this.model = lodash.cloneDeep(this.oldModel)
      let test = this.$refs['editFormEditorPopupForm']
      if (test) {
        test.reset()
      }
    },
    save() {
      if (this.lookupPending) return;

      this.lookupPending = true;
      let promiseResult = null;
      // Removes EMPTY strings from Object
      let data = hlpSetEmptyStringsToNull(this.model);

      if (!this.articleid) {
        // Create article
        promiseResult = this.$http.post('/abda/create' + ((this.locationid) ? '/' + this.locationid : ''), data)
      } else {
        // Edit article
        promiseResult = this.$http.put('/abda/patch/' + this.articleid.toString(), data)
      }

      // Same Promise Result for CREATE and PATCH
      promiseResult
        .then(() => {
          this.visible = false;
          this.$emit('success');
        })
        .catch((error) => {
          this.HandleFormError(this.$refs.editFormEditorPopupForm, error)
            .then(() => {
              this.NotifySystem({title: "Speichern fehlgeschlagen", text: "Bitte überprüfen Sie ihr Formular", type: "error"})
            })
            .catch(this.NotifyError)
        })
        .finally(() => {
          this.lookupPending = false
        })
    },
    getData() {
      if (!this.articleid || this.lookupPending) return;

      this.lookupPending = true;

      this.$http.get('/abda/get/' + this.articleid.toString())
        .then(res => {
          this.model = res.data || {};
          delete this.model._id // wird nicht benötigt... darf auch nicht verwendet werden :-)
          delete this.model.searching

          this.oldModel = lodash.cloneDeep(this.model)
        })
        .catch(error => {
          if (error && error.response && error.response.status === 404) {
            this.snackbar = true;
          } else {
            console.error(error)
          }

          this.visible = false;
        })
        .finally(() => {
          this.lookupPending = false;
        })
    },
    lookupPzn() {
      if (this.lookupPending || !this.model?.pzn) return;

      this.lookupPending = true;

      this.$http.get('/abda/lookup/' + this.model.pzn)
        .then(res => {
          let dummy = res.data || {};
          delete dummy._id; // wird nicht benötigt... darf auch nicht verwendet werden :-)
          delete dummy.searching;

          // MERGE
          this.model = {
            ...dummy,
            ...this.model
          }
        })
        .catch(error => {
          if (error && error.response && error.response.status === 404) {
            this.snackbar = true;
          } else {
            console.error(error)
          }
        })
        .finally(() => {
          this.lookupPending = false
        });
    },
    Show(id) {
      if (this.$refs.ArticleForm) this.$refs.ArticleForm.reset();

      this.articleid = id;
      this.model = {};
      this.visible = true;
      this.getData();
    }
  }
}
</script>

<style scoped>

</style>
