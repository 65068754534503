const SET_GRID_VISIBILITY = 'SET_GRID_VISIBILITY'
const SET_GRID_OPTIONS = 'SET_GRID_OPTIONS'
const SET_GRID_SORT_OPTIONS = 'SET_GRID_SORT_OPTIONS'
const SET_GRID_FILTER_OPTIONS = 'SET_GRID_FILTER_OPTIONS'
const SET_GRID_SCROLL_INDEX = 'SET_GRID_SCROLL_INDEX'
const SET_PAGING_OPTIONS = 'SET_GRID_OPTIONS'

const state = {
    gridOptions: {
        enableSorting: true,
        enableFilter: true,
        multiSortKey: 'ctrl',
        rowSelection: 'multiple',
        enableColResize: true,
        suppressRowClickSelection: true,
        enableServerSideSorting: true,
        enableServerSideFilter: true,
        sortingOrder: ['desc', 'asc']
    },
    pagingOptions: {
    },
    gridSortOptions: {
    },
    gridFilterOptions: {
    },
    gridScrollIndex: {
    },
    gridVisibility: true
}

const mutations = {
    [SET_GRID_VISIBILITY] (state, payload) {
        state.gridVisibility = payload
    },
    [SET_PAGING_OPTIONS] (state, payload) {
        state.pagingOptions = payload
    },
    [SET_GRID_OPTIONS] (state, payload) {
        state.gridOptions = payload
    },
    [SET_GRID_SORT_OPTIONS] (state, payload) {
        // state.gridSortOptions = payload
        state.gridSortOptions[payload[0]] = payload[1]
    },
    [SET_GRID_SCROLL_INDEX] (state, payload) {
        state.gridScrollIndex = payload
    },
    [SET_GRID_FILTER_OPTIONS] (state, payload) {
        // state.gridFilterOptions = payload
        state.gridFilterOptions[payload[0]] = payload[1]
    }
}

const actions = {
    setGridVisibility ({ commit }, payload) {
        commit(SET_GRID_VISIBILITY, payload)
    },
    setGridOptions ({ commit }, payload) {
        // console.log('STATE setGridOptions: ', payload)
        commit(SET_GRID_OPTIONS, payload)
    },
    setGridSortOptions ({ commit }, payload) {
        // console.log('STATE setGridSortOptions: ', payload)
        commit(SET_GRID_SORT_OPTIONS, payload)
    },
    setGridFilterOptions ({ commit }, payload) {
        // console.log('STATE setGridFilterOptions: ', payload)
        commit(SET_GRID_FILTER_OPTIONS, payload)
    },
    setGridScrollIndex ({ commit }, payload) {
        // console.log('STATE setGridScrollIndex: ', payload)
        commit(SET_GRID_SCROLL_INDEX, payload)
    },
    setPagingOptions ({ commit }, payload) {
        commit(SET_PAGING_OPTIONS, payload)
    }
}

const getters = {
    gridVisibility: state => {
        return state.gridVisibility
    },
    pagingOptions: state => {
        return state.pagingOptions
    },
    gridOptions: state => {
        return state.gridOptions
    },
    gridSortOptions: state => {
        return state.gridSortOptions
    },
    gridFilterOptions: state => {
        return state.gridFilterOptions
    },
    gridScrollIndex: state => {
        return state.gridScrollIndex
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}