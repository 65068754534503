<template>
  <div>Ich bin das Krankenhaus</div>
</template>

<script>
export default {
  name: 'ReportsMedicalUnit'
}
</script>

<style scoped>

</style>