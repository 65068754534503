import { render, staticRenderFns } from "./LxSimpleTable.vue?vue&type=template&id=14e13ebf&scoped=true&"
import script from "./LxSimpleTable.vue?vue&type=script&lang=js&"
export * from "./LxSimpleTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14e13ebf",
  null
  
)

export default component.exports