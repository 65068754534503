/* global Boolean Number String Date RegExp */
/* eslint no-extend-native: ["error", { "exceptions": ["Boolean", "Number", "String", "Date", "RegExp"] }] */
/**
 * Created by Thomas Scheibe for Mongo Management Studio 2.X.X and Mongorilla VUE.
 */

import bson from 'bson'

Boolean.prototype.toView = function () {
  return this.toString()
}

Number.prototype.toView = function () {
  return this.toString()
}

String.prototype.toView = function () {
  let value = this.toString()

  // Escape all " chars -)
  value = value.replace(/"/g, '\\"')

  return '"' + value + '"'
}

Date.prototype.toView = function () {
  return 'ISODate("' + this.toISOString() + '")'
}

RegExp.prototype.toView = function () {
  return this.toString()
}

bson.Decimal128.prototype.toView = function () {
  return 'NumberDecimal("' + this.toString() + '")'
}

bson.Timestamp.prototype.toView = function () {
  return 'Timestamp(' + this.high + ', ' + this.low + ')'
}

bson.ObjectID.prototype.toView = function () {
  return 'ObjectID("' + this.toString() + '")'
}

bson.ObjectId.prototype.toView = function () {
  return 'ObjectId("' + this.toString() + '")'
}

bson.Binary.prototype.toView = function () {
  return 'BinData(' + this.sub_type + ', "' + this.buffer.toString('base64') + '")'
}

bson.Double.prototype.toView = function () {
  let tmp = this.value.toString()

  if (tmp.indexOf('.') === -1) {
    tmp += '.0'
  }

  return 'Double(' + tmp + ')'
}

bson.BSONSymbol.prototype.toView = function () {
  let tmp = this.value.toString()
  return 'BSONSymbol("' + tmp + '")'
}

bson.MinKey.prototype.toView = function () {
  return 'MinKey()'
}

bson.MaxKey.prototype.toView = function () {
  return 'MaxKey()'
}

bson.Int32.prototype.toView = function () {
  return 'NumberInt(' + this.toJSON() + ')'
}

bson.Long.prototype.toView = function () {
  return 'NumberLong(' + this.toString() + ')'
}

bson.Code.prototype.toView = function () {
  return this.code
}

bson.DBRef.prototype.toView = function () {
  return 'DBPointer("' + this.namespace + '", ' + this.oid.toView() + ')'
}

/* jshint ignore:start */
function MongoDateHelper (value) {
  if (value) {
    return new Date(value)
  }

  return new Date()
}

function MongoTimestampHelper (h, l) {
  return new bson.Timestamp(l, h)
}

function MongoBinDataHelper (subType, buffer) {
  if (buffer && typeof buffer === 'string') {
    try {
      // Workaround to validate correct BASE64 Strings
      let binary = new bson.Binary('', subType)
      binary.write(window.atob(buffer), 0)
      return binary
    } catch (e) {
      throw new Error('BinData Buffer (' + e.toString() + ')')
    }
  }

  return new bson.Binary(buffer, subType)
}

function MongoNumberDecimalHelper (value) {
  return new bson.Decimal128.fromString(value.toString())
}

/* jshint ignore:end */

let JSUtils = bson // clone bson object

let BsonBuffer = bson.serialize({}, {}).constructor

// Don't enumerate the Objects below in list
let ignoreListForPrepare = [
  Boolean.constructor,
  Number.constructor,
  String.constructor,
  Date.constructor,
  bson.Decimal128.constructor,
  bson.ObjectID.constructor,
  bson.ObjectId.constructor,
  bson.BSONSymbol, // bson.Symbol.constructor,
  bson.Double.constructor,
  bson.MinKey.constructor,
  bson.MaxKey.constructor,
  bson.Int32.constructor,
  bson.Long.constructor,
  bson.Code.constructor
]

// Styling and Type Informations

let MongoTypes = [
  {
    type: null,
    func: function (value) {
      return (value === null)
    },
    name: 'Null',
    style: {
      image: 'glyphicon glyphicon-stop',
      color: '#808080',
      fontStyle: 'italic'
    },
    toString: function () {
      return 'null'
    }
  },
  {
    type: undefined,
    func: function (value) {
      return (value === undefined)
    },
    name: 'Undefined',
    style: {
      image: 'glyphicon glyphicon-stop',
      color: '#808080',
      fontStyle: 'italic'
    },
    toString: function () {
      return 'undefined'
    }
  },
  {
    type: bson.Code,
    name: 'Code',
    style: {
      image: 'glyphicon glyphicon-pause',
      color: '#CB772F',
      fontStyle: 'normal'
    },
    toString: function (value) {
      return value.code.toString()
    }
  },
  {
    type: bson.Map,
    name: 'Map',
    style: {
      image: 'glyphicon glyphicon-stop',
      color: '#808080',
      fontStyle: 'italic'
    },
    toString: function (value) {
      // TODO Change
      return value.toString()
    }
  },
  {
    type: bson.BSONSymbol,
    name: 'Symbol',
    style: {
      image: 'glyphicon glyphicon-stop',
      color: '#808080',
      fontStyle: 'italic'
    },
    toString: function (value) {
      // TODO Change
      return value.toString()
    }
  },
  {
    type: bson.Binary,
    name: 'Binary',
    style: {
      image: 'glyphicon glyphicon-stop',
      color: '#808080',
      fontStyle: 'italic'
    },
    toString: function (value) {
      return value.toString('base64')
    }
  },
  {
    type: bson.DBRef,
    name: 'DBRef',
    style: {
      image: 'glyphicon glyphicon-pause',
      color: '#9A9E6B',
      fontStyle: 'normal'
    },
    toString: function (value) {
      return value.toView()
    }
  },
  {
    type: bson.ObjectID,
    name: 'ObjectID',
    style: {
      image: 'glyphicon glyphicon-unchecked',
      color: '#D25252',
      fontStyle: 'normal'
    },
    toString: function (value) {
      return 'ObjectID("' + value.toHexString() + '")'
    },
    toHint: function (value) {
      return value.getTimestamp().toString()
    }
  },
  {
    type: bson.ObjectId,
    name: 'ObjectId',
    style: {
      image: 'glyphicon glyphicon-unchecked',
      color: '#D25252',
      fontStyle: 'normal'
    },
    toString: function (value) {
      return 'ObjectId("' + value.toHexString() + '")'
    },
    toHint: function (value) {
      return 'Timestamp("' + value.getTimestamp().toString() + '")'
    }
  },
  {
    type: bson.Timestamp,
    name: 'Timestamp',
    style: {
      image: 'glyphicon glyphicon-pause',
      color: '#72617C',
      fontStyle: 'normal'
    },
    toString: function (value) {
      return 'Timestamp(' + value.high.toString() + ', ' + value.low.toString() + ')'
    }
  },
  {
    type: bson.BSONRegExp,
    name: 'BSONRegExp',
    style: {
      image: 'glyphicon glyphicon-pause',
      color: '#283997',
      fontStyle: 'normal'
    },
    toString: function (value) {
      return value.toString()
    }
  },
  {
    type: RegExp,
    name: 'RegExp',
    style: {
      image: 'glyphicon glyphicon-pause',
      color: '#283997',
      fontStyle: 'normal'
    },
    toString: function (value) {
      return value.toString()
    }
  },
  {
    type: bson.Long,
    name: 'Long',
    style: {
      image: 'glyphicon glyphicon-sound-5-1',
      color: '#6897BB',
      fontStyle: 'normal'
    },
    toString: function (value) {
      return value.toString()
    }
  },
  {
    type: bson.Double,
    name: 'Double',
    style: {
      image: 'glyphicon glyphicon-sound-5-1',
      color: '#6897BB',
      fontStyle: 'normal'
    },
    toString: function (value) {
      var tmp = value.value.toString()

      if (tmp.indexOf('.') === -1) {
        tmp += '.0'
      }

      return tmp
    }
  },
  {
    type: bson.Int32,
    name: 'Int32',
    style: {
      image: 'glyphicon glyphicon-sound-5-1',
      color: '#6897BB',
      fontStyle: 'normal'
    },
    toString: function (value) {
      return value.toJSON()
    }
  },
  {
    type: bson.MinKey,
    name: 'MinKey',
    style: {
      image: 'glyphicon glyphicon-pause',
      color: '#CEBE75',
      fontStyle: 'normal'
    },
    toString: function () {
      return 'MinKey'
    }
  },
  {
    type: bson.MaxKey,
    name: 'MaxKey',
    style: {
      image: 'glyphicon glyphicon-pause',
      color: '#CEBE75',
      fontStyle: 'normal'
    },
    toString: function () {
      return 'MaxKey'
    }
  },
  {
    type: bson.Decimal128,
    name: 'Decimal128',
    style: {
      image: 'glyphicon glyphicon-sound-5-1',
      color: '#6897BB',
      fontStyle: 'normal'
    },
    toString: function (value) {
      return value.toString()
    }
  },
  {
    type: String,
    func: function (value) {
      return typeof value === 'string' || value instanceof String
    },
    name: 'String',
    style: {
      image: 'glyphicon glyphicon-subtitles',
      color: '#619647',
      fontStyle: 'normal'
    },
    toString: function (value) {
      return JSON.stringify(value)
    }
  },
  {
    type: Boolean,
    func: function (value) {
      return typeof value === 'boolean'
    },
    name: 'Boolean',
    style: {
      image: 'glyphicon glyphicon-random',
      color: '#CB772F',
      fontStyle: 'italic'
    },
    toString: function (value) {
      return value.toString()
    }
  },
  {
    type: Number,
    func: function (value) {
      return typeof value === 'number'
    },
    name: 'Number',
    style: {
      image: 'glyphicon glyphicon-sound-5-1',
      color: '#6897BB',
      fontStyle: 'normal'
    },
    toString: function (value) {
      return value.toString()
    }
  },
  {
    type: Date,
    name: 'Date',
    style: {
      image: 'glyphicon glyphicon-calendar',
      color: '#9876AA',
      fontStyle: 'normal'
    },
    toString: function (value) {
      if (value.hasOwnProperty('toView')) {
        return value.toView()
      }

      return value.toString()
    },
    toHint: function (value) {
      return value.toString()
    }
  },
  {
    type: Array,
    func: function (value) {
      return Array.isArray(value)
    },
    name: 'Array',
    list: true,
    style: {
      image: 'glyphicon glyphicon-sound-dolby',
      color: '#2B2B2B',
      fontStyle: 'normal'
    },
    toString: function (value) {
      return 'Array [ ' + (value || []).length.toString() + ' ]'
    }
  },
  {
    type: Object,
    func: function (value) {
      return value instanceof Object
    },
    name: 'Object',
    list: true,
    style: {
      image: 'glyphicon glyphicon-sound-stereo',
      color: '#2B2B2B',
      fontStyle: 'normal'
    },
    toString: function (value) {
      return 'Object { ' + Object.keys(value || {}).length.toString() + ' }'
    }
  }
]

JSUtils.stringify = function (obj, options) {
  options = options || { singleline: false }

  options.linebreak = (options && options.singleline === true) ? '' : '\n'
  options.tab = (options && options.singleline === true) ? '' : '\t'
  options.flat = (options.hasOwnProperty('flat') && options.flat === true)
  options.pinfields = options.pinfields || []

  let getViewForData = function (val, tabs) {
    if (val === null) {
      return 'null'
    }

    if (val === undefined) {
      return 'undefined'
    }

    if (typeof val === 'string') {
      return JSON.stringify(val)
    }

    if (val.toView && typeof val.toView === 'function') {
      return val.toView(tabs)
    }

    if (val instanceof Object) {
      if (options.flat === true) {
        return Array.isArray(val) ? '[ ... ]' : '{ ... }'
      }

      if (Array.isArray(val)) {
        return arrayToView(val, tabs)
      }

      return objectToView(val, tabs)
    }

    if (val.toJSON && typeof val.toJSON === 'function') {
      return val.toJSON()
    }

    return val.toString()
  }

  let getViewForKey = function (val) {
    // let re = new RegExp(/[^a-zA-Z0-9_]/g)
    let re = new RegExp(/(^\d)|([^a-zA-Z0-9_])/g)

    if (re.test(val)) {
      return '"' + val + '"'
    }

    return val
  }

  let arrayToView = function (val, tabsize) {
    tabsize = tabsize || ''

    let self = val
    let converter = function (tabs) {
      let res = ''

      let len = self.length

      for (let i = 0; i < len; i++) {
        res += tabs + getViewForData(self[i], tabs)

        if (i < (len - 1)) {
          res += ','
        }

        res += options.linebreak
      }

      return res
    }

    return '[' + options.linebreak + converter(tabsize + options.tab) + tabsize + ']'
  }

  let objectToView = function (val, tabsize) {
    tabsize = tabsize || ''

    let self = val
    let converter = function (tabs) {
      let res = ''
      let keys = Object.keys(self)
      let hasPrimaryKeys = options.pinfields.length > 0

      keys.sort((A, B) => {
        if (hasPrimaryKeys) {
          let pinA = options.pinfields.indexOf(A)
          let pinB = options.pinfields.indexOf(B)
          let abc = -(pinA !== -1) + (pinB !== -1)

          if (abc !== 0) {
            return abc
          }
        }

        if (A < B) {
          return -1
        }

        if (A > B) {
          return 1
        }

        return 0
      })

      keys.forEach(function (key, idx) {
        if (self.hasOwnProperty(key)) {
          if (idx > 0) {
            res += ',' + options.linebreak
          }

          res += tabs + getViewForKey(key) + ': ' + getViewForData(self[key], tabs)
        }
      })

      return res + options.linebreak
    }

    return '{' + options.linebreak + converter(tabsize + options.tab) + tabsize + '}'
  }

  if (obj === null) {
    return 'null'
  }

  if (obj === undefined) {
    return 'undefined'
  }

  if (typeof obj === 'string') {
    return JSON.stringify(obj)
  }

  if (obj && typeof obj.toView === 'function') {
    return obj.toView()
  }

  if (obj instanceof Object) {
    if (Array.isArray(obj)) {
      return arrayToView(obj)
    }

    return objectToView(obj)
  }

  return JSON.stringify(obj)
}

/**
 * Converts some Javascript values (like Javascript code) to BSON friendly Objects
 *
 * @param data
 * @returns {*}
 */
JSUtils.prepareData = function (data) {
  if (!data) {
    return data
  }

  // Convert a function to BSON Code )
  if (typeof data === 'function') {
    return bson.Code(data.toString())
  }

  // Enum array
  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      if (data[i] && data[i].constructor && ignoreListForPrepare.indexOf(data[i].constructor) !== -1) {
        data[i] = JSUtils.prepareData(data[i])
      }
    }

    return data
  }

  Object.keys(data).forEach(function (keyname) {
    if (data[keyname] && data[keyname].constructor && ignoreListForPrepare.indexOf(data[keyname].constructor) !== -1) {
      data[keyname] = JSUtils.prepareData(data[keyname])
    }
  })

  return data
}

/**
 * Serialize a Javascript Object to BSON for transport
 *
 * @param data
 * @param options
 * @returns {Buffer}
 */
JSUtils.serializeDataForTransport = function (data, options) {
  options = options || {}
  options.raw = true

  if (!options.hasOwnProperty('ignoreUndefined')) {
    options.ignoreUndefined = false
  }

  data = JSUtils.prepareData(data || {})

  if (Array.isArray(data)) {
    let result = []
    result.length = data.length

    data.forEach(function (item, itemindex) {
      if (item && item.type !== 'Buffer') {
        result[itemindex] = bson.serialize({ $mmsItem: item },
          options
        )
      } else {
        result[itemindex] = item
      }
    })

    return result
  }

  return bson.serialize(
    data,
    options
  )
}

/**
 * Deserialize a BSON Object to a Javascript Object
 *
 * @param data
 * @returns {*}
 */
JSUtils.deserializeDataFromTransport = function (data) {
  if (Array.isArray(data)) {
    let result = []
    result.length = data.length

    data.forEach(function (item, itemindex) {
      if (item instanceof ArrayBuffer) {
        result[itemindex] = bson.deserialize(new BsonBuffer(item), { promoteValues: false })
      } else {
        // Fallback
        if (item && item.type === 'Buffer') {
          result[itemindex] = bson.deserialize(new BsonBuffer(item.data), { promoteValues: false })
        } else {
          result[itemindex] = item
        }
      }

      if (result[itemindex] && result[itemindex].hasOwnProperty('$mmsItem')) {
        result[itemindex] = result[itemindex].$mmsItem
      }
    })

    return result
  }

  return bson.deserialize(new BsonBuffer(data), { promoteValues: false })
}

/* jshint ignore:start */
/**
 * Evaluates a source code to js object
 * http://dfkaye.github.io/2014/03/14/javascript-eval-and-function-constructor/
 *
 * @param sourcecode
 * @returns {{ok, res}}
 */
JSUtils.evaluate = function (sourcecode) {
  let sandbox = function () {
    try {
      return {
        ok: 1,
        res: (
          'ObjectID',
          'ISODate',
          'Binary',
          'MinKey',
          'MaxKey',
          'DbRef',
          'DBPointer',
          'Timestamp',
          'RegExp',
          'NumberLong',
          'NumberInt',
          'NumberDecimal',
          'Double',
          'Decimal128',
          'Code',
          'BinData',
          'Binary',
          'return ' + sourcecode
        )(
          bson.ObjectId,
          MongoDateHelper,
          bson.Binary,
          bson.MinKey,
          bson.MaxKey,
          bson.DBRef,
          bson.DBRef,
          MongoTimestampHelper,
          RegExp,
          bson.Long,
          bson.Int32,
          MongoNumberDecimalHelper,
          bson.Double,
          bson.Decimal128,
          bson.Code,
          MongoBinDataHelper,
          bson.Binary
        )
      }
    } catch (error) {
      return {
        ok: 0,
        res: error
      }
    }
  }

  return sandbox()
}

/**
 * getTypeFromValue
 *
 */
Object.defineProperty(JSUtils, 'getTypeFromValue', {
  enumerable: false,
  configurable: false,
  writeable: false,
  value: function (value) {
    for (let i = 0; i < MongoTypes.length; i++) {
      let result = false

      if (MongoTypes[i].hasOwnProperty('func')) {
        result = MongoTypes[i].func(value)
      } else {
        result = MongoTypes[i].type && value instanceof MongoTypes[i].type
      }

      if (result) {
        return MongoTypes[i]
      }
    }

    return null
  }
})

/**
 * getMetadataFromValue
 *
 */
Object.defineProperty(JSUtils, 'getMetadataFromValue', {
  enumerable: false,
  configurable: false,
  writeable: false,
  value: function (value) {
    let metadata

    for (let i = 0; i < MongoTypes.length; i++) {
      let result = false

      if (this[i].hasOwnProperty('func')) {
        result = MongoTypes[i].func(value)
      } else {
        result = MongoTypes[i].type && value instanceof MongoTypes[i].type
      }

      if (result) {
        if (MongoTypes[i].list === true) {
          let key
          metadata = {}

          for (key in value) {
            if (value.hasOwnProperty(key)) {
              metadata[key] = this.getMetadataFromValue(value[key])
            }
          }
        } else {
          metadata = MongoTypes[i].name
        }

        return metadata
      }
    }

    return 'Unknow'
  }
})

/**
 * getMetadataFromValue
 *
 */
Object.defineProperty(JSUtils, 'getInfoFromValue', {
  enumerable: false,
  configurable: false,
  writeable: false,
  value: function (value) {
    let result
    let datatype = this.getTypeFromValue(value)

    if (!datatype) {
      console.error('Error: Unknow Datatype for Value "', value, '"')
      return {
        $type: 'Unknow',
        $style: {},
        $text: String(value),
        $value: value,
        $valid: true,
        $hasChilds: false
      }
    }

    result = {
      $type: datatype.name,
      $style: datatype.style || {},
      $text: datatype.toString(value),
      $value: value,
      $valid: true,
      $hasChilds: datatype.list || false
    }

    // Custom Hint?
    if (datatype && datatype.hasOwnProperty('toHint')) {
      result.$hint = datatype.toHint(value)
    }

    return result
  }
})

/* jshint ignore:end */
export default JSUtils
