<template>
  <div>
    <v-btn text icon @click="editStorageLocation(item)"
           :color="isDarkMode ? compColor.primeHighlightColor : compColor.prime"
           title="Löschen" :disabled="!item._id">
      <v-icon size="18">edit</v-icon>
    </v-btn>


    <lx-dialog v-model="editStorage" :allow-drag="true" :overlay="true" :size="{x: '40%', y: '50%'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          Lagerort bearbeiten
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <div class="flex layout-column pa-1">
            <ValidationObserver tag="div" ref="editStoragePopupForm" class="layout-column flex">

              <fake-input-frame label="Allgemeine Informationen"
                                :input-background="isDarkMode ? 'transparent':'#f5f5f5'">
                <div class="layout-column px-2 pb-1">
                  <!--                  <div class="layout-row layout-align-start-center">-->
                  <span class="blue-grey--text text--darken-2">
                      <small style="min-width:170px;display: inline-block;">Lagerort erstellt am:</small>
                      <strong>{{ storagePopupModel.creation_date | formatDate }}</strong>
                    </span>
                  <!--                  </div>-->
                  <!--                  <div class="layout-row layout-align-start-center">-->
                  <span class="blue-grey--text text--darken-2">
                      <small style="min-width:170px;display: inline-block;">Anzahl zugeordneter Artikel:</small>
                      <strong>{{ storagePopupModel.articles.length }}</strong>
                    </span>
                  <!--                  </div>-->
                </div>
              </fake-input-frame>

              <v-divider class="my-10"></v-divider>
              <div>
                <validation-provider tag="div" ref="Name"
                                     name="Name"
                                     rules="required" v-slot="{ errors }">
                  <v-text-field
                    :color="compColor.primeColor"
                    :error-messages="errors[0]"
                    label="Name *"
                    :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                    class="mb-1"
                    v-model="storagePopupModel.name"
                    required
                    :dark="compColor.isInDarkMode"
                    outlined
                  >
                    <template #append v-if="!isDarkMode">
                      <v-icon color="grey lighten-2">mdi-map-marker</v-icon>
                    </template>
                  </v-text-field>
                </validation-provider>
              </div>
              <div>
                <validation-provider tag="div" ref="Bemerkung"
                                     name="Bemerkung"
                                     v-slot="{ errors }">
                  <v-text-field
                    :color="compColor.primeColor"
                    :error-messages="errors[0]"
                    label="Bemerkung"
                    :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                    class="mb-1"
                    v-model="storagePopupModel.comment"
                    required
                    height="50"
                    :dark="compColor.isInDarkMode"
                    outlined
                  >
                    <template #append v-if="!isDarkMode">
                      <v-icon color="grey lighten-2">mdi-message-bulleted</v-icon>
                    </template>
                  </v-text-field>
                </validation-provider>
              </div>
            </ValidationObserver>

          </div>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">

          <!-- CREATE INVOICE BUTTON -->
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  :disabled="!showSaveBtn"
                  classes="text-none mr-1 flex"
                  @click.stop="processButtonResult('yes')"
                  icon="save"
                  tooltip="Änderungen speichern"
                  :label="'Änderungen speichern'"></lx-btn>

          <!-- CANCEL BUTTON -->
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click.stop="processButtonResult('no')"
                  icon="cancel"
                  tooltip="Vorgang abbrechen und Fenster schließen"
                  :label="'Abbrechen'"></lx-btn>
        </div>
      </template>
    </lx-dialog>
  </div>
</template>

<script>
import lodash from "lodash";

export default {
  name: 'ClinicalUnitStorages',
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      storagePopupModel: null,
      editStorage: false,
      oldModel: {}
    }
  },
  computed: {
    showSaveBtn() {
      if (lodash.isEqual(this.oldModel, this.storagePopupModel)) {
        return false
      }
      let test = this.$refs['editStoragePopupForm']
      if (test) {
        return !test.flags.pristine && test.flags.valid
      }

      return true
    }
  },
  methods: {
    processButtonResult(btnResult) {
      if (btnResult === 'no') {
        this.editStorage = false
      } else {
        this.saveStorage()
      }
    },
    editStorageLocation(item) {
      this.editStorage = true
      this.storagePopupModel = item
      this.oldModel = lodash.cloneDeep(this.storagePopupModel)
      // router.push({path: `/administration/clinical_units/${this.departmentid.toString()}/storage/${id.toString()}`});
    },
    saveStorage() {
      const data = {
        storage_id: this.storagePopupModel._id,
        department_id: this.storagePopupModel.department_id,
        name: this.storagePopupModel.name,
        comment: this.storagePopupModel.comment
      };

      this.$http.patch('/storage', data)
        .then(() => {
          this.NotifySystem('Änderungen gespeichert');
          this.editStorage = false
        })
        .catch(this.NotifyError)
    }
  }
}
</script>
