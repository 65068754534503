<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div style="min-height: 4px;">
        <v-progress-linear
          :active="isBusy"
          indeterminate
          query
        ></v-progress-linear>
      </div>
      <v-divider v-if="!hoverHeader" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <!-- Ansicht für Apotheke -->
      <reports-pharmacy v-if="isPharmacy && !isBusy" :disabled="false" :modules="modules"></reports-pharmacy>

      <!-- Ansicht für Krankenhaus -->
      <reports-medical-unit v-if="isMedicalUnit && !isBusy" :disabled="false" :modules="modules"></reports-medical-unit>

    </div>
  </div>
</template>

<script>
import ReportsPharmacy from './ReportsPharmacy'
import ReportsMedicalUnit from "@/views/Reports/ReportsMedicalUnit";

export default {
  name: 'Reports',
  components: {
    ReportsPharmacy,
    ReportsMedicalUnit
  },
  data() {
    return {
      loading: 0,
      hoverHeader: false,
      isPharmacy: (this.getUserLocationType() === "pharmacy"),
      isMedicalUnit: (this.getUserLocationType() === "medicalunit"),
      modules: [],
    }
  },
  computed: {
    isBusy() {
      return this.loading > 0
    }
  },
  mounted() {
    this.loading++
    this.$http.get('/settings/modules')
      .then(res => {
        this.modules = res.data?.modules || []
      })
      .finally(() => {
        this.loading--
      })
  }

}
</script>

<style scoped>

</style>
