/**
 * Import Dependency
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
/**
 * Import Language
 */
import en from './i18n/en_US'
import de from './i18n/de_DE'
// import cn from './i18n/zh_CN'

/**
 * Config
 */
Vue.use(VueI18n)

const messages = {
    en: en,
    de: de,
    // cn: cn
}

/**
 * Export
 */
export default new VueI18n({
    locale: 'de',
    silentTranslationWarn: true,
    messages
})
