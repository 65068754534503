<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <ValidationObserver ref="MyCompanyDetailForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">
      <div class="layout-column flex ma-2 elevation-5"
           style="border-radius:4px;position:relative;"
           v-bind:style="{background: compColor.contentFrame}">

        <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1" :class="hoverHeader">
          <div class="layout-row">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="!valid || (pristine && noNewAdresses)"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="save(reset)"
                    icon="save"
                    :tooltip="$t('buttons.save.tooltip')"
                    :label="$t('buttons.save.title')"></lx-btn>
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="(pristine && noNewAdresses)"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="clearForm(reset)"
                    icon="cancel"
                    :tooltip="$t('buttons.revert.tooltip')"
                    :label="$t('buttons.revert.title')"></lx-btn>

          </div>
          <div class="flex layout-row"></div>
        </div>

        <v-divider v-if="!hoverHeader" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

        <div ref="scrollContainer" style="overflow-y: auto;" class="flex pa-1 layout-column">
          <lx-expansion-panels :single-open="false" v-model="panel">
            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-city"
                                         main-headline="Unternehmensdaten"
                                         sub-headline="Allgemeine Unternehmensdaten">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="Apothekenname" name="Apothekenname"
                                         rules="required" v-slot="{ errors }">
                      <v-text-field
                        :label="$t('inputs.titles.companyname') + ' *'"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        v-model="model.name"
                        outlined
                        class="mb-1"
                        :dark="compColor.isInDarkMode"
                        :error-messages="errors[0]"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">house</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div class="flex-gt-sm-75 flex-100 pa-2">
                    <validation-provider tag="div" ref="Strasse" name="Strasse"
                                         v-slot="{ errors }">
                      <v-text-field
                        :color="compColor.primeColor"
                        :label="$t('inputs.titles.street')"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        v-model="model.address.street"
                        outlined
                        class="mb-1"
                        :dark="compColor.isInDarkMode"
                        :error-messages="errors[0]"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">mdi-road</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div class="flex-gt-sm-25 flex-100 pa-2">
                    <validation-provider tag="div" ref="Hausnummer" name="Hausnummer"
                                         v-slot="{ errors }">
                      <v-text-field
                        :color="compColor.primeColor"
                        :label="$t('inputs.titles.number')"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        v-model="model.address.number"
                        outlined
                        class="mb-1"
                        :dark="compColor.isInDarkMode"
                        :error-messages="errors[0]"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">mdi-home-floor-2</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div class="flex-gt-sm-25 flex-100 pa-2">
                    <validation-provider tag="div" ref="Postleitzahl" name="Postleitzahl"
                                         v-slot="{ errors }">
                      <v-text-field
                        :color="compColor.primeColor"
                        :label="$t('inputs.titles.zipcode')"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        v-model="model.address.zipcode"
                        outlined
                        class="mb-1"
                        :dark="compColor.isInDarkMode"
                        :error-messages="errors[0]"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">email</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div class="flex-gt-sm-75 flex-100 pa-2">
                    <validation-provider tag="div" ref="Stadt" name="Stadt"
                                         v-slot="{ errors }">
                      <v-text-field
                        :color="compColor.primeColor"
                        :label="$t('inputs.titles.city')"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        v-model="model.address.city"
                        outlined
                        class="mb-1"
                        :dark="compColor.isInDarkMode"
                        :error-messages="errors[0]"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">mdi-city-variant</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="Bundesland" name="Bundesland"
                                         v-slot="{ errors }">
                      <v-select
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :label="$t('inputs.titles.regionalstate')"
                        v-model="model.state"
                        :rules="[rules.required]"
                        :items="states"
                        item-text="name"
                        item-value="statecode"
                        :menu-props="{ bottom: true, offsetY: true }"
                        hide-no-data
                        required
                        outlined
                        class="mb-1"
                        :error-messages="errors[0]"
                        :dark="isDarkMode"
                        clearable
                        :dense="denseFields"
                      >
                        <template #selection="{item}">
                          <div class="blue-grey--text text--darken-2">{{ item.name }}</div>
                        </template>
                        <template #item="{item}">
                          <div class="blue-grey--text text--darken-2">{{ item.name }}</div>
                        </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
            <lx-expansion-panel v-if="isPharmacy">
              <lx-expansion-panel-header icon="mdi-cart"
                                         main-headline="Bestellungen"
                                         sub-headline="Alle benötigten Grundeinstellungen für die Abwicklung einer Bestellung">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="Bestell E-Mail Eingabe"
                                         rules="email"
                                         name="Bestell E-Mail Eingabe"
                                         v-slot="{ errors }">
                      <div class="layout-row">
                        <div class="flex">
                          <v-text-field
                            :color="compColor.primeColor"
                            :label="$t('inputs.titles.addorderemail')"
                            prepend-inner-icon="email"
                            :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                            v-model="ordermail"
                            outlined
                            clearable
                            class="mb-1"
                            :dark="compColor.isInDarkMode"
                            :dense="denseFields"
                            @keyup="addOrderMail"
                            :error-messages="errors[0]"
                          >
                            <template #prepend-inner v-if="!isDarkMode">
                              <v-icon color="grey lighten-2">mdi-mail</v-icon>
                            </template>
                            <template #append>
                              <div style="height:100%;line-height: 34px;margin-top: -7px;">

                                <lx-btn :color="'#353a47'"
                                        :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                                        small
                                        :disabled="typeof errors[0] === 'string' || !ordermail"
                                        iconSize="20"
                                        width="auto"
                                        height="34"
                                        classes="text-none mr-n1 ml-3"
                                        @click="addOrderMail"
                                        icon="add_circle"
                                        tooltip="E-Mail zur Liste hinzufügen"
                                        :label="$t('buttons.add.title')"></lx-btn>
                              </div>
                            </template>
                          </v-text-field>
                        </div>
                      </div>
                    </validation-provider>
                  </div>
                  <div class="flex-100 pa-2">
                    <fake-input-frame :label="$t('inputs.titles.orderemails')"
                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                      class="mb-6">
                      <div class="layout-row layout-align-start-center px-2 mt-n2"
                           :style="{height: denseFields ? '40px':'50px'}">
                        <v-chip v-for="(mail, index) in (model.ordermail_address || [])"
                                v-bind:key="index"
                                :class="compColor.prime"
                                class="mr-2 mt-1 blue-grey--text text--darken-2 lighten-4" style="border-radius: 3px">
                          <v-avatar :color="compColor.prime + ' lighten-1'" left class="ml-n2"
                                    style="border-radius: 2px">
                            <v-icon color="white" size="12">email</v-icon>
                          </v-avatar>
                          <strong>{{ mail }}</strong>
                          <v-icon class="ml-2 mr-n1" size="20" style="opacity: 0.5;" @click="removeOrderMail(index)">
                            mdi-delete-forever
                          </v-icon>
                        </v-chip>
                      </div>
                    </fake-input-frame>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
            <lx-expansion-panel v-if="isPharmacy">
              <lx-expansion-panel-header icon="mdi-headset"
                                         main-headline="Supportdaten"
                                         sub-headline="Supportdaten werden jedem Nutzer angezeigt um schneller Hilfe anfordern zu können">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="Support-Telefon"
                                         name="Support-Telefon" v-slot="{ errors }">
                      <v-text-field
                        :color="compColor.primeColor"
                        :label="$t('inputs.titles.supportphone')"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        v-model="model.supportphone"
                        outlined
                        class="mb-1"
                        :dark="compColor.isInDarkMode"
                        :error-messages="errors[0]"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">phone</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="Support-E-Mail"
                                         name="Support-E-Mail" rules="email"
                                         v-slot="{ errors }">
                      <v-text-field
                        :color="compColor.primeColor"
                        :label="$t('inputs.titles.supportemail')"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        v-model="model.supportemail"
                        outlined
                        class="mb-1"
                        :dark="compColor.isInDarkMode"
                        :error-messages="errors[0]"
                        :dense="denseFields"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">email</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
<!--            <lx-expansion-panel v-if="isPharmacy">-->
<!--              <lx-expansion-panel-header icon="mdi-folder-table"-->
<!--                                         main-headline="Artikelstammeinstellungen"-->
<!--                                         sub-headline="Einstellung für den Import von ABDA-/Artikellisten">-->
<!--              </lx-expansion-panel-header>-->
<!--              <lx-expansion-panel-content>-->
<!--                <div class="layout-row layout-wrap mt-3 pa-2">-->
<!--                  <div class="flex-100 pa-2">-->
<!--                    <fake-input-frame :label="$t('inputs.titles.dataset')"-->
<!--                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'"-->
<!--                                      class="mb-6">-->
<!--                      <div class="layout-row layout-align-start-center px-2 mt-n2"-->
<!--                           :style="{height: denseFields ? '40px':'50px'}">-->
<!--                        <validation-provider tag="div" ref="use_user_abda"-->
<!--                                             name="use_user_abda">-->
<!--                          &lt;!&ndash;                        v-slot="{ errors }"&ndash;&gt;-->
<!--                          <v-switch-->
<!--                            :color="compColor.primeColor"-->
<!--                            v-model="model.use_user_abda"-->
<!--                            :dark="compColor.isInDarkMode"-->
<!--                            hide-details-->
<!--                            inset-->
<!--                            dense-->
<!--                            class="ma-0 ml-2"-->
<!--                            label="Eigenen Artikelstamm für Sonderartikel nutzen"-->
<!--                          ></v-switch>-->
<!--                        </validation-provider>-->
<!--                      </div>-->
<!--                    </fake-input-frame>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </lx-expansion-panel-content>-->
<!--            </lx-expansion-panel>-->
            <lx-expansion-panel v-if="isPharmacy && Array.isArray(model.modules) && model.modules.length > 0">
              <lx-expansion-panel-header icon="mdi-folder-table"
                                         main-headline="Aktivierte Module"
                                         sub-headline="Zusatzmodule welche für Ihr Unternehmen freigeschaltet sind">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2">
                    <fake-input-frame :label="$t('inputs.titles.modules')"
                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                      class="mb-0">
                      <div class="layout-row layout-align-start-center px-2 mt-n2"
                           :style="{height: denseFields ? '40px':'50px'}">

                        <div v-for="module in model.modules"
                             :key="module.label"
                             class="pa-1 mr-2 blue-grey--text text--darken-2"
                             :class="module.active ? compColor.prime + ' lighten-4' : 'grey lighten-2'"
                             style="border-radius:2px;">

                          <v-tooltip bottom open-delay="750">
                            <template v-slot:activator="{ on }">
                              <div v-on="on" class="layout-row">
                                <v-avatar :color="module.active ? compColor.prime: 'grey'" tile size="22">
                                  <v-icon size="16" color="white">mdi-view-grid-plus</v-icon>
                                </v-avatar>
                                <!--                              <v-divider vertical class="mx-1"></v-divider>-->
                                <div class="layout-column layout-align-center-center ml-2">
                                  <strong>{{ module.label }}</strong>
                                </div>
                                <v-divider vertical class="mx-1"></v-divider>
                                <div class="layout-column layout-align-center-center">
                                  <small>{{ module.active ? 'aktiv' : 'inaktiv' }}</small>
                                </div>
                              </div>
                            </template>
                            <span
                              v-if="module.active">Modul wurde von der Litixsoft GmbH freigeschaltet und ist aktiv</span>
                            <span v-else>Modul ist inaktiv und muss für die Verwendung von der Litixsoft GmbH freigeschaltet werden</span>
                          </v-tooltip>
                        </div>

                      </div>
                    </fake-input-frame>
                  </div>
                </div>

                <div class="layout-row layout-wrap pa-2">
                  <div class="flex-100 pa-2">
                    <fake-input-frame :label="$t('LICENSES.QUOTA_TITLE')"
                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                      class="mb-6">
                      <div class="layout-row layout-align-start-center px-2 mt-n2"
                           :style="{height: denseFields ? '70px':'80px'}"
                           :class="compColor.textClass"
                      >
                        <div class="layout-row layout-wrap pa-2">
                          <span class="flex-100 pt-2">Medizinische Einrichtungen {{ licenses.num_medicalunit }} von {{ licenses.max_medicalunit }}</span>
                          <span class="flex-100 pt-2">Sonderartikellisten {{ licenses.num_special_article_lists }} von {{ licenses.max_special_article_lists }}</span>
                        </div>
                      </div>
                    </fake-input-frame>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
          </lx-expansion-panels>
        </div>

        <v-divider v-bind:style="{'border-color':compColor.lightDivider}" class="mb-2"></v-divider>

      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import lodash from 'lodash'

const EMailAddressRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  name: 'PharmacyDetails',
  props: {
    pharmacyid: {
      type: [String, null],
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      hoverHeader: false,
      panel: [0, 1, 2, 3, 4],
      loading: 0,
      model: {
        address: {}
      },
      oldModel: null,
      error: {},
      isDirty: false,
      states: [],
      ordermail: '',
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          if (!value) return true;

          return EMailAddressRegEx.test(value) || 'Ungültige Adresse';
        },
      },
      isPharmacy: (this.getUserLocationType() === "pharmacy"),
      dummyPending: false,
      orderEmailAddresses: null,
      oldOrderEmailAddresses: null,
      licenses: {}
      // moduleList: []
    }
  },
  created() {
    this.getCountryStates();
    this.getData()

    this.$nextTick(() => {
      let scroller = this.$refs.scrollContainer
      scroller.addEventListener('scroll', () => {
        if (scroller.scrollTop > 0) {
          let number = scroller.scrollTop
          number = number > 4 ? 4 : number
          this.hoverHeader = 'elevation-' + number
        } else {
          this.hoverHeader = ''
        }
      })
    })
  },
  computed: {
    noNewAdresses() {
      return lodash.isEqual(this.model.ordermail_address, this.oldOrderEmailAddresses)
    },
    isBusy() {
      return this.loading > 0
    }
  },
  methods: {
    addOrderMail(e) {
      if (this.dummyPending || e instanceof KeyboardEvent && e.keyCode !== 13) return;
      if (!EMailAddressRegEx.test(this.ordermail)) return;

      this.dummyPending = true;

      this.$nextTick()
        .then(() => {
          const value = this.ordermail.trim().toLowerCase();

          if (Array.isArray(this.model.ordermail_address)) {
            const index = this.model.ordermail_address.findIndex((a) => {
              return a.toLowerCase() === value;
            });

            if (index !== -1) return;
          } else {
            this.model.ordermail_address = [];
          }

          this.model.ordermail_address.push(value);
          this.ordermail = null;

        })
        .finally(() => {
          this.dummyPending = false;
        })
    },
    removeOrderMail(index) {
      this.dummyPending = true;

      this.$nextTick()
        .then(() => {
          this.$delete(this.model.ordermail_address, index);
        })
        .finally(() => {
          this.dummyPending = false;
        })
    },
    openCloseExpansionPanel(index) {
      if (this.panel.indexOf(index) < 0) {
        this.panel.push(index)
      } else {
        this.panel.splice(this.panel.indexOf(index), 1)
      }
    },
    /**
     * wird aufgerufen, wenn man den zurück Button drückt
     *
     * */
    back() {
      window.history.back()
    },

    /**
     * Get all users, all rights, all rights of selected role and all users with selected role
     */
    getData() {
      if (this.isBusy) {
        return
      }

      this.loading++

      this.$http.get('/mycompany')
        .then(res => {
          if (res.data) {
            this.model = res.data
            if (!this.model.address) {
              this.model.address = {}
            }

            // this.orderEmailAddresses = this.model.ordermail_address || []
            this.oldOrderEmailAddresses = lodash.cloneDeep(this.model.ordermail_address);

            this.oldModel = lodash.cloneDeep(this.model)
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading--
        })

      if (this.isPharmacy) {
        this.loading++

        this.$http.get('/pharmacy/licenses/quota')
          .then(res => {
            this.licenses = res.data || {}
          })
          .catch(this.NotifyError)
          .finally(() => {
            this.loading--
          })
      }
    },
    getCountryStates() {
      this.$http.get('/calendar/states/ger')
        .then((result) => {
          this.states = result.data || []
        })
        .catch(this.NotifyError)
    },
    save(reset) {
      let data = lodash.cloneDeep(this.model);
      delete data.modules
      delete data.additionally
      delete data.corporate_group

      this.$http.put('/mycompany', data)
        .then(() => {
          if (this.$refs.MyCompanyDetailForm) {
            this.$refs.MyCompanyDetailForm.reset()
          }

          this.oldModel = lodash.cloneDeep(this.model);

          this.NotifySystem('Änderungen gespeichert')
          this.clearForm(reset)
        })
        .catch((error) => {
          this.HandleFormError(this.$refs.MyCompanyDetailForm, error)
            .catch(this.NotifyError)
        })
    },

    /**
     * resetet das Formular, also alles prestine, keine Fehler etc. anschließend wird das model wieder mit dem Ursprungsmodel gefüllt
     *
     * **/
    clearForm(reset) {
      reset();

      this.model = lodash.cloneDeep(this.oldModel)
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dark_login {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  padding: 50px;
  min-width: 500px;
  min-height: 700px;
  background-image: linear-gradient(#3b4b65, #212c3e);
}

h1, h2 {
  font-weight: normal;
}

.subheader {
  height: 20px;
  font-size: 16px;
  padding: 0 16px 0 2px;
  font-weight: 300;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.tabs__div {
  text-transform: none;
  color: #575757;
}
</style>
