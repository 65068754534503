/**
 * Executes a given Function if internal lockcounter zero
 *
 * Lock increments counter by 1
 * UnLock decrease counter by 1 until greater 0
 * @param fn
 * @returns {{UnLock(): void, Lock(): void}}
 * @constructor
 */
function LockEvent(fn) {
  let lockCounter = 0
  let nnn = []

  const callFunction = typeof fn === 'function' ? fn : () => {}

  return {
    Lock () {
      lockCounter++
    },
    UnLock () {
      lockCounter = Math.max(--lockCounter, 0)

      if (lockCounter === 0) {
        let data = callFunction()

        if (Array.isArray(data)) {
          nnn = data
        }
      }
    },
    HasKey (key) {
      return nnn.includes(key)
    }
  }
}

export {
  LockEvent
}