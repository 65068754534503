<template>
    <v-dialog v-model="getMessagingSettingsVisibility" persistent scrollable max-width="640" overlay-opacity="0.9">
        <ValidationObserver ref="messageSettingsPopup" class="layout-column flex" v-slot="{ valid, pristine, reset }">
            <v-card class="flex" :dark="compColor.isInDarkMode" v-bind:class="[compColor.contentFrameBack]">
                <v-card-title class="title elevation-4 layout-row pa-0">
                    <div class="pa-1 flex" v-bind:class="[compColor.contentFrameBack]"
                         style="z-index: 2;border-radius:4px 4px 0 0;">
                        <div class="py-1 px-1 layout-row layout-align-start-center"
                             v-bind:class="[compColor.contentFrameFront,compColor.widget.headline]">
                            <div class="layout-column layout-align-center-center pr-3">
                                <v-avatar :color="compColor.avatarTile" tile height="40" width="40"
                                          style="min-width:40px;">
                                    <v-icon :color="compColor.primeColor">mdi-message-cog</v-icon>
                                </v-avatar>
                            </div>
                            Benachrichtigungseinstellungen
                        </div>
                    </div>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text class="pa-5" style="min-height: 130px;">
                    <table style="width:100%;">
                        <thead>
                        <tr>
                            <th class="text-left">Benachrichtigungsgrund</th>
                            <th>Mitteilung</th>
                            <th>E-Mail</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(option, index) in options" :key="index">
                            <td>{{ option.name }}</td>
                            <td>
                                <div class="layout-row layout-align-center-center">
                                    <validation-provider tag="div" ref="notifications"
                                                         name="notifications"
                                                         v-slot="{  }">
                                        <v-switch
                                                inset
                                                :disabled="!option.notification"
                                                v-model="notifications"
                                                :value="option.key"
                                                :dark="compColor.isInDarkMode"
                                                :color="compColor.primeColor"
                                                hide-details
                                        ></v-switch>
                                    </validation-provider>
                                </div>
                            </td>
                            <td>
                                <div class="layout-row layout-align-center-center">
                                    <validation-provider tag="div" ref="emails"
                                                         name="emails"
                                                         v-slot="{  }">
                                        <v-switch
                                                inset
                                                :disabled="!option.email"
                                                v-model="emails"
                                                :value="option.key"
                                                :dark="compColor.isInDarkMode"
                                                :color="compColor.primeColor"
                                                hide-details
                                        ></v-switch>
                                    </validation-provider>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :dark="compColor.isInDarkMode" class="white--text text-none mx-1"
                           :disabled="!valid || pristine"
                           :color="compColor.primeColor" @click="save()">Speichern

                    </v-btn>
                    <v-btn :dark="compColor.isInDarkMode" class="white--text text-none mx-1"
                           :color="compColor.primeColor" @click="close(reset)">Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </ValidationObserver>
    </v-dialog>
</template>

<script>
    function reverse(a, b, c) {
        if (b.length === 0) return a;

        let d = [];
        b.forEach((e) => {
            if (e[c] === true && a.indexOf(e.key) === -1) {
                d.push(e.key)
            }
        });

        return d;
    }

    export default {
        name: 'LxMessagingSettings',
        beforeMount() {
            this.getSettings();
        },
        data() {
            return {
                options: [],
                emails: [],
                notifications: [],
            }
        },
        methods: {
            getSettings() {
                this.messaging = [];

                this.$http.get('/messaging/settings')
                    .then((res) => {
                        if (res.data) {
                            this.options = res.data.available || [];
                            this.notifications = reverse(res.data.notifications || [], this.options, 'notification');
                            this.emails = reverse(res.data.emails || [], this.options, 'email');
                        }
                    });
            },
            save() {
                this.$http.put('/messaging/settings', {
                    notifications: reverse(this.notifications, this.options, 'notification'),
                    emails: reverse(this.emails, this.options, 'email')
                }).then(this.NotifySuccess)
                    .catch(this.NotifyError)
                    .finally(() => {
                        this.Messaging.dispatch('setSettingsVisibility', false)
                    })
            },
            close(reset) {
                this.Messaging.dispatch('setSettingsVisibility', false);
                reset();
            },
        }
    }
</script>

<style scoped>

</style>
