<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="back()"
                  icon="mdi-chevron-left"
                  tooltip="zurück zur Übersicht"
                  :hide-label="true"
                  :label="'Zurück'"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  :disabled="isLoadingData > 0"
                  classes="text-none mr-1"
                  @click="getData()"
                  v-if="tabIndex === 1"
                  icon="refresh"
                  tooltip="Ansicht aktualisieren"
                  :hide-label="true"
                  :label="'Aktualisieren'"></lx-btn>
          <lx-dropdown-button :color="'#353a47'"
                              :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                              v-if="tabIndex !== 1"
                              small
                              hide-list-icons
                              iconSize="20"
                              width="auto"
                              :disabled="isLoadingData > 0"
                              classes="text-none mr-1 v-btn--tile"
                              icon="refresh"
                              tooltip="Ansicht aktualisieren"
                              :items="refreshItems"
                              @click="getData"
                              :label="'Aktualisieren'">
          </lx-dropdown-button>

        </div>
        <div class="flex layout-row"></div>

      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <v-tabs
        :color="compColor.primeColor"
        :dark="compColor.isInDarkMode"
        :background-color="compColor.isInDarkMode ? 'transparent':'grey lighten-3'"
        :dense="denseFields"
        v-on:change="changeTab"
      >
        <v-tab>
          <v-icon left>
            mdi-eye
          </v-icon>
          Übersicht
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-calendar
          </v-icon>
          Monat/Jahr
        </v-tab>

        <v-tab-item class="pa-1 lx-delivery-background" :style="{'background': compColor.lxExpansion.background}">
<!--          <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">-->
            <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px"
                 :style="{'background': compColor.lxExpansion.background}">


              <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1); overflow-y: auto"
                   class="flex layout-column">
                <line-chart
                  v-if="loaded"
                  :chartdata="chartdata"
                  :options="options"/>
              </div>

              <v-container>
                <!-- Stack the columns on mobile by making one full-width and the other half-width -->
                <v-row>
                  <v-col
                      cols="12"
                      md="8"
                  >
                    <v-card
                        class="pa-1"
                        style="border-radius:6px;"
                        :style="{color: compColor.primeColor, 'background': compColor.context.background}"
                        outlined
                    >
                      Verarbeitete Bestellungen im Zeitraum
                    </v-card>
                  </v-col>
                  <v-col
                      cols="6"
                      md="4"
                  >
                    <v-card
                        class="pa-1"
                        style="border-radius:6px;"
                        :style="{color: compColor.primeColor, 'background': compColor.context.background}"
                        outlined
                    >
                      {{ orders_total }}
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>

            </div>
<!--          </div>-->
        </v-tab-item>

        <v-tab-item class="pa-1 lx-delivery-background" :style="{'background': compColor.lxExpansion.background}">
          <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px"
               :style="{'background': compColor.lxExpansion.background}">
            <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1); overflow-y: auto"
                 class="flex layout-column">
              <bar-chart
                  v-if="loaded"
                  :chartdata="data_1.chartdata"
                  :options="data_1.options"/>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>


<!--      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">-->
<!--        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px"-->
<!--             :style="{'background': compColor.lxExpansion.background}">-->


<!--          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1); overflow-y: auto"-->
<!--               class="flex layout-column">-->
<!--            <line-chart-->
<!--              v-if="loaded"-->
<!--              :chartdata="chartdata"-->
<!--              :options="options"/>-->
<!--          </div>-->

<!--          <v-container>-->
<!--            &lt;!&ndash; Stack the columns on mobile by making one full-width and the other half-width &ndash;&gt;-->
<!--            <v-row>-->
<!--              <v-col-->
<!--                  cols="12"-->
<!--                  md="8"-->
<!--              >-->
<!--                <v-card-->
<!--                    class="pa-1"-->
<!--                    style="border-radius:6px;"-->
<!--                    :style="{color: compColor.primeColor, 'background': compColor.context.background}"-->
<!--                    outlined-->
<!--                >-->
<!--                  Verarbeitete Bestellungen im Zeitraum-->
<!--                </v-card>-->
<!--              </v-col>-->
<!--              <v-col-->
<!--                  cols="6"-->
<!--                  md="4"-->
<!--              >-->
<!--                <v-card-->
<!--                    class="pa-1"-->
<!--                    style="border-radius:6px;"-->
<!--                    :style="{color: compColor.primeColor, 'background': compColor.context.background}"-->
<!--                    outlined-->
<!--                >-->
<!--                  {{ orders_total }}-->
<!--                </v-card>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-container>-->

<!--        </div>-->
<!--      </div>-->

    </div>
  </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart'
import BarChart from '@/components/charts/BarChart'
import LxDropdownButton from '@/components/lx_dropdown_button/LxDropdownButton'

function toChartData(input) {
  const weekdaysLabels = [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ]

  let hourLabels = []

  for (let i = 0; i < 24; i++) {
    hourLabels.push(i)
  }

  let data = [];

  for (let i = 0; i < 7; i++) {
    let rgb = {
      r: Math.floor(((i % 2) / 2) * 255),
      g: Math.floor(((i % 3) / 3) * 255),
      b: Math.floor((i / 7) * 255)
    }

    data.push({
      label: weekdaysLabels[i],
      borderColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.90`,
      backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.20`,
      data: (new Array(24)).fill(0)
    })
  }

  input.forEach((x) => {
    const weekday = Math.floor(x.n / 100) - 1;
    const hour = (x.n % 100);
    data[weekday].data[hour] = x.c
  })

  return {
    labels: hourLabels,
    datasets: data
  }
}

export default {
  name: 'StatisticsOrderDetails',
  components: { LineChart, BarChart, LxDropdownButton },
  data() {
    return {
      refreshItems: [
        {caption: 'Jeder Zeitraum', value: 0, default: true},
        {caption: 'Letzten 7 Tage', value: 168},
        {caption: 'Letzten 30 Tage', value: 720},
        {caption: 'Letzten 90 Tage', value: 2160},
        {caption: 'Letzten 120 Tage', value: 2880},
      ],
      isLoadingData: 0,
      orders_total: 0,
      loaded: false,
      tabIndex: 0,
      chartdata: null,
      data_1: {
        chartdata: null,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          stacked: false,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            text: 'Bestellungen Monat/Jahr'
          }
        },
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        stacked: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          text: 'Bestellungen nach Wochentag und Uhrzeit'
        }
      },
    }
  },
  // async mounted() {
  //   this.getData()
  // },
  methods: {
    back() {
      window.history.back()
    },
    changeTab (newIndex) {
      this.tabIndex = newIndex
      this.getData()
    },
    getData(event, timespanValue, itemindex) {
      this.loaded = false

      switch (this.tabIndex) {
        case 0:
          this.$http.get('statistics/data/orders', {timespan: timespanValue || 0})
              .then((res) => {
                this.chartdata = toChartData(res.data.v1)

                let n = 0
                res.data.v1.forEach((e) => {
                  n += e.c
                })

                this.orders_total = n
              })
              .catch(this.NotifyError)
              .finally(() => {
                this.loaded = true
              })
          break

        case 1:
          this.$http.get('statistics/data/orders/monthyear', {timespan: timespanValue || 0})
              .then((res) => {
                let tmpDataset = {}

                res.data.v1.forEach((item)  => {
                  let yearmonth = item.n.split('-')

                  if (!tmpDataset[yearmonth[0]]) {
                    tmpDataset[yearmonth[0]] = (new Array(12)).fill(0)
                  }

                  let month = parseInt(yearmonth[1]);
                  tmpDataset[yearmonth[0]][month - 1] = item.c
                })

                let datasets = []
                Object.keys(tmpDataset).forEach((x, i) => {
                  let rgb = {
                    r: Math.floor(((i % 2) / 2) * 255),
                    g: Math.floor(((i % 3) / 3) * 255),
                    b: Math.floor(255 - (i / 7) * 255)
                  }

                  datasets.push({
                    label: x,
                    borderColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.90`,
                    backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.20`,
                    data: tmpDataset[x]
                  })
                })

                this.data_1.chartdata = {
                  labels: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                  datasets: datasets
                }

              })
              .catch(this.NotifyError)
              .finally(() => {
                this.loaded = true
              })
          break
        default:
          this.loaded = true
      }

    }
  }
}
</script>

<style scoped>

</style>
