<template>
  <v-tooltip bottom :disabled="!tooltip||!visible" open-delay="550">
    <template v-slot:activator="{ on }">
      <v-btn light
             v-on="on"
             :type="type"
             :icon="asIcon"
             :text="asText"
             :small="small"
             :block="block"
             :height="calcHeight"
             :width="width"
             :outlined="outlined"
             :color="finalColor"
             v-bind:style="{'cursor': disabled ? 'not-allowed' : 'pointer', 'opacity': disabled ? '0.3' : '1.0', 'color': finalTextColor}"
             class="lx-btn ma-0 elevation-0"
             v-bind:class="[classes]"
             style="position: relative;"
      >
        <div class="layout-row layout-align-start-center">
          <div v-if="!loading">
            <v-icon light v-if="icon" left :size="iconSize ? iconSize : Math.round(calcHeight / 2.3)" class="ma-0"
                    @click.prevent=""
                    :color="finalIconColor"
            >{{ icon }}
            </v-icon>
          </div>
          <div v-else>
            <v-progress-circular
              indeterminate
              size="22"
              width="3"
              class="ma-0"
              :color="compColor.primeHighlightColor"
            ></v-progress-circular>
          </div>
          <div>
            <span :class="{'ml-2': icon}" v-if="label && !hideLabel">{{ label }}</span>
            <!--                      class="body-2"-->
          </div>
          <div v-if="isMenu" class="pl-2">
            <v-icon light v-if="icon" left :size="iconSize ? iconSize : Math.round(calcHeight / 2.3)" class="ma-0"
                    :color="finalIconColor"
            >mdi-menu-down
            </v-icon>
          </div>
        </div>
        <div @click.self="execute($event)"
             style="position: absolute;top:-11px;left:-10px;bottom:-11px;right:-10px;z-index:1;"></div>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import Vue from 'vue'
import {isMobile} from 'mobile-device-detect';

export default Vue.component('lxBtn', {
  props: {
    icon: {
      type: String,
      default: null
    },
    iconColor: {
      type: String,
      default: null
    },
    iconSize: {
      type: String,
      default: null
    },
    asIcon: {
      type: Boolean,
      default: false
    },
    asText: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    height: {
      type: [Number, String],
      default: 40
    },
    width: {
      type: [Number, String],
      default: 40
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null
    },
    tooltipAlwaysOn: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: null
    },
    classes: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'button'
    },
    outlined: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    isMenu: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      visible: false,
      isMobileDevice: isMobile,
    }
  },
  created() {
    this.visible = this.tooltipAlwaysOn
  },
  activated() {
    this.visible = true
  },
  deactivated() {
    this.visible = false
  },
  methods: {
    execute(event) {
      if (!this.disabled) {
        this.$emit('click', event)
      }
    }
  },
  computed: {
    calcHeight() {
      let hg = this.height
      if (this.denseFields) {
        hg *= 0.9
      }
      return hg
    },
    finalIconColor() {
      if (!this.iconColor) {
        return this.compColor.isInDarkMode ? this.compColor.primeHighlightColor : this.compColor.primeColor
      } else {
        return this.iconColor
      }
    },
    finalColor() {
      if (!this.color) {
        return this.compColor.btnColor
      }

      return this.color
    },
    finalTextColor() {
      if (!this.color) {
        return this.compColor.isInDarkMode ? this.compColor.primeHighlightColor : '#607D8B'
      }
      return '#fff'
    }
  }
})
</script>

<style scoped lang="scss">
.lx-square {
  min-width: 40px;
  width: 40px;
  min-height: 35px !important;
}

.lx-btn {
  &:not(.v-btn--round).v-size--default {
    min-width: 2em;
  }
}

.lx-btn.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: inherit; //#272b36;
}

.v-subheader {
  font-size: 13px;
}

.v-btn.v-btn--small {
  padding: 0 4px !important;
}

.lxpager {
  margin: 2px 2px;
  padding: 6px 6px;
  color: #000;
  cursor: pointer;
}

.lxpager.disabled {
  color: #CCC;
}

.v-btn {
  min-width: auto;

  &.btn--small {
    max-width: 28px;
  }
}

.list__tile__content.pagesize-is-active {
  .list__tile__title {
    font-weight: bold;
    color: #00bfa5;
  }
}

.v-list-item__content {
  padding: 0 !important;
}
</style>
