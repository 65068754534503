<template>
    <div>
        <div v-if="model === 1">
            <v-icon color="green lighten-3" class="mr-2">schedule</v-icon>
            <span class="green--text">Bearbeitung steht aus</span>
        </div>
        <div v-if="model === 2">
            <v-icon color="amber darken-2" class="mr-2">settings</v-icon>
            <span>In Bearbeitung durch User</span>
        </div>

    </div>

</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    data() {
      return {
        model: null
      }
    },
    created() {
      if (this.params.data && this.params.colDef.field) {
        this.model = this.params.data[this.params.colDef.field]
      }
    }
  })
</script>
