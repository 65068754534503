<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="back()"
                  icon="mdi-chevron-left"
                  :hide-label="true"
                  tooltip="zurück zur Übersicht"
                  :label="'Zurück'"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  :disabled="isLoadingData"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="refresh"
                  :hide-label="true"
                  tooltip="Ansicht aktualisieren"
                  :label="'Aktualisieren'"></lx-btn>
        </div>
        <div class="flex layout-row"></div>

      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px;"
             :style="{'background': compColor.lxExpansion.background}">


          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1);" class="flex layout-column">
            <lx-data-table
              v-model="tableObj.selection"
              tableName="MailLogList"
              :items="items"
              :headers="tableObj.headers"
              :loading="tableObj.loading"
              :tableOptions="tableObj.options"
              :hideContextFilter="true"
              @changeOptions="changeTableOptions"
              @rowClicked="doRowClick">

              <template v-slot:cellrenderer="{ value }">
                <div v-if="value.f.renderer === 'dateTime'" style="padding:1px;height:35px;">
                  <div class="layout-row layout-align-start-center" style="border-radius:3px;height:33px;">
                    {{ value.i[value.f.value] | formatDate('DD.MM.YYYY HH:mm:ss') }}
                  </div>
                </div>
                <div v-else-if="value.f.renderer === 'transportIdCellRender'" style="padding:1px;height:35px;">
                  <div class="layout-row layout-align-start-center" style="border-radius:3px;height:33px;">
                    {{ '0x' + (value.i[value.f.value] || '').toLocaleUpperCase() }}
                  </div>
                </div>
              </template>
            </lx-data-table>
          </div>
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="layout-row pa-0">
        <lx-pagination-light
                       :page-name="'MailLogList'"
                       :page-options="pagingOptions"
                       :page-change="getData"
        ></lx-pagination-light>
      </div>

    </div>

    <lx-dialog v-model="showDetails" :allow-drag="true" :overlay="true" :size="{x: '60%', y: '60%'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          Datenansicht
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <div class="flex layout-column pa-1">
            <pre class="blue-grey--text text--darken-2">{{ detailData | json }}</pre>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <!-- CANCEL BUTTON -->
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  block
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click.stop="showDetails = null"
                  icon="cancel"
                  tooltip="Fenster schließen"
                  :label="'Schließen'"></lx-btn>
        </div>
      </template>
    </lx-dialog>

  </div>
</template>
<script>
import LxPaginationLight from '@/components/lx_pagination/LxPaginationLight.vue'
export default {
  name: 'MailLogList',
  components: {
    LxPaginationLight
  },
  data() {
    return {
      searchString: null,
      searchDelayTimer: null,
      tableObj: {
        loading: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
        options: {
          sortBy: [],
          sortDesc: [],
        },
        filterValue: null,
        selection: null,
        headers: [
          {
            text: 'Transport Id',
            value: '_id',
            width: '180px',
            renderer: 'transportIdCellRender'
          },
          {
            text: 'Letzter Status',
            value: 'last_state',
          },
          {
            text: 'Empfänger',
            value: 'recipient',
          },
          {
            text: 'Betreff',
            value: 'subject',
          },
          {
            text: 'Erster Eintrag',
            value: 'first_state_timestamp',
            width: '200px',
            class: 'pinned',
            renderer: 'dateTime'
          },
          {
            text: 'Letzter Eintrag',
            value: 'last_state_timestamp',
            width: '200px',
            class: 'pinned',
            renderer: 'dateTime'
          },
          // {
          //   text: 'ZeitDifferenz',
          //   value: 'duration',
          //   width: '200px',
          //   renderer: 'duration'
          // }
        ]
      },
      items: [],
      isLoadingData: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
      frameworkComponents: null,
      columnDefs: null,
      rowData: null,
      FilterAreFloating: false,
      allowBlurOnLoading: true,
      showDetails: false,
      detailData: null,
      defaultSortOpts: [],
    }
  },
  created() {
    // this.createColumnDefs();
    // this.getData();
  },
  watch: {
    searchString() {
      clearInterval(this.searchDelayTimer);

      this.searchDelayTimer = setInterval(() => {
        clearInterval(this.searchDelayTimer);
        this.getData()
      }, 500)
    }
  },
  methods: {
    changeTableOptions(value) {
      if (value) {
        this.tableObj.options = value
      }
      this.getData()
    },

    doRowClick(params) {
      this.detailData = params.data;
      this.showDetails = true;
    },

    /**
     * Wird verschiedenen Stellen aus aufgerufen und soll die Daten vom Backend abholen. Mittels axios....bla bla bla
     * Besonderheit:
     * "this.pagingOptions.count = res.data.count"
     * - Anzahl der Datensätze vom Server wird auch in den pagingOptions gespeichert
     *
     * "this.$store.dispatch('setPagingOptions', this.pagingOptions)"
     * - nach der Speicherung der Anzahl werden die pagingOptions wieder in den Store geschrieben
     *
     * "this.gridOptions.api.ensureIndexVisible(this.$store.getters.gridScrollIndex, 'top')"
     * - sorgt dafür das nach dem neuladen der Daten das Grid wieder an die Stelle scrollt wo es war. notwendig z.B. bei der Rückkehr zum Grid
     * **/
    getData(options) {
      if (this.tableObj.loading) return

      this.tableObj.loading = true

      if (options && typeof options.skip === 'number') {
        this.pagingOptions.skip = options.skip
      }
      if (options && options.limit) {
        this.pagingOptions.limit = options.limit
      }

      const params = this.$helper.GetQueryParam({
        ...this.tableObj.options,
        skip: this.pagingOptions.skip,
        limit: this.pagingOptions.limit,
        query: {},
        count: true
        // searchString: this.searchString,
        // searchFields: ['username', 'importId']
      })

      this.$http.get('/logging/mails', params)
        .then(res => {
          if (res.data) {
            this.items = res.data.result || []
            this.pagingOptions.count = this.items.length
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.tableObj.loading = false
        })
    },
    back() {
      window.history.back()
    }
  }
}
</script>
<!-- Add 'scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
