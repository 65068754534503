<template>
  <v-app id="mainApp"
         v-bind:class="{'blurContent': showLoginPopup, 'lx-dark-app': compColor.isInDarkMode, 'lx-dense-app': denseFields}"

         v-bind:style="{'background': compColor.appBackground, 'font-size': denseFields ? '14px':'inherit'}"
         v-resize="windowResized">

    <lx-admin-panel :is-admin-panel-visible="showAdminPanel"></lx-admin-panel>

    <!-- ** impressums + datenschutz link beim login, eigene komponente aus lx_imprint_and_cookie ** -->
    <header v-if="!isLoggedIn" color="transparent" style="z-index:9999;"
            class="py-2 px-2 layout-row layout-align-center-center" dark app clipped-left :height="48" padless>
      <v-spacer></v-spacer>
      <v-btn text v-show="!imprintDialogVisibility" @click="imprintDialogVisibility = true" :dark="true"
             style="background: rgba(255,255,255,0.1);border:1px solid rgba(255,255,255,0.2);backdrop-filter: blur(9px)">
        <span class="green--text text--accent-3 caption mr-3 text-none">Impressum + Datenschutz</span>
      </v-btn>
    </header>

    <!-- impressums popup, eigene komponente lx_imprint_and_cookie -->
    <!-- während lx-imprint-dialog sich in lxvue befindet, wird der Inhaltstext in jeder Anwendung selber gepflegt und per slot übergeben-->
    <lx-imprint-dialog>
      <imprint-content></imprint-content>
    </lx-imprint-dialog>

    <!-- ** top app-bar ** -->
    <v-app-bar v-if="isLoggedIn && !isMobileDevice && !smallMonitor"
               v-ripple="{ center: true, class: compColor.textInput + '--text' }" app clipped-left clipped-right
               :extended="true" dark
               :height="denseFields ? '35':'48'"
               :color="compColor.topHeader.backgroundRight"
               v-bind:style="{ 'background-image': 'linear-gradient(90deg, transparent, #263238)' }">

      <!--      <v-toolbar-title style="font-size:25px;" class="font-weight-regular"><strong class="font-weight-bold">Krankenhaus</strong>bestellsystem</v-toolbar-title>-->

      <div class="layout-row layout-align-center-start">
        <v-icon :size="denseFields ? '28px':'36px'" class="mr-2">mdi-truck-fast</v-icon>
        <v-toolbar-title :style="{'font-size': denseFields ? '20px':'25px'}" class="font-weight-regular"><strong
          class="font-weight-bold">Medi</strong>Shop
        </v-toolbar-title>
        <div class="mx-2 layout-column layout-align-center-start body-1"
             :style="{height: denseFields ? '30px':'40px'}"
             :class="denseFields ? 'caption':'body-1'"
             v-if="pharmacyInformation && pharmacyInformation.name">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on" class="layout-row" style="cursor:pointer;"><span
                class="mx-2">-</span>{{ pharmacyInformation.name }}
              </div>
            </template>
            <div class="layout-column">
              <span class="body-1 my-1">Wir sind für Sie erreichbar unter:</span>
              <v-divider v-bind:style="{'border-color': compColor.primeColor}"></v-divider>
              <div class="layout-row mt-2">
                <span style="min-width:100px;">Telefon:</span>
                <span>{{ pharmacyInformation.supportphone }}</span>
              </div>
              <div class="layout-row">
                <span style="min-width:100px;">E-Mail:</span>
                <span>{{ pharmacyInformation.supportemail }}</span>
              </div>
              <div class="layout-row">
                <span style="min-width:100px;">Straße, Nr.:</span>
                <span>{{ pharmacyInformation.address.street }} {{ pharmacyInformation.address.number }}</span>
              </div>
              <div class="layout-row">
                <span style="min-width:100px;">PLZ:</span>
                <span>{{ pharmacyInformation.address.zipcode }}</span>
              </div>
              <div class="layout-row">
                <span style="min-width:100px;">Stadt:</span>
                <span>{{ pharmacyInformation.address.city }}</span>
              </div>
            </div>
          </v-tooltip>
        </div>
      </div>

      <v-spacer></v-spacer>

      <v-tooltip bottom open-delay="550">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text style="min-width:30px;" @click="enterFullscreen" tabindex="0">
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
        </template>
        <span>Vollbildmodus aktivieren/deaktivieren</span>
      </v-tooltip>

      <v-divider vertical inset class="mx-1"></v-divider>

      <v-tooltip bottom open-delay="550">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text style="min-width:30px;" @click="openThemeSidebar" tabindex="0">
            <v-icon>mdi-cogs</v-icon>
          </v-btn>
        </template>
        <span>Theme-Einstellungen öffnen</span>
      </v-tooltip>

      <v-divider vertical inset class="mx-1"></v-divider>

      <v-tooltip bottom open-delay="550">
        <template v-slot:activator="{ on }">

          <v-btn v-on="on" text style="min-width:30px;" @click="openMessagesSidebar" tabindex="0"
                 v-show="getMessagingEnabled">
            <v-icon v-if="!getHasSystemMessages">mdi-chat-processing</v-icon>
            <v-icon v-else color="red">mdi-chat-alert</v-icon>
            <div v-if="getMessageCount"
                 class="green ml-1 mt-n1 font-weight-black"
                 :class="{'flash_icon' : getHasNewMessages, 'flash_icon_ex': false}"
                 style="padding-left:3px;padding-right:3px;min-width:20px;height:20px;line-height: 22px;border-radius: 4px;font-size: 12px;"
            >{{ getMessageCount }}
            </div>
          </v-btn>
        </template>
        <span>Nachrichtenübersicht öffnen</span>
      </v-tooltip>

      <v-divider vertical inset class="mx-1"></v-divider>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text class="text-none" v-on="on">
            <v-icon class="mr-2">mdi-shield-account</v-icon>
            <div class="layout-column layout-align-center-start">
              <span>{{ authUserTitle }} {{ authUserName }}</span>
              <small class="font-weight-light">{{ getLocationName }}</small>
            </div>
          </v-btn>
        </template>


        <v-list v-bind:class="{'blue-grey darken-3': compColor.isInDarkMode}">
          <v-list-item two-line to="/account" :dark="compColor.isInDarkMode">
            <v-icon :color="compColor.primeColor" class="mx-2">face</v-icon>
            <v-list-item-content class="ml-3">
              <v-list-item-title :class="{'white--text': compColor.isInDarkMode}">Account verwalten</v-list-item-title>
              <v-list-item-subtitle>Hier können Sie Änderungen an ihren Accountdaten vornehmen
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <template v-if="authSystem !== 'median' && getUserHasMultiLocations">
            <v-divider></v-divider>

            <v-list-item @click="changeLocation" two-line :dark="compColor.isInDarkMode">
              <v-icon :color="compColor.primeColor" class="mx-2">business</v-icon>
              <v-list-item-content class="ml-3">
                <v-list-item-title>Standort wechseln</v-list-item-title>
                <v-list-item-subtitle>Legen Sie Ihre Organisation fest</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-divider></v-divider>

          <v-list-item @click="logout(false)" two-line :dark="compColor.isInDarkMode">
            <v-icon :color="compColor.primeColor" class="mx-2">lock</v-icon>
            <v-list-item-content class="ml-3">
              <v-list-item-title>Abmelden</v-list-item-title>
              <v-list-item-subtitle>Bitte melden Sie sich immer ordnungsgemäß ab.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <template v-slot:extension>
        <div class="grow">
          <v-divider></v-divider>
          <v-tabs centered v-model="activeTab" background-color="transparent" :height="denseFields ? '38':'48'"
                  ref="centertabs">
            <v-tab key="tab-1" :ripple="false" class="text-none px-5" to="/dashboard"
                   v-if="showHTMLByRole(['pharmacyadmin','pharmacist','abdaeditor','admin','nurse','doctor','seniordoctor', 'controller'])">
              {{ $t('main_nav.dashboard') }}
            </v-tab>
            <v-tab key="tab-2" :ripple="false" class="text-none px-5" to="/account" v-if="authSystem !== 'median'">
              {{ $t('main_nav.account') }}
            </v-tab>
            <v-tab key="tab-3" :ripple="false" class="text-none px-5"
                   v-if="showHTMLByRole(['pharmacyadmin','pharmacist','abdaeditor','admin', 'root']) && firstAdminNavRoute && firstAdminNavRoute.indexOf('/administration/') >= 0 && authSystem !== 'median'"
                   :to="firstAdminNavRoute">{{ $t('main_nav.administration') }}
            </v-tab>
            <v-tab key="tab-4" :ripple="false" class="text-none" to="/shopping"
                   v-if="showHTMLByRole(['nurse']) && !showHTMLByRole(['doctor','seniordoctor'])">
              {{ $t('main_nav.articlesearch') }}
            </v-tab>
            <v-tab key="tab-5" :ripple="false" class="text-none" to="/shopping"
                   v-if="showHTMLByRole(['doctor','seniordoctor']) && !showHTMLByRole(['root']) && authSystem !== 'median'">
              {{ $t('main_nav.shoppingcart') }}
            </v-tab>
            <v-tab key="tab-6" :ripple="false" class="text-none" to="/orders"
                   v-if="showHTMLByRole(['pharmacyadmin','pharmacist','nurse','doctor','seniordoctor', 'controller'])">
              {{ $t('main_nav.orders') }}
            </v-tab>
            <v-tab key="tab-7" :ripple="false" class="text-none" to="/reports"
                   v-if="showHTMLByRole(['pharmacist']) && authSystem !== 'median'">
              {{ $t('main_nav.report') }}
            </v-tab>
          </v-tabs>
        </div>
      </template>

    </v-app-bar>

    <v-app-bar v-if="isLoggedIn && isMobileDevice || isLoggedIn && smallMonitor"
               v-ripple="{ center: true, class: compColor.textInput + '--text' }" app clipped-left clipped-right
               :extended="false" dense dark :height="40"
               :color="compColor.topHeader.backgroundRight"
               v-bind:style="{ 'background-image': 'linear-gradient(90deg, transparent, #263238)' }">

      <v-menu offset-y v-if="authSystem !== 'median'">
        <template v-slot:activator="{ on }">
          <v-btn text icon class="text-none mr-4 ml-n2" v-on="on">
            <v-icon size="24">menu</v-icon>
          </v-btn>
        </template>

        <v-list v-bind:class="{'blue-grey darken-3': compColor.isInDarkMode}">

          <v-list-item two-line to="/dashboard" :dark="compColor.isInDarkMode"
                       v-if="showHTMLByRole(['pharmacyadmin','pharmacist','abdaeditor','admin','nurse','doctor','seniordoctor']) && !showHTMLByRole(['root'])">
            <v-icon :color="compColor.primeColor" class="mx-2">dashboard</v-icon>
            <v-list-item-content class="ml-3">
              <v-list-item-title>Dashboard</v-list-item-title>
              <v-list-item-subtitle>Sehen sie alles im Überblick
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item two-line to="/account" :dark="compColor.isInDarkMode" v-if="authSystem !== 'median'">
            <v-icon :color="compColor.primeColor" class="mx-2">face</v-icon>
            <v-list-item-content class="ml-3">
              <v-list-item-title>Account</v-list-item-title>
              <v-list-item-subtitle>Ihre persönlichen Daten
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item two-line :to="firstAdminNavRoute" :dark="compColor.isInDarkMode"
                       v-if="showHTMLByRole(['pharmacyadmin','pharmacist','abdaeditor','admin']) && firstAdminNavRoute && firstAdminNavRoute.indexOf('/administration/') >= 0 && authSystem !== 'median'">
            <v-icon :color="compColor.primeColor" class="mx-2">settings</v-icon>
            <v-list-item-content class="ml-3">
              <v-list-item-title>Administration</v-list-item-title>
              <v-list-item-subtitle>alle notwendigen Einstellungen
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item two-line to="/shopping" :dark="compColor.isInDarkMode"
                       v-if="showHTMLByRole(['nurse']) && !showHTMLByRole(['doctor','seniordoctor'])">
            <v-icon :color="compColor.primeColor" class="mx-2">shopping_cart</v-icon>
            <v-list-item-content class="ml-3">
              <v-list-item-title>Artikelsuche</v-list-item-title>
              <v-list-item-subtitle>befüllen Sie ihren Warenkorb
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line to="/shopping" :dark="compColor.isInDarkMode"
                       v-if="showHTMLByRole(['doctor','seniordoctor']) && !showHTMLByRole(['root']) && authSystem !== 'median'">
            <v-icon :color="compColor.primeColor" class="mx-2">shopping_cart</v-icon>
            <v-list-item-content class="ml-3">
              <v-list-item-title>Warenkorb</v-list-item-title>
              <v-list-item-subtitle>befüllen Sie ihren Warenkorb
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item two-line to="/orders" :dark="compColor.isInDarkMode"
                       v-if="showHTMLByRole(['pharmacyadmin','pharmacist','nurse','doctor','seniordoctor']) && !showHTMLByRole(['root'])">
            <v-icon :color="compColor.primeColor" class="mx-2">list_alt</v-icon>
            <v-list-item-content class="ml-3">
              <v-list-item-title>Bestellungen</v-list-item-title>
              <v-list-item-subtitle>kontrollieren Sie alle Bestellungen
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item two-line to="/reports" :dark="compColor.isInDarkMode"
                       v-if="showHTMLByRole(['pharmacist']) && !showHTMLByRole(['root']) && authSystem !== 'median'">
            <v-icon :color="compColor.primeColor" class="mx-2">list_alt</v-icon>
            <v-list-item-content class="ml-3">
              <v-list-item-title>Berichte</v-list-item-title>
              <v-list-item-subtitle>Erstellen Sie Berichte und Statistiken</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-menu>

      <v-toolbar-title style="font-size:18px;" class="font-weight-regular"><strong
        class="font-weight-bold">Medi</strong>Shop
      </v-toolbar-title>
      <v-icon class="ml-2">mdi-truck-fast</v-icon>

      <div class="mx-2 pt-1 layout-column layout-align-center body-1"
           v-if="pharmacyInformation && pharmacyInformation.name">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on" class="layout-row" style="cursor:pointer;"><span
              class="mx-2">-</span>{{ pharmacyInformation.name }}
            </div>
          </template>
          <div class="layout-column">
            <span class="body-1 my-1">Wir sind für Sie erreichbar unter:</span>
            <v-divider v-bind:style="{'border-color': compColor.primeColor}"></v-divider>
            <div class="layout-row mt-2">
              <span style="min-width:100px;">Telefon:</span>
              <span>{{ pharmacyInformation.supportphone }}</span>
            </div>
            <div class="layout-row">
              <span style="min-width:100px;">E-Mail:</span>
              <span>{{ pharmacyInformation.supportemail }}</span>
            </div>
            <div class="layout-row">
              <span style="min-width:100px;">Straße, Nr.:</span>
              <span>{{ pharmacyInformation.address.street }} {{ pharmacyInformation.address.number }}</span>
            </div>
            <div class="layout-row">
              <span style="min-width:100px;">PLZ:</span>
              <span>{{ pharmacyInformation.address.zipcode }}</span>
            </div>
            <div class="layout-row">
              <span style="min-width:100px;">Stadt:</span>
              <span>{{ pharmacyInformation.address.city }}</span>
            </div>
          </div>
        </v-tooltip>
      </div>

      <v-spacer></v-spacer>

      <v-btn text style="min-width:30px;" @click="enterFullscreen" tabindex="0">
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>

      <v-divider vertical inset class="mx-1"></v-divider>

      <v-btn text style="min-width:30px;" @click="openThemeSidebar" tabindex="0">
        <v-icon>mdi-cogs</v-icon>
      </v-btn>

      <v-divider vertical inset class="mx-1"></v-divider>

      <v-btn text style="min-width:30px;" @click="openMessagesSidebar" tabindex="0" v-show="getMessagingEnabled">
        <v-icon>mdi-chat-processing</v-icon>
        <v-badge
          color="green"
          :content="getMessageCount"
          inline
          v-if="getMessageCount"
        >
        </v-badge>
        <!--                                    {{getMessageCount}}-->

      </v-btn>

      <v-divider vertical inset class="mx-1"></v-divider>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text class="text-none" v-on="on">
            <v-icon class="mr-2">mdi-shield-account</v-icon>
            <div class="layout-column layout-align-center-start">
              <span>{{ authUserTitle }} {{ authUserName }}</span>
              <small class="font-weight-light">{{ getLocationName }}</small>
            </div>
          </v-btn>
        </template>

        <v-list v-bind:class="{'blue-grey darken-3': compColor.isInDarkMode}">

          <v-list-item two-line to="/account" :dark="compColor.isInDarkMode" v-if="authSystem !== 'median'">
            <v-icon :color="compColor.primeColor" class="mx-2">face</v-icon>
            <v-list-item-content class="ml-3">
              <v-list-item-title>Account verwalten</v-list-item-title>
              <v-list-item-subtitle>Hier können Sie Änderungen an ihren Accountdaten vornehmen
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <template v-if="authSystem !== 'median' && getUserHasMultiLocations">
            <v-divider></v-divider>

            <v-list-item @click="changeLocation" two-line :dark="compColor.isInDarkMode">
              <v-icon :color="compColor.primeColor" class="mx-2">business</v-icon>
              <v-list-item-content class="ml-3">
                <v-list-item-title>Standort wechseln</v-list-item-title>
                <v-list-item-subtitle>Legen Sie Ihre Organisation fest</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-divider v-if="authSystem !== 'median'"></v-divider>

          <v-list-item @click="logout(false)" two-line :dark="compColor.isInDarkMode">
            <v-icon :color="compColor.primeColor" class="mx-2">lock</v-icon>
            <v-list-item-content class="ml-3">
              <v-list-item-title>Abmelden</v-list-item-title>
              <v-list-item-subtitle>Bitte melden Sie sich immer ordnungsgemäß ab.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- ** Notifications for Group "system" ** -->
    <!--        <notifications group="system" position="top center" width="400"></notifications>-->

    <notifications group="custom-template" position="bottom right" width="550">
      <template slot="body" slot-scope="props">
        <div class="green mt-12 white--text elevation-16 layout-row ma-12" style="border-radius: 5px;"
             v-if="props.item.type !== 'error' && props.item.type !== 'warning'">
          <div class="pa-4 layout-column layout-align-center-center">
            <v-icon>done</v-icon>
          </div>
          <div class="layout-column flex py-2">
            <div>
              <div class="body-2 font-weight-bold">{{ props.item.title }}</div>
            </div>
            <div class="flex layout-row body-2 layout-align-start-center">
              <div v-html="props.item.text">
              </div>
            </div>
          </div>
          <div class="layout-column layout-align-center-center pa-4">
            <a class="close green--text text--lighten-3" @click="props.close">ok</a>
          </div>
        </div>
        <div class="orange mt-12 white--text elevation-16 layout-row ma-12" style="border-radius: 5px;"
             v-else-if="props.item.type !== 'error' && props.item.type === 'warning'">
          <div class="pa-4 layout-column layout-align-center-center">
            <v-icon>info</v-icon>
          </div>
          <div class="layout-column flex py-2">
            <div>
              <div class="body-2 font-weight-bold">{{ props.item.title }}</div>
            </div>
            <div class="flex layout-row body-2 layout-align-start-center">
              <div v-html="props.item.text">
              </div>
            </div>
          </div>
          <div class="layout-column layout-align-center-center pa-4">
            <a class="close orange--text text--darken-3" @click="props.close">ok</a>
          </div>
        </div>

        <div class="red mt-12 white--text elevation-16 layout-row ma-12" style="border-radius: 5px;" v-else>
          <div class="pa-4 layout-column layout-align-center-center">
            <v-icon>error</v-icon>
          </div>
          <div class="layout-column flex py-2">
            <div>
              <div class="body-2 font-weight-bold">{{ props.item.title }}</div>
            </div>
            <div class="flex layout-row body-2 layout-align-start-center">
              <div v-html="props.item.text">
              </div>
            </div>
          </div>
          <div class="layout-column layout-align-center-center pa-4">
            <a class="close red--text text--lighten-3" @click="props.close">ok</a>
          </div>
        </div>
      </template>
    </notifications>


    <!-- ** Content Bereich ** -->
    <v-main>
      <router-view ref="routerView"/>
    </v-main>

    <!-- ** Messaging Sidebar - Drawer auf der rechten Seite Komponente in lx_theme  ** -->
    <lx-messaging-sidebar v-if="isLoggedIn"></lx-messaging-sidebar>
    <lx-messaging-settings v-if="isLoggedIn"></lx-messaging-settings>

    <!-- ** Themeauswahl - Drawer auf der rechten Seite Komponente in lx_theme  ** -->
    <!--    <lx-theme-sidebar :position="isLayoutMinimal ? 'left' : 'right'"></lx-theme-sidebar>-->
    <lx-theme-sidebar :allowed-layouts="[]" :allowed-lightings="[1,2]"></lx-theme-sidebar>

    <!-- ** Hilfe Drawer auf der rechten Seite ** -->
    <lx-user-help :is-helper-visible="messageDrawer"></lx-user-help>

    <!-- ** Cookie Nachfrage beim Login, aus Komponente lx_imprint_and_cookie ** -->
    <lx-cookie-question></lx-cookie-question>

    <!-- ** Footer **-->
    <v-footer v-if="isLoggedIn" color="blue-grey darken-4 px-2" dark app clipped-left elevation="20"
              :class="{'py-1': !denseFields}"
              :height="denseFields? '22':'28'" padless>
      <div class="caption layout-row layout-align-start-center" v-if="pharmacyInformation">
        <span class="font-weight-black"
              v-if="isLoggedIn && isMobileDevice || isLoggedIn && smallMonitor">{{ pharmacyInformation.name }}</span>
        <v-icon class="mx-1" color="blue-grey darken-2" small
                v-if="isLoggedIn && isMobileDevice || isLoggedIn && smallMonitor">mdi-rhombus-medium
        </v-icon>

        <v-icon class="mr-2" :size="denseFields ? 14 : 16">email</v-icon>
        <span>{{ pharmacyInformation.supportemail }}</span>
        <v-icon class="mx-1" color="blue-grey darken-2" :size="denseFields ? 14 : 16">mdi-rhombus-medium</v-icon>
        <v-icon :size="denseFields ? 14 : 16" class="mr-2">phone</v-icon>
        <span>{{ pharmacyInformation.supportphone }}</span>
        <v-icon class="mx-1" color="blue-grey darken-2" :size="denseFields ? 14 : 16"
                v-if="isLoggedIn && !isMobileDevice && !smallMonitor">mdi-rhombus-medium
        </v-icon>

        <v-icon :size="denseFields ? 14 : 16" class="mr-2" v-if="isLoggedIn && !isMobileDevice && !smallMonitor">house
        </v-icon>
        <span v-if="isLoggedIn && !isMobileDevice && !smallMonitor">
                  {{ pharmacyInformation.address.street }} {{
            pharmacyInformation.address.number
          }}, {{ pharmacyInformation.address.zipcode }} {{ pharmacyInformation.address.city }}</span>
      </div>
      <v-divider vertical class="mx-3" v-if="pharmacyInformation"></v-divider>
      <v-spacer></v-spacer>
      <div class="layout-row layout-align-center-center px-3 caption">
        <strong>Litix</strong>soft<sup>&copy;</sup> <span class="ml-1">{{ new Date().getFullYear() }}</span>
      </div>
      <v-divider vertical></v-divider>
      <div class="layout-row layout-align-center-center px-3 caption" style="cursor:pointer;"
           @click="disableOrEnableLxContext(!isLxContextDisabled)">
        <strong>MediShop</strong><span class="ml-2"> v.{{ getAppVersionNumber }}</span>
        <div v-if="isLxContextDisabled" style="width:10px;height:10px;border-radius: 50%;" class="ml-2 green"></div>
      </div>
      <v-divider vertical></v-divider>
      <div class="layout-column layout-align-center-center px-4">
        <div class="caption" @click="imprintDialogVisibility = true" style="cursor:pointer;">
          <strong>Impressum</strong>
        </div>
      </div>

    </v-footer>

    <!-- ** öffnet Popup um sich erneut zu authentifizieren ** -->
    <auth-relogin></auth-relogin>

    <!-- this.$refs.changeLocationPopup.open().then((confirm) => {}) -->
    <lx-select-location ref="changeLocationPopup"></lx-select-location>

    <lx-change-location :visible="showChangeLocationMessage"></lx-change-location>

    <lx-context-menu :menu-items="items"></lx-context-menu>

<!--    <lx-frontend-reload-on-version-change></lx-frontend-reload-on-version-change>-->

  </v-app>
</template>

<script>
import Vue from 'vue'
import lodash from 'lodash'
import router from './router'
import {deviceDetect, isMobile} from 'mobile-device-detect'
import imprintContent from './components/imprint_content/imprintContent'
import LxUserHelp from './components/lx_user-help/LxUserHelp'
import LxAdminPanel from './components/lx_admin_panel/LxAdminPanel'
import LxSelectLocation from '@/components/lx_select_location/LxSelectLocation'
import LxChangeLocation from "@/components/lx_select_location/LxChangeLocation";

const themeConfig = {
  color: 'teal',
  mode: 'medium',
  autoMode: false,
  zoomValue: 100,
  layoutVertical: false,
  layoutMinimalistic: false,
  minimal: true,
  horizontal: true
}

const dashboardConfigPharmacy = [
  {
    x: 3,
    y: 0,
    h: 16,
    i: "1",
    moved: false,
    type: "news",
    w: 3
  },
  {
    x: 0,
    y: 0,
    h: 16,
    i: "0",
    moved: false,
    type: "orders",
    w: 3
  },
  {
    moved: false,
    type: "downloads",
    w: 3,
    x: 6,
    y: 0,
    h: 16,
    i: "2"
  }
]

const dashboardConfigME = [
  {
    y: 0,
    h: 16,
    i: "0",
    moved: false,
    type: "shoppingcarts",
    w: 3,
    x: 0
  },
  {
    moved: false,
    type: "orders",
    w: 3,
    x: 3,
    y: 0,
    h: 9,
    i: "1"
  },
  {
    moved: false,
    type: "news",
    w: 3,
    x: 3,
    y: 9,
    h: 7,
    i: "2"
  },
  {
    moved: false,
    type: "deliveries",
    w: 3,
    x: 6,
    y: 0,
    h: 9,
    i: "3"
  },
  {
    moved: false,
    type: "downloads",
    w: 3,
    x: 6,
    y: 9,
    h: 7,
    i: "4"
  }
]

const dashboardConfig = [
  {
    moved: false,
    type: "empty",
    w: 9,
    x: 0,
    y: 0,
    h: 16,
    i: "1"
  }
]

export default {
  name: 'App',
  data: () => ({
    activeTab: null,
    messageDrawer: null,
    showAdminPanel: false,
    firstAdminNavRoute: null,
    pharmacyInformation: null,
    isMobileDevice: isMobile,
    deviceDetectComplete: deviceDetect(),
    smallMonitor: false,
    items: null,
    showChangeLocation: false,
    showChangeLocationMessage: false,
  }),
  components: {
    LxChangeLocation,
    LxUserHelp,
    LxAdminPanel,
    imprintContent,
    LxSelectLocation
  },
  beforeMount() {

  },
  mounted() {
    // wenn irgendwo in der anwendung ein eigenes context menu auf dem
    // rechts klick liegt kann dieses geschlossen werden wenn man irgendwo hin klickt
    let main = document.getElementById('mainApp')
    main.addEventListener('click', (event) => {
      this.$lxContext.close(event)
    })

    main.addEventListener('contextmenu', (event) => {
      if (!this.isLxContextDisabled) {
        event.preventDefault()
      }
    })
  },
  created() {
    //this.$i18n.locale = 'en'

    /** wird bei einem refresh der seite ausgeführt strg + r oder refresh button **/
    if (this.isLoggedIn) {
      if (this.showHTMLByRole(['pharmacist', 'abdaeditor', 'pharmacyadmin'])) {
        Vue.fillLxvueModuleStoresWithUserValues(this.getUser(), themeConfig, dashboardConfigPharmacy)
      } else if (this.showHTMLByRole(['admin', 'nurse', 'doctor', 'seniordoctor', 'controller'])) {
        Vue.fillLxvueModuleStoresWithUserValues(this.getUser(), themeConfig, dashboardConfigME)
      } else {
        Vue.fillLxvueModuleStoresWithUserValues(this.getUser(), themeConfig, dashboardConfig)
      }
    }

  },
  methods: {
    changeLocation() {
      this.$refs.changeLocationPopup.open().then((location) => {
        // console.log('Switch to', location.name)
        this.showChangeLocationMessage = true
        this.setLocation(location._id.toString())
          .then(() => {
            // IMPORTENT
            window.location = '/'
            // router.push('/').catch(()=>{
            //   this.$nextTick(() => {
            //     window.location.reload()
            //   })
            // })
          })
          .catch((e) => {
            console.error(e);
            // Prefend error in console
            this.showChangeLocationMessage = false
          })
      }).catch(() => {
      })
    },
    enterFullscreen() {
      const doc = window.document;
      const docEl = doc.documentElement;

      let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
      let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

      if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
        requestFullScreen.call(docEl);
      } else {
        cancelFullScreen.call(doc);
      }
    },
    windowResized() {
      // var w = window.innerWidth;
      // var h = window.innerHeight;
      // if(h < 1024 || w < 1024){
      //     this.smallMonitor= true
      // } else {
      //     this.smallMonitor= false
      // }
    },
    /**
     * der Oberpunkt "Administration" soll als link den ersten Administrationslink anspringen, der kann aber immer ein anderer sein
     * abhänging von den Rechten des Benutzers, diese Method sucht nun anhand der Benutzerrechte
     * den ersten Link und verwendet ihm im ':to' der Top Navigation für den Administrationslink ca. Zeile 85 ^
     *
     * die Admin Navigationspunkte wurden in die router.js in den meta Bereich der Router Options ausgelagert
     *
     * **/
    getFirstAdminNavRoute(isLoggedIn) {
      if (isLoggedIn) {
        let allAdminNavRoutes = router.options.meta.adminNavigation || [];
        for (let i = 0; i < allAdminNavRoutes.length; i++) {
          let categories = allAdminNavRoutes[i];
          for (let j = 0; j < categories.items.length; j++) {
            let item = categories.items[j];
            /**
             * "showHTMLByRight()" mixin methode zum checken von Rechten befindet sich in der index.js in der lxAuth Komponente
             * **/
            if (this.showHTMLByRight(item.visibility)) {
              this.firstAdminNavRoute = item.route;
              return;
            }
          }
        }
      } else {
        this.firstAdminNavRoute = '/administration'
      }
    },
    getPharmacyInformationForDisplaying() {
      let isLoggedIn = this.authStore.getters.isLoggedIn;
      if (isLoggedIn && !this.getUser().is_system) {
        this.$http.get('/hospital/pharmacy')
          .then(res => {
            if (res.data) {
              this.pharmacyInformation = res.data
            }
          })
          .catch(this.NotifyError)
      }
    },
    clearPharmacyInformation() {
      this.pharmacyInformation = null
    }
  },
  watch: {
    getHasNewMessages(newMsg) {
      if (newMsg) {
        setTimeout(() => {
          this.Messaging.dispatch('resetNewMessages')
        }, 10000)
      }
    },
    isLoggedIn: {
      handler: function (isLoggedIn) {
        if (!isLoggedIn) {
          Vue.checkCoockieStore()
          this.clearPharmacyInformation()
        } else {
          /** browserinfo **/
          let browserMetadata = lodash.cloneDeep(this.deviceDetectComplete)
          browserMetadata.resolution = {
            width: window.innerWidth,
            height: window.innerHeight
          }

          browserMetadata.screen = {
            availWidth: window.screen.availWidth,
            availHeight: window.screen.availHeight,
            width: window.screen.width,
            height: window.screen.height,
            pixelDepth: window.screen.pixelDepth,
            colorDepth: window.screen.colorDepth
          }

          browserMetadata.isEdge = (this.deviceDetectComplete && this.deviceDetectComplete.userAgent && this.deviceDetectComplete.userAgent.indexOf('Edg') >= 0)

          this.$http.post('/account/user/metadata', browserMetadata)
            .then(() => {
            })
            .catch(() => {
            })

          this.getPharmacyInformationForDisplaying()

          if (this.showHTMLByRole(['pharmacist', 'abdaeditor', 'pharmacyadmin'])) {
            Vue.fillLxvueModuleStoresWithUserValues(this.getUser(), themeConfig, dashboardConfigPharmacy)
          } else if (this.showHTMLByRole(['admin', 'nurse', 'doctor', 'seniordoctor', 'controller'])) {
            Vue.fillLxvueModuleStoresWithUserValues(this.getUser(), themeConfig, dashboardConfigME)
          } else {
            Vue.fillLxvueModuleStoresWithUserValues(this.getUser(), themeConfig, dashboardConfig)
          }
        }

        this.getFirstAdminNavRoute(isLoggedIn);
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    isLoggedIn() {
      return this.authStore.getters.isLoggedIn
    },
    showLoginPopup() {
      return this.authStore.getters.showLoginPopup;
    },
    authUserName() {
      return this.authStore.getters.getAuthUserName;
    },
    authUserTitle() {
      return this.authStore.getters.getAuthUserTitle;
    },
    getLocationName() {
      return this.getUser().location_name;
    },
    getHasSystemMessages() {
      return this.Messaging.getters.getHasSystemMessages;
    },
    getHasNewMessages() {
      return this.Messaging.getters.hasNewMessages;
    },
    getMessageCount() {
      return this.Messaging.getters.getMessagesUnreadCount;
    },
    getMessagingEnabled() {
      return this.Messaging.getters.getMessagingEnabled;
    },
    getUserHasMultiLocations() {
      return this.authStore.getters.getUserHasMultiLocations;
    }
  }
};
</script>
<style lang="scss">
.v-tooltip__content.detailInfo {
  padding: 0px !important;
  background-color: transparent !important;
  box-shadow: 0 0 20px rgba(0, 0.0, 0, 0.7)
}

.flash_icon {
  animation: whipe 1s ease 0s 8;
}

.flash_icon_ex {
  animation: zoom-in-zoom-out 1s ease 0s infinite;
}

@keyframes whipe {
  0% {
    transform: rotate(-15deg);
  }

  50% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(-15deg);
  }
}

@keyframes updown {
  0% {
    transform: translateY(-30%);
  }

  50% {
    transform: translateY(30%);
  }

  100% {
    transform: translateY(-30%);
  }
}


@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.25, 1.25);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>
