import Vuex from 'vuex';
import abda from './store/abda'
import LxAbdaSearchFields from './components/LxAbdaSearchFields'

export default {
    install(Vue) {

        Vue.use(Vuex)

        Vue.component('lx-abda-search-fields', LxAbdaSearchFields);

        let Store = new Vuex.Store({
            modules: {
                abda
            }
        });

        Vue.fillABDAStoreWithSavedValue = function (abda) {
            Store.dispatch('setAllStoreValues', abda)
        }

        Vue.mixin({
            computed: {
                getSetSingleSearchField: {
                    // getter
                    get: function () {
                        return Store.getters.getSingleSearchFields
                    },
                    // setter
                    set: function (value) {
                        Store.dispatch('setSingleSearchField', value)
                    },
                }
            }
        })
    },

}
