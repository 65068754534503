<template>
  <div>
    <v-data-table
      dense fixed-header
      :dark="compColor.isInDarkMode"
      :color="compColor.primeColor"
      :headers="fields"
      :items="items"
      :loading="isBusy"
      locale="de-DE"
      loading-text="Lade... Bitte warten"
      class="ma-0 pa-0"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="purple"
          :height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <template v-slot:no-data>
        Keine Daten zum anzeigen
      </template>

      <template v-slot:item="{ item, headers, index }">
        <tr>
          <td v-for="col in headers" :key="col.value + index" style="line-height: 25px;">
            <span v-if="!item[col.value].isEmpty"
                  :class="(item[col.value].isValid) ? 'blue-grey--text' : 'error--text font-weight-bold'">{{
                item[col.value].formatted || item[col.value].value
              }}</span>
            <span v-else class="orange--text">--- fehlt ---</span>
          </td>
        </tr>

      </template>

      <template
        v-if="errorCnt"
        v-slot:body.prepend="{ headers }"
      >
        <tr>
          <td :colspan="headers.length">
            <v-alert
              border="left"
              prominent
              style="font-size:15px;"
              class="pa-3 ma-2"
              v-bind:class="{'red lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 red lighten-5': !compColor.isInDarkMode}">
              <template slot="prepend">
                <v-avatar>
                  <v-icon large color="red">mdi-information-variant</v-icon>
                </v-avatar>
              </template>
              Einige Datensätze haben Validierungsfehler
            </v-alert>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CSV from './csv'

const EncodingListTranslation = {
  'UTF-8': 'UTF-8',
  'Windows1250': 'WINDOWS-1250',
  'Windows1254': 'WINDOWS-1254',
  'Windows1257': 'WINDOWS-1257',
  'Windows1258': 'WINDOWS-1258',
  'Windows1251': 'WINDOWS-1251',
  'Windows1252': 'WINDOWS-1252',
  'Windows1253': 'WINDOWS-1253',
  'Windows1255': 'WINDOWS-1255',
  'Windows1256': 'WINDOWS-1256'
};

const ValidationList = {
  'Key_ATCA': null,
  'Abgabebestimmung': null,
  'Hinweis': null,
  'RoteListe': null,
  'Key_ATC': null,
  'Key_DAR': null,
  'Produktname': null,
  'Apo_Bestand': {
    Validate: (value, custom) => {
      if (custom instanceof RegExp) {
        return custom.test(value)
      }

      return !isNaN(parseInt(value, 10))
    },
    Format: (value) => {
      return parseInt(value, 10)
    }
  },
  'Apo_Storage': null,
  'agent': null,
  'manufacturer_Kurzname': null,
  'Einheit': null,
  'Zahl': null,
  'country': null,
  'Info_Aufbewahrung': null,
  'Apo_Vk': {
    Validate: (value, custom) => {
      if (custom instanceof RegExp) {
        return custom.test(value)
      }

      // return !isNaN(parseInt(value, 10))
      return !isNaN(parseFloat(value))
    },
    Format: (value) => {
      if (typeof value !== 'string') value = value.toString()

      let n = value.replace(',', '.')

      if (n.indexOf('.') !== -1) {
        return parseFloat(n).toFixed(2)
      }

      return (parseInt(value, 10) / 100).toFixed(2)
    }
  },
  'pzn': {
    Validate: (value, custom) => {
      if (custom instanceof RegExp) {
        return custom.test(value)
      }

      return true
    }
  },
  'Key_ADR_Anbieter': null,
  'Key_FAT': null,
  'manufacturer_Langname': null,
  'dosage_form': null,
  'searching': null
};

function IsEmpty(value) {
  const _vt = typeof value;

  if (_vt === 'string') {
    return _vt.length === 0;
  }

  return _vt !== 'number';
}

export default {
  name: 'CsvViewer',
  props: {
    file: File,
    capacity: {
      type: Number,
      default: () => {
        return 20
      }
    }
  },
  data() {
    return {
      items: [],
      fields: [],
      isBusy: false,
      options: null,
      errorCnt: 0,
    }
  },
  mounted() {
    this.progress()
  },
  watch: {
    file() {
      this.progress()
    }
  },
  methods: {
    progress() {
      this.fields = [];
      this.items = [];

      if (!this.file) {
        return
      }

      this.$http.get('/imports/settings/abda')
        .then((res) => {
          const options = (res.data || {}).options;
          let settings = (res.data || {}).settings;
          const fields = (options || {}).fields || {};

          // Patch Format to REGEX
          (settings.columns || []).forEach((column) => {
            column.friendlyName = (fields[column.abdaFieldname]) ? fields[column.abdaFieldname] : column.abdaFieldname;

            if (column.csvColumnFormat) {
              column.csvColumnFormat = new RegExp(column.csvColumnFormat)
            }
          });

          this.options = settings;
          this.selectedFile();
        })
        .catch(this.NotifyError)
    },
    selectedFile() {
      this.isBusy = true;
      this.fields = [];
      this.items = [];

      CSV.fetch({
        file: this.file,
        encoding: EncodingListTranslation[this.options.encoding] || 'UTF-8',
        dialect: {
          'csvddfVersion': 1.2,
          'delimiter': this.options.comma,
          'doubleQuote': true,
          'lineTerminator': '\n',
          'quoteChar': '"',
          'skipInitialSpace': true,
          'header': this.options.hasHeader,
          'commentChar': '#'
        }
      }).then((dataset) => {
        this.errorCnt = 0;

        let self = this;

        this.options.columns.forEach((fieldValue) => {
          self.fields.push({
            text: fieldValue.friendlyName,
            value: fieldValue.abdaFieldname,
            align: 'start',
            sortable: false
          })
        });

        for (let i = 0; i < Math.min(this.capacity, dataset.records.length); i++) {
          let fieldData = {};
          let isValid = false;
          let isEmpty = false;
          let fieldValue = null;

          this.options.columns.forEach((column) => {
            fieldValue = dataset.records[i][column.csvColumnIdent];
            isValid = true;
            isEmpty = IsEmpty(fieldValue);

            const validation = ValidationList[column.abdaFieldname] || {};

            if (column.csvColumnFormat) {
              const re = new RegExp(column.csvColumnFormat);
              isValid = re.test(fieldValue)
            }

            if (isValid && typeof validation.Validate === 'function') {
              isValid = (validation.Validate(fieldValue, column.csvColumnFormat))
            }

            // console.log(fieldValue, isValid)
            fieldData[column.abdaFieldname] = {
              value: fieldValue,
              formatted: (typeof validation.Format === 'function') ? validation.Format(fieldValue) : fieldValue,
              isValid: isValid && !isEmpty,
              isEmpty: isEmpty
            };

            if (!isValid) {
              this.errorCnt++;
            }
          });

          self.items.push(fieldData)
        }
      }).catch(
        this.NotifyError
      ).finally(() => {
        this.isBusy = false
      })
    }
  }
}
</script>

<style scoped>

</style>
