import VueComponent from "vue"

const errorMapper = {
  string_gte: 'min',
  string_lte: 'max',
  number_gte: 'min',
  number_gt: 'min',
  number_lte: 'max',
  number_lt: 'max',
  format: 'format',
  pattern: 'pattern',
  unique: 'type',
  array_max_items: 'max',
  array_min_items: 'min',
  enum: 'allowed',
  invalid_type: 'expected',
  missing_dependency: 'dependency',
  required: 'property'
}
/*
"VALIDATION": {
    "SERVER": {
      "STRING_LTE": "Die Anzahl der Zeichen muss kleiner/gleich {expected} sein.",
      "STRING_GTE": "Die Anzahl der Zeichen muss größer/gleich {expected} sein.",
      "NUMBER_GTE": "Der Wert muss größer/gleich {expected} sein.",
      "NUMBER_GT": "Der Wert muss größer als {expected} sein.",
      "NUMBER_LTE": "Der Wert muss kleiner/gleich {expected} sein.",
      "NUMBER_LT": "Der Wert muss kleiner als {expected} sein.",
      "FORMAT": "Der Wert muss vom Format {expected} sein.",
      "PATTERN": "Der Wert entspricht nicht dem Muster von {expected}.",
      "UNIQUE": "Die Werte vom {expected} müssen einzigartig sein.",
      "ARRAY_MAX_ITEMS": "Dieses Feld darf höchstens 5 Elemente enthalten.",
      "ARRAY_MIN_ITEMS": "Dieses Feld muss mindenstens 5 Elemente enthalten.",
      "ENUM": "Der Wert muss einem der folgenden Inhalte entsprechen: {expected}.",
      "INVALID_TYPE": "Invalider Datentyp. Der Wert muss vom Typ \"{expected}\" sein.",
      "MISSING_DEPENDENCY": "Der Wert hat eine Abhängigkeit zu {expected}.",
      "REQUIRED": "Dieses Feld ist ein Pflichtfeld.",
      "ADDITIONAL_PROPERTY_NOT_ALLOWED": "Dieses Feld ist nicht erlaubt.",
      "DUPLICATE_KEY_ERROR": "Wert schon vorhanden",
      "ERROR_LOGIN_NAME_EXISTS": "Benutzername existiert bereits."
    }
  },
 */

let verbosity = false

export default {
  install: function (Vue, options) {
    options = options || {}
    verbosity = options.verbosity || verbosity

    Vue.mixin({
      ...options.components,
      methods: {
        HandleFormError: function ShowFormError(formRef, error, options) {
          const self = this

          return new Promise(
            function (resolve, reject) {
              if (error && error.response && error.response.status === 422) {
                let errors = {}
                // VALIDATION.SERVER.
                let prefix = ''

                if (formRef instanceof VueComponent) {
                  if (typeof options === 'string') {
                    prefix = options.toUpperCase()
                    if (prefix.charAt(prefix.length - 1) !== '.') {
                      prefix += '.'
                    }
                  }

                  (error.response.data || []).forEach((errdata) => {
                    if (!Array.isArray(errors[errdata.field])) {
                      errors[errdata.field] = []
                    }

                    // details optional, only required when server validation with schema
                    if (errdata.message && errdata.details && errdata.details[errorMapper[errdata.message]]) {
                      errdata.message = self.$t(prefix + errdata.message.toUpperCase(), {expected: self.$t(errdata.details[errorMapper[errdata.message]]) || ''})
                    } else if (errdata.message) {
                      // Localize error.message by default, if exists
                      errdata.message = self.$t(prefix + errdata.message.toUpperCase())
                    }

                    errors[errdata.field].push(errdata.message)
                  })

                  formRef.setErrors(errors)

                  return resolve(error)
                }
              }

              return reject(error)
            }
          )
        }
      }
    })
  }
}
