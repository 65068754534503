<template>

  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <ValidationObserver ref="NewsDetailsForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">
      <div class="layout-column flex ma-2 elevation-5"
           style="border-radius:4px;position:relative;"
           v-bind:style="{background: compColor.contentFrame}">

        <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1" :class="hoverHeader">
          <div class="layout-row">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="back()"
                    icon="mdi-chevron-left"
                    :hide-label="true"
                    :tooltip="$t('buttons.back.tooltip')"
                    :label="$t('buttons.back.title')"></lx-btn>

            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="!hasChanged && pristine || !valid "
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="save(reset)"
                    icon="save"
                    :tooltip="$t('buttons.save.tooltip')"
                    :label="$t('buttons.save.title')"></lx-btn>
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="!hasChanged && pristine"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="resetModel(reset)"
                    icon="cancel"
                    :tooltip="$t('buttons.revert.tooltip')"
                    :label="$t('buttons.revert.title')"></lx-btn>
          </div>
          <div class="flex layout-row"></div>
        </div>

        <v-divider v-if="!hoverHeader" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

        <div ref="scrollContainer" style="overflow-y: auto;" class="flex pa-1 layout-column">
          <lx-expansion-panels :single-open="false" v-model="panel">
            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-email-newsletter"
                                         :main-headline="$t('inputs.titles.newspreview')"
                                         sub-headline="Erstellen Sie Nachrichten für ihre Kunden und Mitarbeiter">
              </lx-expansion-panel-header>

              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">

                  <div class="flex-100">
                    <div class="layout-column flex">
                      <div class="layout-row layout-wrap flex px-3 pb-2">
                        <div class="layout-column flex">
                          <news-item-widget
                                  :headline="model.headline"
                                  :subheading="model.subheading"
                                  :color="model.color"
                                  :content="model.content"
                                  :creation_date="model.creation_date"
                          ></news-item-widget>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>

            <lx-expansion-panel disabled>
              <lx-expansion-panel-header icon="mdi-email-newsletter"
                                         main-headline="News"
                                         sub-headline="Erstellen Sie Nachrichten für ihre Kunden und Mitarbeiter">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>

                <div class="layout-row layout-wrap mt-3 pa-2">

                  <div class="flex-100 pa-2">
                    <ValidationProvider ref="Nachrichtenüberschrift" name="Nachrichtenüberschrift"
                                        rules="required|max:80" v-slot="{ errors }">
                      <v-text-field
                        :dark="compColor.isInDarkMode"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :dense="denseFields"
                        :label="$t('inputs.titles.messagetitle') + ' *'"
                        placeholder=" "
                        outlined
                        clearable
                        :error-messages="errors[0]"
                        v-model="model.headline"
                        class="mb-0"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">assignment</v-icon>
                        </template>
                      </v-text-field>
                    </ValidationProvider>
                  </div>

                  <div class="flex-100 layout-row">

                  <div class="flex pa-2">
                    <ValidationProvider ref="Subheading" name="Unterüberschrift"
                                        rules="max:120" v-slot="{ errors }">
                      <v-text-field
                        :dark="compColor.isInDarkMode"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :dense="denseFields"
                        :label="$t('inputs.titles.subheadingtitle')"
                        placeholder=" "
                        outlined
                        clearable
                        :error-messages="errors[0]"
                        v-model="model.subheading"
                        class="mb-0"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">assignment</v-icon>
                        </template>
                      </v-text-field>
                    </ValidationProvider>
                  </div>

                  <div class="pa-2" style="width: 310px; max-width: 310px">
                    <v-menu offset-y max-width="300">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          :color="'#353a47'"
                          :height="denseFields ? '36' : '40'"
                          v-on="on"
                          block
                          elevation="0"
                          class="white--text text-none mt-1"
                          >
                            <template #default>
                              <div class="layout-row py-4" style="width:100%;">
                                <div
                                  class="flex layout-row layout-align-start-center pl-2"
                                  :style="getStyle"
                                >{{ $t('inputs.titles.accentcolor') }}</div>
                              </div>
                            </template>
                        </v-btn>
                      </template>

                      <v-color-picker
                        :dark="compColor.isInDarkMode"
                        v-model="colorValue"
                        hide-canvas
                        hide-mode-switch
                        hide-sliders
                        hide-inputs
                        mode="hexa"
                        show-swatches
                        swatches-max-height="200"

                      ></v-color-picker>
                  </v-menu>
                  </div>
                  </div>

                  <div class="flex-100 pa-2">
                    <ValidationProvider ref="Nachrichtentext" name="Nachrichtentext" rules="required|max:1024"
                                        v-slot="{ errors }">
                      <v-textarea
                        outlined
                        clearable
                        no-resize
                        height="auto"
                        rows="4"
                        row-height="30"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :label="$t('inputs.titles.messagecontent') + ' *'"
                        name="input-7-4"
                        :error-messages="errors[0]"
                        v-model="model.content"
                        :dark="compColor.isInDarkMode"
                        :color="compColor.primeColor"
                        class="mb-0"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">comment</v-icon>
                        </template>
                      </v-textarea>
                    </ValidationProvider>
                  </div>

                  <div class="flex-100 pa-2">
                      <fake-input-frame :label="$t('inputs.titles.newsduration')"
                                        :input-background="isDarkMode ? 'transparent':'#f5f5f5'">
                        <div class="layout-column flex pa-2 pt-5">
<!--                          <div class="layout-row layout-wrap flex px-3 pb-2">-->
<!--                            <div class="layout-column flex mx-4">-->
<!--                              <v-alert type="info" border="left" outlined dense text>Ist kein Veröffentlichungsdatum festgelegt, wird die Nachricht sofort veröffentlicht.</v-alert>-->
<!--                            </div>-->
<!--                          </div>-->

                          <div class="layout-row layout-wrap flex px-3 pb-2">
                              <div class="layout-column flex mx-4">
                                  <validation-provider tag="div"
                                                       ref="af_from"
                                                       name="af_from"
                                                       rules="date_must_be_lesser_than:@af_to"
                                                       v-slot="{ errors }">
                                      <lx-date-time-picker
                                              v-model="model.creation_date"
                                              :dark="compColor.isInDarkMode"
                                              :color="compColor.primeColor"
                                              :label="$t('inputs.titles.releasedate')"
                                              :errors="errors[0]"
                                              :disabled="!!newsid"
                                              :outlined="true"
                                              clearable
                                      ></lx-date-time-picker>
                                  </validation-provider>
                              </div>

                              <div class="layout-column flex mx-4">
                                  <validation-provider tag="div"
                                                       ref="af_to"
                                                       name="af_to"
                                                       rules="date_must_in_future|date_must_be_greater_than:@af_from"
                                                       v-slot="{ errors }">
                                    <lx-date-time-picker
                                      v-model="model.expiry_date"
                                      :dark="compColor.isInDarkMode"
                                      :color="compColor.primeColor"
                                      :label="$t('inputs.titles.expirydate')"
                                      :errors="errors[0]"
                                      :outlined="true"
                                      clearable
                                    ></lx-date-time-picker>
                                  </validation-provider>
                              </div>
                          </div>
                        </div>
                      </fake-input-frame>
                  </div>

                  <div class="flex-100 pa-2">
                    <fake-input-frame :label="$t('inputs.titles.newsvisibility')"
                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'">
                      <div class="layout-column flex pa-2">
                        <div class="pb-1">

                          <v-tooltip bottom open-delay="550">
                            <template v-slot:activator="{ on }">
                              <v-btn :color="'#353a47'"
                                     v-on="on"
                                     block
                                     :height="denseFields ? '36' : '40'"
                                     elevation="0"
                                     class="white--text text-none"
                                     @click="selectAllEntries">
                                <template #default>
                                  <div class="layout-row py-4" style="width:100%;">
                                    <div class="layout-column layout-align-start-center px-1">
                                      <v-icon
                                        :color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor">
                                        {{ hospitalListAreSelected ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch' }}
                                      </v-icon>
                                    </div>
                                    <div class="flex layout-row layout-align-start-center pl-5">
                                      {{ hospitalListAreSelected ? $t('buttons.deselectall.title') : $t('buttons.selectall.title') }}
                                    </div>
                                  </div>
                                </template>
                              </v-btn>
                            </template>
                            <span>{{ hospitalListAreSelected ? $t('buttons.deselectall.tooltip') : $t('buttons.selectall.tooltip') }}</span>
                          </v-tooltip>
                        </div>

                        <v-divider class="mb-2 mt-1"></v-divider>

                        <div class="layout-row layout-wrap flex px-3 pb-2">
                          <div v-for="(item, index) in hospitalList" :key="index" class="mx-3 flex-30">
                            <ValidationProvider :ref="'Location' + index" :name="'Location' + index"
                                                v-slot="{ errors }">

                              <div class="layout-row flex">
                                <div class="">
                                  <v-switch inset dense :dark="compColor.isInDarkMode" :color="compColor.primeColor"
                                            hide-details class="ma-0 mb-0"
                                            v-model="item.is_selected">
                                  </v-switch>
                                </div>
                                <div class="flex">
                                  <div class="blue-grey--text text--darken-2"
                                       style="line-height:44px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                    {{ item.name }}
                                  </div>
                                </div>
                              </div>

                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                    </fake-input-frame>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
          </lx-expansion-panels>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import lodash from 'lodash'
import router from '../../../router'
import lxDateTimePicker from '@/components/lx_datetimepicker/LxDateTimePicker.vue'
import NewsItemWidget from '@/components/newsItem/NewsItem.vue'

const DefaultAccentColor = '#ECEFF1FF'
const DefaultMinutesInterval = 5

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

function GetWellFormattedDateTime() {
  let now = new Date()
  let min = Math.trunc(now.getMinutes() / DefaultMinutesInterval) * DefaultMinutesInterval
  now.setMinutes(min, 0, 0)
  return now.toISOString()
}

export default {
  name: 'NewsAndAnnouncement',
  props: {
    newsid: {
      type: [String, null],
      default: () => {
        return null;
      }
    }
  },
  components: {
    lxDateTimePicker,
    NewsItemWidget
  },
  data() {
    return {
      hoverHeader: false,
      hospitalList: null,
      oldModel: null,
      oldList: null,
      panel: [0, 1],
      loading: false,
      model: {
        creation_date: GetWellFormattedDateTime(),
      },
      error: {},
      errorDialog: false,
      isDirty: false,
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
      },
    }
  },
  created() {
    this.$nextTick(() => {
      let scroller = this.$refs.scrollContainer
      scroller.addEventListener('scroll', () => {
        if (scroller.scrollTop > 0) {
          let number = scroller.scrollTop
          number = number > 4 ? 4 : number
          this.hoverHeader = 'elevation-' + number
        } else {
          this.hoverHeader = ''
        }
      })
    })

    this.getData()
  },
  computed: {
    /**
     * Color Picker can't handle Alpha Values
     */
    colorValue: {
      // getter
      get() {
        return (this.model?.color || DefaultAccentColor).substring(0, 7)
      },
      // setter
      set(newValue) {
        this.$set(this.model, 'color', newValue)
      }
    },
    hospitalListAreSelected() {
      if (this.hospitalList) {
        let selected = this.hospitalList.filter(item => {
          if (item.is_selected) return item
        })
        return selected.length === this.hospitalList.length
      } else {
        return false
      }
    },
    hasChanged() {
      return !lodash.isEqual(this.oldModel, this.model) || !lodash.isEqual(this.oldList, this.hospitalList)
    },
    getStyle() {
      const color = this.model.color || DefaultAccentColor

      return {
        "border-left": "8px solid " + color,
        "border-right": "8px solid " + color,
        "border-radius": "4px"
      }
    }
  },
  methods: {
    selectAllEntries() {
      let booleanValue = this.hospitalListAreSelected

      this.hospitalList.forEach((item, index) => {
        this.$set(this.hospitalList[index], 'is_selected', !booleanValue)
      })
    },

    resetModel(reset) {
      this.model = lodash.cloneDeep(this.oldModel)
      this.hospitalList = lodash.cloneDeep(this.oldList)
      reset()
    },

    openCloseExpansionPanel(index) {
      if (this.panel.indexOf(index) < 0) {
        this.panel.push(index)
      } else {
        this.panel.splice(this.panel.indexOf(index), 1)
      }
    },

    /**
     * wird aufgerufen, wenn man den zurück Button drückt
     *
     * */
    back() {
      window.history.back()
    },

    /**
     * Get all users, all rights, all rights of selected role and all users with selected role
     */
    getData() {
      this.$http.get('/hospital')
        .then(res => {
          if (res.data && Array.isArray(res.data.data)) {
            let hospitalList = res.data.data.map((v) => {
              return {is_selected: false, ...v}
            })

            if (this.newsid) {
              if (this.loading) {
                return
              }

              this.loading = true

              // get all pharmacies
              this.$http.get('/news/' + this.newsid)
                .then(res => {
                  if (res.data) {
                    this.model = res.data

                    if (this.model && this.model.hospitalsToInform && this.model.hospitalsToInform.length > 0) {
                      for (let i = 0; i < hospitalList.length; i++) {
                        for (let j = 0; j < this.model.hospitalsToInform.length; j++) {
                          if (hospitalList[i]._id === this.model.hospitalsToInform[j]) {
                            hospitalList[i].is_selected = true
                            break
                          }
                        }
                      }
                    }

                    this.hospitalList = hospitalList.sort(compare)
                    this.oldModel = lodash.cloneDeep(this.model)
                    this.oldList = lodash.cloneDeep(this.hospitalList)
                  }

                })
                .catch(this.NotifyError)
                .finally(this.loading = false)
            } else {
              this.hospitalList = hospitalList.sort(compare)
              this.oldModel = lodash.cloneDeep(this.model)
              this.oldList = lodash.cloneDeep(this.hospitalList)
            }

          }

        })
        .catch(this.NotifyError)
    },
    save(reset) {
      let hospitalsToInform = []

      if (this.hospitalList && this.hospitalList.length > 0) {
        for (let i = 0; i < this.hospitalList.length; i++) {
          if (this.hospitalList[i].is_selected) {
            hospitalsToInform.push(this.hospitalList[i]._id)
          }
        }
      }

      this.model.hospitalsToInform = hospitalsToInform

      if (!this.model._id) {
        /**
         * Create news
         */
        this.$http.post('/news', this.model)
          .then((res) => {
            reset()
            this.NotifySystem('Änderungen gespeichert')

            if (res.data && res.data._id && this.$refs.NewsDetailsForm) {
              this.$refs.NewsDetailsForm.reset()
              this.model._id = res.data._id
              this.oldModel = lodash.cloneDeep(this.model)
              this.oldList = lodash.cloneDeep(this.hospitalList)

              router.replace('/administration/news/' + res.data._id + '/details')
            } else {
              this.oldModel = lodash.cloneDeep(this.model)
              this.oldList = lodash.cloneDeep(this.hospitalList)
            }
          })
          .catch(this.NotifyError)
      } else {
        /**
         * Update news
         */
        this.$http.put('/news/' + this.model._id, this.model)
          .then(() => {
            reset()
            this.NotifySystem('Änderungen gespeichert')
            this.oldModel = lodash.cloneDeep(this.model)
            this.oldList = lodash.cloneDeep(this.hospitalList)
          })
          .catch(this.NotifyError)
      }
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.scheduler-container {
  /*border-bottom: 1px solid #ddd !important;*/
  /*border-right: 1px solid #eee !important;*/
  .scheduler-header {
    /*border-top: 1px solid #eee !important;*/
    /*border-left: 1px solid #eee !important;*/
  }

  .scheduler-field {
    border-bottom: 1px solid #f0f0f0 !important;
    border-left: 1px solid #f0f0f0 !important;
  }
}

.dark_login {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  padding: 50px;
  min-width: 500px;
  min-height: 700px;
  background-image: linear-gradient(#3b4b65, #212c3e);
}

h1, h2 {
  font-weight: normal;
}

.subheader {
  height: 20px;
  font-size: 16px;
  padding: 0 16px 0 2px;
  font-weight: 300;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.tabs__div {
  text-transform: none;
  color: #575757;
}

.schraffiert {
  /*position: relative;*/
  /*overflow: hidden;*/

  //background-image: url("../../../assets/doodle_medishop3_opac.png") !important;
  background-repeat: repeat;
  background-size: 300px 300px;

  /*> div {*/
  /*    z-index: 2;*/
  /*}*/

}

.lx-dark-app {
  .schraffiert {
    /*position: relative;*/
    /*overflow: hidden;*/

    /*> div {*/
    /*    z-index: 2;*/
    /*}*/

    &:before {

    }
  }
}

.border-anima {

  .box {

    box-sizing: border-box;
    /*padding: 15px;*/
    position: relative;
    overflow: hidden;
  }

  .box::before {
    content: '';
    position: absolute;
    width: 150%;
    height: 150%;
    background: repeating-linear-gradient(
        white 0%,
        white 7.5px,
        hotpink 7.5px,
        hotpink 15px,
        white 15px,
        white 22.5px,
        hotpink 22.5px,
        hotpink 30px);
    transform: translateX(-20%) translateY(-20%) rotate(-45deg);
    animation: animate 15s linear infinite;
  }

  .box .content {
    position: relative;
    /*background-color: white;*/
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px;
    text-align: center;
    font-family: sans-serif;
    z-index: 2;
  }

  .box,
  .box .content {
    box-shadow: 0 0 2px deeppink,
    0 0 5px rgba(0, 0, 0, 1),
    inset 0 0 5px rgba(0, 0, 0, 1);
    border-radius: 10px;
  }

  .box .content h2 {
    color: deeppink;
  }

  .box .content p {
    color: dimgray;
  }

  @keyframes animate {
    from {
      background-position: 0;
    }

    to {
      background-position: 0 450px;
    }
  }
}


</style>
