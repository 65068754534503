<script>
export default {
  name: 'OrderStates',
  data() {
    const isController = this.hasRole('controller') === true

    return {
      isController: isController,
      isPharmacy: (this.getUserLocationType() === "pharmacy"),
      statusList: [
        { code:  8, name: "Controllerfreigabe", active: true, color: '#fffccc', colorDark: '#d7843b', colorBorder: '#ffcb9d', hideForPharmacy: true },
        { code: 10, name: "eingegangen", active: !isController && true, color: '#F3E5F5', colorDark: '#844aa7', colorBorder: '#c696e3', hideForPharmacy: false},
        { code: 15, name: "übermittelt", active: !isController && true, color: '#ceefff', colorDark: '#2173b4', colorBorder: '#7cafd8', hideForPharmacy: false},
        { code: 20, name: "in Bearbeitung", active: !isController && true, color: '#e5ffe5', colorDark: '#469746', colorBorder: '#90EE90', hideForPharmacy: false},
        { code: 30, name: "storniert", active: !isController && true, color: '#ffb4ab', colorDark: '#CD5C5C', colorBorder: '#e37b7b', hideForPharmacy: false }, // Durch Apotheke storniert
        { code: 32, name: "Zurückgewiesen", active: !isController && true, color: '#ffb4ab', colorDark: '#CD5C5C', colorBorder: '#e37b7b', hideForPharmacy: true }, // Durch Kunde storniert
        { code: 50, name: "erledigt", active: !isController && true, color: '#f4f3f3', colorDark: '#575757', colorBorder: '#959595', hideForPharmacy: false },
      ],
    }
  },
  methods: {
    getStatusForCode(code) {
      for (let i = 0; i < this.statusList.length; i++) {
        if (this.statusList[i].code === code) {
          return this.statusList[i]
        }
      }

      return {}
    }
  },
  computed: {
    availableStates () {
      if (this.isPharmacy) {
        return this.statusList.filter((e) => e.hideForPharmacy !== true)
      }

      return this.statusList
    }
  },

}
</script>
