<template>
  <div v-if="isAdminPanelVisible" id="square" style="position: fixed;top:0;left:50%;z-index:99999;" class="layout-row blue-grey darken-4 elevation-12">
    <div class="layout-column blue-grey--text text--lighten-2">
      <div class="layout-row pa-2 elevation-4" v-bind:class="compColor.textInput">
        <span class="subtitle-2 mx-3 font-weight-bold white--text">Admin - Info - Panel</span>
        <v-spacer></v-spacer>
        <v-icon color="white" @mousedown="dragThatThing" @mouseup="leftThatThing">drag_indicator</v-icon>
        <!--                </div>-->
      </div>
      <div class="layout-column pa-3">
        <div class="layout-row">
<!--          <v-icon class="blue-grey&#45;&#45;text mr-2" small>mdi-clock-outline</v-icon>-->
          <span class=" subtitle-2 font-weight-light" style="width:120px;">aktuelle Uhrzeit:</span>
          <span class="font-weight-bold subtitle-2">{{repeatCounterTime.now}}</span>
          <span class="caption ml-1 font-italic">Uhr</span>
        </div>
        <div class="layout-row">
<!--          <v-icon class="blue-grey&#45;&#45;text mr-2" small>mdi-restore</v-icon>-->
          <span class=" subtitle-2 font-weight-light" style="width:120px;">Refresh Uhrzeit:</span>
          <span class="font-weight-bold subtitle-2">{{repeatCounterTime.later}}</span>
          <span class="caption ml-1 font-italic">Uhr</span>
        </div>
        <v-divider class="blue-grey darken-4 my-2"></v-divider>
        <div class="layout-row layout-align-start-center" v-if="repeatCounterTime.timer">
<!--          <v-icon class="blue-grey&#45;&#45;text mr-2" small>mdi-camera-timer</v-icon>-->
          <span class=" subtitle-2 font-weight-light" style="width:120px;">Timer:</span>
          <span class="font-weight-bold subtitle-2">{{repeatCounterTime.timer.min}}</span>
          <span class="caption ml-1 font-italic">min</span>
          <span class="font-weight-bold subtitle-2 ml-2">{{repeatCounterTime.timer.sec}}</span>
          <span class="caption ml-1 font-italic">sek</span>
        </div>
        <!--                <div class="layout-row layout-align-center-center blue-grey darken-2 mt-3" style="width:60px;">-->
        <div class="layout-row layout-align-center-center blue-grey darken-3 pa-1 mt-3">
          <span class="subtitle-1 mx-3 font-weight-bold" v-bind:class="{'green--text': idleStatus === 'active','yellow--text': idleStatus === 'idle'}"><small>{{idleStatus}}</small></span>
          <!--                </div>-->
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import Draggable from 'gsap/Draggable'

export default Vue.component('lxAdminPanel', {
  props: ['isAdminPanelVisible'],
  data: function () {
    return {
      visibility: false
    }
  },
    created() {
    },
  methods: {
    /**
     * Wird aufgerufen wenn man das popup draggen möchte, dabei klickt man auf den Header des Popups
     * - es wird der Body aus dem HTML geholt über die ID 'app'
     * - dann die Größe des Fenster bestimmt mit getBoundingClientRect, da Body auf 100% in beide Richtung stretcht
     * - dann wird Popup zu Draggable Obejct gemacht in den Grenzen des Body (bounds)
     * */
    dragThatThing () {
      let slider = document.getElementById('bodyTag')
      // console.log('slider: ', slider)
      let bounds = slider.getBoundingClientRect()
      Draggable.create('#square', {
        type: 'x,y',
        bounds: bounds,
        throwProps: true,
        onDragStart: function () {
          this.target.style.opacity = 0.6
        },
        onDragEnd: function () {
          this.target.style.opacity = 1.0
        }
      })
    },
    /**
     * Wird aufgerufen wenn man beim popup draggen loslässt
     * - killt das Draggable Object
     * */
    leftThatThing () {
      Draggable.get('#square').kill()
    },
  },
  watch: {

  },
  computed: {

  }
})
</script>

<style scoped>

</style>
