<template>
  <div>
    <v-snackbar top color="warning" :timeout="3000" v-model="snackbar" :multi-line="true" class="mt-12">
      <v-icon class="mr-3">warning</v-icon>
      Artikel nicht gefunden
      <v-btn color="gray darken-3" text @click="snackbar = false">Ok</v-btn>
    </v-snackbar>

    <!--    <v-dialog v-model="visible" width="60%" persistent :dark="compColor.isInDarkMode" :color="compColor.primeColor" class="pa-4">-->
    <v-dialog v-model="visible" persistent width="50%" overlay-opacity="0.9">
      <ValidationObserver ref="CreateApiKeyForm" v-slot="{ valid, pristine, reset }"
                          class="layout-column flex">


        <v-card class="flex" :dark="compColor.isInDarkMode" v-bind:class="[compColor.contentFrameBack]">
          <v-card-title class="title elevation-4 layout-row pa-0">
            <div class="pa-1 flex" v-bind:class="[compColor.contentFrameBack]"
                 style="z-index: 2;border-radius:4px 4px 0 0;">
              <div class="py-1 px-1 layout-row layout-align-start-center"
                   v-bind:class="[compColor.contentFrameFront,compColor.widget.headline]">
                <div class="layout-column layout-align-center-center pr-3">
                  <v-avatar :color="compColor.avatarTile" tile height="40" width="40"
                            style="min-width:40px;">
                    <v-icon :color="compColor.primeColor">mdi-key-variant</v-icon>
                  </v-avatar>
                </div>
                Neuer Schlüssel
              </div>
            </div>
          </v-card-title>
          <v-divider v-bind:class="compColor.textInput" v-if="compColor.isInDarkMode"></v-divider>
          <v-card-text class="pa-3">
            <div class="layout-column mb-0 pt-1">
              <validation-provider tag="div" ref="LocationId" name="LocationId" rules="required" v-slot="{ errors }">
                <v-select
                  :dark="compColor.isInDarkMode"
                  :color="compColor.primeColor"
                  :items="pharmacies"
                  :return-object="false"
                  :disabled="lookupPending"
                  :error-messages="errors[0]"
                  label="Apotheke*"
                  :menu-props="{ bottom: true, offsetY: true }"
                  required
                  item-text="name"
                  item-value="_id"
                  v-model="model.location_id"
                  outlined
                />
              </validation-provider>
              <validation-provider tag="div" ref="Permissions" name="Permissions" rules="required" v-slot="{ errors }">
                <v-select
                  :dark="compColor.isInDarkMode"
                  :color="compColor.primeColor"
                  :items="permissions"
                  :return-object="false"
                  :disabled="lookupPending"
                  :error-messages="errors[0]"
                  label="Berechtigung*"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="text"
                  item-value="value"
                  v-model="model.permissions"
                  required
                  outlined
                  multiple
                />
              </validation-provider>
            </div>
            <small class="ml-4">* diese Felder werden benötigt</small>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :dark="compColor.isInDarkMode" class="white--text text-none mx-1" :color="compColor.primeColor"
                   @click="save()" :disabled="lookupPending || !valid || pristine">Speichern
            </v-btn>
            <v-btn :dark="compColor.isInDarkMode" class="white--text text-none mx-1" :color="compColor.primeColor"
                   @click="visible = false" :disabled="lookupPending">Schließen
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>

    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ApiManagementDialog',
  props: {
    onchange: [null, Function],
  },
  data() {
    return {
      errors: null,
      model: {},
      lookupPending: false,
      visible: false,
      snackbar: false,
      pharmacies: [],
      permissions: []
    }
  },
  created() {

  },
  methods: {
    save() {
      if (this.lookupPending) return;

      this.lookupPending = true;
      this.$http.post('/apimanagement/' + this.model.location_id, this.model)
        .then(() => {
          this.visible = false;
          this.$emit('success');
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.lookupPending = false
        })
    },
    getData() {
      this.lookupPending = true;
      this.$http.get('/apimanagement/permissions')
        .then((res) => {
          this.permissions = res.data || []
        })
        .finally(() => {
          this.lookupPending = false;
        })
    },
    Show() {
      this.model = {};
      this.visible = true;
      this.getData();
    }
  }
}
</script>

<style scoped>

</style>
