<template>
  <div>
    <v-data-table
        dense fixed-header
        :dark="compColor.isInDarkMode"
        :color="compColor.primeColor"
        :headers="headers"
        :items="items"
        :loading="isBusy"
        locale="de-DE"
        loading-text="Lade... Bitte warten"
        class="ma-0 pa-0"
    >
      <template v-slot:progress>
        <v-progress-linear
            color="purple"
            :height="10"
            indeterminate
        ></v-progress-linear>
      </template>

      <template v-slot:no-data>
        Keine Daten zum anzeigen
      </template>

      <template v-slot:item="{ item, headers, index }">
        <tr>
          <td v-for="col in headers" :key="col.index + '_'+ index" style="line-height: 25px;">
            <span v-if="item[col.value]">{{item[col.value]}}</span>
            <span v-else class="orange--text">--- fehlt ---</span>
          </td>
        </tr>

      </template>

    </v-data-table>
  </div>
</template>

<script>
const StringCompare = function (a, b) {
  const nameA = a.toUpperCase(); // Groß-/Kleinschreibung ignorieren
  const nameB = b.toUpperCase(); // Groß-/Kleinschreibung ignorieren
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export default {
  name: 'LxSimpleDataTable',
  props: {
    url: String,
    capacity: {
      type: Number,
      default: () => {
        return 20
      }
    }
  },
  data() {
    return {
      items: [],
      headers: [],
      isBusy: false,
      options: null,
      errorCnt: 0,
    }
  },
  mounted() {
    this.progress()
  },
  watch: {
    url() {
      this.progress()
    }
  },
  methods: {
    progress() {
      this.headers = []
      this.items = []
      this.isBusy = true

      this.$http.get(this.url)
          .then((res) => {
            if (!Array.isArray(res.data)) {
              return
            }

            // Find Header Fields
            const keys = Array.from(
                res.data.reduce(
                    (s, o) => Object
                        .keys(o)
                        .reduce((t, k) => !Array.isArray(o[k]) ? t.add(k) : t, s),
                    new Set
                )
            ).sort(StringCompare)

            keys.forEach((v, i) => {
              this.headers.push({
                text: v,
                value: v,
                align: 'start',
                sortable: false,
                index: i
              })
            })

            this.items = res.data

          })
          .catch(() => {})
          .finally(() => {
            this.isBusy = false
          })
    }
  }
}
</script>

<style scoped>

</style>