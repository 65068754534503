import Vuex from 'vuex';
import messaging from './store/messaging'
import LxMessagingSidebar from './components/LxMessagingSidebar'
import LxMessagingSettings from './components/LxMessagingSettings';
// Polling 45 seconds
const MessagingDefaultPollInterval = 45000;

export default {
  install(Vue) {
    const BrowserNotificationAvailable = ("Notification" in window);

    Vue.use(Vuex);

    Vue.component('lx-messaging-sidebar', LxMessagingSidebar);
    Vue.component('lx-messaging-settings', LxMessagingSettings);

    const Store = new Vuex.Store({
      modules: {
        messaging
      }
    });

    const VueMessaging = new Vue({
      data() {
        return {
          timestamp: null,
          timer: null,
        }
      },
      created() {
        if (BrowserNotificationAvailable) {
          Store.dispatch('setNotificationPermission', Notification.permission);
        }

        if(this.userIsLoggedIn){
          this.fetchEventsList();
          this.timer = setInterval(this.fetchEventsList, MessagingDefaultPollInterval)
        }
      },
      methods: {
        fetchEventsList() {
          if (!this.authStore.getters.isLoggedIn || this.authStore.getters.showLoginPopup || !this.$http) {
            this.timestamp = null;
            return;
          }

          let url = '/messaging';
          const alertable = (this.timestamp != null);

          if (alertable) {
            const unixTime = Math.floor(this.timestamp / 1000);
            url += '?t=' + unixTime.toString()
          }

          Store.dispatch('checkMessages');

          this.$http.get(url)
            .then((res) => {
              if (res && res.data && this.authStore.getters.isLoggedIn && !this.authStore.getters.showLoginPopup) {
                this.timestamp = new Date(res.data.timestamp);
                const messages = res.data.messages;

                if (!Array.isArray(messages) || messages.length === 0) return;

                if (alertable && Array.isArray(messages)) {
                  if (messages.length === 1) {
                    this.notifyMe("MediShop", {body: messages[0].message.text, tag: messages[0]._id});
                  } else {
                    this.notifyMe("MediShop", {body: "Es liegen Nachrichten für Sie vor"});
                  }

                  Store.dispatch('pushMessages', messages)
                } else {
                  Store.dispatch('setMessages', messages)
                }
              }
            })
            .catch((err) => {
              if (err instanceof Error) {
                if (err && err.response && err.response.status === 401) {
                  // Forbidden
                  this.cancelAutoUpdate();
                  Store.dispatch('setMessagingEnabled', false);
                }
              }
            })
        },
        cancelAutoUpdate() {
          clearInterval(this.timer);
          this.timestamp = null;
        },
        notifyMe(title, options) {
          // Let's check if the browser supports notifications
          if (!("Notification" in window) || (Notification.permission !== "granted") || !options) return false;

          // If it's okay let's create a notification
          let notification = new Notification(title, options);
          notification.addEventListener('click', this.handleNotificationClick);
        },
        handleNotificationClick () {
          // console.log('Event', event.srcElement)
          this.getMessagingSidebarVisibility = true;
        },
        resetMessaging () {
          // this.cancelAutoUpdate();
          this.timestamp = null;
          Store.dispatch('clearMessages')
        },
      },
      watch:{
        userIsLoggedIn(newVal,oldVal){
          /* wenn sich nutzer eingeloggt hat */
          if(newVal && !oldVal && !this.timer){
            this.fetchEventsList();
            this.timer = setInterval(this.fetchEventsList, MessagingDefaultPollInterval)
          }
          /* wenn sich nutzer auslogged */
          else if(!newVal && oldVal){
            clearInterval(this.timer)
            this.timer = null
          }
        }
      },
      computed: {
        userIsLoggedIn(){
          return this.authStore.getters.isLoggedIn
        },
      },
      beforeDestroy() {
        clearInterval(this.timer);
      }
    });

    Vue.mixin({
      methods: {
        openMessagesSidebar() {
          Store.dispatch('setSideBarVisibility', false)
          this.$nextTick(() => {
            Store.dispatch('setSideBarVisibility', true)
          })
        },
        DeleteMessage(id) {
          this.$http.delete('/messaging/' + id)
            .then(() => {
              Store.dispatch('removeMessage', id)
            });
        },
        DeleteAllMessages() {
          let timestamp = VueMessaging.timestamp

          if (!timestamp) {
            console.log('no timestamp')
            return;
          }

          timestamp = Math.floor(timestamp / 1000).toString()

          this.$http.delete('/messaging/all/' + timestamp)
            .then(() => {
              Store.dispatch('clearMessagesWithTimstamp', timestamp)
            });
        },
        ConfirmMessage(id) {
          this.$http.patch('/messaging/' + id.toString(), {id: id, action: 'confirm', t: Math.floor(new Date() / 1000)})
              .then(() => {})
              .catch((e) => { console.error(e) })
        },
        ResetMessaging() {
          VueMessaging.resetMessaging();
        },
      },
      computed: {
        Messaging() {
          return Store
        },
        getMessagingSidebarVisibility: {
          // getter
          get: function () {
            // return false;
            return Store.getters.getSideBarVisibility;
          },
          // setter
          set: function (value) {
            Store.dispatch('setSideBarVisibility', value)
          },
        },
        getMessagingSettingsVisibility: {
          // getter
          get: function () {
            // return false;
            return Store.getters.getSettingsVisibility;
          },
          // setter
          set: function (value) {
            Store.dispatch('setSettingsVisibility', value)
          },
        },
        BrowserNotificationPermission: {
          get: function () {
            //Notification.permission
            return Store.getters.getNotificationPermission;
            // return BrowserNotificationAvailable ? Notification.permission : null;
          },
          set: function (value) {
            Store.dispatch('setNotificationPermission', value)
          }
        },
      }
    });

    Vue.use(VueMessaging);
  }
}
