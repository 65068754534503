<template>
  <lx-dialog v-model="visible" :allow-drag="true" :overlay="true" :size="{x: '70', y: 'auto'}">
    <template #header>
      <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
           :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
        ZE- / NUB-Artikel
      </div>
    </template>

    <template #default>
      <div class="ma-1 pa-4 flex layout-column elevation-3"
           style="border-radius: 5px;position: relative;overflow-y: auto;"
           :style="{'background': compColor.lxExpansion.background}">
        <div class="flex-100 pa-2">
          <v-alert
              border="left"
              prominent
              style="font-size:15px;"
              class="px-3 py-2 mb-1"
              v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
            <template slot="prepend">
              <v-avatar>
                <v-icon large>mdi-information-variant</v-icon>
              </v-avatar>
            </template>
            Bei diesem Artikel handelt es sich um ein <strong>Zusatzentgelt</strong>- oder <strong>NUB</strong>-Artikel.
            Um diesen in den Warenkorb übernehmen und
            bestellen zu können, ist die Eingabe zusätzlicher Daten notwendig. Bitte geben Sie dafür die Fallnummer
            des Patienten an und die Anzahl des gewünschten Artikels.
          </v-alert>
        </div>
        <div class="flex layout-column pa-1" style="overflow-y: auto">
          <div class="layout-column pa-1">
            <fake-input-frame label="Artikelinformationen" class="mb-3"
                              :input-background="isDarkMode ? 'transparent':'#f5f5f5'">
              <div class="layout-column pa-3 blue-grey--text">
                <div>
                  <span v-if="zeModelItem.name">{{ zeModelItem.name }}</span>
                  <span v-else>{{ zeModelItem.Produktname }}</span>
                  <span>, {{ zeModelItem.manufacturer_Kurzname }} <small
                      style="opacity: 0.5;">(Artikel/Hersteller)</small></span>
                </div>
                <span>{{ zeModelItem.pzn }} <small style="opacity: 0.5;">(PZN)</small></span>
                <span>{{ zeModelItem.agent }} <small style="opacity: 0.5;">(Wirkstoff)</small></span>
              </div>
            </fake-input-frame>
          </div>
          <v-divider></v-divider>
          <ValidationObserver ref="zeAddItemForm" class="layout-column" v-slot="{ valid, pristine, reset}">
            <div class="layout-row layout-wrap layout-align-center-start mt-1">
              <div class="flex pa-2">
                <validation-provider tag="div"
                                     name="Fallnummer" rules="required"
                                     v-slot="{ errors }">
                  <v-text-field
                      :dark="compColor.isInDarkMode"
                      label="Fallnummer *"
                      :color="compColor.primeColor"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      v-model="zeModel.case_number"
                      placeholder=" "
                      outlined
                      class="mb-0"
                      :dense="denseFields"
                      :error-messages="errors[0]"
                  >
                    <template #append v-if="!isDarkMode">
                      <v-icon color="grey lighten-2">mdi-clipboard-edit</v-icon>
                    </template>
                  </v-text-field>
                </validation-provider>
              </div>
              <div class="flex pa-2">
                <validation-provider tag="div"
                                     name="Die Artikelanzahl" rules="required|min_value:1"
                                     v-slot="{ errors }">
                  <lx-numberfield :dense="denseFields"
                                  v-model="zeModel.article_count"
                                  label="Benötigte Anzahl des Artikels *"
                                  separator=","
                                  classes="mb-0"
                                  :error-messages="errors[0]"
                  ></lx-numberfield>
                </validation-provider>
              </div>
              <div class="pa-2">
                <v-btn
                    class="white--text text-none mx-1"
                    height="50"
                    v-if="!arcticleID"
                    :dark="compColor.isInDarkMode"
                    :disabled="!valid || pristine"
                    :color="compColor.primeColor"
                    @click="pushCaseNumberToZeArray(reset)">
                  Hinzufügen
                </v-btn>
<!--                <v-btn-->
<!--                    class="white&#45;&#45;text text-none mx-1"-->
<!--                    height="50"-->
<!--                    v-if="!arcticleID"-->
<!--                    :dark="compColor.isInDarkMode"-->
<!--                    :disabled="zeArrayIndex === -1 || !valid || pristine"-->
<!--                    :color="compColor.primeColor"-->
<!--                    @click="editCaseNumber()">-->
<!--                  Ändern-->
<!--                </v-btn>-->
              </div>
            </div>
          </ValidationObserver>
          <div class="pa-2" v-if="zeArray.length > 0">
            <fake-input-frame label="ZE-Artikel / Patienten"
                              class="flex layout-column"
                              :input-background="isDarkMode ? 'transparent':'#f0f0f0'">
              <div class="layout-column pa-2">
                <div v-for="(items, index) in zeArray"
                     :key="index"
                     class="px-2 py-1"
                     :class="index < zeArray.length - 1 ? 'mb-1' : ''"
                     :style="{'background': isDarkMode ? '#192330' : '#cfd8dc', 'border': zeArrayIndex === index ? '1px solid ' + compColor.primeColor : '0' }"
                     style="border-radius: 4px;">
                  <div class="layout-row">
                    <div class="layout-column layout-align-center-center ml-2">
                      <v-icon
                        :color="items.selected ? compColor.prime + ' lighten-3': (isDarkMode ? 'blue-grey darken-3' : 'blue-grey lighten-3')"
                        size="25">mdi-clipboard-account
                      </v-icon>
                    </div>
                    <v-divider vertical class="mx-3"></v-divider>




                    <div v-if="zeArrayIndex !== index" class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start pt-1">
                      <div style="line-height: 16px;"><span
                          style="width:100px;display: inline-block;">Fallnummer:</span><strong>{{
                          items.case_number
                        }}</strong></div>
                      <div><small style="line-height: 16px;"><span style="width:100px;display: inline-block;">Artikelanzahl:</span>{{
                          items.article_count
                        }}</small></div>
                    </div>

                    <ValidationObserver v-else ref="zeAddItemForm" class="flex layout-column" v-slot="{ valid, pristine, reset}">
                      <div class="layout-row layout-wrap layout-align-center-start mt-1">
                        <div class="flex py-2 pr-2">
                          <validation-provider tag="div"
                                               name="Fallnummer" rules="required"
                                               v-slot="{ errors }">
                            <v-text-field
                              :dark="compColor.isInDarkMode"
                              label="Fallnummer *"
                              :color="compColor.primeColor"
                              hide-details
                              v-model="items.case_number"
                              placeholder=" "
                              outlined
                              class="mb-0"
                              :dense="denseFields"
                              :error-messages="errors[0]"
                            >
                            </v-text-field>
                          </validation-provider>
                        </div>
                        <div class="flex py-2">
                          <validation-provider tag="div"
                                               name="Die Artikelanzahl" rules="required|min_value:1"
                                               v-slot="{ errors }">
                            <lx-numberfield :dense="denseFields"
                                            v-model="items.article_count"
                                            label="Benötigte Anzahl des Artikels *"
                                            separator=","
                                            hide-details
                                            classes="mb-0"
                                            :error-messages="errors[0]"
                            ></lx-numberfield>
                          </validation-provider>
                        </div>
                        <div class="pa-2">

                          <v-tooltip bottom open-delay="550">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                class="white--text text-none mx-1"
                                height="50"
                                v-if="!arcticleID"
                                :dark="compColor.isInDarkMode"
                                :disabled="zeArrayIndex === -1 || !valid || pristine"
                                :color="compColor.primeColor"
                                @click="editCaseNumber(items)">
                                <v-icon>
                                  save
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Änderung speichern</span>
                          </v-tooltip>

                          <v-tooltip bottom open-delay="550">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                class="white--text text-none mx-1"
                                height="50"
                                v-if="!arcticleID"
                                :dark="compColor.isInDarkMode"
                                :color="compColor.primeColor"
                                @click="cancelChange()">
                                <v-icon>
                                  cancel
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Änderung zurücksetzen</span>
                          </v-tooltip>



                        </div>
                      </div>
                    </ValidationObserver>


                    <v-divider v-if="zeArrayIndex !== index" vertical class="mr-2 pl-2"></v-divider>

                    <div v-if="zeArrayIndex !== index" class="pa-2 pl-1 layout-column layout-align-center-center">
                      <v-tooltip bottom open-delay="550">
                        <template v-slot:activator="{ on }">
                          <v-btn text icon v-on="on" :color="compColor.prime" @click="editZeItemByIndex(index)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Fallnummer/Anzahl bearbeiten</span>
                      </v-tooltip>
                    </div>

                    <v-divider v-if="zeArrayIndex !== index" vertical class="mr-2 pl-2"></v-divider>

                    <div v-if="zeArrayIndex !== index" class="pa-2 pl-1 layout-column layout-align-center-center">
                      <v-tooltip bottom open-delay="550">
                        <template v-slot:activator="{ on }">
                          <v-btn text icon v-on="on" :color="compColor.prime" @click="deleteCaseNumberFromZeArray(index)">
                            <v-icon>mdi-delete-forever</v-icon>
                          </v-btn>
                        </template>
                        <span>Fallnummer löschen</span>
                      </v-tooltip>

                    </div>

                  </div>
                </div>
              </div>
            </fake-input-frame>
          </div>
        </div>
      </div>
    </template>

    <template #actions>
      <div class="layout-row layout-align-center-center pa-1">
        <div class="flex mr-1">
          <lx-btn :color="'#353a47'"
                  block
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="!zeArray || zeArray.length <= 0"
                  class="flex"
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click.stop="save()"
                  icon="save"
                  tooltip="ZE-/NUB-Artikel-Änderungen übernehmen"
                  :label="'Übernehmen'"></lx-btn>
          <!--            @click.stop="addItem(articleData, true)"-->
        </div>
        <lx-btn :color="'#353a47'"
                :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                small
                class="flex"
                iconSize="20"
                width="auto"
                classes="text-none"
                @click.stop="visible = null"
                icon="cancel"
                tooltip="Vorgang abbrechen"
                :label="'Abbrechen'"></lx-btn>
      </div>
    </template>
  </lx-dialog>
</template>

<script>
import moment from 'moment/moment';
import lxNumberfield from '@/components/lx_numberfield/LxNumberfield'

export default {
  name: 'zeDialog',
  props: {
    // pzn: {
    //   type: String
    // },
    departmentid: {
      type: String
    },
    // onsave: {
    //   type: Function,
    //   default: null,
    //   //required: true
    // },
    value: {
      type: Object,
      default: null
    }
  },
  emits: ['onsave'],
  components: {
    lxNumberfield
  },
  data () {
    return {
      zeModel: {
        patient_name: '',
        patient_birthday: null,
        case_number: null,
      },
      zeArray: [],
      zeModelItem: null,
      zeArrayIndex: -1,
      zeDialogVisible: false,
      arcticleID: null,
      tempModel: null
    }
  },
  computed: {
    visible: {
      get() {
        if (this.value && this.value.pzn) {
          this.reset()
          return true
        }

        return false
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {

    reset() {
      this.zeModelItem = this.value
      this.zeArray = this.value.ze_item || [];
      this.zeArrayIndex = -1

    },

    save() {
      this.$emit('onsave', this.zeArray, true)
    },

    pushCaseNumberToZeArray(reset) {
      reset()

      let temp = {
        case_number: this.zeModel.case_number,
        article_count: this.zeModel.article_count
      }

      this.zeArray.push(temp)

      this.zeModel = {}
      this.zeArrayIndex = -1
    },

    editCaseNumber (item) {
      if (this.zeArrayIndex === -1 || !this.zeArray[this.zeArrayIndex]) {
        return
      }

      this.zeArray[this.zeArrayIndex] = {
        case_number: item.case_number,
        article_count: item.article_count
      }

      this.zeArrayIndex = -1
    },

    deleteCaseNumberFromZeArray(index) {
      this.zeArray.splice(index, 1)
      this.zeArrayIndex = -1
    },

    openZeItemDialog(articleData) {
      const pzn = articleData.pzn// '04823543'

      this.$http.get('/shoppingcart/article/' + this.departmentid.toString() + '/' + pzn)
          .then((result) => {
            this.zeModelItem = result.data
            this.zeArray = result.data.ze_item
          })
          .catch(() => {
            this.zeModelItem = articleData
            this.zeArray = []
            // this.NotifyError(error)
          })
          .finally(() => {
            this.zeArrayIndex = -1
            this.zeDialog = true;
          })
    },

    getZeNubValues(zeItem) {
      // const articles = zeItem.length
      let article_count = 0

      zeItem.forEach(item => {
        article_count += item.article_count
      })

      return article_count // + '(' + articles + ' Artikel)'
    },

    editZeIdem(data) {
      if (data && data.articleid) {
        this.zeModel = data.zeItem
        this.zeDialog = true
      }
    },

    editZeItemByIndex (index) {
      if (!this.zeArray[index]) {
        return
      }
      //
      // this.zeModel.case_number = this.zeArray[index].case_number
      // this.zeModel.article_count = this.zeArray[index].article_count
      this.tempModel = null
      this.tempModel = JSON.parse(JSON.stringify(this.zeArray[index]))
      this.zeArrayIndex = index

    },

    changeZeItem() {
      this.zeModel.patient_birthday = moment(this.zeModel.patient_birthday).toISOString();
      this.zeModel.application_date = moment(this.zeModel.application_date).toISOString();

      this.patchItem(this.arcticleID, {
        zeItem: this.zeModel
      });

      this.arcticleID = null;
      this.zeDialog = null
    },

    cancelChange(){
      this.zeArray[this.zeArrayIndex] = JSON.parse(JSON.stringify(this.tempModel))
      this.zeArrayIndex = -1
    }
  }
}
</script>

<style scoped>

</style>
