<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="pa-lg-2 pa-md-0 flex layout-column">
      <div class="flex elevation-2 pa-3 layout-column" v-bind:class="[compColor.contentFrameBack]">

        <form class="flex layout-column grey lighten-4" style="overflow-y:auto;">
          <v-container style="max-width: 1024px">
            <div class="flex pa-0" style="overflow-y: auto;">
              <h2 class="text-center mb-5 mt-3 blue-grey--text text--darken-2" v-if="onOff === 'true'">
                Aktivierung - Zwei-Faktor-Authentisierung</h2>
              <h2 class="text-center mb-5 mt-3 blue-grey--text text--darken-2" v-if="onOff === 'false'">
                Deaktivierung - Zwei-Faktor-Authentisierung</h2>

              <v-card color="white" class="mb-12 elevation-6 ma-6" v-if="onOff === 'false'">
                <div class="pa-4">
                  <p class="body-2 mb-4">Um die Zwei-Faktor-Authentisierung zu deaktivieren geben sie
                    bitte einen gültigen Zugriffscode aus ihrer Authentikator App ein.</p>
                  <div class="layout-row layout-align-center-center">
                    <div class="flex">
                      <v-text-field
                        :dark="compColor.isInDarkMode"
                        :color="compColor.primeColor"
                        :dense="denseFields"
                        label="Zugriffscode eingeben"
                        placeholder=" "
                        outlined
                        max-length="6"
                        @keydown="failedCode = true"
                        prepend-inner-icon="mdi-barcode-scan"
                        v-model="deact_confirmation_code"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="pa-4">
                  <v-btn :color="compColor.primeColor" :dark="compColor.isInDarkMode"
                         :disabled="!deact_confirmation_code"
                         class="text-none white--text" @click="deactivate2FA">Deaktivieren
                  </v-btn>
                  <v-btn :color="compColor.primeColor" :dark="compColor.isInDarkMode"
                         class="text-none white--text ml-2" to="/account">Abbrechen
                  </v-btn>
                </div>
                <!--                                    :error-messages="errors[0]"-->
              </v-card>

              <v-stepper v-model="e6" vertical class="elevation-0 transparent" v-if="onOff === 'true'">
                <v-stepper-step :complete="e6 > 1" step="1" :color="e6 <= 1 ? 'blue-grey' : 'green'">
                  <span class="blue-grey--text text--darken-2">Programm installieren</span>
                </v-stepper-step>

                <v-stepper-content step="1" class="py-0 pl-0 pr-6">
                  <v-card color="white" class="mb-12 elevation-6 ma-6">
                    <div class="pa-4">
                      <p class="body-2 blue-grey--text text--darken-2">Um die
                        Zwei-Faktor-Authentisierung nutzen zu können benötigen
                        Sie ein Smartphone und eine App zur Erzeugung von Zugangsschlüsseln.
                        Im folgenden haben Sie die Möglichkeit, je nach Betriebssystem ihres
                        Smartphones, verschiedene Apps zur Authentifizierung herunterzuladen und
                        zu
                        installieren.</p>
                      <div class="layout-row blue-grey--text text--darken-2 mb-4">
                        <div class="flex text-center body-2 my-0 ml-0 mr-2 pa-4 elevation-0 blue-grey lighten-5">
                          <h3 class="mb-2">Microsoft Authenticator</h3>
                          <p>Eine Microsoft Anwendung zur Generierung von Zugriffscodes.</p>
                          <v-divider></v-divider>
                          <div class="layout-row mt-2">
                            <div class="flex layout-row layout-align-center-center">
                              <small class="blue-grey--text text--lighten-3">Downloads:</small>
                            </div>
                            <v-btn text icon class="ma-1"
                                   href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                                   target="_blank">
                              <v-avatar color="blue-grey lighten-3" size="36" tile>
                                <v-icon color="white">mdi-google-play</v-icon>
                              </v-avatar>
                            </v-btn>
                            <v-btn text icon class="ma-1"
                                   href="https://apps.apple.com/app/microsoft-authenticator/id983156458"
                                   target="_blank">
                              <v-avatar color="blue-grey lighten-3" size="36" tile>
                                <v-icon color="white">mdi-apple</v-icon>
                              </v-avatar>
                            </v-btn>
                            <v-btn text icon class="ma-1"
                                   href="https://www.microsoft.com/p/microsoft-authenticator/9nblgggzmcj6"
                                   target="_blank">
                              <v-avatar color="blue-grey lighten-3" size="36" tile>
                                <v-icon color="white">mdi-microsoft-windows</v-icon>
                              </v-avatar>
                            </v-btn>
                            <div class="flex"></div>
                          </div>
                        </div>
                        <div class="flex text-center body-2 my-0 mr-0 ml-2 pa-4 elevation-0 blue-grey lighten-5">
                          <h3 class="mb-2">Google Authenticator</h3>
                          <p>Eine Google Anwendung zur Generierung von Zugriffscodes.</p>
                          <v-divider></v-divider>
                          <div class="layout-row mt-2">
                            <div class="flex layout-row layout-align-center-center">
                              <small class="blue-grey--text text--lighten-3">Downloads:</small>
                            </div>
                            <v-btn text icon class="ma-1"
                                   href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                   target="_blank">
                              <v-avatar color="blue-grey lighten-3" size="36" tile>
                                <v-icon color="white">mdi-google-play</v-icon>
                              </v-avatar>
                            </v-btn>
                            <v-btn text icon class="ma-1"
                                   href="https://apps.apple.com/app/google-authenticator/id388497605"
                                   target="_blank">
                              <v-avatar color="blue-grey lighten-3" size="36" tile>
                                <v-icon color="white">mdi-apple</v-icon>
                              </v-avatar>
                            </v-btn>
                            <div class="flex"></div>
                          </div>
                        </div>
                      </div>
                      <v-alert dense color="error" text prominent class="my-0 body-2 text-center">
                        Bitte entfernen Sie die Authentifizierungs App niemals von Ihrem
                        Smartphone, da Sie sonst den Zugang zu Ihrem Account verlieren könnten.
                      </v-alert>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-4">
                      <v-btn :color="compColor.primeColor" :dark="compColor.isInDarkMode"
                             class="text-none white--text" @click="createSecret">Fortfahren
                      </v-btn>
                    </div>
                  </v-card>

                </v-stepper-content>

                <v-stepper-step :complete="e6 > 2" step="2" :color="e6 <= 2 ? 'blue-grey' : 'green'">
                  <span>Geheimer Schlüssel</span>
                  <!--                                <small v-show="e6 !== 2">Diesen benötigen Sie um in der Authentifizierungs App einen Eintrag zur Generierung von Keys zu erzeugen.</small>-->
                </v-stepper-step>

                <v-stepper-content step="2" class="pb-2">
                  <v-card color="white" class="mb-12 elevation-6 ma-6">
                    <div class="pa-4">
                      <p class="body-2">Wenn Sie sich über ein anderes Gerät anmelden wollen oder
                        Sie
                        nicht mehr auf ihren mobilen Authentifikator zugreifen können, verwenden
                        Sie
                        den QR-Code oder geben Sie den geheimen Schlüssel manuell ein.</p>
                      <div class="layout-row">
                        <div class="flex text-center body-2 my-0 ml-0 mr-2 pa-4 elevation-0 blue-grey lighten-5">
                          <h3 class="mb-2">Scannen Sie den QR-code</h3>
                          <p>Starten Sie in der installiereten App das Setup (oder fügen Sie
                            ein
                            neues Konto hinzu) und scannen Sie dann den QR-Code mit Ihrer
                            Kamera</p>
                          <div class="white pa-2 mt-4">
                            <img v-if="model && model.qr_code"
                                 :src="'data:image/png;base64, ' + model.qr_code"/>
                          </div>
                        </div>
                        <div
                          class="flex text-center body-2 my-0 mr-0 ml-2 pa-4 elevation-0 blue-grey lighten-5 layout-column">
                          <h3 class="mb-2">Geheimen Schlüssel eingeben</h3>
                          <p>Wenn Sie den QR-Code nicht scannen können, wählen Sie die
                            manuelle
                            Eingabe aus, geben Sie dann Ihre E-Mail-Adresse (sofern
                            benötigt)
                            und den geheimen Code in die entsprechenden Felder ein</p>
                          <div class="body-1 font-weight-black flex layout-column layout-align-center-center"
                               v-if="model && model.secret">
                            {{ model.secret }}
                          </div>
                          <v-alert dense color="info" text outlined prominent
                                   class="mt-4 mb-0">Geben
                            Sie Ihren geheimen Schlüssel und QR-Code nicht weiter und zeigen
                            Sie
                            diesen niemanden!
                          </v-alert>
                        </div>
                      </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-4">
                      <v-btn :color="compColor.primeColor" :dark="compColor.isInDarkMode"
                             class="text-none white--text" @click="e6 = 3">Fortfahren
                      </v-btn>
                      <v-btn :color="compColor.primeColor" :dark="compColor.isInDarkMode"
                             class="text-none white--text ml-2" to="/account">Abbrechen
                      </v-btn>
                    </div>
                  </v-card>

                </v-stepper-content>

                <v-stepper-step :complete="e6 > 3" step="3" :color="e6 <= 3 ? 'blue-grey' : 'green'"
                                :rules="[() => failedCode]">
                  Bestätigungscode
                  <!--                                <small>Diesen wird in der App erzeugt und sollte hier eingetragen werden.</small>-->
                </v-stepper-step>

                <v-stepper-content step="3" class="pb-2">
                  <v-card color="white" class="mb-12 elevation-6 ma-6">
                    <div class="pa-4">
                      <p class="body-2 mb-4">Um die Aktivierung der Zwei-Faktor-Authentisierung
                        abzuschließen, müssen Sie noch einen Zugriffscode eingeben, den Sie über
                        die
                        Authentikator-App auf Ihrem Smartphone generiert haben.</p>
                      <div class="layout-row layout-align-center-center">
                        <div class="flex">
                          <v-text-field
                            :dark="compColor.isInDarkMode"
                            :color="compColor.primeColor"
                            :dense="denseFields"
                            label="Zugriffscode eingeben"
                            placeholder=" "
                            outlined
                            max-length="6"
                            @keydown="failedCode = true"
                            prepend-inner-icon="mdi-barcode-scan"
                            v-model="confirmation_code"
                          ></v-text-field>
                        </div>
                      </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-4">
                      <v-btn :color="compColor.primeColor" :dark="compColor.isInDarkMode"
                             :disabled="!confirmation_code"
                             class="text-none white--text" @click="activate2FA">Aktivieren +
                        Fortfahren
                      </v-btn>
                      <v-btn :color="compColor.primeColor" :dark="compColor.isInDarkMode"
                             class="text-none white--text ml-2" to="/account">Abbrechen
                      </v-btn>
                    </div>
                    <!--                                    :error-messages="errors[0]"-->
                  </v-card>
                </v-stepper-content>

                <v-stepper-step step="4" :color="e6 <= 4 ? 'blue-grey' : 'green'">
                  Backup-Codes
                  <!--                                <small>Diese benötigen Sie nur im Notfall um sich ohne Authentikator App zu authentifizieren.</small>-->
                </v-stepper-step>

                <v-stepper-content step="4" class="pb-2">
                  <v-card color="white" class="mb-12 elevation-6 ma-6">
                    <div class="pa-4">
                      <p class="body-2">Sie können diese Codes nutzen um Ihren Account
                        wiederherzustellen, bitte sichern Sie diese. <strong class="info--text">Bitte
                          geben und zeigen Sie diese Codes niemals Dritten.</strong></p>
                      <div class="flex text-center body-2 my-4 mx-0 pa-4 elevation-0 blue-grey lighten-5">
                        <div class="layout-column font-weight-black mb-4" v-if="model">
                          <!--                                                    <span v-if="model">backup_codes: {{model.backup_codes}}</span>-->
                          <div class="layout-row" v-for="(item, index) in model.backup_codes"
                               :key="index">
                            <span class="mr-3">{{ item }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-4">
                      <v-btn :color="compColor.primeColor" :dark="compColor.isInDarkMode"
                             class="text-none white--text" to="/account">Abschließen
                      </v-btn>
                    </div>
                  </v-card>

                </v-stepper-content>
              </v-stepper>
            </div>
          </v-container>
        </form>
      </div>
    </div>

    <!--        </ValidationObserver>-->
  </div>
</template>

<script>
import router from '../../../router'

export default {
  name: 'TwofactorEditComponent',
  props: {
    onOff: {
      type: String
    },
  },
  created() {
  },
  mounted() {

  },
  components: {},
  computed: {},
  watch: {
    // isChangable(){
    //     this.calculateRowHeight()
    // }
  },
  methods: {
    createSecret() {
      this.e6 = 2;
      this.$http.post(process.env.VUE_APP_BASE_AUTH + '/users/2fa')
        .then(res => {
          if (res.data) {
            this.model = res.data;
            if (typeof res.data.backup_codes !== 'undefined') {
              this.backup_codes = res.data.backup_codes
            }
          }
        }).catch(this.NotifyError)
    },
    deactivate2FA() {
      this.e6 = 4;
      let data = {
        "passcode": this.deact_confirmation_code
      };

      this.$http.post(process.env.VUE_APP_BASE_AUTH + '/users/2fa/deactivate', data)
        .then(() => {
          this.authStore.dispatch('updateUser', { is_active_2fa: false });
          router.push({ path: '/account' })
        }).catch(err => {
        this.deact_confirmation_code = null;
        this.NotifyError(err)
      })
    },
    activate2FA() {
      this.e6 = 4;
      let data = {
        "passcode": this.confirmation_code
      };

      this.$http.post(process.env.VUE_APP_BASE_AUTH + '/users/2fa/activate', data)
        .then(() => {
          this.authStore.dispatch('updateUser', { is_active_2fa: true });
        }).catch(err => {
        this.failedCode = false;
        this.confirmation_code = null;
        this.e6 = 3;
        this.NotifyError(err)
      })
    }
  },
  data() {
    return {
      backup_codes: null,
      failedCode: true,
      loading: false,
      e6: 1,
      confirmation_code: null,
      deact_confirmation_code: null,
      model: null
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">


</style>
