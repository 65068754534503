<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="back()"
                  icon="mdi-chevron-left"
                  :hide-label="true"
                  tooltip="zurück zur Übersicht"
                  :label="'Zurück'"></lx-btn>
<!--          <lx-btn :color="'#353a47'"-->
<!--                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"-->
<!--                  small-->
<!--                  iconSize="20"-->
<!--                  width="auto"-->
<!--                  :disabled="isLoadingData > 0"-->
<!--                  classes="text-none mr-1"-->
<!--                  @click="getData()"-->
<!--                  icon="refresh"-->
<!--                  :hide-label="true"-->
<!--                  tooltip="Ansicht aktualisieren"-->
<!--                  :label="'Aktualisieren'"></lx-btn>-->
          <lx-dropdown-button :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  hide-list-icons
                  iconSize="20"
                  width="auto"
                  :disabled="isLoadingData > 0"
                  classes="text-none mr-1 v-btn--tile"
                  icon="refresh"
                  tooltip="Ansicht aktualisieren"
                  :items="refreshItems"
                  @click="refreshWithValue"
                  :label="'Aktualisieren'">
          </lx-dropdown-button>

        </div>

        <div class="flex layout-row">
        </div>

      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px"
             :style="{'background': compColor.lxExpansion.background}">


          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1); overflow-y: auto"
               class="flex layout-column">
            <v-container>
              <v-row no-gutters>
                <v-col>
                  <doughnut-chart
                    v-if="loaded"
                    :chartdata="chartdataOS"
                    :options="optionsOS"/>
                </v-col>
                <v-col>
                  <doughnut-chart
                    v-if="loaded"
                    :chartdata="chartdataWEB"
                    :options="optionsWEB"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <bar-chart
                    v-if="loaded"
                    :chartdata="chartdataFRONTEND"
                    :options="optionsFRONTEND"/>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/charts/BarChart'
import DoughnutChart from '@/components/charts/DoughnutChart'
import LxDropdownButton from '@/components/lx_dropdown_button/LxDropdownButton'

function toChartData(input, label) {
  if (!Array.isArray(input)) return null;

  let labels = [];
  let data = [];
  let bgcolors = [];
  let borders = [];

  input.forEach((x, i) => {
    labels.push(x.n);
    data.push(x.c);

    let rgb = {
      r: Math.floor(((i % 3) / 3) * 64),
      g: Math.floor(((i % 6) / 6) * 128),
      b: Math.floor(((i % 9) / 9) * 196),
      d: 196 + ((i % 4) * 8)
    }

    borders.push(`rgba(${rgb.r + ((i % 2) * 32)}, ${rgb.g + 64}, ${rgb.d - rgb.b}, 1.00`)
    bgcolors.push(`rgba(${rgb.r + ((i % 2) * 32)}, ${rgb.g + 64}, ${rgb.d - rgb.b}, 0.50`)
  })

  return {
    labels: labels,
    datasets: [
      {
        label: label,
        borderColor: borders,
        backgroundColor: bgcolors,
        data: data
      },
    ]
  }
}

export default {
  name: 'StatisticsMetadataDetails',
  components: { DoughnutChart, BarChart, LxDropdownButton },
  data() {
    const basicOptions = {
      responsive: true,
      maintainAspectRatio: false,
    }

    return {
      isLoadingData: 0,
      loaded: false,

      refreshItems: [
        {caption: 'Jeder Zeitraum', value: 0, default: true},
        {caption: 'Letzten 12 Stunden', value: 12},
        {caption: 'Letzten 24 Stunden', value: 24},
        {caption: 'Letzten 48 Stunden', value: 48},
        {caption: 'Letzten 72 Stunden', value: 72},
      ],

      timespan: 0,
      chartdataOS: null,
      chartdataWEB: null,
      chartdataFRONTEND: null,
      optionsOS: {
        ...basicOptions,
        title: {
          display: true,
          text: 'Betriebssysteme'
        }
      },
      optionsWEB: {
        ...basicOptions,
        title: {
          display: true,
          text: 'Webbrowser'
        }
      },
      optionsFRONTEND: {
        ...basicOptions,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        title: {
          display: true,
          text: 'Frontend Versionen'
        }
      },
    }
  },
  async mounted() {
    this.getData()
  },
  methods: {
    back() {
      window.history.back()
    },
    refreshWithValue (event, timespanValue, itemindex) {
      this.loaded = false

      this.$http.get('statistics/data/metadata', {timespan: timespanValue || 0})
          .then((res) => {
            this.chartdataOS = toChartData(res.data.o, 'Betriebssysteme');
            this.chartdataWEB = toChartData(res.data.w, 'Webbrowser');
            this.chartdataFRONTEND = toChartData(res.data.f, 'Frontend Versionen');
          })
          .catch(this.NotifyError)
          .finally(() => {
            this.loaded = true
          })
    },
    getData() {
      this.loaded = false;

      this.$http.get('statistics/data/metadata', {timespan: 0})
        .then((res) => {
          this.chartdataOS = toChartData(res.data.o, 'Betriebssysteme');
          this.chartdataWEB = toChartData(res.data.w, 'Webbrowser');
          this.chartdataFRONTEND = toChartData(res.data.f, 'Frontend Versionen');
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loaded = true
        })
    }
  }
}
</script>

<style scoped>

</style>
