<template>
  <div class="pa-2">
    <div v-for="(item, index) in items"
         :key="index"
         class="px-2 py-2"
         :class="index < items.length - 1 ? 'mb-1' : ''"
         :style="{'background': item.selected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
         style="border-radius: 4px;">
      <div class="layout-row">
        <div class="layout-column layout-align-center-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon
                  :color="compColor.primeColor"
                  @click="getFile(item.name)"
                >cloud_download
                </v-icon>
              </v-btn>
            </template>
            <span>Softwaretool herunterladen</span>
          </v-tooltip>
        </div>
        <v-divider vertical class="mx-2"></v-divider>
        <div class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start">
          <span style="line-height: 16px;"><strong class="mr-3">{{ item.name }}</strong>
              <v-chip :class="isDarkMode ? 'blue-grey':compColor.prime + ' lighten-3'" class="ma-1 font-weight-bold"
                      x-small>v{{ item.version }}</v-chip>
              <v-chip :class="isDarkMode ? 'blue-grey':compColor.prime + ' lighten-3'" class="ma-1 font-weight-bold"
                      x-small>{{ item.platform }}</v-chip>
              <v-chip :class="isDarkMode ? 'blue-grey':compColor.prime + ' lighten-3'" class="ma-1 font-weight-bold"
                      x-small v-if="item.build">{{ item.build }}</v-chip></span>
          <small style="opacity: 0.6;line-height: 15px;">Veröffentlicht am {{
              item.created | formatDate('LLL')
            }}</small>
        </div>
        <div class="layout-column layout-align-center-center mx-2 blue-grey--text text--darken-1">
          <small>{{ Math.round(item.size / 1000) }} kb</small>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DownloadView',
  data() {
    return {
      loading: 0,
      items: []
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    IsBusy() {
      return this.loading > 0
    }
  },
  methods: {
    getData() {
      this.loading++
      this.items = []

      this.$http.get('/downloads/importer')
        .then((res) => {
          if (res && Array.isArray(res.data)) {
            this.items = res.data
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading--
        })
    },
    getFile(filename) {
      if (!filename) return;

      this.$http.getAsBlob('/downloads/importer/' + encodeURIComponent(filename))
        .then((res) => {
          let objectURL = URL.createObjectURL(res.data);
          const hiddenElement = document.createElement('a');
          hiddenElement.style.display = 'none';
          hiddenElement.href = objectURL;
          hiddenElement.setAttribute('download', filename);
          document.body.appendChild(hiddenElement);
          hiddenElement.click();
          document.body.removeChild(hiddenElement);

          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(objectURL);
          }, 100);
        })
        .catch(this.NotifyError)
    }
  }
}
</script>

<style scoped>

</style>
