<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <ValidationObserver ref="HospitalDetailForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">
      <div class="layout-column flex ma-2 elevation-5"
           style="border-radius:4px;position:relative;"
           v-bind:style="{background: compColor.contentFrame}">

        <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1" :class="hoverHeader">
          <div class="layout-row">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="back()"
                    icon="mdi-chevron-left"
                    :hide-label="true"
                    tooltip="zurück zur Übersicht"
                    :label="'Zurück'"></lx-btn>

<!--            <lx-btn :color="'#353a47'"-->
<!--                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"-->
<!--                    small-->
<!--                    :disabled="isDirty"-->
<!--                    iconSize="20"-->
<!--                    width="auto"-->
<!--                    classes="text-none mr-1"-->
<!--                    @click="saveUserData(reset)"-->
<!--                    icon="save"-->
<!--                    tooltip="Änderungen speichern"-->
<!--                    :label="'Speichern'"></lx-btn>-->
<!--            <lx-btn :color="'#353a47'"-->
<!--                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"-->
<!--                    small-->
<!--                    :disabled="isDirty && !hasSelectedDepartementsToAdd && !hasSelectedDepartementsToRemove && !hasSelectedToAdd && !hasSelectedToRemove"-->
<!--                    iconSize="20"-->
<!--                    width="auto"-->
<!--                    classes="text-none mr-1"-->
<!--                    @click="clear(reset)"-->
<!--                    icon="cancel"-->
<!--                    tooltip="Letzte Änderungen rückgängig machen"-->
<!--                    :label="'Zurücksetzen'"></lx-btn>-->
          </div>
          <div class="flex layout-row"></div>
        </div>

        <v-divider v-if="!hoverHeader" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

        <div ref="scrollContainer" style="overflow-y: auto;" class="flex pa-1 layout-column">
          <lx-expansion-panels :single-open="false" v-model="panel">
            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-account"
                                         main-headline="Benutzerdaten"
                                         sub-headline="Allgemeine Daten eines Nutzers">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2"
                       v-if="authUser.auth_user_id === model.auth_user_id">
                    <v-alert
                      border="left"
                      prominent
                      style="font-size:15px;"
                      class="pa-3"
                      v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
                      <template slot="prepend">
                        <v-avatar>
                          <v-icon large>mdi-information-variant</v-icon>
                        </v-avatar>
                      </template>
                      Bei dem ausgewählten Nutzer handelt es sich um <strong
                      style="text-decoration: underline;">SIE</strong> selbst. Um
                      Komplikationen zu vermeiden können Sie sich <strong
                      style="text-decoration: underline;">NICHT</strong>
                      selber deaktivieren, sowie sich <strong
                      style="text-decoration: underline;">NICHT</strong> die Administrator-Rolle entfernen.
                    </v-alert>
                  </div>
                  <div class="flex-gt-sm-50 flex-100 pa-2">
                    <v-text-field
                      :dark="compColor.isInDarkMode"
                      label="Anrede"
                      :color="compColor.primeColor"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      :value="salutations[model.salutation]"
                      disabled
                      outlined
                      class="mb-0"
                      :dense="denseFields"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">mdi-gender-male-female</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                  <div class="flex-gt-sm-50 flex-100 pa-2">
                    <v-text-field
                      :dark="compColor.isInDarkMode"
                      :color="compColor.primeColor"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      class="mb-0"
                      label="Titel"
                      v-model="model.title"
                      disabled
                      outlined
                      :dense="denseFields"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">account_balance</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                  <div class="flex-gt-sm-50 flex-100 pa-2">
                    <v-text-field
                      :dark="compColor.isInDarkMode"
                      :color="compColor.primeColor"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      class="mb-0"
                      label="Vorname"
                      v-model="model.first_name"
                      disabled
                      outlined
                      :dense="denseFields"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">person</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                  <div class="flex-gt-sm-50 flex-100 pa-2">
                    <v-text-field
                      :dark="compColor.isInDarkMode"
                      :color="compColor.primeColor"
                      label="Nachname"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      class="mb-0"
                      v-model="model.last_name"
                      disabled
                      outlined
                      :dense="denseFields"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">person</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                  <div class="flex-gt-sm-50 flex-100 pa-2">
                    <v-text-field
                      :dark="compColor.isInDarkMode"
                      :color="compColor.primeColor"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      class="mb-0"
                      label="Benutzername"
                      v-model="model.username"
                      disabled
                      outlined
                      :dense="denseFields"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">mdi-account-circle</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                  <div class="flex-gt-sm-50 flex-100 pa-2">
                    <v-text-field
                      :dark="compColor.isInDarkMode"
                      :color="compColor.primeColor"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      class="mb-0"
                      label="E-Mail"
                      v-model="model.email"
                      disabled
                      outlined
                      :dense="denseFields"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">email</v-icon>
                      </template>
                    </v-text-field>
                  </div>

                  <div class="flex-100 pa-2" v-if="!isPharmacy">
                    <v-alert
                      border="left"
                      prominent
                      style="font-size:15px;"
                      class="px-3 py-2 mb-0"
                      v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
                      <template slot="prepend">
                        <v-avatar>
                          <v-icon large>mdi-information-variant</v-icon>
                        </v-avatar>
                      </template>
                      Durch das Deaktivieren des Nutzers kann sich dieser nicht mehr am MediShop anmelden. Dies gilt in allen Standorten zu welcher der Benutzer hinzugefügt wurde.
                    </v-alert>
                  </div>

                  <div class="flex-100 pa-2">
                    <fake-input-frame label="Nutzeraktivierung" :disabled="authUser.auth_user_id === model.auth_user_id"
                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                      class="mb-6">
                      <div class="layout-row layout-align-start-center px-4 mt-n2"
                           :style="{height: denseFields ? '40px':'50px'}">
                        <v-switch
                          :dark="compColor.isInDarkMode"
                          :dense="denseFields"
                          :color="compColor.primeColor"
                          :disabled="authUser.auth_user_id === model.auth_user_id"
                          v-model="userActiveValue"
                          @click="toggleUserActivation"
                          inset
                          class="ma-0"
                          hide-details
                          name="is_active">
                          <template v-slot:label>
                            Ist aktiv <small class="ml-2" v-if="authUser.auth_user_id === model.auth_user_id"
                                             v-bind:style="{color: compColor.primeColor}">(nicht änderbar)</small>
                          </template>
                        </v-switch>
                      </div>
                    </fake-input-frame>
                  </div>
                </div>

              </lx-expansion-panel-content>
            </lx-expansion-panel>
            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-account-group"
                                         main-headline="Benutzerrollen"
                                         sub-headline="Weisen sie dem  aktuellen Nutzer seine Rollen zu">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2">
                    <div class="layout-row">
                      <fake-input-frame :label="'Zugeordnete Benutzerrollen (' + addedRoles.length + ')'"
                                        class="flex layout-column"
                                        :input-background="isDarkMode ? 'transparent':'#f0f0f0'">
                        <div class="layout-column pa-2">
                          <div v-for="(items, index) in addedRoles"
                               :key="index"
                               class="px-2 py-1"
                               :class="index < addedRoles.length - 1 ? 'mb-1' : ''"
                               :style="{'background': items.selected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
                               style="border-radius: 4px;">
                            <div class="layout-row">
                              <div class="pa-2 pl-1 layout-column layout-align-center-center">
                                <v-simple-checkbox :ripple="false"
                                                   :dark="isDarkMode"
                                                   :color="compColor.prime"
                                                   v-model="items.selected"></v-simple-checkbox>
                              </div>
                              <v-divider vertical class="mr-2"></v-divider>
                              <div class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start">
                                <span style="line-height: 16px;"><strong>{{ items.name }}</strong></span>
                              </div>
                              <div class="layout-column layout-align-center-center mx-1 ml-2 mr-2" v-if="items.unsaved">
                                <div class="py-1 px-1 font-weight-bold amber"
                                     style="font-size:9px;line-height: 14px;border-radius:3px;">
                                  unsaved
                                </div>
                              </div>
                              <div class="layout-column layout-align-center-center ml-2">
                                <v-icon
                                  :color="items.selected ? compColor.prime + ' lighten-3': (isDarkMode ? 'blue-grey darken-3' : 'blue-grey lighten-3')"
                                  size="25">mdi-account-supervisor-circle
                                </v-icon>
                              </div>
                            </div>
                          </div>

                          <!--                          <div class="layout-column" style="width:100%;"-->
                          <!--                               v-if="authUser.auth_user_id === model.auth_user_id">-->


                          <div v-if="notEditable.length > 0">

                            <v-divider class="my-3" v-if="addedRoles.length > 0"></v-divider>

                            <div v-for="(items, index) in notEditable"
                                 :key="index"
                                 class="px-2 py-1"
                                 :class="index < notEditable.length - 1 ? 'mb-1' : ''"
                                 :style="{'background': items.selected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
                                 style="border-radius: 4px;opacity:0.55;border:1px dashed #9faaaf;">
                              <div class="layout-row">
                                <div class="pa-2 pl-7 layout-column layout-align-center-center">

                                </div>
                                <v-divider vertical class="mr-2"></v-divider>
                                <div
                                  class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start">
                                  <span style="line-height: 16px;"><strong>{{ items }}</strong></span>
                                </div>
                                <div class="layout-column layout-align-center-center mx-1 ml-2 mr-2">
                                  <small class="ml-2" v-bind:style="{color: compColor.primeColor}">(nicht
                                    änderbar)</small>
                                </div>
                                <div class="layout-column layout-align-center-center mx-1 ml-2 mr-2"
                                     v-if="items.unsaved">
                                  <div class="py-1 px-1 font-weight-bold amber"
                                       style="font-size:9px;line-height: 14px;border-radius:3px;">
                                    unsaved
                                  </div>
                                </div>
                                <div class="layout-column layout-align-center-center ml-2">
                                  <v-icon
                                    :color="items.selected ? compColor.prime + ' lighten-3': (isDarkMode ? 'blue-grey darken-3' : 'blue-grey lighten-3')"
                                    size="25">mdi-account-supervisor-circle
                                  </v-icon>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--                        </div>-->
                      </fake-input-frame>
                      <div class="pa-5 layout-column layout-align-center-center">
                        <lx-btn :color="'#353a47'"
                                :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                                small
                                :disabled="!hasSelectedToAdd"
                                iconSize="20"
                                width="auto"
                                classes="text-none my-1"
                                @click="addRolesToUser"
                                icon="mdi-arrow-left-bold"
                                :hide-label="true"
                                tooltip="Rolle dem aktuellen Mitarbeiter zuweisen"></lx-btn>
                        <lx-btn :color="'#353a47'"
                                :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                                small
                                :disabled="!hasSelectedToRemove"
                                iconSize="20"
                                width="auto"
                                classes="text-none my-1"
                                @click="removeRolesFromUser"
                                icon="mdi-arrow-right-bold"
                                :hide-label="true"
                                tooltip="Rolle vom aktuellen Mitarbeiter entfernen"></lx-btn>
                      </div>
                      <fake-input-frame :label="'Mögliche Benutzerrollen (' + allRoles.length + ')'"
                                        class="flex layout-column"
                                        :input-background="isDarkMode ? 'transparent':'#f5f5f5'">
                        <div class="layout-column pa-2">
                          <div v-for="(items, index) in allRoles"
                               :key="index"
                               class="px-2 py-1"
                               :class="index < allRoles.length - 1 ? 'mb-1' : ''"
                               :style="{'background': items.selected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
                               style="border-radius: 4px;">
                            <div class="layout-row">
                              <div class="pa-2 pl-1 layout-column layout-align-center-center">
                                <v-simple-checkbox :ripple="false"
                                                   :dark="isDarkMode"
                                                   :color="compColor.prime"
                                                   v-model="items.selected"></v-simple-checkbox>
                              </div>
                              <v-divider vertical class="mr-2"></v-divider>
                              <div class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start">
                                <span style="line-height: 16px;"><strong>{{ items.name }}</strong></span>
                              </div>
                              <div class="layout-column layout-align-center-center mx-1 ml-2 mr-2" v-if="items.unsaved">
                                <div class="py-1 px-1 font-weight-bold amber"
                                     style="font-size:9px;line-height: 14px;border-radius:3px;">
                                  unsaved
                                </div>
                              </div>
                              <div class="layout-column layout-align-center-center ml-2">
                                <v-icon
                                  :color="items.selected ? compColor.prime + ' lighten-3': (isDarkMode ? 'blue-grey darken-3' : 'blue-grey lighten-3')"
                                  size="25">mdi-account-supervisor-circle
                                </v-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fake-input-frame>
                    </div>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
            <lx-expansion-panel v-if="!isPharmacy">
              <lx-expansion-panel-header icon="mdi-hospital-building"
                                         main-headline="Abteilungen"
                                         sub-headline="Weisen sie dem  aktuellen Nutzer seine Abteilungen zu">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2">
                    <div class="layout-row">
                      <fake-input-frame :label="'Zugeordnete Abteilungen (' + addedDepartments.length + ')'"
                                        class="flex layout-column"
                                        :input-background="isDarkMode ? 'transparent':'#f0f0f0'">
                        <div class="layout-column pa-2">

                          <div class="pb-1">
                            <v-tooltip bottom open-delay="550">
                              <template v-slot:activator="{ on }">
                                <v-btn :color="'#353a47'"
                                       v-on="on"
                                       block
                                       :height="denseFields ? '36' : '40'"
                                       elevation="0"
                                       class="white--text text-none"
                                       @click="selectAllEntries('addedDepartments')">
                                  <template #default>
                                    <div class="layout-row py-4" style="width:100%;">
                                      <div class="layout-column layout-align-start-center px-1">
                                        <v-icon
                                          :color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor">
                                          {{ allAddedDepartmentsAreSelected ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked-outline' }}
                                        </v-icon>
                                      </div>
                                      <div class="flex layout-row layout-align-start-center pl-5">
                                        {{ allAddedDepartmentsAreSelected ? 'Alle abwählen' : 'Alle auswählen' }}
                                      </div>
                                    </div>
                                  </template>
                                </v-btn>
                              </template>
                              <span>Mitarbeiter zum hinfügen für die Station auswählen</span>
                            </v-tooltip>
                          </div>

                          <v-divider class="mb-2 mt-1"></v-divider>

                          <div v-for="(items, index) in addedDepartments"
                               :key="index"
                               class="px-2 py-1"
                               :class="index < addedDepartments.length - 1 ? 'mb-1' : ''"
                               :style="{'background': items.selected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
                               style="border-radius: 4px;">
                            <div class="layout-row">
                              <div class="pa-2 pl-1 layout-column layout-align-center-center">
                                <v-simple-checkbox :ripple="false"
                                                   :dark="isDarkMode"
                                                   :color="compColor.prime"
                                                   v-model="items.selected"></v-simple-checkbox>
                              </div>
                              <v-divider vertical class="mr-2"></v-divider>
                              <div class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start">
                                <span style="line-height: 14px;"><strong>{{ items.name }}</strong></span>
                              </div>
                              <div class="layout-column layout-align-center-center mx-1 ml-2 mr-2" v-if="items.unsaved">
                                <div class="py-1 px-1 font-weight-bold amber"
                                     style="font-size:9px;line-height: 14px;border-radius:3px;">
                                  unsaved
                                </div>
                              </div>
                              <div class="layout-column layout-align-center-center ml-2">
                                <v-icon
                                  :color="items.selected ? compColor.prime + ' lighten-3': (isDarkMode ? 'blue-grey darken-3' : 'blue-grey lighten-3')"
                                  size="25">mdi-home-circle
                                </v-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fake-input-frame>
                      <div class="pa-5 layout-column layout-align-center-center">
                        <lx-btn :color="'#353a47'"
                                :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                                small
                                :disabled="!hasSelectedDepartementsToAdd"
                                iconSize="20"
                                width="auto"
                                classes="text-none my-1"
                                @click="addDepartmentToUser"
                                icon="mdi-arrow-left-bold"
                                :hide-label="true"
                                tooltip="Mitarbeiter in Station übernehmen"></lx-btn>
                        <lx-btn :color="'#353a47'"
                                :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                                small
                                :disabled="!hasSelectedDepartementsToRemove"
                                iconSize="20"
                                width="auto"
                                classes="text-none my-1"
                                @click="removeDepartmentFromUser"
                                icon="mdi-arrow-right-bold"
                                :hide-label="true"
                                tooltip="Mitarbeiter aus Station entfernen"></lx-btn>
                      </div>
                      <fake-input-frame :label="'Mögliche Abteilungen (' + allDepartments.length + ')'"
                                        class="flex layout-column"
                                        :input-background="isDarkMode ? 'transparent':'#f5f5f5'">
                        <div class="layout-column pa-2">

                          <div class="pb-1">
                            <v-tooltip bottom open-delay="550">
                              <template v-slot:activator="{ on }">
                                <v-btn :color="'#353a47'"
                                       v-on="on"
                                       block
                                       :height="denseFields ? '36' : '40'"
                                       elevation="0"
                                       class="white--text text-none"
                                       @click="selectAllEntries('allDepartments')">
                                  <template #default>
                                    <div class="layout-row py-4" style="width:100%;">
                                      <div class="layout-column layout-align-start-center px-1">
                                        <v-icon
                                          :color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor">
                                          {{ allDepartmentsAreSelected ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked-outline' }}
                                        </v-icon>
                                      </div>
                                      <div class="flex layout-row layout-align-start-center pl-5">
                                        {{ allDepartmentsAreSelected ? 'Alle abwählen' : 'Alle auswählen' }}
                                      </div>
                                    </div>
                                  </template>
                                </v-btn>
                              </template>
                              <span>Mitarbeiter zum hinfügen für die Station auswählen</span>
                            </v-tooltip>
                          </div>

                          <v-divider class="mb-2 mt-1"></v-divider>

                          <div v-for="(items, index) in allDepartments"
                               :key="index"
                               class="px-2 py-1"
                               :class="index < allDepartments.length - 1 ? 'mb-1' : ''"
                               :style="{'background': items.selected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
                               style="border-radius: 4px;">
                            <div class="layout-row">
                              <div class="pa-2 pl-1 layout-column layout-align-center-center">
                                <v-simple-checkbox :ripple="false"
                                                   :dark="isDarkMode"
                                                   :color="compColor.prime"
                                                   v-model="items.selected"></v-simple-checkbox>
                              </div>
                              <v-divider vertical class="mr-2"></v-divider>
                              <div class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start">
                                <span style="line-height: 16px;"><strong>{{ items.name }}</strong></span>
                              </div>
                              <div class="layout-column layout-align-center-center mx-1 ml-2 mr-2" v-if="items.unsaved">
                                <div class="py-1 px-1 font-weight-bold amber"
                                     style="font-size:9px;line-height: 14px;border-radius:3px;">
                                  unsaved
                                </div>
                              </div>
                              <div class="layout-column layout-align-center-center ml-2">
                                <v-icon
                                  :color="items.selected ? compColor.prime + ' lighten-3': (isDarkMode ? 'blue-grey darken-3' : 'blue-grey lighten-3')"
                                  size="25">mdi-home-circle
                                </v-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fake-input-frame>
                    </div>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
          </lx-expansion-panels>
        </div>
      </div>
    </ValidationObserver>

  </div>
</template>

<script>
import lodash from 'lodash'

function comparer(otherArray) {
  return function (current) {
    return otherArray.filter(function (other) {
      return other._id == current._id
    }).length == 0;
  }
}

export default {
  name: 'UserDetails',
  data() {
    return {
      hoverHeader: false,

      authUser: null,
      notEditable: [],
      isPharmacy: (this.getUserLocationType() === "pharmacy"),
      /** Expansionpanel **/
      panel: [0, 1, 2],
      /** ist im Ladezustand ja/neine -> spinner animation etc. **/
      loading: false,
      /** Userid kommt aus route params wenn id dann User finden wenn nicht dann User neu **/
      userId: null,
      /** Models über die Felder **/
      model: {},
      oldModel: null,
      /** Booleans für Popup bei Fehler und Snackbar bei Erfolg **/
      errorDialog: false,
      userActiveValue: false,
      /** Rechtegruppen Tabellen**/
      addedRoles: [],
      allRoles: [],
      addedDepartments: [],
      allDepartments: [],
      salutations: {
        'mr': "Herr",
        'ms': "Frau",
        'u': "Divers",
      }
    }
  },
  created() {
    this.userId = this.$route.params.userid;
    this.getUserData();
    this.authUser = this.getUser();

    this.$nextTick(() => {
      let scroller = this.$refs.scrollContainer
      scroller.addEventListener('scroll', () => {
        if (scroller.scrollTop > 0) {
          let number = scroller.scrollTop
          number = number > 4 ? 4 : number
          this.hoverHeader = 'elevation-' + number
        } else {
          this.hoverHeader = ''
        }
      })
    })
  },
  methods: {
    selectAllEntries(list) {
      let booleanValue = this[list + 'AreSelected']
      this[list].forEach(item => {
        this.$set(item, 'selected', !booleanValue)
      })
    },
    openCloseExpansionPanel(index) {
      if (this.panel.indexOf(index) < 0) {
        this.panel.push(index)
      } else {
        this.panel.splice(this.panel.indexOf(index), 1)
      }
    },
    /**
     * wird aufgerufen wenn man den zurücksetzen Button drückt.
     * - Ursprungsmodel wird wieder an model übergeben
     * - alle Fehler werden gelöscht
     * - alle Felder werden resetet sind also pristine und NICHT dirty
     *
     * */
    clear() {
      this.model = lodash.cloneDeep(this.oldModel)

      this.addedDepartments.forEach(item => {
        item.selected = false
      })
      this.allDepartments.forEach(item => {
        item.selected = false
      })
      this.addedRoles.forEach(item => {
        item.selected = false
      })
      this.allRoles.forEach(item => {
        item.selected = false
      })
    },

    /**
     * wird aufgerufen, wenn man den zurück Button drückt
     *
     * */
    back() {
      window.history.back()
    },

    /**
     * wird aufgerufen wenn man den speichern Button drückt.
     *
     * 1 Fall neu anlegen (UserID NICHT vorhanden === Neuanlage):
     *    - per axios.post('/users'... Model schicken
     *    - im Erfolgsfall route zu UserDetails erneut aufrufen, diesmal mit erhaltener UserID
     * 2 Fall User updaten (UserID vorhanden === Update):
     *    - per axios.patch('/users/' .. mit UserID und Model wird User upgedatet
     *    - anschließend GetData ausgeführt
     *
     * */
    saveUserData(reset) {
      if (this.loading) {
        return
      }

      this.loading = true;
      // delete this.model.id;

      let data = {
        is_active: this.model.is_active
      };

      this.$http.put('/users/' + this.userId, data)
        .then(() => {
          reset()
          this.getUserData();
          this.oldModel = lodash.clone(this.model)
          this.NotifySystem('Änderungen gespeichert');

        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading = false
        })
    },
    addRolesToUser() {
      if (this.loading) {
        return
      }

      this.loading = true;


      let selected = []
      if (this.allRoles && this.allRoles.length > 0) {
        selected = this.allRoles.filter(item => {
          if (item.selected) return item
        })
      }

      let addables = selected.map((key) => {
        return key.name
      });

      let data = { user: this.model.auth_user_id, roles: addables };

      this.$http.post('/auth/perm/add/roles/user', data)
        .then(() => {
          this.NotifySystem('Änderungen gespeichert');
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading = false;
          this.getUserData()

        })
    },

    removeRolesFromUser() {
      if (this.loading) {
        return
      }

      this.loading = true;

      let selected = []
      if (this.addedRoles && this.addedRoles.length > 0) {
        selected = this.addedRoles.filter(item => {
          if (item.selected) return item
        })
      }

      let removeable = selected.map((key) => {
        return key.name
      });

      let data = { user: this.model.auth_user_id, roles: removeable };
      this.$http.post('/auth/perm/delete/roles/user', data)
        .then(() => {
          this.NotifySystem('Änderungen gespeichert');
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading = false;
          this.getUserData()
        })
    },

    addDepartmentToUser() {
      if (this.loading) {
        return
      }

      this.loading = true;

      let selected = []
      if (this.allDepartments && this.allDepartments.length > 0) {
        selected = this.allDepartments.filter(item => {
          if (item.selected) return item
        })
      }

      let addables = selected.map((key) => {
        return key._id
      });

      let data = { user: this.userId, departments: addables };

      this.$http.put('/users/bulk/add/user/departments', data)
        .then(() => {
          this.NotifySystem('Änderungen gespeichert');
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading = false;
          this.getUserData()

        })

    },

    removeDepartmentFromUser() {
      if (this.loading) {
        return
      }

      this.loading = true;

      let selected = []
      if (this.addedDepartments && this.addedDepartments.length > 0) {
        selected = this.addedDepartments.filter(item => {
          if (item.selected) return item
        })
      }

      let removeable = selected.map((key) => {
        return key._id
      });

      let data = { user: this.userId, departments: removeable };
      this.$http.post('/users/bulk/delete/user/departments', data)
        .then(() => {
          this.NotifySystem('Änderungen gespeichert');
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading = false;
          this.getUserData()
        })
    },
    getUserData() {
      if (this.loading) {
        return
      }

      this.loading = true;

      this.$http.get('/auth/perm/roles') // get all existing roles
        .then(resultRoles => {
          if (resultRoles.data) {
            this.$http.get('/users/id/' + this.userId) // get auth user data
              .then(resultUser => {
                if (resultUser.data) {
                  this.model = resultUser.data.result;
                  this.oldModel = Object.assign({}, this.model);
                  this.userActiveValue = this.model.is_active

                  if (resultUser.data.departments) {
                    this.addedDepartments = resultUser.data.departments;
                  } else {
                    this.addedDepartments = []
                  }

                  // let query = {}
                  this.$http.get('/department?config={"query": {}}')
                    .then(resDep => {
                      if (resDep.data && resDep.data.data) {
                        let arr1 = resDep.data.data;
                        let arr2 = resultUser.data.departments;

                        if (arr1 && arr2 && arr1.length > 0 && arr2.length > 0) {
                          var onlyInA = arr1.filter(comparer(arr2));
                          var onlyInB = arr2.filter(comparer(arr1));

                          this.allDepartments = onlyInA.concat(onlyInB);
                        } else if (!arr2 && arr1 && arr1.length > 0) {
                          this.allDepartments = arr1
                        }

                      }
                    })
                    .catch(this.NotifyError)
                    .finally(() => {
                      // this.isLoadingData = false
                    });


                  this.$http.get('/auth/perm/roles/user/' + this.model.auth_user_id) // get roles of selected user
                    .then(resultUserRoles => {
                      if (resultUserRoles.data) {
                        let arr1 = resultRoles.data;
                        let arr2 = resultUserRoles.data;
                        let difference = arr1.filter(x => !arr2.includes(x));
                        let intersection = arr1.filter(x => arr2.includes(x));
                        this.allRoles = difference.map(a => {
                          return { name: a }
                        });
                        this.addedRoles = intersection.map(a => {
                          return { name: a }
                        });

                        //Falls man selber dieser Nutzer ist soll man admin und user rollen nicht entfernen können
                        if (this.authUser.auth_user_id === this.model.auth_user_id) {
                          let notEditable = ['pharmacyadmin', 'users', 'admin'];
                          this.notEditable = intersection.filter(x => notEditable.includes(x));
                          this.addedRoles = intersection.filter(x => !notEditable.includes(x)).map(a => {
                            return { name: a }
                          });
                        } else {
                          let notEditable = ['users'];
                          this.notEditable = intersection.filter(x => notEditable.includes(x));
                          this.addedRoles = intersection.filter(x => !notEditable.includes(x)).map(a => {
                            return { name: a }
                          });
                        }


                      } else {
                        let arr1 = resultRoles.data;
                        // this.allRoles = arr1.map(a => {
                        //     return {name: a}
                        // });
                        let notEditable = ['users'];
                        this.notEditable = arr1.filter(x => notEditable.includes(x));
                        this.allRoles = arr1.filter(x => !notEditable.includes(x)).map(a => {
                          return { name: a }
                        });
                      }

                      this.loading = false
                    })
                    .catch(this.NotifyError)
                    .finally(() => {
                      this.loading = false
                    })
                }
              })
              .catch(this.NotifyError)
              .finally(() => {
                this.loading = false
              })
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading = false
        })
    },
    saveActivation(state) {
      let data = {
        is_active: state === true
      }

      this.$http.put('/users/' + this.userId, data)
        .then(() => {
          this.getUserData();
          this.oldModel = lodash.clone(this.model)
          this.NotifySystem('Änderungen gespeichert')

        })
        .catch(this.NotifyError)
    },
    toggleUserActivation() {
      if (this.model.is_active === true) {
        this.DialogConfirm(
          'Benutzerdeaktivierung',
          this.isPharmacy ? 'Möchten Sie wirklich den Benutzer deaktivieren?':'Möchten Sie wirklich den Benutzer für alle Standorte deaktivieren?',
          { ok: 'Deaktivieren', cancel: this.$t('buttons.cancel.title') })
          .then(() => {
            try {
              this.saveActivation(false)
            } catch (e) {
              console.error(e)
            }
          })
          .catch(() => {
            this.$set(this, 'userActiveValue', true)
          })
      } else {
        this.saveActivation(true)
      }
    }
  },
  computed: {
    allDepartmentsAreSelected() {
      let selected = this.allDepartments.filter(item => {
        if (item.selected) return item
      })
      return selected.length === this.allDepartments.length
    },
    allAddedDepartmentsAreSelected() {
      let selected = this.addedDepartments.filter(item => {
        if (item.selected) return item
      })
      return selected.length === this.addedDepartments.length
    },
    isDirty() {
      if (!this.oldModel) {
        return false
      }
      return lodash.isEqual(this.oldModel, this.model)
    },
    hasSelectedToAdd() {
      let selected = this.allRoles.filter(item => {
        if (item.selected) return item
      })
      return selected.length > 0
    },
    hasSelectedToRemove() {
      let selected = this.addedRoles.filter(item => {
        if (item.selected) return item
      })
      return selected.length > 0
    },
    hasSelectedDepartementsToAdd() {
      let selected = this.allDepartments.filter(item => {
        if (item.selected) return item
      })
      return selected.length > 0
    },
    hasSelectedDepartementsToRemove() {
      let selected = this.addedDepartments.filter(item => {
        if (item.selected) return item
      })
      return selected.length > 0
    },
  },
}
</script>

/*
// watch:{
//   'model.is_active'(newVal, oldVal){
//     console.log(newVal, oldVal)
//     if (oldVal === true && newVal === false) {
//       this.DialogConfirm(
//         'Benutzerdeaktivierung',
//         'Möchten Sie wirklich den Benutzer deaktivieren?',
//         { ok: 'Deaktivieren', cancel: this.$t('buttons.cancel.title') })
//         .then(() => {
//           try {
//             this.saveActivation(false)
//           } catch (e) {
//             console.error(e)
//           }
//         })
//         .catch(() => {
//           this.model.is_active = true
//         })
//     } else {
//       if (oldVal === false && newVal === true) {
//         this.saveActivation(true)
//       }
//     }
//   }
// }*/
